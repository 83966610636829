import { formatDecimal } from 'utils'

const OriginationOfferCompanyOffer = ({ company }) => {
  return (
    <div className='section-nar origination-detail-offer origination-offer-offer'>
      <div className='box orginiation-order-box not-logged-in offer'>
        <div className='offer'>DEAL SUMMARY</div>
        <div className='price-total'>{company && formatDecimal(company.totalPrice)}</div>
        <div className='share-total'>{company && formatDecimal(company.shares, false, 0)} shares</div>
        <div className='share'>Price Per Share: <span>{company && formatDecimal(company.sharePrice)}</span></div>
      </div>
    </div>
  )
}

export default OriginationOfferCompanyOffer
