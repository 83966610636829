import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ wallet }) => ({
  uphold: wallet.uphold
})

const requiredActions = ['disconnectUphold', 'connectUphold', 'getWalletPage']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
