import { useEffect, useState } from 'react'
import connected from '../../containers/WireInstruction'
import parse from 'html-react-parser'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading } from 'components'

const WireInstruction = ({ actions, pageDetail }) => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    actions.getWireInstruction()
      .then(() => {
        setLoading(false)
      })
  }, [actions])
  if (loading) return <PageLoading />
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Wire Instruction')} />
      <div className='page-container'>
        <div className='inner-container'>
          <h1 className='page-title'>Wire Instructions</h1>
          <div className='content'>{parse(pageDetail)}</div>
        </div>
      </div>
    </>
  )
}

export default connected(WireInstruction)
