import Upload from 'components/Global/Upload/Upload'

const OriginationOfferDocuments = ({ fileUploaded, setFileUploaded, onDelete }) => {
  return (
    <div className='section-full origination-detail-upload-conatiner'>
      <div className='content'>
        <h4>Almost there! Please upload proof that you own these shares and review all documents, then submit your sell order. </h4>
      </div>
      <div className='origination-detail-upload'>
        <div className='origination-detail-upload-copy'>
          <div className='content'>Proof of ownership could be a copy of a share certificate or the section of your employment offer/agreement that shows share details.</div>
          <div className='content'>You can also upload a screenshot from an equity management website (e.g., Carta), showing the account holder name, the company, number of shares vested and number of options exercised and/or vested.</div>
        </div>
        <div className='origination-detail-upload-input'>
          <Upload
            type='OR'
            setFileUploaded={setFileUploaded}
            fileUploaded={fileUploaded}
            onDelete={onDelete}/>
        </div>
      </div>
    </div>
  )
}

export default OriginationOfferDocuments
