import {
  SIGN_UP, RESET_ERR, SIGN_IN, SIGN_OUT, RESET_PASSWORD_EMAIL,
  UPDATE_LOADING, RESET_PASSWORD, GET_CORE_USER, PREFILL_EMAIL, HANDLE401, GET_SIGNIN_MFAS, GET_SIGNUP_REFERRAL, GET_REWARD, GET_BRANCH_DATA, GET_KYC_STATUS
} from 'types/actionTypes'

const INITIAL_STATE = {
  user: {},
  userId: '',
  err: '',
  isSignedIn: false,
  rewardsEnabled: false,
  unicornsEnabled: false,
  branchKey: '',
  errMsg: '',
  resetPassword: 'init',
  prefillEmail: '',
  resetErr: '',
  kycRequired: true,
  mfaRequired: false,
  mfas: [],
  branchData: {},
  isKycVerified: false,
  isReviewPending: false,
  accreditationTerms: ''
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case SIGN_UP:
    if (action.payload.err) {
      return { ...state, ...action.payload }
    }
    return { ...state, user: action.payload.user, err: INITIAL_STATE.err, errMsg: INITIAL_STATE.errMsg }
  case RESET_ERR:
    return { ...state, err: INITIAL_STATE.err, errMsg: INITIAL_STATE.errMsg }
  case SIGN_IN:
    if (action.payload.err) {
      return { ...state, ...action.payload }
    }
    if (window.location.hostname === 'localhost') {
      localStorage.setItem('linqto_token', action.payload.accessToken)
    }
    return { ...state, ...action.payload, isSignedIn: true, err: INITIAL_STATE.err, errMsg: INITIAL_STATE.errMsg }
  case GET_SIGNIN_MFAS:
    return { ...state, ...action.payload }
  case SIGN_OUT:
    return { ...state, ...INITIAL_STATE }
  case UPDATE_LOADING:
    return { ...state, ...action.payload }
  case RESET_PASSWORD_EMAIL:
    return { ...state, ...action.payload }
  case RESET_PASSWORD:
    return { ...state, ...action.payload }
  case GET_CORE_USER:
    return { ...state, ...action.payload }
  case PREFILL_EMAIL:
    return { ...state, ...action.payload }
  case HANDLE401:
    return { ...state, ...INITIAL_STATE }
  case GET_SIGNUP_REFERRAL:
    return { ...state, ...action.payload }
  case GET_REWARD:
    return { ...state, ...action.payload }
  case GET_KYC_STATUS:
    return { ...state, ...action.payload }
  case GET_BRANCH_DATA:
    return { ...state, ...action.payload }
  default:
    return state
  }
}
