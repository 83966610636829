import { useEffect, useState } from 'react'
import Button from '../../Global/Button'
import connected from '../../../containers/AutoVerification'
import history from '../../../history'
import Selfie from './Selfie'
import IdentityConfirm from './IdentityConfirm'
import MicroBlink from './MicroBlink'
import ReactGA from 'react-ga'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { images } from 'assets'
import { useQuery } from 'hooks/useQuery'
import * as BlinkIDSDK from '@microblink/blinkid-in-browser-sdk'

const AutoVerification = (props) => {
  const [type, setType] = useState('')
  const [steps, setSteps] = useState('')
  const [microBlinkError, setMicroBlinkError] = useState(false)
  const query = useQuery()

  useEffect(() => {
    const step = props.match.params.steps
    const prevType = query.get('type')
    props.actions.getIdentity()
      .then((res) => {
        if (+step === 1) {
          props.actions.logEvent('/identityVerification/step1/idView')
        }
        setSteps(step)
        setType(prevType)
        if (+step !== 1) {
          history.push(`/identity/auto-verification/${step}?type=${prevType}`)
        }

        if (steps === '2' && type === '') {
          history.push('/identity')
        }
      })
  }, [])

  useEffect(() => {
    const prevStep = steps - 1
    if (prevStep >= 1) {
      window.onpopstate = () => {
        const prevType = query.get('type')
        setSteps(prevStep)
        if (prevStep !== 1) {
          history.push(`/identity/auto-verification/${prevStep}?type=${prevType}`)
        }
      }
    }
  })

  useEffect(() => {
    if (+steps === 1) {
      const data = ['Driver\'s License', 'Passport']
      props.actions.logEvent('/identityVerification/step1/idView', { ids: data })
    }
    if (+steps === 2) {
      props.actions.logEvent('/identityVerification/step2/microBlinkView')
    }
    if (+steps === 3) {
      props.actions.logEvent('/identityVerification/step3/selfieView')
    }
    if (+steps === 4) {
      props.actions.logEvent('/identityVerification/step4/confirmView')
    }
  }, [steps])

  const handleFinishScan = (result) => {
    props.actions.saveIdScanData(result)
      .then(() => {
        props.actions.getIdentity()
        setSteps(3)
        history.push(`/identity/auto-verification/${3}?type=${type}`)
      })
  }

  const handleConfirm = () => {
    props.actions.logEvent('/identityVerification/step4/confirmed')
    props.actions.confirmKycStatusVerified()
      .then((res) => {
        if (res === 'success') {
          ReactGA.ga('send', {
            hitType: 'event',
            eventCategory: 'Lifecycle Event',
            eventAction: 'IDENTITY_VERIFIED',
            eventLabel: 'User confirmed KYC information'
          })
          sessionStorage.setItem('identityVerified', true)
          history.push('/profile')
        }
      })
  }

  const handleRestart = () => {
    props.actions.logEvent('/identityVerification/step4/restart')
    props.actions.restartKycProcess()
      .then(() => {
        props.actions.getIdentity()
        setSteps(1)
        history.push(`/identity/auto-verification/${1}?type=${type}`)
      })
  }

  const handleVerifySuccess = () => {
    props.actions.getIdentity()
      .then(() => {
        setSteps(4)
        history.push(`/identity/auto-verification/${4}?type=${type}`)
      })
  }

  const setIdType = (type) => {
    setType(type)
    setSteps(2)
    history.push(`/identity/auto-verification/${2}?type=${type}`)
    props.actions.logEvent('/identityVerification/step1/pickedIdType', { id: type === 'DL' ? 'Driver\'s License' : 'Passport' })
  }

  const manualUpload = () => {
    history.push('/identity/manual-verification')
    props.actions.logEvent('/identityVerification/step2.5/manualUploadOptionSelected')
  }

  const emailShare = () => {
    const shareTxt = 'I\'m a non-US investor without a passport. How can I complete ID verification for KYC/AML?'
    const mailTo = 'invest@linqto.com'
    const shareTitle = 'ID Verification Support Needed'
    const url = `mailto:${mailTo}?subject=${shareTitle}&body=${shareTxt}`
    window.open(url, '_blank')
  }

  const renderContent = () => {
    const isSupportedBrowser = BlinkIDSDK.isBrowserSupported()
    const confirmData = { ...props }
    switch (steps.toString()) {
    case '1':
      return (
        <>
          <div className='info-title sub-title'>Select one document to use. (Outside of USA must use passport).</div>
          <div className='ID-group'>
            <div className='item content' onClick={() => setIdType('DL')}>
              <div className='item-img'><img alt='Driver’s License' src={images.DL}/></div>
              <div className='item-text'>Driver’s License <span>(US Only)</span></div>
            </div>
            <div className='item content' onClick={() => setIdType('Passport')}>
              <div className='item-img'><img alt='Passport' src={images.passport}/></div>
              <div className='item-text'>Passport</div>
            </div>
          </div>
          <div className='ID-group-footer'>Are you a non-US investor and you do not have a passport? Contact our <a onClick={() => emailShare()}>Support Team</a></div>
        </>
      )
    case '2': {
      if (isSupportedBrowser) {
        return <div>
          {!microBlinkError && <div className='info-title sub-title'>Tap on the camera icon and take a photo of your identification document.</div>}
          <MicroBlink type={type} manualUpload={manualUpload} setMicroBlinkError={setMicroBlinkError} handleFinishScan={handleFinishScan} saveIdScanData={props.actions.saveIdScanData} microblinkLicenseKey={props.microblinkLicenseKey} logEvent={props.actions.logEvent}/>
        </div>
      } else {
        return <div className='auto-verification-error'>
          <div className='content'>Something went wrong. Your device or browser may not be supported. You can update your device and try again.</div>
          <div className='content'>You can also manually upload your identifying documents.</div>
          <Button size='md' mode='secondary' onClick={() => manualUpload() }>Manual Upload</Button>
        </div>
      }
    }
    case '3':
      return <Selfie handleRestart={handleRestart} uploadSelfie={props.actions.uploadSelfie} handleVerifySuccess={handleVerifySuccess} getIdentity={() => props.actions.getIdentity()} logEvent={props.actions.logEvent} />
    case '4':
      return <IdentityConfirm getIdentity={props.actions.getIdentity} handleConfirm={handleConfirm} handleRestart={handleRestart} confirmData={confirmData} type={type}/>
    default: return ''
    }
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Verify Identity')} />
      <div className='page-container'>
        <div className='inner-container'>
          <h1 className='page-title'>Verify Identity</h1>
          {renderContent()}
        </div>
      </div>
    </>
  )
}

export default connected(AutoVerification)
