import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ common }) => ({
  faqs: common.faqs,
  title: common.title
})

const requiredActions = ['getFAQLinks']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)
export default connected
