import React from 'react'
import Modal from '../Global/Modal'
import connect from '../../containers/MfaModal'
import history from '../../history'
import Button from '../Global/Button'
import Input from '../Global/InputField'
import { PageLoading } from 'components'
import _ from 'lodash'
const typeName = {
  TOTP: 'Authenticator App',
  SMS: 'SMS Text Message',
  EMAIL: 'Email Authentication'
}

let interval

class MfaModal extends React.Component {
  state = {
    currentMfa: {},
    value: '',
    loading: false,
    formErr: '',
    userMfaId: '',
    sentTimeStamp: 0,
    currentTime: 0,
    sendErr: '',
    pageLoading: false,
    touched: false
  }

  handleChooseMfa = (m) => {
    this.setState({ currentMfa: m })
    history.push(`/signin/mfas/${m.type.toLowerCase()}/${m.userMfaId}`)
    if (m.type === 'EMAIL' || m.type === 'SMS') {
      this.setState({ pageLoading: true })
      this.props.actions.sendSignInMfa(m.userMfaId)
        .then(res => {
          this.setState({ pageLoading: false })
          if (res && res.err) {
            this.setState({ sendErr: res.err })
          }
        })
    }
  }

  componentDidMount () {
    if (!this.props.isSignedIn) {
      history.push('/signin')
    } else {
      history.push('/signin/mfas')
      this.setState({ pageLoading: true })
      this.props.actions.getSigninMfas()
        .then(() => {
          this.setState({ pageLoading: false })
          const userMfaId = this.props.match.params.userMfaId
          const find = _.find(this.props.mfas, (m) => m.userMfaId === Number(userMfaId))
          this.setState({ currentMfa: find })
        })
    }
  }

  handleSendAgain = () => {
    this.setState({ pageLoading: true })
    clearInterval(interval)
    const date = new Date()
    this.setState({ sentTimeStamp: date.getTime(), currentTime: date.getTime() })
    this.props.actions.sendSignInMfa(this.props.match.params.userMfaId)
      .then(res => {
        this.setState({ pageLoading: false })
        if (res && res.err) {
          this.setState({ sendErr: res.err })
        }
      })
    interval = setInterval(() => {
      const newDate = new Date()
      this.setState({ currentTime: newDate.getTime() })
    }, 1000)
  }

  componentWillUnmount () {
    clearInterval(interval)
  }

  renderResend = () => {
    const seconds = Math.floor((this.state.currentTime - this.state.sentTimeStamp) / 1000)
    if (this.state.sentTimeStamp === 0 || seconds > 30) {
      return (
        <span onClick={() => this.handleSendAgain()}>Click to send again.</span>
      )
    } else if (seconds < 30 || seconds === 30) {
      return (
        <span className='time-counter'>resent {Math.floor((this.state.currentTime - this.state.sentTimeStamp) / 1000)}s ago</span>
      )
    }
  }

  handleChooseOther = () => {
    this.setState({ currentMfa: {}, formErr: '' })
    history.push('/signin/mfas')
  }

  renderContent = () => {
    if (this.state.pageLoading) {
      return <PageLoading className='security-loading'/>
    }
    if (this.state.sendErr.length > 0) {
      return <div className='security mfa'>
        <div className='content' style={{ textAlign: 'center' }}>{this.state.sendErr}</div>
      </div>
    }
    const currentMfa = this.state.currentMfa
    const mfas = this.props.mfas
    if (this.props.match.path === '/signin/mfas' && mfas.length > 1) {
      return (
        <div className='mfa-group'>
          {mfas.map((m) => (
            <div
              className='item'
              key={m.userMfaId}
              onClick={() => this.handleChooseMfa(m)}
            >
              {typeName[m.type]}
            </div>
          ))}
        </div>
      )
    } else if (this.props.match.path === '/signin/mfas' && mfas.length === 1) {
      this.handleChooseMfa(mfas[0])
    } else if (currentMfa.type) {
      return (
        <div className='security mfa mfa-onboarding'>
          <form onSubmit={(e) => this.handleOtpSubmit(e)}>
            <label htmlFor='value'>
              {currentMfa.type === 'TOTP' && 'Please enter the 6-digit one-time password from your Authenticator App'}
              {(currentMfa.type === 'EMAIL' || currentMfa.type === 'SMS') && `Please enter the one-time password sent to ${currentMfa.value}`}
            </label>
            <Input
              type='tel'
              label=''
              input={{ name: 'value', value: this.state.value }}
              meta={{
                touched: this.state.touched,
                error: this.state.formErr
              }}
              onChange={(e) => this.setState({ value: e.target.value, formErr: '' })
              }
              onFocus={() => {
                this.setState({ touched: true })
              }}
              disabled={this.state.loading}
              maxLength='6'
              autoFocus
              autoComplete='one-time-code'
              className='no-label'
            />

            <div className='btn-group centered'>
              <Button
                type='submit'
                mode='primary'
                size='md'
                onClick={(e) => this.handleOtpSubmit(e)}
                disabled={this.state.loading}
                loading={this.state.loading}
              >
                  Confirm
              </Button>
            </div>
          </form>
          {currentMfa.type === 'EMAIL' && <div className='note'>Did not receive the code? {this.renderResend()}</div>}
          {mfas.length > 1 && <div className='note'><span onClick={() => this.handleChooseOther()}>Want to use a different authentication method?</span></div>}
        </div>
      )
    }
  }

  handleOtpSubmit = (e) => {
    e.preventDefault()
    this.setState({ loading: true })
    const userMfaId = this.state.currentMfa.userMfaId
    const otp = this.state.value
    if (userMfaId && otp.length === 6) {
      this.props.actions.verifySignInMfa(userMfaId, otp)
        .then((res) => {
          this.setState({ loading: false })
          if (res === 'success') {
            this.props.hideModal('success')
            localStorage.getItem('prev-route')
            if (localStorage.getItem('prev-route')) {
              history.push(localStorage.getItem('prev-route'))
              localStorage.removeItem('prev-route')
            } else {
              history.push('/')
            }
          }
          if (res.err) {
            this.setState({ formErr: res.err })
          }
        })
    } else if (otp.length < 6 || otp.length > 6) {
      this.setState({ formErr: 'The one-time password should be 6 digits.', loading: false })
    }
  }

  render () {
    const currentMfa = this.state.currentMfa
    return (
      <Modal mode='primary' size='sm' innerStyle='mfa-modal' modalHeader={(currentMfa && currentMfa.type) ? typeName[currentMfa.type] : 'Authentication Method'} hideModal={() => this.props.hideModal()} crossToClose>
        {(this.props.isSignedIn && this.props.mfas) && this.renderContent()}
      </Modal>
    )
  }
}

export default connect(MfaModal)
