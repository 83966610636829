import { images } from 'assets'
import Button from '../../Global/Button'
import history from '../../../history'
import moment from 'moment'
const Feature = ({ securityScorecard }) => {
  const { date, score } = securityScorecard
  return (
    <div className='container feature-container'>
      <div className='inner-container'>
        <div className='hero-header feat-header'>What We Deliver</div>
        <div className='feature-group'>
          <div className='feature'>
            <div className='img-container'>
              <img className='icon' alt='mobility' src={images.landing_mobility}></img>
            </div>
            <div className='right-group'>
              <div className=' feat-title'>Accessibility</div>
              <div className='feat-content'>
                {
                  'Linqto’s digital platform is inclusive, easy-to-use, and fast. We qualify accredited investors and provide access to investments in leading tech companies while they’re still private (pre-IPO).'
                }
              </div>
            </div>
          </div>
          <div className='feature'>
            <div className='img-container'>
              <img
                className='icon'
                alt='Affordability'
                src={images.landing_affordability}
              ></img>
            </div>
            <div className='right-group'>
              <div className=' feat-title'>Affordability</div>
              <div className='feat-content'>
                {
                  'Our private investments are offered at an affordable price. A minimum order is $10,000 with no added fees or hidden costs.'
                }
              </div>
            </div>
          </div>
          <div className='feature'>
            <div className='img-container'>
              <img
                className='icon'
                alt='liquidity'
                src={images.landing_liquidity}
              ></img>
            </div>
            <div className='right-group'>
              <div className=' feat-title'>Liquidity</div>
              <div className='feat-content'>
                {
                  'Linqto provides liquidity in the private tech sector. Founders, early investors, and long-term employees can realize early returns without waiting for IPO or acquisition.'
                }
              </div>
            </div>
          </div>
          <div className='feature'>
            <div className='img-container'>
              <img className='icon' alt='security' src={images.landing_security}></img>
            </div>
            <div className='right-group'>
              <div className='feat-title'>Security</div>
              <div className='feat-content'>
                {
                  `Linqto scores ${score}/100 on security posture and cyber resilience by SecurityScorecard, a global leader in cybersecurity ratings. ${moment(date).format('MMMM DD, YYYY')}`
                }
              </div>
            </div>
          </div>
        </div>
        <div className='btn-group centered'>
          <Button
            mode='primary'
            size='md'
            onClick={() => history.push('/investor-status')}
          >
            Get Accredited
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Feature
