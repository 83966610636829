import Button from '../../Global/Button'
import history from '../../../history'

const LinqtoBanner = () => {
  return (
    <div className='wallet-bucks-container'>
      <div className='wallet-bucks'>
        <div className='wallet-bucks-header'>Earn Linqto Bucks</div>
        <div className='wallet-bucks-body'>Refer friends and they get $250 Linqto Bucks (platform credit). Once they complete their first investment, you earn $750 Linqto Bucks.</div>
        <div className='wallet-bucks-cta'>
          <Button mode='secondary' size='sm' onClick={() => history.push('/rewards')}>Refer Friend to Linqto</Button>
          <div className='wallet-bucks-body wallet-bucks-terms'>Learn more in our <a href='/reward-terms-of-use' className='inline-text-link' target='_blank'>Linqto Bucks Terms and Conditions.</a></div>
        </div>
      </div>
    </div>)
}

export default LinqtoBanner
