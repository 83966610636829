import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ security, user, profile }) => ({
  isVerified: security.isVerified,
  qrCodeUrl: security.qrCodeUrl,
  mfas: security.mfas,
  email: security.email,
  currentMfa: security.currentMfa,
  isSignedIn: user.isSignedIn,
  status: profile.changestatus,
  mfaEnabled: security.mfaEnabled
})
const requiredActions = ['getSecurityPage', 'removeSecurityMfa', 'addSecurityMfa', 'verifySecurityPageMfas', 'sendSecurityPageMfa']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)
export default connected
