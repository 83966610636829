import { useEffect, useState } from 'react'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading } from 'components'
import { Field, reduxForm } from 'redux-form'
import { BrokerInfo as connected } from 'containers'
import SuccessModal from './partials/SuccessModal'
import Button from '../Global/Button'
import _ from 'lodash'
import renderer from './partials/render'

const BrokerInfo = ({ match, status, formValues, actions, initialValues, handleSubmit }) => {
  const [pageLoading, setPageLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [edited, setEdited] = useState(false)
  const [formIntialValues, setFormIntialValues] = useState({})

  const tooltip = {
    brokerAccountName: 'The name on your broker account must match the name on your Linqto account or the name of the entity you invest with on Linqto.',
    brokerDTC: 'Usually the 4 digit DTC# is unique to your broker and can be found on your broker’s website, or they can provide for you upon request.'
  }
  const entityId = match.params.entityId || null

  useEffect(() => {
    setPageLoading(true)
    actions.getBrokerInfo(entityId).then(() => {
      setPageLoading(false)
    })
  }, [actions])

  useEffect(() => {
    if (formValues) {
      if (formValues.initial && (Object.keys(formIntialValues).length === 0 || formIntialValues.accountName === '')) {
        setFormIntialValues(formValues.initial)
      }
      if (_.isEqual(formIntialValues, formValues.values)) {
        setEdited(false)
      } else {
        setEdited(true)
      }
    }
  }, [actions, initialValues, edited, formValues, formIntialValues])

  const parseDTC = (val) => {
    return val.match(/\d/g) ? val.match(/\d+/g).join('') : ''
  }

  const onSubmit = (formValues) => {
    setLoading(true)
    actions
      .submitBrokerInfo({ ...formValues, entityId })
      .then(() => {
        setLoading(false)
        setSubmitSuccess(true)
        setFormIntialValues(formValues)
      })
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Broker Information')} />
        <PageLoading />
      </>)
  }
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Broker Information')} />
      <div className='profile-container page-container'>
        <div className='inner-container'>
          <h1 className='page-title subinfo'>Broker Information</h1>
          <div className='content'>We&apos;ll need this to distribute your shares in the event of an exit.</div>
          <form className='ui grid' onSubmit={handleSubmit(onSubmit)}>
            <div className='ui grid'>
              <div className='eight wide column tablet_eight'>
                <Field
                  name='accountName'
                  component={renderer.renderInput}
                  label='Name on Brokerage Account'
                  type='text'
                  disabled={loading}
                  tooltip={true}
                  maxLength={100}
                  content={tooltip.brokerAccountName}
                />
              </div>
              <div className='eight wide column tablet_eight'>
                <Field
                  name='firmName'
                  component={renderer.renderInput}
                  label='Equity Brokerage Firm Name'
                  placeholder='(ETrade, Charles Schwab, etc.)'
                  type='text'
                  maxLength={100}
                  disabled={loading} />
              </div>
              <div className='eight wide column tablet_eight'>
                <Field
                  name='accountNumber'
                  component={renderer.renderInput}
                  label='Brokerage Account #'
                  type='text'
                  maxLength={100}
                  disabled={loading} />
              </div>
              <div className='eight wide column tablet_eight'>
                <Field
                  name='confirmAccountNumber'
                  component={renderer.renderInput}
                  label='Confirm Account #'
                  type='text'
                  maxLength={100}
                  disabled={loading} />
              </div>
              <div className='eight wide column tablet_eight'>
                <Field
                  name='dtc'
                  component={renderer.renderInput}
                  label='Equity Brokerage 4-Digit DTC#  '
                  type='text'
                  maxLength='4'
                  parse={(value) => parseDTC(value)}
                  disabled={loading}
                  tooltip={true}
                  content={tooltip.brokerDTC}/>
              </div>
              <div className='eight wide column tablet_eight'>
                <Field
                  name='specialInstructions'
                  component={renderer.renderInput}
                  label='Special Instructions: (optional)'
                  type='text'
                  disabled={loading} />
              </div>
              <div className='six wide column tablet_eight'>
                <div className='btn-group'>
                  <Button
                    mode='primary'
                    size='md'
                    type='submit'
                    loading={loading}
                    customClass='full-width'
                    disabled={ loading || !edited }>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      { status === 'success' && submitSuccess && <SuccessModal setSubmitSuccess={setSubmitSuccess} status={status} />}
    </>
  )
}

const validate = (formValues) => {
  const errors = {}
  if (!formValues.accountName) {
    errors.accountName = 'Please enter name on broker account.'
  }
  if (!formValues.firmName) {
    errors.firmName = 'Please enter valid equity brokerage firm name.'
  }
  if (!formValues.accountNumber) {
    errors.accountNumber = 'Please enter valid brokerage account number.'
  }
  if (!formValues.confirmAccountNumber) {
    errors.confirmAccountNumber = 'Account numbers must match.'
  }
  if (formValues.accountNumber !== formValues.confirmAccountNumber) {
    errors.confirmAccountNumber = 'Account numbers must match.'
  }
  if (!formValues.dtc) {
    errors.dtc = 'Please enter valid 4-digit brokerage DTC#'
  }
  if (formValues.dtc && formValues.dtc.length < 4) {
    errors.dtc = 'Please enter valid 4-digit brokerage DTC#'
  }
  return errors
}

const formWrapped = reduxForm({
  form: 'brokerInfoForm',
  validate,
  shouldError: () => true,
  enableReinitialize: true
})(BrokerInfo)

export default connected(formWrapped)
