import { useState, useEffect } from 'react'
import { OrderSection, PageLoading } from 'components'
import { OrderDetail as connected } from 'containers'
import Input from '../Global/InputField'
import Button from '../Global/Button'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'

const OrderDetail = ({ actions, match, location, company, order, documents, message, isSignedIn, history }) => {
  const [msg, setMsg] = useState('')
  const [err, setErr] = useState('')
  const [touched, setTouched] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setPageLoading(true)
    actions.getOrderDetail(match.params.id).then(() => {
      setPageLoading(false)
    })
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault()
    const { retErr, retTouched } = await checkErr()
    setErr(retErr)
    setTouched(retTouched)
    if (retErr === '') {
      setLoading(true)
      actions.messageTrader(msg, order.orderId)
        .then(() => {
          setLoading(false)
          history.push('/contact-trader/success')
        })
    }
  }

  const checkErr = () => {
    let retErr = ''
    let retTouched = false
    if (!msg) {
      retErr = 'Please enter a message.'
      retTouched = true
    }
    return { retErr, retTouched }
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Order Detail')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Order Detail')} />
      <div className='page-container order-detail-container'>
        <div className='inner-container'>
          <OrderSection company={company} documents={documents} order={order} orderId={order.orderId} status={order.status} />
          <div className='contact-form'>
            <div className='header'>Have questions about your order?</div>
            <div className='sub-header'>Contact a Trader</div>
            <form onSubmit={onSubmit} className='ui grid'>
              <div className='sixteen wide column m-sixteen'>
                <Input
                  type='textarea'
                  label='Message'
                  input={{ name: 'message', value: msg }}
                  meta={{
                    touched: touched,
                    error: err
                  }}
                  onChange={(e) => {
                    setTouched(true)
                    setMsg(e.target.value)
                    setErr('')
                  }}
                  onFocus={() => {
                    setTouched(true)
                  }}
                  loading={loading}
                  disabled={loading}
                />
              </div>
              <div className='sixteen wide column m-sixteen'>
                <div className='btn-group start'>
                  <Button size='md' mode='primary' type='submit' loading={loading} disabled={loading}>Send</Button>
                </div>
              </div>
            </form>
            {message === 'failed' && (
              <h3 className='ui header'>
                Something went wrong, try again later
              </h3>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default connected(OrderDetail)
