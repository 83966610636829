const UnicornDetailsOfficors = ({ officers, style }) => {
  return (
    <>
      <div className='ui grid unicorn-table'>
        <div className='four column row unicorn-table-header'>
          <div className='column' style={{ paddingLeft: 0 }}>
              Name
          </div>
          <div className='column'>Work History</div>
          <div className='column'>Title</div>
          <div className='column'>Status</div>
        </div>
        {officers && officers.map((p, i) =>
          <div key={`keyOfficer_${i}`} className='four column row unicorn-table-body'>
            <div className={`column ${style}`}>{p.name}</div>
            <div className={`column ${style}`}>{p.workHistory}</div>
            <div className={`column ${style}`}>{p.title}</div>
            <div className={`column ${style}`}>{p.status}</div>
          </div>
        )}
      </div>
      <div className='unicorn-card-container'>
        {officers && officers.map((p, i) =>
          <div className='unicorn-card' key={`mkeyOfficer_${i}`} >
            <div className='ui grid'>
              <div className='two column row unicorn-card-row'>
                <div className={`column column-bold ${style}`}>{p.name}</div>
                <div className={`column ${style}`}>{p.title} <br/>{p.status && `(${p.status})`}</div>
              </div>
              <div className='one column row unicorn-card-row-line-sepretaor' />
              <div className='one column row unicorn-card-row'>
                <div className={`column column-bold ${style}`}>Work History:</div>
                <div className={`column ${style}`}>{p.workHistory}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default UnicornDetailsOfficors
