
const parseHomePage = ({
  companies, isLoggedIn, hasUpholdWallet, isAccredited, promotedCompany = {
    name: '',
    urlName: '',
    iconUrl: ''
  }, gicDate = '', securityScorecard,
  gicEndDate = ''
}) => ({
  isLoggedIn,
  hasUpholdWallet,
  isAccredited,
  homeProducts: companies ? companies.map(c => ({
    companyId: c.companyId ? c.companyId : '',
    currentSeries: c.currentSeries ? c.currentSeries : '',
    name: c.name ? c.name : '',
    urlName: c.urlName ? c.urlName : '',
    description: c.publicDescription,
    iconUrl: c.s3IconUrl ? c.s3IconUrl : '',
    logoUrl: c.s3LogoUrl ? c.s3LogoUrl : ''
  })) : [],
  promotedCompany: {
    name: promotedCompany.name ? promotedCompany.name : '',
    urlName: promotedCompany.urlName ? promotedCompany.urlName : '',
    iconUrl: promotedCompany.iconUrl ? promotedCompany.iconUrl : ''
  },
  gicDate,
  securityScorecard: {
    score: securityScorecard.score ? securityScorecard.score : 100,
    date: securityScorecard.date ? securityScorecard.date : 1643295600000
  },
  gicEndDate
})

export default parseHomePage
