import React from 'react'
import '../../assets/scss/style.scss'
import PropTypes from 'prop-types'

const Button = ({ mode, size, loading, disabled, customClass, children, ...props }) => {
  return (
    <button className={`${customClass} btn ${mode} ${size}`} {...props} disabled={disabled}>
      {loading ? <div className='loading'></div> : children}
    </button>
  )
}

Button.propTypes = {
  mode: PropTypes.oneOf(['primary', 'secondary', 'special']),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  /**
   * Button contents
   */
  label: PropTypes.string,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Loading
   */
  loading: PropTypes.bool
}

export default Button
