import { BaseModal, Content, Wrapper, Row } from 'components'
import Button from '../Global/Button'
import ReactHtmlParser from 'react-html-parser'

const ErrorModal = ({ twoButtons, error, hideModal, message = '' }) => {
  if (!error.type) return null
  return (
    <BaseModal mode='error' size='md' hideModal={hideModal} modalHeader={error.title}>
      <Wrapper className='error-modal'>
        <Content className='error'>
          <div className='content message'>{ReactHtmlParser(error.message)}</div>
        </Content>
        {twoButtons
          ? <Row className='btn-group two'>
            <Button mode='secondary' size='md' customClass='no-btn' onClick={() => hideModal(false)}>No</Button>
            <Button mode='primary' size='md' onClick={() => hideModal(true)}>Yes</Button>
          </Row>
          : <Row className='btn-group centered'><Button mode='primary' size='md' onClick={hideModal}>{message !== '' ? message : 'Ok'}</Button></Row>}
      </Wrapper>
    </BaseModal>
  )
}

export default ErrorModal
