import UnicornDetailsKeyPartners from './UnicornDetailsKeyPartners'
import UnicornDetailsOfficors from './UnicornDetailsOfficors'

const UnicornDetailsPartners = ({ company, isLoggedIn }) => {
  const blueStyle = !isLoggedIn ? 'blur-info' : ''
  return (
    <>
      {company.partners && company.partners.length > 0 &&
        <div className='unicorn-details-section'>
          <div className='ui grid'>
            <div className='one column row'>
              <div className='column unicorn-details-column'>
                <label className='light'>Key Partners & Customers </label>
                <UnicornDetailsKeyPartners partners={company.partners} style={blueStyle}/>
              </div>
            </div>
          </div>
        </div>
      }
      {company.boardMemebers && company.boardMemebers.length > 0 &&
      <div className='unicorn-details-section'>
        <div className='ui grid'>
          <div className='one column row'>
            <div className='column unicorn-details-column'>
              <label className='light'>Officers & Board of Directors</label>
              {company.boardMemebers && <UnicornDetailsOfficors officers={company.boardMemebers} style={blueStyle} />}
            </div>
          </div>
        </div>
      </div>
      }
    </>
  )
}

export default UnicornDetailsPartners
