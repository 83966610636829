import Modal from 'components/Global/Modal'
import Button from '../Global/Button'

const ReferralError = ({ referralErrorMessage, closeModal }) => {
  return (
    <Modal innerStyle='signup-error' size='sm' modalHeader={referralErrorMessage?.title} mode='primary' crossToClose hideModal={closeModal}>
      <div className='items-center'>
        <p>
          {referralErrorMessage?.message}
        </p>
      </div>
      <div className='btn-group centered'>
        <Button onClick={closeModal} mode='primary'>
          {referralErrorMessage?.actionLabel}
        </Button>
      </div>
    </Modal>
  )
}

export default ReferralError
