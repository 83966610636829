import connected from '../../containers/OriginationOffer'
import { useEffect, useState } from 'react'
import { PageLoading } from 'components'
import SeoMeta from 'components/Global/SeoMeta'
import qs from 'qs'
import OriginationDetailHeader from 'components/OriginationDetail/partials/OriginationDetailHeader'
import OriginationDetailCompanyLogo from 'components/OriginationDetail/partials/OriginationDetailCompanyLogo'
import OriginationOfferCompanyCTA from './partials/OriginationOfferCompanyCTA'
import OriginationOfferCompanyOffer from './partials/OriginationOfferCompanyOffer'
import OriginationOfferDocuments from './partials/OriginationOfferDocuments'
import OriginationFooter from 'components/Origination/partials/OriginationFooter'
import OriginationOfferUploadProof from './partials/OriginationOfferUploadProof'
import OriginationNotAvailableModal from 'components/OriginationDetail/partials/OriginationNotAvailableModal'

import { seoTitleTemplate } from 'utils'

const OriginationOffer = ({ location, actions, offer, history, interestedInBuying }) => {
  const [pageLoading, setPageLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [error, setError] = useState(false)
  const [touched, setTouched] = useState(false)
  const [showDocuments, setShowDocuments] = useState(false)
  const [fileUploaded, setFileUploaded] = useState('')

  useEffect(() => {
    const query = location.search
    const { companyUrlName, shareClass, shares } = qs.parse(query, { ignoreQueryPrefix: true })
    getOrder(companyUrlName, shareClass, shares)
  }, [])

  useEffect(() => {
    if (fileUploaded.length > 0) {
      setShowDocuments(true)
    }
  }, [fileUploaded])

  const onDelete = () => {
    setShowDocuments(false)
    setConfirmed(false)
  }
  const getOrder = (companyUrlName, shareClass, shares) => {
    setPageLoading(true)
    actions.getOriginationOffer(companyUrlName, shareClass, shares).then(() => {
      setPageLoading(false)
    })
  }
  const submitOrder = async () => {
    if (confirmed) {
      setLoading(true)
      await actions.submitOriginationOffer(offer.urlName, offer.shareClass, offer.shares, fileUploaded).then((res) => {
        if (res) {
          history.push(`/sell/offerconfirmed/${res}`)
        }
      })
    } else {
      setError(true)
      setTouched(true)
    }
  }

  const onCheckBoxChange = (checked) => {
    setConfirmed(checked)
    setError(false)
    setTouched(true)
  }

  if (pageLoading) {
    return <>
      <SeoMeta title={seoTitleTemplate('Confirm order | Sell')} />
      <PageLoading />
    </>
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Confirm order | Sell')} />
      {interestedInBuying &&
        <>
          <div className='origination-container origination-details-container unicorns-details-container'>
            <OriginationDetailHeader />
            <div className='page-container'>
              <div className='inner-container'>
                <div className='sections-container'>
                  <OriginationDetailCompanyLogo company={offer} />
                  <OriginationOfferCompanyOffer company={offer} />
                  <OriginationOfferUploadProof fileUploaded={fileUploaded} setFileUploaded={setFileUploaded} onDelete={onDelete}/>
                  {showDocuments &&
                    <>
                      <OriginationOfferDocuments documents={offer && offer.documents} />
                      <OriginationOfferCompanyCTA onClick={submitOrder} onCheckBoxChange={onCheckBoxChange} touched={touched} error={error} confirmed={confirmed} loading={loading}/>
                    </>
                  }
                </div>
                <div className='content-separator' />
                <OriginationFooter />
              </div>
            </div>
          </div>
        </>
      }
      {!interestedInBuying &&
        <div className='page-container'>
          <div className='inner-container'>
            <OriginationNotAvailableModal />
          </div>
        </div>
      }
    </>
  )
}

export default connected(OriginationOffer)
