import privateAPI from '../apis/private'
import { GET_IDENTITY, GET_FINISH_SIGN_UP, GET_SIGNIN_QRCODE, SIGN_IN, GET_KYC_STATUS, SUBMIT_KYC_STATUS } from '../types/actionTypes'
import { handleServerError } from './CommonActions'
import axios from 'axios'
import FormData from 'form-data'
import { identify } from '../functions'

export const finishSignUp = (formValues) => dispatch => {
  return privateAPI
    .post('/page/finishSignup', formValues)
    .then(res => {
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const getFinishSignUp = () => dispatch => {
  return privateAPI
    .get('/page/finishSignup')
    .then(res => {
      dispatch({
        type: GET_FINISH_SIGN_UP,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const getIdentity = () => dispatch => {
  return privateAPI
    .get('/page/kycStatus')
    .then(res => {
      dispatch({
        type: GET_IDENTITY,
        payload: { ...res.data }
      })
      return res.data
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const deleteIDDocs = (name) => dispatch => {
  return privateAPI
    .delete('/page/kycStatus/documents', { data: { name } })
    .then(res => {})
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const submitForReview = () => dispatch => {
  return privateAPI
    .post('/page/kycStatus/submitForReview')
    .then(res => {
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const cancelReview = () => dispatch => {
  return privateAPI
    .post('/page/kycStatus/cancelReview')
    .then(res => {
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const saveIdScanData = (result) => dispatch => {
  return privateAPI
    .post('/page/kycStatus/saveIdScanData/web', result)
    .then((res) => {
      if (res.status === 200) {
        return 'success'
      }
    })
    .catch(err => console.log('saveIdScanData', err))
}

export const uploadSelfie = (file, func) => dispatch => {
  logEvent('/identityVerification/step4/selfieClicked')
  const data = new FormData()
  data.append('file', file)
  data.append('name', 'selfie')
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/page/kycStatus/saveAndVerifySelfie`,
    onUploadProgress: (progressEvent) => func(progressEvent),
    data: data
  }
  if (window.location.hostname === 'localhost') {
    config.headers = { access_token: localStorage.getItem('linqto_token') }
  }
  return axios(config)
    .then(() => {
      dispatch(getIdentity())
    })
    .catch(err => {
      logEvent('/identityVerification/step4/selfieError', { error: err })
      if (err.response && err.response.data.error === 'FACES_DID_NOT_MATCH') {
        return 'FACES_DID_NOT_MATCH'
      } else if (err.response && err.response.data.error === 'FACE_NOT_FOUND') {
        return 'FACE_NOT_FOUND'
      } else {
        return 'error'
      }
    })
}

export const confirmKycStatusVerified = () => dispatch => {
  return privateAPI
    .post('/page/kycStatus/confirm')
    .then((res) => {
      if (res.status === 200) {
        return 'success'
      }
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const restartKycProcess = () => dispatch => {
  return privateAPI
    .post('/page/kycStatus/restart')
    .then(() => {})
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const getSignInQRCode = () => dispatch => {
  return privateAPI
    .post('/page/kycStatus/getAutoSignInQrCode?height=200&width=200')
    .then(res => {
      dispatch({
        type: GET_SIGNIN_QRCODE,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const signInWithCode = (code) => dispatch => {
  return privateAPI
    .post('/signInWithCode', code)
    .then(res => {
      dispatch({
        type: SIGN_IN,
        payload: res.data
      })
      if (res.data.email) {
        identify(res.data.email)
      }
      if (res.status === 200) {
        return 200
      }
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const logEvent = (path, body = null) => dispatch => {
  return privateAPI.post(`/log${path}`, body || {}).catch(err => console.log(err))
}

export const getKycStatus = () => dispatch => {
  return privateAPI
    .get('/page/kycStatus/manual')
    .then(res => {
      dispatch({
        type: GET_KYC_STATUS,
        payload: { ...res.data }
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

function dataURItoBlob (dataURI, filename) {
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1])
  } else {
    byteString = unescape(dataURI.split(',')[1])
  }
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  const theBlob = new Blob([ia], { type: 'image/png' })
  const file = new File([theBlob], filename, { type: 'image/png' })
  return file
}

export const submitKycStatus = (formValues) => (dispatch) => {
  const data = new FormData()
  data.append('idFrontFile', dataURItoBlob(formValues.idFrontFile, 'idFrontFile.png'))
  data.append('selfieFile', dataURItoBlob(formValues.selfieFile, 'selfieFile.png'))
  data.append('jsonData', JSON.stringify(formValues.jsonData))
  if (formValues.idBackFile !== '') data.append('idBackFile', dataURItoBlob(formValues.idBackFile, 'idBackFile.png'))

  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/page/kycStatus/manual`,
    headers: { 'content-type': 'multipart/form-data' },
    data: (data)
  }
  if (window.location.hostname === 'localhost') {
    config.headers = { access_token: localStorage.getItem('linqto_token') }
  }
  return axios(config)
    .then((res) => {
      dispatch({
        type: SUBMIT_KYC_STATUS,
        payload: res.data
      })
      return 200
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return err.response.data.error
    })
}
