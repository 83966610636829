import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ user, common }) => ({
  resetPassword: user.resetPassword,
  status: common.status,
  verifyEmail: user.verifyEmail,
  systemErr: common.systemErr,
  rewardsEnabled: user.rewardsEnabled,
  unicornsEnabled: user.unicornsEnabled,
  branchKey: user.branchKey,
  mfaRequired: user.mfaRequired
})
const requiredActions = ['getCoreUser', 'saveBranchData', 'resetPassword', 'contactUs', 'resetLoading', 'verifyEmail', 'saveCjEvent']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)
export default connected
