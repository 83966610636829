import renderer from './renderer'
import { Field, reduxForm } from 'redux-form'

let AddressInput = ({
  renderDropdown,
  country,
  disabled,
  primaryRegions,
  getRegions,
  setPrimaryRegions
}) => {
  return (
    <>
      <div className='ui small header form-header'>
        <span>Address</span>
      </div>
      <div className='ui grid stackable'>
        <div className='row'>
          <div className='four wide column tablet_eight first-col'>
            <Field
              regions={primaryRegions}
              name='state'
              component={renderDropdown}
              label='State or Province'
              disabled ={disabled}
              country={country}
              getRegions={getRegions}
              setRegions={setPrimaryRegions}
            />
          </div>
          <div className='four wide column tablet_eight'>
            <Field
              name='street1'
              component={renderer.renderInput}
              label='Street 1'
              placeholder=''
              type='text'
              disabled ={disabled}
            />
          </div>
        </div>
        <div className='row'>
          <div className='four wide column tablet_eight first-col' >
            <Field
              name='street2'
              component={renderer.renderInput}
              label='Street 2'
              placeholder=''
              type='text'
              disabled ={disabled}
            />
          </div>
          <div className='four wide tablet_eight column'>
            <Field
              name='city'
              component={renderer.renderInput}
              label='City'
              placeholder=''
              type='text'
              disabled ={disabled}
            />
          </div>
        </div>
        <div className='row'>
          <div className='four wide column tablet_eight first-col'>
            <Field
              name='zip'
              component={renderer.renderInput}
              label='Postal Code'
              placeholder=''
              type='text'
              disabled ={disabled}
            />
          </div>
        </div>
      </div>
    </>
  )
}

AddressInput = reduxForm({
  form: 'editForm'
})(AddressInput)

export default AddressInput
