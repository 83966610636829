import { useEffect } from 'react'
import { month } from '../../data'
import Button from '../../Global/Button'
const IdentityConfirm = ({ confirmData, handleConfirm, handleRestart, getIdentity }) => {
  useEffect(() => {
    getIdentity()
  }, [getIdentity])
  const { legalName, birthYear, birthMonth, birthDay, address } = confirmData
  console.log('address', address)
  return (
    <>
      <div className='info-title content'>The following information was gathered from your document:</div>
      <div className='content identity-confirm'>
        <div className='item'>{legalName && legalName}</div>
        <div className='item'>{birthMonth && month[birthMonth]} {birthDay && birthDay}, {birthYear && birthYear}</div>
        <div className='item'>{address && address.split('\n').map(s => <div key={s}>{s}</div>)}</div>
      </div>
      <div className='btn-group'>
        <Button mode='primary' size='md' onClick={() => handleConfirm()}>Confirm</Button>
      </div>
      <a className='restart-link' onClick={() => handleRestart()}>Restart with New Document</a>
    </>
  )
}

export default IdentityConfirm
