import { BaseModal } from 'components'

const UnicornMosaicModal = ({ setShowModal }) => {
  const hideModal = () => {
    setShowModal(false)
  }
  return (
    <BaseModal mode='primary' size='md' innerStyle='mosaic-modal' hideModal={hideModal} modalHeader='' clickToClose pressToClose crossToClose>
      <div className='mosaic-modal-web'>
        <div className='mosaic-modal-section'>
          <label className='modal-section-title'>What is Mosaic?</label>
          <p>Mosaic Score was developed by CBINSIGHTS in cooperation with the National Science Foundation. It is a quantitative framework used to measure and predict the health and future success potential of private tech companies using non-traditional signals.</p>
          <p>The Mosaic score is comprised of 4 individual models — what CBI calls the 4 M’s, each relying on different signals (although all the signals utilized are not revealed for obvious reasons).</p>
        </div>
        <div className='mosaic-modal-section'>
          <label>Momentum</label>
          <p>CBI captures many volume and frequency signals including news/media, sentiment, partnership & customer momentum, and social media, among other signals.</p>
          <p>They look at these on an absolute and relative basis vs. peers/industry comparables. The relative piece is critical as it ensures that, for example, enterprise software companies that may get less media attention or that spend less time on social media are not penalized vs. consumer-focused tech companies.</p>
        </div>
        <div className='mosaic-modal-section'>
          <label>Market</label>
          <p>The quality of the market or industry a company competes in is critical. If you are part of a hot industry, that serves as a tailwind to push you along. Conversely, being in an out of favor space means fewer investors, partners, media, and more. </p>
          <p>The Market model looks at the number of companies in an industry, the financing and exit momentum in the space, and the overall quality and quantity of investors participating in that industry.</p>
        </div>
        <div className='mosaic-modal-section'>
          <label>Money</label>
          <p>The Money model assesses the financial health of a company, i.e. is it going to run out of money? CBI’s model looks at burn rate, the quality of the investors and syndicate that may be part of the company, its financing position relative to industry peers & competitors, and more. </p>
        </div>
        <div className='mosaic-modal-section'>
          <label>Management</label>
          <p>The CBI Management model centers around the founding and management team at private tech companies.  </p>
          <p>In selecting investments or partnerships, especially with early-stage tech companies, VCs and corporates place the greatest importance on the founding and management team. </p>
          <p>Using the wealth of People data in our platform, CBI’s Management model looks at founding and management teams’ work accomplishments like previous exits, funding rounds, industry experience, as well as their educational background and network. </p>
        </div>
        <div className='mosaic-modal-section'>
          <label>How do our customers use mosaic? </label>
        </div>
        <div className='mosaic-modal-section'>
          <label>Corporate Innovation </label>
          <p>Pinpoint fast-growing private companies to understand viable business models, products, and technologies.</p>
        </div>
        <div className='mosaic-modal-section'>
          <label>Corporate Strategy</label>
          <p>See fast-growing markets and industries before anyone else to inform executives on strategic decisions.</p>
        </div>
        <div className='mosaic-modal-section'>
          <label>Competitive Intel / Market Research </label>
          <p>Assess the health of startups competing in your industry to advise your build, buy, or partner strategy.</p>
        </div>
        <div className='mosaic-modal-section'>
          <label>Corporate Development and M&A </label>
          <p>Monitor the health and growth potential of possible acquisition targets as part of your due diligence process.</p>
        </div>
        <div className='mosaic-modal-section'>
          <label>Corporate Venture Capital </label>
          <p>Identify the startups with the highest growth potential to satisfy your corporate investment philosophy.</p>
        </div>
      </div>
      <div className='mosaic-modal-mobile'>
        <div className='mosaic-modal-section'>
          <label className='modal-section-title'>What is Mosaic? </label>
          <p>Developed by CBINSIGHTS in cooperation with the National Science Foundation, Mosaic is a quantitative framework used to measure and predict the health and future success potential of private tech companies using non-traditional signals. </p>
          <p>The Mosaic score is comprised of 4 individual models — the 4 M’s, each relying on different signals.</p>
        </div>
      </div>
    </BaseModal>
  )
}

export default UnicornMosaicModal
