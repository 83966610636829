import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ unicorn }) => ({
  company: unicorn.company,
  isLoggedIn: unicorn.isLoggedIn
})

const requiredActions = ['getUnicornsDetails', 'setInterestedInUnicorn']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
