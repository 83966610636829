import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ financialAdvisor, common, investorStatus, form }) => ({
  firstName: financialAdvisor.firstName,
  lastName: financialAdvisor.lastName,
  documents: financialAdvisor.documents,
  advisorName: financialAdvisor.advisorName,
  spinnerVisible: common.spinnerVisible,
  uploadErr: investorStatus.uploadErr,
  formValues: form.requestFinancialAdvisor,
  isAccredited: financialAdvisor.isAccredited,
  showVerificationForm: financialAdvisor.showVerificationForm,
  financialAdvisorAttestation: financialAdvisor.financialAdvisorAttestation,
  country: financialAdvisor.country,
  initialValues: financialAdvisor.initialValues
})

const requiredActions = ['getFinancialAdvisor', 'showLoadingSpinner', 'hideLoadingSpinner', 'resetUploadErr', 'getRegions', 'getCountries', 'saveFinancialAdvisorReqest', 'uploadDocument']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
