import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ linqtoPortfolio }) => ({
  companies: linqtoPortfolio.companies,
  completedOrdersTotal: linqtoPortfolio.completedOrdersTotal,
  investorPurchases: linqtoPortfolio.investorPurchases,
  liquidSharesPurchases: linqtoPortfolio.liquidSharesPurchases
})

const requiredActions = ['getLinqtoPortfolio']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
