import { GET_PORTFOLIO, GET_PRODUCT_ORDERS } from 'types/actionTypes'

const INITIAL_STATE = {
  recentOrders: [],
  companies: [],
  company: {},
  filterOrders: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case GET_PORTFOLIO:
    return { ...state, ...action.payload }
  case GET_PRODUCT_ORDERS:
    return { ...state, ...action.payload }
  default:
    return state
  }
}
