import { useState } from 'react'
import Button from '../../Global/Button'
import { Field, reduxForm } from 'redux-form'
import Modal from '../../Global/Modal'
import render from 'components/Profile/partials/renderer'
import { useDispatch, useSelector } from 'react-redux'
import { changePassword } from 'slices/securitySlice'

const PsdModal = ({ handleSubmit, hideModal }) => {
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.securitySlice)
  const [success, setSuccess] = useState(false)
  const [systemErr, setSystemErr] = useState(false)
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const onSubmit = (formValues) => {
    dispatch(changePassword({ ...formValues })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setSuccess(true)
        setTimeout(() => {
          hideModal()
        }, 1000)
      } else {
        setSystemErr(true)
      }
    })
  }

  const renderBigErr = () => {
    if (systemErr) {
      return (
        <div className='sixteen wide column'>
          <div className='ui error message'>
            <div className='header'>Your current password was incorrect.</div>
            <p>Please re-enter and try again.</p>
          </div>
        </div>
      )
    }
  }

  return (
    <Modal
      mode='primary'
      size='sm'
      innerStyle='psd-modal'
      hideModal={hideModal}
      modalHeader='Change Password'
      crossToClose
    >
      {!success ? (
        <form className='ui grid ' onSubmit={handleSubmit(onSubmit)}>
          <div className='sixteen wide column'>
            <Field
              name='currentPsd'
              component={render.renderInput}
              label='Current Password'
              type={showCurrentPassword ? 'text' : 'password'}
              disabled={loading}
              viewPassword={() => setShowCurrentPassword(prev => !prev)}
            />
          </div>
          <div className='sixteen wide column'>
            <Field
              name='newPsd'
              component={render.renderInput}
              label='New Password'
              type={showNewPassword ? 'text' : 'password'}
              disabled={loading}
              viewPassword={() => setShowNewPassword(prev => !prev)}
            />
          </div>
          <div className='sixteen wide column'>
            <Field
              name='confirmPsd'
              component={render.renderInput}
              label='Confirm Password'
              type={showConfirmPassword ? 'text' : 'password'}
              disabled={loading}
              viewPassword={() => setShowConfirmPassword(prev => !prev)}
            />
          </div>
          {renderBigErr()}
          <div className='sixteen wide column'>
            <div className='btn-group two'>
              <Button
                mode='primary'
                size='md'
                type='submit'
                loading={loading}
                disabled={loading}
              >
                Change Password
              </Button>
              <Button
                mode='secondary'
                size='md'
                type='button'
                loading={loading}
                disabled={loading}
                onClick={hideModal}
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      ) : (
        'Password has reset.'
      )}
    </Modal>
  )
}

const validate = (formValues) => {
  const errors = {}
  if (!formValues.currentPsd) {
    errors.currentPsd = 'Please enter the current password.'
  }
  if (!formValues.newPsd) {
    errors.newPsd = 'Please enter the new password.'
  }
  if (!formValues.confirmPsd) {
    errors.confirmPsd = 'Please enter the confirm password.'
  }
  if (formValues.newPsd !== formValues.confirmPsd) {
    errors.confirmPsd = 'Confirm Password should match the new password above.'
  }
  const regex = /\d+/g
  if (formValues.newPsd && !formValues.newPsd.match(regex)) {
    errors.newPsd = 'Password must contain at least one number.'
  }
  if (formValues.newPsd && formValues.newPsd.length < 7) {
    errors.newPsd = 'Password must be at least 8 characters long.'
  }
  return errors
}
const formWrapped = reduxForm({
  form: 'changePasswordForm',
  validate
})(PsdModal)

export default formWrapped
