import React from 'react'
import {
  PageLoading,
  Wrapper,
  Content,
  Image,
  Header,
  Companies
} from 'components'
import { OrderConfirmed as connected } from 'containers'
import { images } from 'assets'
import Button from '../Global/Button'
import ReactGA from 'react-ga'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'

class OrderConfirmed extends React.Component {
  componentDidMount () {
    this.orderId = this.props.match.params.id
    this.props.actions.showLoadingSpinner()
    this.props.actions.getOrderConfirmation(this.orderId)
      .then((data) => {
        this.props.actions.hideLoadingSpinner()
        const eventAndCustomData = {
          revenue: data.order.price,
          transaction_id: data.order.transactionId,
          currency: data.order.currency,
          description: `Purchase of ${data.order.shares} shares of ${data.order.companyName}`,
          user_id: data.userId
        }
        // to add custom data to the event we need to setup google custom dimension
        // please refer to https://app.clickup.com/t/zbfzev
        ReactGA.ga('send', {
          hitType: 'event',
          eventCategory: 'Commerce Event',
          eventAction: 'PURCHASE',
          eventLabel: `Purchase of ${data.order.shares} shares of ${data.order.companyName}`,
          eventAndCustomData
        })
        // add purchase action
        ReactGA.plugin.require('ec')
        ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
          id: data.order.transactionId,
          affiliation: `Purchase of ${data.order.shares} shares of ${data.order.companyName}`,
          revenue: data.order.price,
          currency: data.order.currency
        })
        ReactGA.plugin.execute('ec', 'clear')
      })

      .catch((status) => {
        this.props.actions.hideLoadingSpinner()
        if (!this.props.isSignedIn && status === 401) {
          localStorage.setItem('prev-route', this.props.location.pathname)
          this.props.history.push('/signIn')
        }
      })
  }

  render () {
    const { confirmation, history, spinnerVisible } = this.props
    if (spinnerVisible) {
      return (
        <>
          <SeoMeta title={seoTitleTemplate('Order Confirmed')} />
          <PageLoading />
        </>
      )
    }
    if (!Object.keys(confirmation).length) return null
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Order Confirmed')} />
        <Wrapper className='page-container'>
          <Content className='inner-container order-confirmed'>
            <Image src={images.checkmark} />
            <Header className='header'>Order Confirmed</Header>
            <span className='status'>{confirmation.order.transactionStatus}</span>
            <span
              className='order'
              onClick={() => history.push(`/order/${confirmation.order.orderId}`)}
            >
              Order ID: {confirmation.order.orderId}
            </span>
            <div className={`btn-group ${(confirmation.accStatus === 'PENDING' ||
              confirmation.accStatus === 'FUNDED' || confirmation.order.transactionStatus.includes('wire')) && 'two'}`}>

              {(confirmation.accStatus === 'PENDING' ||
              confirmation.accStatus === 'FUNDED') && (
                <Button
                  mode='primary'
                  onClick={() => history.push('/investor-status')}
                  size='md'
                >Get Verified</Button>
              )}
              {confirmation.order.transactionStatus.includes('wire') &&
                <Button
                  mode='primary'
                  onClick={() => history.push('/wire-instructions')}
                  size='md'
                >Wire Now</Button>}
              <Button
                mode={(confirmation.accStatus === 'PENDING' ||
                confirmation.accStatus === 'FUNDED') ? 'secondary' : 'primary'}
                onClick={() => history.push('/products')}
                size='md'
              >Buy More</Button>
            </div>
          </Content>
          <div className='page-container order-accounts'>
            <div className='inner-container'>
              <Header className='header'>Your Portfolio</Header>
              <div className='accounts-container'>
                <div className='portfolio'>
                  <Companies companies={confirmation.portfolio} />
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </>
    )
  }
}

export default connected(OrderConfirmed)
