import React from 'react'
import { images } from 'assets'
import RequiredDocuments from './RequiredDocuments'
import OptionalDocuments from './OptionalDocuments'
import { InvestorStatus as connected } from 'containers'
import { status, ACCstatus } from '../data'
import RequestModal from './RequestModal'
import AccreditationTypesModal from './AccreditationTypesModal'
import { PageLoading, ConditionalRender } from 'components'
import Button from '../Global/Button'
import Input from '../Global/InputField'
import Modal from '../Global/Modal'
import history from '../../history'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import ProgressBar from '../Profile/partials/ProgressBar'

const quality = [
  {
    title: 'Simple',
    discription:
      'Investors are verified with a few simple steps. Our 100% online process takes less than 10 minutes to complete.',
    img: 'simple'
  },
  {
    title: 'Secure',
    discription:
      'With 256-bit encryption, Linqto offers the most contemporary and comprehensive security in the industry.',
    img: 'secure'
  },
  {
    title: 'Cost-Effective',
    discription:
      'Linqto is a leading solutions provider, offering accredited investor verification at no cost.',
    img: 'cost-effective'
  },
  {
    title: 'User-Friendly',
    discription:
      "With our accredited investor dashboard, view your status at a glance. We'll remind you when it's time to renew.",
    img: 'user-friendly'
  },
  {
    title: 'Private',
    discription:
      'Safety and security. Get verified while maintaining control of your personal information. You control when and who you share your accreditation with.',
    img: 'private'
  },
  {
    title: 'Access to Pre-IPO Stocks',
    discription:
      'Once your accreditation is verified, all Linqto users have access to pre-IPO opportunities, accessing the private market and increasing diversification.',
    img: 'access'
  }
]

class InvestorStatus extends React.Component {
  state = {
    showModal: false,
    crdNumber: '',
    OriginalCrdNumber: '',
    showEditModal: false,
    selfAccreditation: status[this.props.selfAccreditation] || '',
    accreditedCountry: this.props.accreditedCountry || '',
    loading: false,
    editCrd: false,
    touched: true,
    countries: [],
    showAccreditationTypesModal: false
  }

  componentDidMount () {
    /* For the react native webview, since the header has already
      rendered, this needs to be removed here and not in React Naive code */
    if (window.ReactNativeWebView) {
      document.getElementById('header-div').style.display = 'none'
    }
    this.props.actions.showLoadingSpinner()
    this.props.actions.getInvestorStatus().then((crdNumber) => {
      this.props.actions.hideLoadingSpinner()
      this.setState({
        accreditedCountry: this.props.accreditedCountry,
        selfAccreditation: this.props.selfAccreditation
      })
      if (crdNumber) {
        this.setState({ crdNumber, OriginalCrdNumber: crdNumber })
      }
      /* If loaded in react native webview, post message
       so webview can remove required elements from DOM */
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('pageLoaded')
      }
    })
  }

  componentWillUnmount () {
    this.props.actions.resetUploadErr()
  }

  handleRequest = (e) => {
    e.preventDefault()
    this.setState({ showModal: true })
  }

  renderSecurityLicenseInput = () => {
    return (
      <form className='d-flex-col-50'>
        <div className='eight wide column m-sixteen info-container'>
          <div className='info-title option info-group'>
            Required Information
          </div>
          <Input
            type='text'
            label='License Number'
            input={{ name: 'crdNumber', value: this.state.crdNumber }}
            meta={{
              touched: this.state.touched,
              error: this.state.licenseNumberErr
            }}
            onChange={(e) => {
              this.setState({
                crdNumber: e.target.value,
                touched: true,
                editCrd: !(
                  e.target.value.toString() ===
                  this.state.OriginalCrdNumber.toString()
                ),
                licenseNumberErr: ''
              })
            }}
            onFocus={() => {
              this.setState({ touched: true })
            }}
            disabled={this.props.accreditedStatus === 'ACCREDITED'}
          />
          {this.state.editCrd &&
            this.props.accreditedStatus === 'NOT_VERIFIED' && (
            <div className='btn-group start'>
              <Button
                loading={this.state.loading}
                disabled={this.state.loading}
                mode='primary'
                size='md'
                onClick={(e) => this.saveLicenseNumber(e)}
              >
                  Save
              </Button>
            </div>
          )}
        </div>
      </form>
    )
  }

  saveLicenseNumber = (e) => {
    e.preventDefault()
    if (!this.state.editCrd) {
      this.setState({ editCrd: true })
    } else {
      if (this.state.crdNumber || this.state.crdNumber.length > 0) {
        this.setState({ loading: true })
        this.props.actions
          .saveSecurityLicense({ crdNumber: this.state.crdNumber })
          .then(() => {
            this.props.actions.getInvestorStatus()
            this.setState({ loading: false, editCrd: false })
          })
      } else {
        this.setState({
          licenseNumberErr: 'Please enter a license number.',
          touched: true
        })
      }
    }
  }

  getCountriesList = () => {
    this.setState({ loading: true })
    this.props.actions.getCountries().then((res) => {
      if (res && res.length > 0) {
        this.setState({ countries: res, loading: false })
      }
    })
  }

  renderOptions = () => {
    return Object.values(status).map((ele, id) => {
      return (
        <div key={id} className='radio-option'>
          <input
            name='selfAccreditation'
            component='input'
            type='radio'
            value={Object.keys(status)[id]}
            tag={`option-${id}`}
            id={Object.keys(status)[id]}
            defaultChecked={
              this.props.selfAccreditation === Object.keys(status)[id]
            }
            disabled={this.state.loading}
          />
          <label
            key={id}
            htmlFor={Object.keys(status)[id]}
            className='radio__label'
          >
            {ele}
          </label>
        </div>
      )
    })
  }

  renderCountry = () => {
    if (this.state.selfAccreditation === 'FOREIGN_INVESTOR') {
      return (
        <div className='country-dropdown-self-accreditation'>
          <select className={`render-location-dropdown ${this.state.accreditedCountryErr ? 'error' : null}`}
            placeholder= 'Please select a country.'
            value={this.state.accreditedCountry}
            disabled={this.state.loading}
            onChange={(e) =>
              this.setState({
                accreditedCountry: e.target.value,
                accreditedCountryErr: e.target.value ? '' : 'Please select a country.'
              })
            }
          >
            <option value=''>Select Country</option>
            {this.state.countries.length > 0 && this.state.countries.map((e, i) => <option value={e} key={`${i}-${e}`}>{e}</option>)}
          </select>
          {this.state.accreditedCountryErr && <div className='err-msg'>{this.state.accreditedCountryErr}</div>}
        </div>
      )
    }
  }

  handleSaveSelfAccreditation = () => {
    if (
      this.state.selfAccreditation === 'FOREIGN_INVESTOR' &&
      (!this.state.accreditedCountry ||
        this.state.accreditedCountry.length === 0)
    ) {
      this.setState({ accreditedCountryErr: 'Please select a country.' })
    } else {
      this.setState({ loading: true, accreditedCountryErr: '' })
      const { selfAccreditation, accreditedCountry } = this.state
      if (this.state.selfAccreditation !== 'FOREIGN_INVESTOR') {
        this.setState({ accreditedCountry: 'United States' })
      }
      this.props.actions
        .saveSelfAccreditation({
          selfAccreditation,
          accreditedCountry:
            selfAccreditation === 'FOREIGN_INVESTOR'
              ? accreditedCountry
              : 'United States'
        })
        .then(() => {
          this.setState({ loading: false, showEditModal: false })
          this.props.actions.getInvestorStatus()
        })
    }
  }

  setShowAccreditationTypesModal = (status) => {
    this.setState({ showAccreditationTypesModal: status })
  }

  render () {
    if (this.props.spinnerVisible) {
      return (
        <>
          <SeoMeta title={seoTitleTemplate('Accredited Investor')} />
          <PageLoading />
        </>
      )
    }
    const {
      accreditedCountry,
      selfAccreditation,
      accreditedStatus,
      RDocuments,
      ODocuments,
      actions,
      hasRequestedFinancialAdvisor,
      verifiedDocument,
      isSignedIn,
      hasKyc,
      isUserProfileComplete
    } = this.props

    return (
      <>
        <SeoMeta title={seoTitleTemplate('Accredited Investor')} />
        <div className='page-container investor-status-container'>
          <div className='inner-container'>
            <ConditionalRender isVisible={isSignedIn}>
              <div className='header-progressbar-wrapper'>
                <h1 id='investor-status-title' className='page-title subinfo'>Investor Profile</h1>
                <hr className='title-separator'/>
                <ProgressBar hasKyc={hasKyc} isUserProfileComplete={isUserProfileComplete} isAccredited={accreditedStatus === 'ACCREDITED' || accreditedStatus === 'QUALIFIED_PURCHASER'} activeStep='Verify'/>
                <h2>Verify Accreditation</h2>
              </div>
              <div className='content header-content'>
                You indicated that you are an <a className='teal-link' alt='accredited investor' onClick={() => this.setShowAccreditationTypesModal(true)}>accredited investor</a> at sign up. In order to get you investing, we’ll need to confirm your status.
              </div>
              <div className='content info-container'>
                <span className='info-title'>Your Current Status:</span>{' '}
                <div
                  className={ (ACCstatus[accreditedStatus] === 'Qualify Purchaser' || ACCstatus[accreditedStatus] === 'Verified') ? 'accStatus teal' : 'accStatus red'}>
                  {' '}
                  &#9679;
                </div>
                <span className='info'>{ACCstatus[accreditedStatus]}</span>
                {verifiedDocument && (
                  <span>
                    &nbsp;&nbsp;(
                    <a
                      href={verifiedDocument.url}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      View Certificate
                    </a>
                    )
                  </span>
                )}
              </div>
            </ConditionalRender>
            <ConditionalRender isVisible={!isSignedIn}>
              <h1 className='page-title'>Accredited Investor Status</h1>
              <div className='video-container'>
                <div className='info-container'>
                  What is an Accredited Investor?
                </div>
                <iframe
                  title='AIVideo'
                  src='https://www.youtube.com/embed/3Pk9zZp6oSU'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  className='acc-video'
                />
              </div>
              <div className='btn-group centered'>
                <Button mode='primary' onClick={() => history.push('/signup')}>
                  Sign Up
                </Button>
              </div>
              <h2>Why verify through Linqto?</h2>
              <p>
                Linqto helps educated investors comply with the accredited
                investor verification burden
              </p>
              <div className='AI-quality'>
                {quality.map((ele) => (
                  <div key={ele.title} className='quality'>
                    <div className='title-group'>
                      <img alt={ele.img} src={images[ele.img]} />
                      <b>
                        <p>{ele.title}</p>
                      </b>
                    </div>
                    <p>{ele.discription}</p>
                  </div>
                ))}
              </div>
              <div className='btn-group centered'>
                <Button mode='primary' onClick={() => history.push('/signup')}>
                  Get Accredited
                </Button>
              </div>
            </ConditionalRender>
            <ConditionalRender isVisible={isSignedIn}>
              <div className='content info-container'>
                <span className='info-title with-img'>
                  Registration Accreditation Choice{' '}
                  {ACCstatus[accreditedStatus] === 'Not Verified' && (
                    <img
                      alt='edit'
                      src={images.edit}
                      onClick={() => this.setState({ showEditModal: true })}
                    />
                  )}
                </span>
                <span className='info-title'>Country of Residence:</span>{' '}
                <span className='info'>{accreditedCountry}</span>
                <div className='content info acc-info'>
                  <div>
                    (During registration you self-verified with the following
                    status.)
                  </div>
                  <div>{status[selfAccreditation]}</div>
                </div>
              </div>
              {selfAccreditation === 'SECURITIES_LICENSE' ? (
                this.renderSecurityLicenseInput()
              ) : (
                <>
                  <RequiredDocuments
                    type={selfAccreditation}
                    documents={RDocuments}
                    uploadLoading={actions.uploadLoading}
                    accreditedStatus={accreditedStatus}
                  />
                  {selfAccreditation !== 'NET_WORTH' &&
                    selfAccreditation !== 'FOREIGN_INVESTOR' && (
                    <div
                      className='ui horizontal divider'
                      style={{ marginTop: '4em', marginBottom: '2em' }}
                    >
                        Or
                    </div>
                  )}
                  <div className='request-container'>
                    <div className='content request-info'>
                      <div>
                        We can expedite your Accredited Investor Verification
                        for the Linqto platform by having a third-party
                        professional prepare a simple letter on your behalf that
                        states:
                      </div>
                      <ol>
                        <li>
                          Their name, address, professional credentials, and
                          their relationship to you, i.e., your accountant,
                          banker, broker, or attorney{' '}
                        </li>
                        <li>
                          They have access to your financial records and know
                          your financial position{' '}
                        </li>
                        <li>
                          That on the basis of the above, they can attest that
                          you have either the Gross Income or Net Worth to meet
                          the requirements for Accredited Investor status.
                        </li>
                      </ol>
                    </div>
                    <div className='top-margin'>
                      Simply enter the email address of your advisor and we will
                      request the document on your behalf. You will be copied on
                      the message.
                    </div>
                    <div className='centered'>
                      <Button
                        mode='primary'
                        size='md'
                        onClick={(e) => this.handleRequest(e)}
                      >
                        {hasRequestedFinancialAdvisor
                          ? 'Request Sent - Resend'
                          : 'Request Financial Advisor'}
                      </Button>
                    </div>
                    {(selfAccreditation === 'NET_WORTH' ||
                      selfAccreditation === 'FOREIGN_INVESTOR') && (
                      <>
                        <div className='ui horizontal divider'>Or</div>
                        <div className='centered'>
                          <Button
                            mode='secondary'
                            onClick={() => window.open('/contact')}
                            size='md'
                          >
                            Contact Customer Support
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                  <OptionalDocuments
                    documents={ODocuments}
                    accreditedStatus={accreditedStatus}
                  />
                  <div className='support-container'>
                    Have questions?{' '}
                    <a href='/contact' target='_blank'>
                      Contact Support.
                    </a>
                  </div>
                </>
              )}
              {this.state.showModal && (
                <RequestModal
                  hideModal={() => this.setState({ showModal: false })}
                />
              )}
              {this.state.showEditModal && (
                <Modal
                  size='md'
                  mode='primary'
                  modalHeader='Self Accreditation'
                  clickToClose
                  pressToClose
                  crossToClose
                  hideModal={() => this.setState({ showEditModal: false })}
                >
                  <form className='ui'>
                    <div className='accredited-radio-group'>
                      <div className='title'>
                        Please confirm your status as an Accredited Investor.
                      </div>
                      <div
                        className='radio-group'
                        onChange={(e) => {
                          this.setState({
                            selfAccreditation: e.target.value,
                            accreditedCountryErr: ''
                          })
                          if (e.target.value === 'FOREIGN_INVESTOR') {
                            this.getCountriesList()
                          }
                        }
                        }
                      >
                        {this.renderOptions()}
                      </div>
                      {this.renderCountry()}
                    </div>
                  </form>
                  <Button
                    style={{ marginTop: '1rem' }}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                    mode='primary'
                    size='md'
                    onClick={this.handleSaveSelfAccreditation}
                  >
                    Save
                  </Button>
                </Modal>
              )}
              {this.props.uploadErr && (
                <Modal
                  clickToClose={false}
                  pressToClose={false}
                  crossToClose={false}
                  mode='primary'
                  size='md'
                  modalHeader='Failed to Upload'
                >
                  <div className='content'>
                    An error occurred while uploading the file.
                  </div>
                  <Button
                    mode='primary'
                    onClick={() => this.props.actions.resetUploadErr()}
                  >
                    Close
                  </Button>
                </Modal>
              )}
              {this.state.showAccreditationTypesModal && (
                <AccreditationTypesModal setShowAccreditationTypesModal={this.setShowAccreditationTypesModal} status={status}/>
              )}
            </ConditionalRender>
          </div>
        </div>
      </>
    )
  }
}

export default connected(InvestorStatus)
