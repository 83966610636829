import privateAPI from '../apis/private.js'
import publicAPI from '../apis/public.js'
import { GET_UNICORN_DETAILS, POST_INTERESTED_INUNICORN, GET_UNICORNS } from 'types/actionTypes'
import { handleServerError } from './CommonActions'

export const getUnicorns = (pageNum = 0, newSearch = false, country = '', sector = '', query = '', sort = '', length = 12) => (dispatch, getState) => {
  const url = `/page/unicorns?length=${length}&start=${pageNum * length}&filterCountry=${encodeURIComponent(country)}&filterSector=${encodeURIComponent(sector)}&searchTerm=${query}&orderBy=${sort}`
  const totalCompanies = pageNum * length

  if (getState().unicorn.totalValuations !== 1 && (totalCompanies !== getState().unicorn.companies.length) && !newSearch) {
    const totalUnicorns = getState().unicorn.totalUnicorns
    const sectors = getState().unicorn.sectors
    const countries = getState().unicorn.countries
    const totalValuations = getState().unicorn.totalValuations
    const companies = getState().unicorn.companies
    const hasMore = true
    return Promise.resolve('Success').then(() => {
      dispatch({
        type: GET_UNICORNS,
        payload: { companies, totalUnicorns, sectors, countries, totalValuations, hasMore }
      })
    })
  } else {
    return privateAPI
      .get(url)
      .then(res => {
        const totalUnicorns = res.data.totalUnicorns ? res.data.totalUnicorns : getState().unicorn.totalUnicorns
        const sectors = res.data.sectors ? res.data.sectors : getState().unicorn.sectors
        const countries = res.data.countries ? res.data.countries : getState().unicorn.countries
        const totalValuations = res.data.totalValuations ? res.data.totalValuations : getState().unicorn.totalValuations
        const companies = newSearch ? res.data.companies : getState().unicorn.companies ? getState().unicorn.companies.concat(res.data.companies) : res.data.companies
        const hasMore = res.data.companies.length === length
        dispatch({
          type: GET_UNICORNS,
          payload: { ...res.data, companies, totalUnicorns, sectors, countries, totalValuations, hasMore }
        })
      })
      .catch(err => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
}

export const getUnicornsDetails = (id) => (dispatch, getState) => {
  const { isSignedIn } = getState().user
  const api = isSignedIn ? privateAPI : publicAPI
  return api
    .get(`/page/unicorns/${id}`)
    .then(res => {
      dispatch({
        type: GET_UNICORN_DETAILS,
        payload: { ...res.data }
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const setInterestedInUnicorn = (urlName, interested) => (dispatch) => {
  return privateAPI
    .post(`/page/unicorns/${urlName}`, { interested })
    .then((res) => {
      dispatch({
        type: POST_INTERESTED_INUNICORN,
        payload: res && res.data
      })
      return 200
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return err.response.data.error
    })
}
