import { Suspense, useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { Wallet as connected } from 'containers'
import { UpholdBalance, PageLoading, ConditionalRender } from 'components'
import Button from '../Global/Button'
import history from '../../history'
import { images } from 'assets'
import { CircleLoader, ImgWithLoader } from '../Global/ImageWithLoader'
import { seoTitleTemplate } from 'utils'
import LinqtoBalance from './partials/LinqtoBalance'
import LinqtoBanner from './partials/LinqtoBanner'
import SeoMeta from 'components/Global/SeoMeta'
import NotificationBanner from '../Global/NotificationBanner'
const Wallet = ({ uphold, actions, location }) => {
  const [showErrPage, setShowErrPage] = useState(false)
  const [showNotificationBanner, setShowNotificationBanner] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)

  useEffect(() => {
    Cookies.set('redirectPath', location.pathname)
    setPageLoading(true)
    actions.getWalletPage().then((res) => {
      setPageLoading(false)
      if (res === 'UPHOLD_EMAIL_VERIFICATION_REQUIRED') {
        setShowErrPage(true)
      } else if (
        res && res.hasUpholdWallet &&
        sessionStorage.getItem('connectUphold')
      ) {
        setShowNotificationBanner(true)
        sessionStorage.removeItem('connectUphold', true)
      }
    })
  }, [])

  const addFunds = () => {
    localStorage.setItem('queue-action', 'transfer')
    history.push('/wallet/transfer-funds')
  }

  const connectWallet = () => {
    sessionStorage.setItem('connectUphold', true)
    actions.connectUphold()
  }

  const disconnectWallet = () => {
    setShowErrPage(false)
    setShowNotificationBanner(false)
    actions.disconnectUphold()
  }

  const { hasUpholdWallet, cards, isUpholdAchEnabled, linqtoBucks } = uphold
  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Wallet')} />
        <PageLoading />
      </>
    )
  }
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Wallet')} />
      {hasUpholdWallet && showNotificationBanner && (
        <NotificationBanner
          text={'Your wallet is now connected!'}
          delay={500}
          stay={2500}
        />
      )}
      <div className='page-container wallet-container'>
        <div className='inner-container'>
          <h1 className='page-title'>Wallet</h1>
          <ConditionalRender isVisible={hasUpholdWallet} className='wallet'>
            <div className='wallet-accounts'>
              <div className='wallet-accounts-container'>
                <UpholdBalance
                  isUpholdAchEnabled={isUpholdAchEnabled}
                  cards={cards}
                  addFunds={addFunds}
                  onSelect={null}
                />
                <a
                  className='inline-text-link'
                  onClick={() => disconnectWallet()}
                >
                  Disconnect Wallet
                </a>
                <LinqtoBalance balance={linqtoBucks} />
              </div>
              <LinqtoBanner />
            </div>
          </ConditionalRender>
          <ConditionalRender
            isVisible={!hasUpholdWallet && !showErrPage}
            className='wallet'
          >
            <div className='wallet-connect'>
              <div className='wallet-connect-copy'>
                <div className='content'>
                  Connect your Uphold wallet to quickly and easily fund your
                  next Linqto purchase - free of hassle!
                </div>
                <div className='mobile-container'>
                  <div className='mobile-copy content'>
                    Uphold&apos;s platform gives you the ability to go from
                    &quot;anything to anything&quot; in the click of a button,
                    from national currencies, to cryptocurrencies & utilities
                    tokens, US stocks (fractional equities) and precious metals.
                  </div>
                  <div className='mobile-image'>
                    <Suspense fallback={<CircleLoader />}>
                      <ImgWithLoader
                        alt='uphold not connect'
                        src={images['wallet-not-connect']}
                      />
                    </Suspense>
                  </div>
                </div>
                <div className='wallet-connect-cta'>
                  <Button
                    mode='primary'
                    size='md'
                    customClass='wallet-connect-btn'
                    onClick={() => connectWallet()}
                  >
                    <img
                      alt='uphold not connect'
                      src={images['wallet-connect-icon']}
                    />
                    Connect Uphold Wallet
                  </Button>
                  <div className='content'>
                    If you don&apos;t have an Uphold account yet, you can{' '}
                    <a
                      className='inline-text-link'
                      onClick={() => connectWallet()}
                    >
                      create one now.
                    </a>
                  </div>
                </div>
              </div>
              <div className='wallet-connect-image'>
                <div className='wallet-connect-image-bg' />
                <Suspense fallback={<CircleLoader />}>
                  <img
                    className='image'
                    alt='uphold not connect'
                    src={images['wallet-not-connect']}
                  />
                </Suspense>
              </div>
            </div>
          </ConditionalRender>
          <ConditionalRender isVisible={showErrPage}>
            <div className='wallet-error'>
              <h3>Almost there! Check your email.</h3>
              <div className='content'>
                {' '}
                Confirm your email to complete your Uphold Wallet account.{' '}
              </div>
              <div className='content wallet-support'>
                Need help?{' '}
                <a href='/contact' className='inline-text-link' target='_blank'>
                  Contact Us
                </a>
              </div>
            </div>
          </ConditionalRender>
        </div>
      </div>
    </>
  )
}

export default connected(Wallet)
