import Modal from 'components/Global/Modal'
import Button from 'components/Global/Button'
import history from '../../history'
import { images } from 'assets'
const CompleteTransfer = () => {
  return (
    <Modal size='md' modalHeader='Transfer' mode='primary'>
      <div className='items-center'>
        <img alt='pdf' src={images['transfer-complete']} />
        <h3 style={{ margin: '1rem 0' }}>Transfer completed</h3>
      </div>
      <div className='btn-group two'>
        <Button onClick={() => history.push('/wallet')} mode='secondary'>See Wallet</Button>
        <Button onClick={() => history.push('/products')} mode='primary'>Invest Now</Button>
      </div>
    </Modal>
  )
}

export default CompleteTransfer
