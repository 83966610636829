import { DISCONNECT_UPHOLD, GET_UPHOLD_ACCOUNTS, GET_UPHOLD_PLAID_LINK_TOKEN, GET_PLAID_ACCOUNTS, GET_ACH, UPHOLD_UNKNOWN_ERROR, RESET_ERR, GET_UPHOLD_DEPOSIT } from 'types/actionTypes'
import privateAPI from '../apis/private'
import parseUpholdAccounts from './parsing/parseUpholdAccounts'
import { handleServerError } from './CommonActions'
import parseErrorMessages from './parsing/parseErrorMessages'
/**
 * @function connectUphold
 * Connects to Uphold Wallet
 * @returns uphold url opened in browser
 */
export const connectUphold = () => (dispatch, getState) => {
  privateAPI
    .get('/getUpholdConnectUrl')
    .then(res => {
      return window.open(res.data.url, '_self')
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

/**
 * @function getUpholdAccounts
 * Gets list of Uphold Account Balances
 * @returns Array of Accounts
 */
export const getUpholdAccounts = () => (dispatch, getState) => {
  return privateAPI
    .get('/page/paymentMethod')
    .then(res => {
      dispatch({
        type: GET_UPHOLD_ACCOUNTS,
        payload: parseUpholdAccounts(res.data)
      })
      return 'success'
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return err.response.data.error
    })
}
/**
 * @function getWalletPage
 * Gets list of Uphold Account Balances
 * @returns Array of Accounts
 */
export const getWalletPage = () => (dispatch, getState) => {
  return privateAPI
    .get('/page/wallet')
    .then(res => {
      dispatch({
        type: GET_UPHOLD_ACCOUNTS,
        payload: parseUpholdAccounts(res.data)
      })
      return res.data
    })
    .catch(err => {
      const errRes = err.response.data.error
      if (errRes && errRes === 'UPHOLD_EMAIL_VERIFICATION_REQUIRED') {
        return errRes
      }
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

/**
 * @function createUpholdDeposit
 * Create deposit for new account
 * @returns 200, uphold OTP error, uphold unknown error, or other server error
 */
export const createUpholdDeposit = (data) => (dispatch) => {
  return privateAPI
    .post('/page/wallet/ach/deposit', data)
    .then(res => {
      if (res.status === 200) {
        return 200
      } else {
        dispatch({ type: UPHOLD_UNKNOWN_ERROR, payload: 'UPHOLD_UNKNOWN_ERROR' })
      }
    })
    .catch(err => {
      if (err.response && err.response.status === 500) {
        const data = err.response.data
        dispatch({ type: UPHOLD_UNKNOWN_ERROR, payload: parseErrorMessages(data) })
      } else {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const resetError = (dispatch) => {
  dispatch({ type: RESET_ERR })
}

export const disconnectUphold = () => dispatch => {
  privateAPI
    .post('/disconnectUphold')
    .then(res => {
      dispatch({
        type: DISCONNECT_UPHOLD
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}
/**
 * @function getUpholdPlaidLinkToken
 * Gets plaid link token from uphold
 * @returns string
 */
export const getUpholdPlaidLinkToken = () => dispatch => {
  return privateAPI
    .get('/page/wallet/ach/getPlaidLinkToken')
    .then(res => {
      dispatch({
        type: GET_UPHOLD_PLAID_LINK_TOKEN,
        payload: res.data.plaidLinkToken
      })
      return res.data.plaidLinkToken
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}
/**
 * @function walletPagePlaidAccountsRequest
 * post plaid public token
 * @returns array of plaid accounts
 */
export const walletPagePlaidAccountsRequest = (token) => dispatch => {
  return privateAPI
    .post('/page/wallet/ach/getPlaidPublicTokenAccounts', { plaidPublicToken: token })
    .then(res => {
      dispatch({
        type: GET_PLAID_ACCOUNTS,
        payload: res.data.walletPagePlaidAccounts
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}
/**
 * @function upholdLinkPlaidAccounts
 * post selected plaidAccounts array
 * @returns array of plaid accounts
 */
export const upholdLinkPlaidAccounts = (plaidAccounts) => dispatch => {
  return privateAPI
    .post('/page/wallet/ach/linkPlaidAccounts', { plaidAccounts })
    .then(res => {
      dispatch(cleanPlaidAccounts())
      return 200
    })
    .catch(err => {
      // TODO: need to handle when failed to link account
      // return if the err is uphold/plaid error
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}
/**
 * @function getWalletPageAch
 * get user bank accounts and daily Limits
 * @returns array of plaid accounts
 */

export const getWalletPageAch = () => dispatch => {
  return privateAPI
    .get('/page/wallet/ach')
    .then(res => {
      dispatch({
        type: GET_ACH,
        payload: res.data
      })
      return res.data
    })
    .catch(err => console.log('err', err))
}

/**
 * @function getWalletPageAchDeposit
 * get banks and isOtpRequired value
 * @returns array of plaid accounts
 */

export const getWalletPageAchDeposit = () => dispatch => {
  return privateAPI
    .get('/page/wallet/ach/deposit')
    .then(res => {
      dispatch({
        type: GET_UPHOLD_DEPOSIT,
        payload: res.data
      })
      return res.data
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const cleanPlaidAccounts = () => dispatch => {
  dispatch({
    type: GET_PLAID_ACCOUNTS,
    payload: []
  })
}
