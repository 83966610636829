
import { reducer as formReducer } from 'redux-form'
import UserReducer from './UserReducer'
import PlaceOrderReducer from './PlaceOrderReducer'
import ProfileReducer from './ProfileReducer'
import PortfolioReducer from './PortfolioReducer'
import OrderReducer from './OrderReducer'
import WalletReducer from './WalletReducer'
import CommonReducer from './CommonReducer'
import InvestorStatusReducer from './InvestorStatusReducer'
import FinancialAdvisorReducer from './FinancialAdvisorReducer'
import KycReducer from './KycReducer'
import SecurityReducer from './SecurityReducer'
import RewardReducer from './RewardReducer'
import LinqtoPortfolioReducer from './LinqtoPortfolioReducer'
import UnicornsReducer from './UnicornsReducer'
import InvestReducer from './InvestReducer'
import OriginationReducer from './OriginationReducer'
import BrokerInfoReducer from './BrokerInfoReducer'

export default {
  form: formReducer,
  user: UserReducer,
  profile: ProfileReducer,
  portfolio: PortfolioReducer,
  order: OrderReducer,
  placeOrder: PlaceOrderReducer,
  wallet: WalletReducer,
  common: CommonReducer,
  investorStatus: InvestorStatusReducer,
  financialAdvisor: FinancialAdvisorReducer,
  KYC: KycReducer,
  security: SecurityReducer,
  reward: RewardReducer,
  linqtoPortfolio: LinqtoPortfolioReducer,
  unicorn: UnicornsReducer,
  invest: InvestReducer,
  origination: OriginationReducer,
  broker: BrokerInfoReducer
}
