import { Wrapper, Row, Content, Image } from 'components'
import {
  cleanSeriesName,
  formatDecimal,
  formatWholeNumber,
  toCamelCase,
  formatDate
} from 'utils'
import StatusTag from './partials/StatusTag'
import ProductDocuments from './partials/ProductDocuments'
import { isMobile } from 'react-device-detect'
const OrderSection = ({
  isOrderConfirm,
  children,
  company,
  order,
  documents,
  orderId,
  status
}) => (
  <Wrapper className='order-group'>
    <Content className='product-header'>
      <Image
        alt={`Linqto ${company.name} series`}
        src={company.s3IconUrl}
        className='icon'
      ></Image>
      <h1 className='product-name'>{company.name}</h1>
      {!isOrderConfirm && <div className='series'>Series {cleanSeriesName(order.seriesName)}</div>}
      {isOrderConfirm && (
        <div className='price'>
          {formatDecimal(order.sharePrice)}{' '}
          <span>
            per Share
          </span>
        </div>
      )}
      {!isMobile && <ProductDocuments documents={documents} />}
    </Content>
    <Content className='product-order box'>
      {isOrderConfirm ? (
        <>
          <div className='buy-product'>
            <div className='title'>{"Let's review your investment."}</div>
            <div className='summary'>
              You are buying {formatWholeNumber(order.shares)} shares of{' '}
              {company.name} at {formatDecimal(order.sharePrice)} {' '}
              per share.
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='buy-product'>
            <div className='title'><span>Order {orderId}</span> {status && <StatusTag status={status.toUpperCase()}/>}</div>
            <div className='summary'>
              This order includes {formatWholeNumber(order.shares)} shares of{' '}
              {company.name} at {formatDecimal(order.sharePrice)}{' '}
              per share.
            </div>
          </div>
        </>
      )}
      <div className='place-order-box review'>
        <Row className='place-order-box__details'>
          <div>Payment Method</div>
          <div>{order.total === 0 ? 'Linqto Bucks' : toCamelCase(order.paymentType)}</div>
        </Row>
        {!isOrderConfirm &&
          <><Row className='place-order-box__details'>
            <div>Ordered At</div>
            <div>{formatDate(order.createdAt)}</div>
          </Row>
          {order.status !== 'Completed' && order.status !== 'Rejected' && order.status !== 'Canceled' && (
            <Row className='place-order-box__details'>
              <div>Expires At</div>
              <div>{formatDate(order.expiresAt)}</div>
            </Row>
          )}
          </>
        }

        {order.entityName && (
          <Row className='place-order-box__details'>
            <div>Purchase As</div>
            <div className='max-50'>{order.entityName}</div>
          </Row>
        )}
        {order.paymentType && order.paymentType.toLowerCase() === 'uphold' && (
          <>
            <Row className='place-order-box__details'>
              <div>Uphold Exchange Rate</div>
              <div>{order.upholdExchangeRate}</div>
            </Row>
            <Row className='place-order-box__details'>
              <div>Uphold Account</div>
              <div>{order.upholdCardLabel}</div>
            </Row>
          </>
        )}
        <div className='line'></div>
        <Row className='place-order-box__details'>
          <div>
            Total
            <small>{' '}(before applying credit)</small>
          </div>
          <div>{formatDecimal(order.amount)}</div>
        </Row>
        {order.paymentType && order.paymentType.toLowerCase() === 'uphold' && (
          <>
            <Row className='place-order-box__details'>
              <div>Uphold Fee</div>
              <div>{formatDecimal(order.upholdFee)}</div>
            </Row>
            {order.upholdCommission > 0 && <Row className='place-order-box__details'>
              <div>Uphold Commission</div>
              <div>{formatDecimal(order.upholdCommission)}</div>
            </Row>}
          </>
        )}
        <Row className='place-order-box__details'>
          <div>Linqto Fee</div>
          <div>{formatDecimal(order.linqtoFee)}</div>
        </Row>
        {order.linqtoBucksUsed > 0 && <Row className='place-order-box__details'>
          <div>Linqto Bucks</div>
          <div>
              -{formatDecimal(order.linqtoBucksUsed)}
          </div>
        </Row>}
        <div className='line'></div>
        <Row className='place-order-box__details'>
          <div>
            <b className='total-amt'>TOTAL</b>
          </div>
          <div className='total-amt'>{formatDecimal(order.total)}</div>
        </Row>
        <div className='info'>
          *You are purchasing the number of units in the series that represents
          the equivalent number of shares identified above.
        </div>
        { isMobile && <div className='place-order-m-content'>
          {<ProductDocuments documents={documents} />}
        </div>}
        {children}
      </div>
    </Content>
  </Wrapper>
)

export default OrderSection
