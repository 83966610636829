import React from 'react'
import Input from '../Global/InputField'

const renderInput = ({ input, label, placeholder, meta, type, autoComplete, className, disabled, children, viewPassword, disableLabel }) => {
  return (
    <Input input={input} meta={meta} label={label} placeholder={placeholder} type={type} autoComplete={autoComplete} className={className} disabled={disabled} viewPassword={viewPassword} disableLabel={disableLabel}>{children}</Input>
  )
}

export default {
  renderInput
}
