import { useState, useEffect } from 'react'
import { ConditionalRender, PageLoading } from 'components'
import connected from '../../containers/UnicornsDetails'
import parsingUnicorns from '../../actions/parsing/parsingUnicorns'
import SeoMeta from 'components/Global/SeoMeta'
import UnicornDetailsHeader from './partials/UnicornDetailsHeader'
import UnicornDetailsTabs from './partials/UnicornDetailsTabs'
import UnicornDetailsSummary from './partials/UnicornDetailsSummary'
import UnicornDetailsFunding from './partials/UnicornDetailsFunding'
import UnicornDetailsPartners from './partials/UnicornDetailsPartners'
import UnicornMosaicModal from './partials/UnicornMosaicModal'

const UnicornDetails = ({ company, isLoggedIn, actions, match }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [interestedUnicron, setInterestedUnicorn] = useState({})
  const [pageLoading, setPageLoading] = useState(true)
  const [parsedCompany, setParsedCompany] = useState({})
  const unicornId = match.params.id

  const setInterested = (urlName, interested) => {
    setButtonLoading(true)
    actions.setInterestedInUnicorn(urlName, interested).then((res) => {
      if (res === 200) setInterestedUnicorn(interested)
      setButtonLoading(false)
    })
  }

  useEffect(() => {
    if (unicornId) {
      setPageLoading(true)
      actions.getUnicornsDetails(unicornId).then(() => {
        setPageLoading(false)
      })
    }
  }, [unicornId, actions])

  useEffect(() => {
    if (unicornId) {
      setParsedCompany(parsingUnicorns(company, isLoggedIn))
    }
  }, [company, isLoggedIn, unicornId])

  useEffect(() => {
    setInterestedUnicorn(company.isInterested)
  }, [company.isInterested])

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={'Linqto Market Insights | Linqto | private investing made simple'} />
        <PageLoading />
      </>)
  }

  return (
    <>
      <SeoMeta title={`${company.name} | Linqto Market Insights`} />
      <div className='unicorns-details-container'>
        {company && <UnicornDetailsHeader isLoggedIn={isLoggedIn} company={parsedCompany} setInterestedUnicorn={setInterested} buttonLoading={buttonLoading} interestedUnicron={interestedUnicron}/>}
        <div className='page-container'>
          <div className='inner-container'>
            <div className='container'>
              <UnicornDetailsTabs activeIndex={activeIndex} setActiveIndex={setActiveIndex} company={parsedCompany}/>
              <ConditionalRender isVisible={activeIndex === 0 }>
                <UnicornDetailsSummary company={parsedCompany} isLoggedIn={isLoggedIn} setShowModal={setShowModal} />
              </ConditionalRender>
              <ConditionalRender isVisible={activeIndex === 1 }>
                <UnicornDetailsFunding company={parsedCompany} isLoggedIn={isLoggedIn} />
              </ConditionalRender>
              <ConditionalRender isVisible={activeIndex === 2 }>
                <UnicornDetailsPartners company={parsedCompany} isLoggedIn={isLoggedIn} />
              </ConditionalRender>
            </div>
          </div>
        </div>
      </div>
      <ConditionalRender isVisible={showModal}>
        <UnicornMosaicModal setShowModal={setShowModal}/>
      </ConditionalRender>
    </>
  )
}

export default connected(UnicornDetails)
