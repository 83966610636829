export const SIGN_IN = 'SIGN_IN'
export const SIGN_UP = 'SIGN_UP'
export const RESET_ERR = 'RESET_ERR'
export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const GET_CORE_USER = 'GET_CORE_USER'
export const SIGN_OUT = 'SIGN_OUT'
export const RESET_PASSWORD_EMAIL = 'RESET_PASSWORD_EMAIL'
export const UPDATE_LOADING = 'UPDATE_LOADING'
export const GET_CONFIRM_DETAILS = 'GET_CONFIRM_DETAILS'
export const PLACE_ORDER_ERROR = 'PLACE_ORDER_ERROR'
export const CLEAR_ORDER_ERROR = 'CLEAR_ORDER_ERROR'
export const COMMIT_ORDER = 'COMMIT_ORDER'
export const GET_RECENT_ORDERS = 'GET_RECENT_ORDERS'
export const GET_ORDER_CONFIRMATION = 'GET_ORDER_CONFIRMATION'
export const GET_UPHOLD_ACCOUNTS = 'GET_UPHOLD_ACCOUNTS'
export const CREATE_UPHOLD_DEPOSIT = 'CREATE_UPHOLD_DEPOSIT'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const EDIT_PROFILE = 'EDIT_PROFILE'
export const GET_PORTFOLIO = 'GET_PORTFOLIO'
export const GET_ORDER_DETAIL = 'GET_ORDER_DETAIL'
export const MESSAGE_TRADER = 'MESSAGE_TRADER'
export const GET_PRODUCT_ORDERS = 'GET_PRODUCT_ORDERS'
export const PREFILL_EMAIL = 'PREFILL_EMAIL'
export const DISCONNECT_UPHOLD = 'DISCONNECT_UPHOLD'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CONTACT_US = 'CONTACT_US'
export const HANDLE401 = 'HANDLE401'
export const RESET_LOADING = 'RESET_LOADING'
export const SET_LOADING_SPINNER = 'SET_LOADING_SPINNER'
export const HIDE_LOADING_SPINNER = 'HIDE_LOADING_SPINNER'
export const GET_INVESTOR_STATUS = 'GET_INVESTOR_STATUS'
export const UPLOAD_LOADING = 'UPLOAD_LOADING'
export const REQUEST_FINANCIAL_ADVISOR = 'REQUEST_FINANCIAL_ADVISOR'
export const GET_FINANCIAL_ADVISOR = 'GET_FINANCIAL_ADVISOR'
export const DELETE_AI_DOCS = 'DELETE_AI_DOCS'
export const DELETE_FA_DOCS = 'DELETE_FA_DOCS'
export const UPLOAD_AI = 'UPLOAD_AI'
export const GET_EBOOK = 'GET_EBOOK'
export const FINISH_SIGN_UP = 'FINISH_SIGN_UP'
export const GET_IDENTITY = 'GET_IDENTITY'
export const GET_FINISH_SIGN_UP = 'GET_FINISH_SIGN_UP'
export const HANDLE_SERVER_ERROR = 'HANDLE_SERVER_ERROR'
export const GET_DOCUMENTS = 'GET_DOCUMENTS'
export const GET_WIRE_INSTRUCTION = 'GET_WIRE_INSTRUCTION'
export const GET_SECURITY_PAGE = 'GET_SECURITY_PAGE'
export const SYSTEM_ERROR = 'SYSTEM_ERROR'
export const GET_PRIVACY = 'GET_PRIVACY'
export const GET_TERMS_OF_USE = 'GET_TERMS_OF_USE'
export const GET_SECURITY = 'GET_SECURITY'
export const ADD_SECURITY_MFA = 'ADD_SECURITY_MFA'
export const GET_SIGNIN_MFAS = 'GET_SIGNIN_MFAS'
export const GET_SIGNIN_QRCODE = 'GET_SIGNIN_QRCODE'
export const UPLOAD_ERROR = 'UPLOAD_ERROR'
export const UPHOLD_UNKNOWN_ERROR = 'UPHOLD_UNKNOWN_ERROR'
export const GET_FAQ_LINKS = 'GET_FAQ_LINKS'
export const GET_UPHOLD_PLAID_LINK_TOKEN = 'GET_UPHOLD_PLAID_LINK_TOKEN'
export const GET_PLAID_ACCOUNTS = 'GET_PLAID_ACCOUNTS'
export const GET_ACH = 'GET_ACH'
export const GET_UPHOLD_DEPOSIT = 'GET_UPHOLD_DEPOSIT'
export const GET_REWARD = 'GET_REWARD'
export const GET_SIGNUP_REFERRAL = 'GET_SIGNUP_REFERRAL'
export const GET_REWARDS_TERMS_OF_USE = 'GET_REWARDS_TERMS_OF_USE'
export const GET_BRANCH_DATA = 'GET_BRANCH_DATA'
export const GET_KYC_STATUS = 'GET_KYC_STATUS'
export const SUBMIT_KYC_STATUS = 'SUBMIT_KYC_STATUS'
export const GET_LINQTO_PORTFOLIO = 'GET_LINQTO_PORTFOLIO'
export const GET_UNICORNS = 'GET_UNICORNS'
export const GET_UNICORN_DETAILS = 'GET_UNICORN_DETAILS'
export const POST_INTERESTED_INUNICORN = 'POST_INTERESTED_INUNICORN'
export const GET_CONTACT_US = 'GET_CONTACT_US'
export const GET_INVEST = 'GET_INVEST'
export const GET_INVEST_DETAILS = 'GET_INVEST_DETAILS'
export const SEARCH_ORIGINATION = 'SEARCH_ORIGINATION'
export const GET_ORIGINATION_DETAILS = 'GET_ORIGINATION_DETAILS'
export const GET_ORIGINATION_OFFER = 'GET_ORIGINATION_OFFER'
export const COMMIT_ORIGINATION_OFFER = 'COMMIT_ORIGINATION_OFFER'
export const GET_ORIGINATION_CONFIRMED = 'GET_ORIGINATION_CONFIRMED'
export const ORIGINATION_ERROR = 'ORIGINATION_ERROR'
export const GET_BROKER_INFO = 'GET_BROKER_INFO'
export const SUBMIT_BROKER_INFO = 'SUBMIT_BROKER_INFO'
export const TOGGLE_INTEREST = 'TOGGLE_INTEREST'
