import {
  GET_INVESTOR_STATUS,
  UPLOAD_LOADING,
  REQUEST_FINANCIAL_ADVISOR,
  UPLOAD_ERROR
} from 'types/actionTypes'

const INITIAL_STATE = {
  accreditedAt: '',
  accreditedCountry: '',
  accreditedStatus: '',
  RDocuments: [],
  ODocuments: [],
  selfAccreditation: '',
  uploadingStatus: 'init',
  requestStatus: 'init',
  hasRequestedFinancialAdvisor: false,
  allowDocumentDelete: true,
  crdNumber: 0,
  uploadErr: false,
  isSignedIn: false,
  hasKyc: false,
  isUserProfileComplete: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case GET_INVESTOR_STATUS: {
    const documents = action.payload.documents
    const RDocuments = []
    const ODocuments = []
    for (let i = 0; i < documents.length; i++) {
      const ele = documents[i]
      if (
        ele.name === 'Last year W2' ||
        ele.name === 'Previous year W2' ||
        ele.name === 'Last year tax returns' ||
        ele.name === 'Previous year tax returns' ||
        ele.name === 'Last year 1099' ||
        ele.name === 'Previous year 1099' ||
        ele.name === 'Spouse Last year W2' ||
        ele.name === 'Spouse Previous year W2' ||
        ele.name === 'Spouse Last year tax returns' ||
        ele.name === 'Spouse Previous year tax returns' ||
        ele.name === 'Spouse Last year 1099' ||
        ele.name === 'Spouse Previous year 1099'
      ) {
        RDocuments.push(ele)
      } else {
        ODocuments.push(ele)
      }
    }

    return { ...state, ...action.payload, RDocuments, ODocuments }
  }
  case UPLOAD_LOADING:
    return { ...state, ...action.payload }
  case REQUEST_FINANCIAL_ADVISOR:
    return { ...state, ...action.payload }
  case UPLOAD_ERROR:
    return { ...state, ...action.payload }
  default:
    return state
  }
}
