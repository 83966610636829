import { formatNumberWithLetter } from 'utils'
import moment from 'moment'

const InvestDetailValuationHeader = ({ lastRound, postMoney, status, totalRaised, impliedValuation, isSoldOut }) => {
  const ImpliedValuation = (isSoldOut || status === 'EXITED') ? 'TBD' : impliedValuation ? formatNumberWithLetter(impliedValuation, true, true, 2, true) : ''
  return (
    <div className='three column mobile-one row'>
      <div className='column'>
        <div className='row'>
          <span className='semi-bold dark full-line mobile-title'>Valuation & Fundraising</span>
        </div>
        <div className='row'>
          <span className='semi-bold mobile-normal dark'>Last Round: </span>
          <span>{lastRound && lastRound.date &&
                moment(lastRound.date).format('MMM. YYYY')}, {lastRound && lastRound.round} </span>
        </div>
        <div className='row'>
          <span className='semi-bold mobile-normal dark'>Valuation Post-Money: </span>
          <span>{postMoney &&
                formatNumberWithLetter(postMoney, true, true, 2, true)}</span>
        </div>
      </div>
      <div className='column'>
        <div className='row'>
          <span className='semi-bold mobile-normal dark no-title'>Amount Raised: </span>
          <span> {lastRound && lastRound.amount &&
                formatNumberWithLetter(lastRound.amount, true, true, 2, true)}</span>
        </div>
        <div className='row'>
          <span className='semi-bold mobile-normal dark'>Total Funds Raised: </span>
          <span> {totalRaised &&
                formatNumberWithLetter(totalRaised, true, true, 2, true)}</span>
        </div>
      </div>
      <div className='column'>
        <div className='row rounded bordered floated-right'>
          <span className='semi-bold dark full-line mobile-line'>Linqto Implied Valuation:</span>
          <span className='semi-bold dark full-line mobile-line'>{ImpliedValuation}</span>
        </div>
      </div>
    </div>
  )
}
export default InvestDetailValuationHeader
