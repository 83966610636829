import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ portfolio }) => ({
  companies: portfolio.companies,
  recentOrders: portfolio.recentOrders,
  isLoggedIn: portfolio.isLoggedIn
})

const requiredActions = ['getPortfolio']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
