import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ portfolio, user, common }) => ({
  company: portfolio.company,
  filterOrders: portfolio.filterOrders,
  isSignedIn: user.isSignedIn,
  spinnerVisible: common.spinnerVisible
})

const requiredActions = ['getProductOrders', 'showLoadingSpinner', 'hideLoadingSpinner']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
