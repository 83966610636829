import { useState, useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import { BaseModal, PageLoading } from 'components'
import Cropper from 'react-cropper'
import Button from '../../Global/Button'
import { images } from 'assets'

function ManualUpload ({ documentName, disabled, input }) {
  const crop = useRef()
  const [image, setImage] = useState('')
  const [cropper, setCropper] = useState()
  const [showModal, setShowModal] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [uploadError, setUploadError] = useState('')
  const [thumbImage, setthumbImage] = useState('')
  const [modalLoading, setModalLoading] = useState(true)
  const [buttonLoading, setBttonLoading] = useState(false)

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles, acceptedFile, fileRejections) => completeDrop(acceptedFiles, fileRejections),
    onDragEnter: () => setDragging(true),
    onDragLeave: () => setDragging(false),
    accept: '.docx, .png, .jpg, .jpeg',
    maxFiles: 1,
    disabled: disabled,
    multiple: false
  })

  const completeDrop = (acceptedFiles, fileRejections) => {
    setModalLoading(false)
    setDragging(false)
    if (fileRejections.length > 0) {
      setUploadError(fileRejections[0].errors[0].message)
    } else {
      setUploadError('')
      const reader = new FileReader()
      reader.onload = () => {
        setImage(reader.result)
      }
      reader.readAsDataURL(acceptedFiles[0])
      setShowModal(true)
      setModalLoading(true)
      setBttonLoading(true)
    }
  }

  const rotate = () => {
    setthumbImage('')
    const data = cropper.getCropBoxData()
    const contData = cropper.getContainerData()
    data.width = 2
    data.height = 2
    data.top = 0
    const leftNew = (contData.width / 2) - 1
    data.left = leftNew

    cropper.setCropBoxData(data)
    cropper.rotate(90)
    const canvData = cropper.getCanvasData()

    const heightOld = canvData.height
    const heightNew = contData.height
    const koef = heightNew / heightOld
    const widthNew = heightNew < heightOld ? canvData.width * koef : canvData.width
    canvData.height = heightNew
    canvData.width = widthNew
    canvData.top = 0
    if (canvData.width >= contData.width) {
      canvData.left = 0
    } else {
      canvData.left = (contData.width - canvData.width) / 2
    }
    cropper.setCanvasData(canvData)
    data.left = 0
    data.top = 0
    data.width = canvData.width
    data.height = canvData.height
    cropper.setCropBoxData(data)
  }

  const remove = () => {
    input.onChange({
      name: input.name,
      value: ''
    })
    setthumbImage('')
  }

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setthumbImage(cropper.getCroppedCanvas().toDataURL('image/jpeg'))
      input.onChange({
        name: input.name,
        value: cropper.getCroppedCanvas().toDataURL('image/jpeg')
      })
    }
    hideModal()
  }

  const hideModal = () => {
    setShowModal(false)
    setModalLoading(true)
    setImage('')
  }

  return (
    <>
      <section className='drop-zone-container drop-zone-container-with-thumb'>
        <div {...getRootProps({ className: thumbImage === '' ? 'dropzone' : 'dropzone-with-thumb-img' })}>
          <input {...getInputProps()} />
          <div
            className={`drop-area ${
              dragging && 'dragging'
            }`}
          >
            <img
              alt='upload'
              className='upload-img'
              src={images[`${
                dragging ? 'dragging' : 'upload'
              }`]}
            />
            <span className='doc-title'>{documentName}</span>
          </div>
        </div>
        {thumbImage !== '' &&
        <div className='upload-thumb-img-container'>
          <img src={thumbImage} alt='cropped' className='upload-thumb-img'/>
          <span className='delete-thumb' onClick={remove} ><img src={images.delete} alt='Rotate Document' /></span>
        </div>
        }
      </section>
      {(uploadError !== '') && <div className='err-msg'>{uploadError}</div>}
      {showModal &&
      <>
        <BaseModal mode='primary' size='md' innerStyle='upload-document-modal kyc-manual-modal' hideModal={hideModal} modalHeader={`Upload ${documentName}`} outerStyle='dimmer-no-padding' clickToClose pressToClose crossToClose>
          <div style={{ width: '100%', position: 'relative' }}>
            <Cropper
              style={{ height: 400, width: '100%' }}
              zoomTo={0.5}
              preview='.img-preview'
              src={image}
              viewMode={1}
              minCropBoxHeight={60}
              minCropBoxWidth={60}
              background={false}
              scalable= {false}
              responsive={true}
              autoCropArea={1}
              zoomOnTouch={true}
              checkOrientation={false}
              zoomOnWheel={true}
              onInitialized={(instance) => {
                setCropper(instance)
                setModalLoading(true)
                setTimeout(() => {
                  if (instance) {
                    if (instance.imageData && instance.containerData) {
                      const widthZoomRatio = instance.containerData.width / instance.imageData.naturalWidth
                      const heightZoomRatio = instance.containerData.height / instance.imageData.naturalHeight
                      const zoomRatio = widthZoomRatio < heightZoomRatio ? widthZoomRatio : heightZoomRatio
                      instance.zoomTo(zoomRatio)
                    }
                  }
                  setModalLoading(false)
                }, 750)
              }}
              ref={crop}
              ready = { () => {
                const cropper = crop.current.cropper
                if (cropper.containerData && cropper.imageData) {
                  const widthZoomRatio = cropper.containerData.width / cropper.imageData.naturalWidth
                  const heightZoomRatio = cropper.containerData.height / cropper.imageData.naturalHeight
                  const zoomRatio = widthZoomRatio < heightZoomRatio ? widthZoomRatio : heightZoomRatio
                  cropper.zoomTo(zoomRatio)
                  setModalLoading(false)
                  setBttonLoading(false)
                }
              } }
              guides={true}
            />
          </div>
          <div>
            <button className='rotate-btn' onClick={rotate} >
              <img src={images.icon_rotate} alt='Rotate Document' />
            </button>
            <div className='btn-group'>
              <Button mode='primary' size='md' onClick={getCropData} customClass='request-btn' loading={buttonLoading}>Save</Button>
            </div>
          </div>
          {modalLoading && <PageLoading /> }
        </BaseModal>
      </>
      }
    </>
  )
}

export default ManualUpload
