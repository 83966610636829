import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ investorStatus, user, common }) => ({
  accreitedAt: investorStatus.accreitedAt,
  accreditedCountry: investorStatus.accreditedCountry,
  accreditedStatus: investorStatus.accreditedStatus,
  RDocuments: investorStatus.RDocuments,
  ODocuments: investorStatus.ODocuments,
  selfAccreditation: investorStatus.selfAccreditation,
  hasRequestedFinancialAdvisor: investorStatus.hasRequestedFinancialAdvisor,
  isSignedIn: investorStatus.isSignedIn,
  spinnerVisible: common.spinnerVisible,
  verifiedDocument: investorStatus.verifiedDocument,
  crdNumber: investorStatus.crdNumber,
  uploadErr: investorStatus.uploadErr,
  hasKyc: investorStatus.hasKyc,
  isUserProfileComplete: investorStatus.isUserProfileComplete
})

const requiredActions = ['getInvestorStatus', 'showLoadingSpinner', 'hideLoadingSpinner', 'saveSelfAccreditation', 'saveSecurityLicense', 'resetUploadErr', 'getCountries']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
