import { Wrapper, Content, Image } from 'components'
import { images } from 'assets'
import Button from '../../Global/Button'

const LinqtoUnicrons = ({ linqtoUnicornAction }) => (
  <Wrapper className='container unicorns-container'>
    <img src={images['radial-lines']} className='unicorn-backgeound' alt='unciron background'/>
    <div className='inner-container'>
      <div className='hero-container'>
        <Image alt='Linqto Unicorns' onClick={linqtoUnicornAction} className='hero-img' src={images['unicorn-hard-light']} />
        <Content className='text-content'>
          <h1 className='linqto-header'>Linqto Market Insights Daily</h1>
          <div className='powered-by'>Powered by <img alt='CB Insights' src={images.cbinsightslogo} /></div>
          <div className='linqto-content'>
          Industry leading market insights provided to our members
          </div>
          <Button mode='special' size='md' onClick={linqtoUnicornAction}>Market Insights</Button>
        </Content>
      </div>
    </div>
  </Wrapper>
)

export default LinqtoUnicrons
