import { Wrapper, Content, Image } from 'components'
import { images } from 'assets'
import Button from '../../Global/Button'

const Uphold = ({ upholdText, upholdAction }) => (
  <Wrapper className='container uphold-container'>
    <div className='inner-container'>
      <div className='uphold-inner-container'>
        <Content className='text-content'>
          <span className='uphold-title'>‘Anything to Anything’</span>
          <span className='uphold-content'>
            Connect your Uphold wallet. Transfer easily and globally
            to make your Linqto investments.
          </span>
          <Button mode='secondary' size='md' onClick={upholdAction}>{upholdText}</Button>
        </Content>
        <Image alt='connect with uphold wallet' src={images['uphold-cards']} />
      </div>
    </div>
  </Wrapper>
)

export default Uphold
