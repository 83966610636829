import { useEffect, useState, useCallback } from 'react'
import { ManualVerification as connected } from 'containers'
import { PageLoading, ConditionalRender } from 'components'
import Button from '../../Global/Button'
import { Field, reduxForm, SubmissionError, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import 'react-phone-input-2/lib/style.css'
import history from '../../../history'
import renderer from './renderer'
import _ from 'lodash'
import AddressInput from './AddressInput'
import BirthInput from './BirthInput'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'

const DOC = [
  {
    label: 'Passport',
    type: 'ID',
    name: 'passport'
  },
  {
    label: "Driver's License (Front Side)",
    type: 'ID',
    name: 'idFrontFile'
  },
  {
    label: "Driver's License (Back Side)",
    type: 'ID',
    name: 'idBackFile'
  },
  {
    label: 'Selfie',
    type: 'ID',
    name: 'selfieFile'
  }
]

const documentTypes = [{ label: 'Passport', value: 'PASSPORT' }, { label: 'Driver\'s License', value: 'DRIVERS_LICENSE' }]

const ManualVerification = ({ isKycVerified, isReviewPending, formValues, actions, handleSubmit }) => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [serverError, setServerError] = useState(false)
  const [countries, setCountries] = useState([])
  const [primaryRegions, setPrimaryRegions] = useState([])
  const [selectedType, setSelectedType] = useState('')
  const [showContent, setShowContent] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    setPageLoading(true)
    actions.getKycStatus().then(() => {
      actions.getCountries().then(res => {
        if (res && res.length > 0) {
          setCountries(res)
        }
        setPageLoading(false)
      })
    })
  }, [actions])

  useEffect(() => {
    if (isKycVerified) {
      history.push('/identity')
    }
  }, [isKycVerified])

  const onSubmit = useCallback(async (formValues) => {
    setSubmitLoading(true)
    const address = { ..._.pick(formValues, ['street1', 'street2', 'city', 'zip', 'state', 'country']) }
    const jsonData = { ..._.pick(formValues, ['legalName', 'birthMonth', 'birthMonth', 'birthDay', 'birthYear', 'idType']), address }
    const idFrontFile = (formValues.idType === 'PASSPORT') ? formValues.passport.value : formValues.idFrontFile.value
    const idBackFile = (formValues.idBackFile && formValues.idBackFile.value) || ''
    const selfieFile = formValues.selfieFile.value

    return actions.submitKycStatus({ idFrontFile, idBackFile, selfieFile, jsonData }).then((res) => {
      setSubmitLoading(false)
      if (res === 200) {
        setSuccess(true)
      } else {
        setServerError(true)
        const err = {}
        if (formValues.legalName === '') {
          err.firstName = 'Enter your legal name'
        }
        throw new SubmissionError(err)
      }
    })
  }, [actions])

  const renderUpload = (documents) => {
    return documents.map((doc, i) => {
      let display = false
      if (doc.name === 'selfieFile') {
        display = true
      } else {
        if ((selectedType === 'global' || selectedType === 'PASSPORT') && doc.name === 'passport') {
          display = true
        }
        if (selectedType === 'DRIVERS_LICENSE' && (doc.name === 'idFrontFile' || doc.name === 'idBackFile')) {
          display = true
        }
      }

      return (
        <div key={doc.name + i}>
          {doc.label === 'Selfie' && <div className='sub-header'>Upload Your Selfie</div>}
          <Field key={doc.name} name={doc.name} component={renderer.renderupload} label={doc.label} type='text' disabled={submitLoading || pageLoading} display={display} />
        </div>
      )
    })
  }
  const renderRequiredDocuments = (value, type = null, resetState = true) => {
    if (value) {
      setShowContent(true)
      if (type) {
        setSelectedType(type)
      } else {
        setSelectedType('')
      }
    } else {
      setShowContent(false)
      setSelectedType('')
    }
    if (formValues && formValues.values && formValues.values.pState && resetState) formValues.values.pState = ''
  }

  if (pageLoading) {
    return (
      <>
        <PageLoading />
        <SeoMeta title={seoTitleTemplate('Verify Identity')} />
      </>
    )
  }
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Verify Identity')} />
      <div className='manual-verfication-container page-container'>
        <div className='inner-container'>
          <h1 className='page-title'>Verify Identity</h1>
          <ConditionalRender isVisible={!success} >
            {!isReviewPending && <div className='content'>Submit your information for identity verification. Position your ID photos right side up before upload.​</div>}
            {isReviewPending && <div className='content'>You have already submitted your information and it is pending review.​</div>}
            <form onSubmit={handleSubmit(onSubmit)} className='manual_kyc_wrapper'>
              <div className='ui padded grid stackable'>
                <div className='four wide column tablet_eight first-col'>
                  <Field
                    countries={countries}
                    name='country'
                    component={renderer.renderDropdown}
                    label='Select your country of residence.'
                    disabled = {submitLoading || pageLoading}
                    onChange={value => renderRequiredDocuments(value, ((formValues && formValues.values && formValues.values.idType) || null))}
                    getRegions={actions.getRegions}
                    setRegions={setPrimaryRegions}
                    isCountry
                  />
                </div>
                {formValues && formValues.values && formValues.values.country &&
                    <div className='four wide column tablet_eight'>
                      <Field
                        options={documentTypes}
                        name='idType'
                        component={renderer.renderDocumentType}
                        label='Document Type*'
                        disabled = {submitLoading || pageLoading}
                        onChange={e => renderRequiredDocuments(formValues.values.country, e.target.value, false)}
                      />
                    </div>
                }
              </div>
              <ConditionalRender isVisible={showContent}>
                {(formValues && formValues.values && formValues.values.idType) && <div className='sub-header'>Upload Your Identification Documents</div>}
                {renderUpload(DOC)}
                <div className='verfication-fields'>
                  <div className='ui padded grid stackable'>
                    <div className='row'>
                      <div className='four wide column tablet_eight first-col'>
                        <Field name='legalName' component={renderer.renderInput} label='Legal Name*' placeholder='Legal Name' type='text' disabled={submitLoading || pageLoading} />
                      </div>
                      <BirthInput formValues={formValues} disabled={submitLoading || pageLoading} />
                    </div>
                    <AddressInput renderDropdown={renderer.renderDropdown} disabled={submitLoading || pageLoading} primaryRegions={primaryRegions} getRegions={actions.getRegions} setPrimaryRegions={setPrimaryRegions} />
                  </div>
                  <div className='ui padded grid stackable'>
                    <div className='error-container'>
                      {formValues && formValues.errors && renderer.renderBigErr('Please make sure to fill in all required fields')}
                      {(serverError || (formValues && formValues.submitFailed)) && renderer.renderBigErr('Please make sure to fill in all required fields')}
                    </div>
                  </div>
                  <div className='btn-group start'>
                    <Button mode='primary' type='submit' size='md' loading={submitLoading} disabled={submitLoading}>Submit</Button>
                  </div>
                </div>
              </ConditionalRender>
            </form>
          </ConditionalRender>
          <ConditionalRender isVisible={success}>
            <div className='content'>We received your documents and information. Your submission is being reviewed.​</div>
          </ConditionalRender>
        </div>
      </div>
    </>
  )
}

const validateFormValues = (formValues) => {
  const errors = {}
  if (!formValues.legalName) {
    errors.legalName = 'Enter your legal name.'
  }
  if (!formValues.country) {
    errors.country = 'Select country.'
  }
  if (!formValues.birthMonth) {
    errors.birthMonth = 'Select month.'
  }
  if (!formValues.birthDay) {
    errors.birthDay = 'Select day.'
  }
  if (!formValues.birthYear) {
    errors.birthYear = 'Select year.'
  }
  if (formValues.country && !formValues.idType) {
    errors.idType = 'Select document type'
  }
  if (formValues.idType === 'PASSPORT' && (!formValues.passport || (formValues.passport && formValues.passport.value === ''))) {
    errors.passport = 'Upload your passport.'
  }
  if (formValues.idType === 'DRIVERS_LICENSE' && (!formValues.idBackFile || (formValues.idBackFile && formValues.idBackFile.value === ''))) {
    errors.idBackFile = 'Upload your driver\'s license (back side).'
  }
  if (formValues.idType === 'DRIVERS_LICENSE' && (!formValues.idFrontFile || (formValues.idFrontFile && formValues.idFrontFile.value === ''))) {
    errors.idFrontFile = 'Upload your driver\'s license (front side).'
  }
  if (!formValues.selfieFile || (formValues.selfieFile && formValues.selfieFile.value === '')) {
    errors.selfieFile = 'Upload selfie.'
  }
  return errors
}

let formWrapped = reduxForm({
  form: 'editForm',
  validate: validateFormValues
})(ManualVerification)

const selector = formValueSelector('editForm')
formWrapped = connect(state => {
  const country = selector(state, 'country')
  return {
    country
  }
})(formWrapped)

export default connected(formWrapped)
