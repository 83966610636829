import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ user }) => ({
  errMsg: user.errMsg,
  mfaRequired: user.mfaRequired,
  isSignedIn: user.isSignedIn
})
const requiredActions = ['signIn', 'resetLoading', 'resetErr', 'getSigninMfas', 'signOut']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)
export default connected
