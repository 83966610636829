import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ user, form }) => {
  return {
    isReviewPending: user.isReviewPending,
    isKycVerified: user.isKycVerified,
    formValues: form.editForm,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
  }
}
const requiredActions = ['getKycStatus', 'submitKycStatus', 'getCountries', 'getRegions']
const actions = mapActions(requiredActions)
const connected = component => reduxConnect(mapStateToProps, actions)(component)
export default connected
