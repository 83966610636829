import aboutSlice from './aboutSlice'
import teamSlice from './teamSlice'
import userSlice from './userSlice'
import homeSlice from './homeSlice'
import securitySlice from './securitySlice'
import documentsSlice from './documentsSlice'
import placeOrderSlice from './placeOrderSlice'

export default {
  about: aboutSlice,
  team: teamSlice,
  home: homeSlice,
  userSlice: userSlice,
  placeOrderSlice: placeOrderSlice,
  securitySlice: securitySlice,
  documents: documentsSlice
}
