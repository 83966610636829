import { useState } from 'react'
import { BaseModal, ComponentRender, UpholdConnect, UpholdBalance, Wrapper } from 'components'
import ConditionalRender from '../Global/ConditionalRender'
import Button from 'components/Global/Button'

const UpholdWallet = ({ uphold, investAmount, productId, urlName, history, entityId, useLinqtoBucks, totalInvestedAmount }) => {
  const [error, setError] = useState(false)
  return (
    <div className='order-uphold'>
      {error &&
        <div className='error'>Please select a balance that is greater than the purchase amount.</div>}
      <UpholdBalance
        cards={uphold.cards}
        totalInvestedAmount={totalInvestedAmount}
        onSelect={acc => {
          if (acc.balance >= totalInvestedAmount) {
            history.push({
              pathname: `/orderconfirmation/${acc.id}`,
              search: `?companyId=${productId}&amount=${investAmount}&type=UPHOLD&productId=${productId}&entityId=${entityId}&useLinqtoBucks=${useLinqtoBucks}`,
              state: { urlName }
            })
          } else {
            setError(true)
          }
        }}
      />
    </div>
  )
}

const OrderModal = ({ investAmount, productId, urlName, uphold, hideModal, connectUphold, history, entityId, useLinqtoBucks, maxOrderLinqtoBucksPercent, linqtoBucks, upholdEmailVerficationError }) => {
  const addFunds = () => {
    localStorage.setItem('queue-action', uphold.cards && uphold.cards.length ? 'transfer' : 'transfer-no-bank')
    history.push('/wallet/transfer-funds')
  }

  const linqtoUsed = (linqtoBucks <= investAmount * maxOrderLinqtoBucksPercent) ? linqtoBucks : investAmount * maxOrderLinqtoBucksPercent
  const totalInvestedAmount = (useLinqtoBucks && maxOrderLinqtoBucksPercent > 0) ? investAmount - linqtoUsed : investAmount
  const suffAccounts = uphold.cards.filter(account => account.balance > totalInvestedAmount).length

  return (
    <BaseModal innerStyle='order-modal' hideModal={() => hideModal()} mode='primary' size='md' modalHeader={uphold.hasUpholdWallet ? 'Choose Account' : 'Payment Method'} clickToClose pressToClose crossToClose>
      <Wrapper className='order-modal-inner'>
        <ConditionalRender isVisible={!upholdEmailVerficationError}>
          <ComponentRender
            condition={uphold.hasUpholdWallet}
            ComponentOne={() =>
              <>
                <div className='insufficient-funds'>
                  {suffAccounts < 1 && <span className='uphold-no-funds'>No accounts with sufficient funds.</span>}
                  {uphold.isUpholdAchEnabled &&
                    <Button size='sm' mode={suffAccounts > 0 ? 'secondary' : 'primary' } onClick={addFunds}>Add Funds</Button>}
                </div>
                <UpholdWallet uphold={uphold} investAmount={investAmount} productId={productId} urlName={urlName} history={history} entityId={entityId} useLinqtoBucks={useLinqtoBucks} totalInvestedAmount={totalInvestedAmount}/>
              </>}
            ComponentTwo={() => (
              <div className='uphold-container'>
                <div className='uphold-payment-copy'>Quickly and seamlessly pay with Uphold Wallet. Uphold allows you to pay with your global currency or crypto of choice. If you don’t have an Uphold account yet, you can create one now.</div>
                <div>
                  <div className='uphold-btn-container'>
                    <div className='uphold-side-container'></div>
                    <div className='uphold-btn'><UpholdConnect connectUphold={connectUphold} buttonText='Connect Uphold Wallet' fullWidth stackable /></div>
                    <div className='uphold-side-container'><span>(Recommended)</span></div>
                  </div>
                  <div className='uphold-btn-container'>
                    <div className='uphold-side-container'></div>
                    <div className='uphold-btn'><UpholdConnect connectUphold={connectUphold} buttonText='Sign up with Uphold' mode='secondary' fullWidth /></div>
                    <div className='uphold-side-container'></div>
                  </div>
                </div>
              </div>
            )}
          />
          <div className='wire-link-container'>
            <span>You can also pay by <span onClick={() =>
              history.push({
                pathname: '/orderconfirmation/wire',
                search: `?companyId=${productId}&amount=${investAmount}&type=WIRE&productId=${productId}&entityId=${entityId}&useLinqtoBucks=${useLinqtoBucks}`,
                state: { urlName }
              })}>Wire Transfer</span></span>
          </div>
        </ConditionalRender>
        <ConditionalRender isVisible={upholdEmailVerficationError}>
          <div className='uphold-error-container'>
            <div className='uphold-container'>
              <div className='uphold-payment-copy'>Confirm your email to complete your Uphold Wallet account.</div>
            </div>
            <div className='wire-link-container'>
              <span>You can also pay by <span onClick={() =>
                history.push({
                  pathname: '/orderconfirmation/wire',
                  search: `?companyId=${productId}&amount=${investAmount}&type=WIRE&productId=${productId}&entityId=${entityId}&useLinqtoBucks=${useLinqtoBucks}`,
                  state: { urlName }
                })}>Wire Transfer</span></span>
            </div>
          </div>
        </ConditionalRender>
      </Wrapper>
    </BaseModal>
  )
}

export default OrderModal
