import { formatNumberWithLetter } from 'utils'
import moment from 'moment'

const UnicornDetailsFundingRounds = ({ fundingRounds, style }) => {
  return (
    <>
      <div className='ui grid unicorn-table'>
        <div className='five column row unicorn-table-header'>
          <div className='column' style={{ paddingLeft: 0 }}>Date</div>
          <div className='column'>Round</div>
          <div className='column column-right'>Amount</div>
          <div className='column column-right'>Valuation</div>
          <div className='column'>Investors</div>
        </div>
        { fundingRounds.map((f, i) =>
          <div key={`fundingRound_${i}`} className='five column row unicorn-table-body'>
            <div className={`column ${style}`}>{f.date && moment(f.date).format('MM/DD/YYYY')}</div>
            <div className={`column ${style}`}>{f.round}</div>
            <div className={`column column-right ${style}`}>{f.amount && formatNumberWithLetter(f.amount, true, true, 1, true)}</div>
            <div className={`column column-right ${style}`}>{f.postMoneyValuation && formatNumberWithLetter(f.postMoneyValuation, true, true, 1, true)}</div>
            <div className={`column ${style}`}>{f.investors}</div>
          </div>
        )}
      </div>
      <div className='unicorn-card-container'>
        {fundingRounds && fundingRounds.map((f, i) =>
          <div className='unicorn-card' key={`mfundingRound_${i}`} >
            <div className='ui grid'>
              <div className='two column row unicorn-card-row'>
                <div className={`column ${style}`}>{f.date && moment(f.date).format('MM/DD/YYYY')}</div>
                <div className={`column ${style}`}><strong>Round: </strong> {f.round}</div>
              </div>
              <div className='one column row unicorn-card-row-line-sepretaor' />
              <div className='two column row unicorn-card-row'>
                <div className={`column  ${style}`}><strong>Amount: </strong> {f.amount && formatNumberWithLetter(f.amount, true, true, 1, true)}</div>
                <div className={`column ${style}`}><strong>Valuation: </strong>{f.postMoneyValuation && formatNumberWithLetter(f.postMoneyValuation, true, true, 1, true)}</div>
              </div>
              <div className='one column row unicorn-card-row'>
                <div className={`column ${style}`}><strong>Investors: </strong>{f.investors}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default UnicornDetailsFundingRounds
