import {
  GET_BROKER_INFO,
  SUBMIT_BROKER_INFO
} from 'types/actionTypes'
import parseBroker from '../actions/parsing/parsingBroker'

const INITIAL_STATE = {
  changestatus: 'init',
  status: '',
  initialValues: {
    accountName: '',
    accountNumber: '',
    confirmAccountNumber: '',
    dtc: '',
    firmName: '',
    specialInstructions: ''
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case GET_BROKER_INFO:
    return { ...state, ...action.payload, initialValues: { ...parseBroker(action.payload) } }
  case SUBMIT_BROKER_INFO: {
    return { ...state, ...action.payload }
  }
  default:
    return state
  }
}
