import {
  GET_USER_PROFILE,
  EDIT_PROFILE
} from 'types/actionTypes'
import privateAPI from '../apis/private'
import { handleServerError } from './CommonActions'

export const getUserProfile = () => (dispatch) => {
  return privateAPI
    .get('/page/userProfile')
    .then((res) => {
      dispatch({
        type: GET_USER_PROFILE,
        payload: res.data
      })
      return res.data
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const getCountries = () => async dispatch => {
  try {
    const res = await privateAPI.get('/countries')
    if (res && res.data) {
      return res.data
    }
    return []
  } catch (err) {
    dispatch(handleServerError(err.response.data.error))
  }
}

export const getRegions = (country) => async dispatch => {
  return privateAPI
    .get(`/regions?countryName=${country}`)
    .then(res => {
      if (res && res.data) {
        return res.data
      } else {
        return []
      }
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const submitEntityCreation = () => (dispatch) => {
  return privateAPI
    .post('/page/userProfile/createEntityRequest')
    .then((res) => {
      return res.status
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return err.response.data.error
    })
}

export const editProfile = (formValues) => (dispatch) => {
  return privateAPI
    .post('/page/userProfile', { ...formValues })
    .then((res) => {
      dispatch({
        type: EDIT_PROFILE,
        payload: res.data
      })
      return res
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return err.response.data.error
    })
}
