import { images } from 'assets'
const ProgressBar = ({ hasKyc, isAccredited, isUserProfileComplete, activeStep }) => {
  const completeImg = images['progressbar-step-complete']
  const step2 = images['progressbar-step-2-grey']
  const step2active = images['progressbar-step-2-teal']
  const step3 = images['progressbar-step-3-grey']
  const step3active = images['progressbar-step-3-teal']

  return (
    <div className='progressbar-wrapper'>
      <div className={`progressbar-step ${hasKyc ? 'completed' : ''}`}>
        <img alt='Identity Step' src={completeImg} className='progressbar-icon'/>
        Identity
      </div>
      <div className='progressbar-line'></div>
      <div className={`progressbar-step ${isUserProfileComplete ? 'completed' : activeStep === 'BasicInfo' ? 'active' : ''}`}>
        <img alt='Basic Info Step' src={isUserProfileComplete ? completeImg : activeStep === 'BasicInfo' ? step2active : step2} className='progressbar-icon'/>
        Basic Info
      </div>
      <div className='progressbar-line'></div>
      <div className={`progressbar-step ${isAccredited ? 'completed' : activeStep === 'Verify' ? 'active' : ''}`}>
        <img alt='Verify Step' src={isAccredited ? completeImg : activeStep === 'Verify' ? step3active : step3} className='progressbar-icon'/>
        Verify
      </div>
    </div>
  )
}

export default ProgressBar
