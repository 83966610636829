
// Get all images from the images directory
const allImages = require.context('./images', true, /\.(png|svg|jpg)$/)

// Return an object of all images with the filename as the key.
const images = allImages.keys().reduce((images, path) => {
  const key = path.match('/(?!.*/)(.*)(.png|.svg|.jpg)')[1]
  images[key] = allImages(path).default
  return images
}, {})
export { images }
