import { images } from 'assets'
import { useEffect, useState } from 'react'
import { formatDecimal } from 'utils'

const ReferralInfo = ({ referrerName, referralAmount, referrerLogo }) => {
  const [name, setName] = useState('')

  const imgURL = (referrerLogo !== '') ? referrerLogo : images['linqto-bucks']
  const boxClass = (referrerLogo !== '') ? 'signup-reward-container partner-signup-reward-container' : 'signup-reward-container'

  // abbreviate referrer name
  useEffect(() => {
    if (referrerName !== 'Forge Trust') {
      const nameArr = referrerName ? referrerName.split(' ') : []
      const abbreviatedName = nameArr && nameArr.length ? `${`${nameArr[0]} ${nameArr.pop().charAt(0)}.`}` : ''
      setName(abbreviatedName)
    } else {
      setName(referrerName)
    }
  }, [referrerName])

  return (
    <div className={boxClass}>
      <img
        src={imgURL}
        alt='Linqto Referral Program'
      />
      <div className='signup-reward-text'>
        Your <strong>{formatDecimal(referralAmount, true, 0)}</strong> referral credit from <strong>{name}</strong> is waiting for
        you!
      </div>
    </div>
  )
}

export default ReferralInfo
