import privateAPI from '../apis/private.js'
import { GET_REWARD } from 'types/actionTypes'
import parseRewardHistory from './parsing/parseRewardHistory'
import { handleServerError } from './CommonActions'

export const getReward = () => (dispatch) => {
  return privateAPI
    .get('/page/rewards')
    .then(res => {
      const transactions = (res.data.transactions) ? parseRewardHistory(res.data.transactions) : {}
      dispatch({
        type: GET_REWARD,
        payload: { ...res.data, transactions }
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}
