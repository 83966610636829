import { images } from 'assets'
import Button from '../../Global/Button'
import { CircleLoader } from '../../Global/ImageWithLoader'

const Hero = ({ promotedCompany, history }) => {
  const { iconUrl, name, urlName } = promotedCompany

  return (
    <div className='hero-container'>
      <div className='container inner-hero-container'>
        <div className='hero-content'>
          {iconUrl.length > 0 && <div className='new-listing-news'>
            <div className='new-listing-product hide-mobile'>
              <span role='img' aria-label='New Listing'>🎉 New series</span>
            </div>
            <div className='new-listing-product pointer' onClick={() => history.push(`/products/${urlName}`)}>
              {iconUrl ? <img alt='listing-logo' src={iconUrl} className='list-logo'/> : <CircleLoader />}
              <span className='list-content'><span className='list-name'>{name}</span> available now</span>
            </div>
          </div>}
          <h1 className='hero-title'>Private Investing Made Simple</h1>
          <div className='hero-content'>Linqto helps you identify, evaluate and invest in the world&apos;s leading Unicorns.</div>
          <div className='btn-group two left'>
            <Button mode='primary' size='lg' onClick={() => history.push('/investor-status')}>Get Accredited</Button>
            <Button mode='secondary' size='lg' onClick={() => history.push('/signup')}>Sign Up</Button>
          </div>
        </div>
        <div className='img-container'>
          <div className='circle-bg'></div>
          <div className='apps-group'>
            <img
              alt='Linqto Private Investing Mobile App'
              src={images.AppScreenShot}
              className='app-img pointer'
              onClick={() => history.push('/products')}
            />
            <div className='app-icon-group'>
              <a href='https://apps.apple.com/us/app/linqto/id1464707632' target='_blank' rel='noopener noreferrer'>
                <img alt='Linqto Private Investing Mobile App on app store' className='app-store' src={images['app-store']}></img>
              </a>
              <a href='https://play.google.com/store/apps/details?id=com.linqto.investor.id' target='_blank' rel='noopener noreferrer'>
                <img alt='Linqto Private Investing Mobile App on android ' className='app-store' src={images['google-play']}></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
