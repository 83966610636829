import ProductCard from './ProductCard'

const ProductList = ({ list, showProductDetails }) => (
  <div className='product-list-container invest-list-container'>
    <div className='ui grid'>
      <div className='two column row'>
        {list.map((p, index) => (
          <ProductCard p={p} key={index} showProductDetails={showProductDetails} />
        ))}
      </div>
    </div>
  </div>
)

export default ProductList
