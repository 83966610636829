const InvestDetailAboutKeyFacts = ({ keyFacts }) => {
  return (
    <ul>
      {keyFacts && keyFacts.map((p, i) =>
        <li key={`key-fact${i}`}>{p.fact}</li>
      )}
    </ul>
  )
}
export default InvestDetailAboutKeyFacts
