import { useEffect } from 'react'
import Upload from '../Global/Upload/Upload'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { useDispatch, useSelector } from 'react-redux'
import { getDocuments, deleteDocument } from 'slices/documentsSlice'
import { PageLoading, ConditionalRender } from 'components'

const Documents = () => {
  const dispatch = useDispatch()
  const { pageLoading, taxDocuments, uploadedDocuments } = useSelector(
    (state) => state.documents
  )
  useEffect(() => {
    dispatch(getDocuments())
  }, [])

  const handleDelete = (documentId) => {
    dispatch(deleteDocument(documentId)).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        dispatch(getDocuments('noRefresh'))
      }
    })
  }
  if (pageLoading) return <PageLoading />
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Documents')} />
      <div className='page-container'>
        <div className='inner-container'>
          <h1 className='page-title'>Documents</h1>
          <ConditionalRender isVisible={taxDocuments.length > 0}>
            <h4>Tax Documents</h4>
            <div className='content'>
              If you have tax documents they are listed below. If you have any
              questions, please contact us at{' '}
              <a href='mailto:invest@linqto.com' className='inline-text-link'>
                invest@linqto.com
              </a>
              .
            </div>
            <div className='margined'>
              {taxDocuments.map((doc) => {
                return (
                  <Upload
                    key={doc.name}
                    enableView={true}
                    {...doc}
                    ariaLabel={doc.name}
                  />
                )
              })}
            </div>
          </ConditionalRender>
          <h4>Upload a Document</h4>
          <div className='documents-list'>
            {uploadedDocuments.map((doc) => {
              return (
                <Upload
                  key={doc.name}
                  ariaLabel={doc.name}
                  onDelete={() => handleDelete(doc.documentId)}
                  enableView={true}
                  allowDelete={true}
                  {...doc}
                />
              )
            })}
          </div>
          <Upload type='USER_UPLOADED' autoUpload={true} callAfterSuccessUpload={() => dispatch(getDocuments('noRefresh'))}/>
        </div>
      </div>
    </>
  )
}

export default Documents
