const InvestDetailTabs = ({ activeIndex, setActiveIndex, stickyHeader }) => {
  return (
    <div className='invest-tabs-container'>
      <div className={`page-tabs section-seperator ${stickyHeader ? 'stikey-tabs' : ''}`}>
        <div className={`tab ${activeIndex === 0 ? 'active' : ''}`} onClick={() => setActiveIndex(0)}>
          <span>About</span>
        </div>
        <div className={`tab ${activeIndex === 1 ? 'active' : ''}`} onClick={() => setActiveIndex(1)}>
          <span>Summary</span>
        </div>
        <div className={`tab ${activeIndex === 2 ? 'active' : ''}`} onClick={() => setActiveIndex(2)}>
          <span>Valuation</span>
        </div>
      </div>
    </div>
  )
}

export default InvestDetailTabs
