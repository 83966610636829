import {
  SET_LOADING_SPINNER,
  HIDE_LOADING_SPINNER,
  CONTACT_US,
  GET_EBOOK,
  HANDLE401,
  SIGN_UP,
  GET_WIRE_INSTRUCTION,
  SYSTEM_ERROR,
  UPLOAD_ERROR,
  GET_PRIVACY,
  GET_TERMS_OF_USE,
  RESET_LOADING,
  GET_FAQ_LINKS,
  GET_REWARDS_TERMS_OF_USE,
  GET_CONTACT_US,
  ORIGINATION_ERROR
} from 'types/actionTypes'
import privateAPI from '../apis/private.js'
import history from '../history'
import axios from 'axios'
// COMMON ACTIONS THAT ARE USED THROUGHT THE WEBSITE

export const showLoadingSpinner = () => ({
  type: SET_LOADING_SPINNER,
  payload: true
})

export const hideLoadingSpinner = () => ({
  type: HIDE_LOADING_SPINNER,
  payload: false
})

export const getContactUs = _ => (dispatch) => {
  return privateAPI
    .get('/page/contactUs')
    .then((res) => {
      dispatch({
        type: GET_CONTACT_US,
        payload: res.data?.addresses
      })
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const contactUs = (formValues) => (dispatch) => {
  dispatch({
    type: CONTACT_US,
    payload: { status: 'loading' }
  })
  return privateAPI
    .post('/contactUs', formValues)
    .then((res) => {
      dispatch({
        type: CONTACT_US,
        payload: { status: 'success' }
      })
    })
    .catch((err) => {
      dispatch({
        type: CONTACT_US,
        payload: { status: 'failed' }
      })
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return err
    })
}

export const getEbook = () => (dispatch) => {
  return privateAPI
    .get('/page/ebook/1')
    .then((res) => {
      dispatch({
        type: GET_EBOOK,
        payload: res.data
      })
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const handleServerError = (status) => async (
  dispatch
) => {
  switch (status) {
  case 'NOT_LOGGED_IN_TOKEN_INVALID':
    if (history.location.pathname !== '/signin') {
      const returnUrl = window.location.href.replace(window.location.origin, '')
      console.log('returnUrl', returnUrl)
      localStorage.setItem('prev-route', returnUrl)
    }
    localStorage.removeItem('linqto_token')
    dispatch({
      type: HANDLE401
    })
    history.push('/signin')
    break
  case 'SIGNUP_INCOMPLETE_KYC_REQUIRED':
    history.push('/identity')
    break
  case 'SIGNUP_INCOMPLETE_SELF_ACCREDITATION_REQUIRED':
    history.push('/confirm-investor-status')
    break
  case 'REGISTRATION_EMAIL_ALREADY_ACTIVATED':
    dispatch({
      type: SIGN_UP,
      payload: { err: 500, errMsg: 'REGISTRATION_EMAIL_ALREADY_ACTIVATED' }
    })
    break
  case 'Internal Server Error':
    dispatch({
      type: SYSTEM_ERROR,
      payload: { systemErr: true }
    })
    break
  case 'MFA_REQUIRED':
    history.push('/signin/mfas')
    break
  case 'UPLOAD_FAILED':
    dispatch({
      type: UPLOAD_ERROR,
      payload: { uploadErr: true }
    })
    break
  case 'ORIGINATION_NOT_INTERESTED_IN_BUYING':
    dispatch({
      type: ORIGINATION_ERROR,
      payload: { interestedInBuying: false }
    })
    break
  default:
    console.log('[handleServerError]: default', status)
  }
}

export const getWireInstruction = () => dispatch => {
  return privateAPI
    .get('/page/wireInstructions')
    .then(res => {
      dispatch({
        type: GET_WIRE_INSTRUCTION,
        payload: { wirePage: res.data.html }
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const getPrivacy = () => dispatch => {
  return privateAPI
    .get('/page/privacyPolicy')
    .then(res => {
      dispatch({
        type: GET_PRIVACY,
        payload: { privacyPage: res.data.html }
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}
export const getTermsOfUse = () => dispatch => {
  return privateAPI
    .get('/page/termsOfService')
    .then(res => {
      dispatch({
        type: GET_TERMS_OF_USE,
        payload: { termsOfUsePage: res.data.html }
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const resetUploadErr = () => dispatch => {
  dispatch({
    type: UPLOAD_ERROR,
    payload: { uploadErr: false }
  })
}

export const resetLoading = () => dispatch => {
  dispatch({
    type: RESET_LOADING
  })
}

export const getFAQLinks = (name) => dispatch => {
  return privateAPI
    .get(`/page/faq/${name}`)
    .then(res => {
      dispatch({
        type: GET_FAQ_LINKS,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const getRewardsTermsOfUse = () => dispatch => {
  return privateAPI
    .get('/page/rewardsTermsOfUse')
    .then(res => {
      dispatch({
        type: GET_REWARDS_TERMS_OF_USE,
        payload: { rewardsTermsOfUsePage: res.data.html }
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const saveCjEvent = (cjEvent) => dispatch => {
  return privateAPI
    .post(`/saveCjEvent?cjEvent=${cjEvent}`)
    .then(res => {
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const uploadDocument = (acceptedFile, fileName, type, query, func) => dispatch => {
  let apiEndPoint = ''
  switch (type) {
  case 'AI':
    apiEndPoint = `${process.env.REACT_APP_API_URL}/page/accreditedInvestorStatus/documents`
    break
  case 'FA':
    apiEndPoint = `${process.env.REACT_APP_API_URL}/page/financialAdvisor/documents`
    break
  case 'ID':
    apiEndPoint = `${process.env.REACT_APP_API_URL}/page/kycStatus/documents`
    break
  case 'USER_UPLOADED':
    apiEndPoint = `${process.env.REACT_APP_API_URL}/page/userDocuments`
    break
  default:
    apiEndPoint = `${process.env.REACT_APP_API_URL}/page/accreditedInvestorStatus/documents`
  }
  const token = localStorage.getItem('linqto_token')
  let headers = type !== 'AI' && query
  if (type === 'AI' && window.location.hostname === 'localhost') {
    headers = { access_token: token }
  }
  const data = new FormData()
  data.append('file', acceptedFile)
  data.append('name', fileName)

  const config = {
    method: 'post',
    url: apiEndPoint,
    headers,
    onUploadProgress: (progressEvent) => {
      func(progressEvent)
    },
    data: data
  }
  return axios(config)
    .then(res => {
      return res.status
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError('UPLOAD_FAILED'))
      }
    })
}
