import { useState, useEffect } from 'react'
import { BaseModal } from 'components'
import { RequestModal as connected } from 'containers'
import { images } from 'assets'
import Button from '../Global/Button'
import { Field, formValueSelector, reduxForm, touch } from 'redux-form'
import { connect, useSelector } from 'react-redux'

const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Please enter a valid email address.' : undefined

const renderField = ({ input, label, type, className = '', meta: { touched, error, warning } }) => (
  <div className={`input-group ${className} ${(touched && error) && 'error'}`}>
    <label>{label}</label>
    <input {...input} type={type} />
    {touched && ((error && <span className='err-msg'>{error}</span>) || (warning && <span className='err-msg'>{warning}</span>))}
  </div>
)

let RequestModal = ({ touch, name, actions, hideModal, handleSubmit }) => {
  const [content, setContent] = useState('initial')
  const { requestStatus } = useSelector(state => state.investorStatus)

  useEffect(() => {
    document.addEventListener('keydown', _handleKeyDown)
    return () => document.removeEventListener('keydown', _handleKeyDown)
  }, [])

  const _handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      touch('name')
      touch('email')
      handleSubmit(onSubmit({ name, email }))
    }
  }

  const onSubmit = async (formValues) => {
    if (!formValues || !formValues.name || !formValues.email) {
      return
    }
    try {
      await actions.requestFinancialAdvisor(formValues)
      setContent('success')
      setTimeout(() => hideModal(), 1000)
    } catch (e) {
      setContent('failed')
    }
  }

  return (
    <BaseModal
      mode='primary' size='sm' innerStyle='request-modal' hideModal={hideModal} modalHeader='Request Financial Advisor' crossToClose>
      <div className='content'>
        {content === 'success' ? (
          <div className='success-msg'>
            <img
              alt='email-success'
              src={images.success}
              className='success-icon'
              style={{ height: '30px' }}
            ></img>
            <p className='msg'>Email successfully sent.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Field name='name' type='text' label='Name' component={renderField} />
            <Field name='email' type='email' label='Email' component={renderField} validate={email} />
            <Button mode='primary' size='md' type='submit' customClass='request-btn' loading={requestStatus === 'loading'} disabled={requestStatus === 'loading'}>Request</Button>
          </form>
        )}
      </div>
    </BaseModal>
  )
}

const validate = (formValues) => {
  const errors = {}
  if (!formValues.name || formValues.name === undefined) {
    errors.name = 'Please enter a name.'
  }
  if (!formValues.email || formValues.email === undefined) {
    errors.email = 'Please enter an email.'
  }
  return errors
}

RequestModal = reduxForm({
  form: 'requestModalForm',
  validate,
  touch
})(RequestModal)

const selector = formValueSelector('requestModalForm')
RequestModal = connect(state => {
  const name = selector(state, 'name')
  const email = selector(state, 'email')
  return { name, email }
})(RequestModal)

export default connected(RequestModal)
