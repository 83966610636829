const errorTypes = {
  UPHOLD_OTP_REQUIRED: 'UPHOLD_OTP_REQUIRED',
  UPHOLD_OTP_INCORRECT: 'UPHOLD_OTP_INCORRECT',
  UPHOLD_UNKNOWN_ERROR: 'UPHOLD_UNKNOWN_ERROR',
  UPHOLD_TOKEN_INVALID: 'UPHOLD_TOKEN_INVALID',
  UPHOLD_INSUFFICIENT_FUNDS: 'UPHOLD_INSUFFICIENT_FUNDS',
  UPHOLD_INSUFFICIENT_UNLOCKED_FUNDS: 'UPHOLD_INSUFFICIENT_UNLOCKED_FUNDS',
  UPHOLD_NOT_FOUND: 'UPHOLD_NOT_FOUND',
  UPHOLD_IDENTITY_VERIFICATION_REQUIRED: 'UPHOLD_IDENTITY_VERIFICATION_REQUIRED',
  UPHOLD_EMAIL_VERIFICATION_REQUIRED: 'UPHOLD_EMAIL_VERIFICATION_REQUIRED',
  UPHOLD_PASSWORD_RESET_RESTRICTION: 'UPHOLD_PASSWORD_RESET_RESTRICTION',
  UPHOLD_DAILY_DEPOSIT_LIMIT_EXCEEDED: 'UPHOLD_DAILY_DEPOSIT_LIMIT_EXCEEDED',
  UPHOLD_USER_SSN_MISSING: 'UPHOLD_USER_SSN_MISSING',
  UPHOLD_USER_STATUS_NOT_VALID: 'UPHOLD_USER_STATUS_NOT_VALID',
  UPHOLD_CONNECTION_TIMEOUT: 'UPHOLD_CONNECTION_TIMEOUT',
  PLACE_ORDER_USER_PROFILE_NOT_COMPLETE: 'PLACE_ORDER_USER_PROFILE_NOT_COMPLETE',
  PLACE_ORDER_PRODUCT_INSUFFICIENT_AVAILABLE_SHARES: 'PLACE_ORDER_PRODUCT_INSUFFICIENT_AVAILABLE_SHARES',
  PLACE_ORDER_PRODUCT_INSUFFICIENT_AVAILABLE_OWNERS: 'PLACE_ORDER_PRODUCT_INSUFFICIENT_AVAILABLE_OWNERS',
  PLACE_ORDER_PRODUCT_USER_NOT_PREFERRED: 'PLACE_ORDER_PRODUCT_USER_NOT_PREFERRED',
  PLACE_ORDER_PRODUCT_USER_NOT_QUALIFIED_PURCHASER: 'PLACE_ORDER_PRODUCT_USER_NOT_QUALIFIED_PURCHASER',
  PLACE_ORDER_EXCEEDED_NON_ACCREDITED_WIRE_ORDERS_CAP: 'PLACE_ORDER_EXCEEDED_NON_ACCREDITED_WIRE_ORDERS_CAP',
  PLACE_ORDER_USER_WIRE_NOT_ALLOWED: 'PLACE_ORDER_USER_WIRE_NOT_ALLOWED'
}

export default errorTypes
