import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = state => ({
  isSignedIn: state.user.isSignedIn
})
const requiredActions = ['signOut']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)
export default connected
