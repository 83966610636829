
import { SEOvalues } from '../../components/data'

const parseInvestDetails = ({ isLoggedIn, company, entities, user, linqtoBucks, maxOrderLinqtoBucksPercent }) => {
  const { isSoldOut } = company

  const canParse = isLoggedIn && !isSoldOut
  const companyDetail = company.companyDetail

  const keyInvestors = companyDetail && companyDetail.keyInvestors && companyDetail.keyInvestors.length > 0 && companyDetail.keyInvestors.map(function (obj) {
    return obj.name
  }).join(', ')

  const defaultOfficers = [
    { name: 'officer name 1', workHistory: ['Work History'], companyTitles: [{ title: 'Founder', isCurrent: true }] },
    { name: 'officer name 1', workHistory: ['Work History'], companyTitles: [{ title: 'Founder', isCurrent: true }] },
    { name: 'officer name 1', workHistory: ['Work History'], companyTitles: [{ title: 'Founder', isCurrent: true }] }
  ]

  const defaultFacts = [
    { fact: ' Businesses that accept or are transitioning to accept bitcoin payments. Customers that own or trade cryptocurrency' }
  ]

  const defaultFundingRounds = [
    { date: '2021-01-01', round: 'Seed', amount: '11000000', valuation: '11000000' },
    { date: '2021-01-01', round: 'Seed', amount: '11000000', valuation: '11000000' },
    { date: '2021-01-01', round: 'Seed', amount: '11000000', valuation: '11000000' }
  ]

  const defaultLastRound = { date: '2021-01-01', round: 'Seed', amount: '1000000' }

  const defaultValuationOverTime = [
    { year: 1994, amount: 2003000, valuationSource: '' },
    { year: 2000, amount: 3500000, valuationSource: '' },
    { year: 2004, amount: 50000000, valuationSource: '' },
    { year: 2012, amount: 1555000000, valuationSource: '' },
    { year: 2013, amount: 5235325330, valuationSource: 'buyback' }]

  const defaultCompetitors = [
    { companyName: 'Company Name', headquarters: { city: 'San Francisco', country: 'United States', state: 'California' }, revenue: '1277000000', revenueFootnote: '', value: '65325000000', valueFootnote: '' },
    { companyName: 'Company Name', headquarters: { city: 'San Francisco', country: 'United States', state: 'California' }, revenue: '1277000000', revenueFootnote: '', value: '65325000000', valueFootnote: '' },
    { companyName: 'Company Name', headquarters: { city: 'San Francisco', country: 'United States', state: 'California' }, revenue: '1277000000', revenueFootnote: '', value: '65325000000', valueFootnote: '' },
    { companyName: 'Company Name', headquarters: { city: 'San Francisco', country: 'United States', state: 'California' }, revenue: '1277000000', revenueFootnote: '', value: '65325000000', valueFootnote: '' }
  ]
  return {
    productId: company.companyId,
    name: company.name,
    iconUrl: company.iconUrl,
    logoUrl: company.logoUrl,
    sharePrice: canParse && company.sharePrice ? company.sharePrice : '',
    availableShares: canParse && company.availableShares ? company.availableShares : '',
    minPurchase: canParse && company.minimumPurchase ? company.minimumPurchase : '',
    maxPurchase: canParse && company.maximumPurchase ? company.maximumPurchase : '',
    purchaseIncrement: canParse ? company.purchaseIncrement : '',
    isSoldOut: typeof isSoldOut === 'undefined' ? false : isSoldOut,
    products: company.products,
    documents: company.documents,
    entities: entities,
    user: user,
    linqtoBucks,
    maxOrderLinqtoBucksPercent,
    pageTitle: company.pageTitle ? company.pageTitle : SEOvalues.PAGE_TITLE,
    pageMetaDescription: company.pageMetaDescription ? company.pageMetaDescription : SEOvalues.PAGE_META_DESCRIPTION,
    pageMetaKeywords: company.pageMetaKeywords ? company.pageMetaKeywords : SEOvalues.PAGE_KEYWORDS,
    status: company.status ? company.status : '',
    urlName: company.urlName ? company.urlName : '',
    isInterested: !!company.isInterested,
    // About tab data
    description: company.description ? company.description : '',
    founded: (companyDetail && companyDetail.founded) ? companyDetail.founded : '',
    website: (companyDetail && companyDetail.website) ? companyDetail.website : '',
    headquarters: (companyDetail && companyDetail.headquarters) ? companyDetail.headquarters : null,
    keyFacts: (companyDetail && companyDetail.keyFacts) ? companyDetail.keyFacts : null,
    // Summary tab data
    employeeCount: isLoggedIn ? (companyDetail && companyDetail.employeeCount) ? companyDetail.employeeCount : '' : '500',
    industry: isLoggedIn ? (companyDetail && companyDetail.industry) ? companyDetail.industry : null : 'Financial Software',
    vertical: isLoggedIn ? company.vertical ? company.vertical : '' : 'FinTech',
    mosaic: {
      market: isLoggedIn ? (companyDetail && companyDetail.mosaicScore && companyDetail.mosaicScore.market) ? companyDetail.mosaicScore.market : null : '100',
      momentum: isLoggedIn ? (companyDetail && companyDetail.mosaicScore && companyDetail.mosaicScore.momentum) ? companyDetail.mosaicScore.momentum : null : '100',
      money: isLoggedIn ? (companyDetail && companyDetail.mosaicScore && companyDetail.mosaicScore.money) ? companyDetail.mosaicScore.money : null : '100',
      overall: isLoggedIn ? (companyDetail && companyDetail.mosaicScore && companyDetail.mosaicScore.overall) ? companyDetail.mosaicScore.overall : '-' : '100',
      management: isLoggedIn ? (companyDetail && companyDetail.mosaicScore && companyDetail.mosaicScore.management) ? companyDetail.mosaicScore.management : null : '100'
    },
    keyOfficers: isLoggedIn ? (companyDetail && companyDetail.keyOfficers) ? companyDetail.keyOfficers : [] : defaultOfficers,
    customerFacts: isLoggedIn ? (companyDetail && companyDetail.customerFacts) ? companyDetail.customerFacts : [] : defaultFacts,
    marketFacts: isLoggedIn ? (companyDetail && companyDetail.marketFacts) ? companyDetail.marketFacts : [] : defaultFacts,
    marketPositionFacts: isLoggedIn ? (companyDetail && companyDetail.marketPositionFacts) ? companyDetail.marketPositionFacts : [] : defaultFacts,
    keyInvestors: isLoggedIn ? (companyDetail && companyDetail.keyInvestors && companyDetail.keyInvestors.length > 0) ? [{ fact: keyInvestors }] : [] : defaultFacts,
    // Valuation tab
    fundingRounds: isLoggedIn ? (companyDetail && companyDetail.fundingRounds) ? companyDetail.fundingRounds : [] : defaultFundingRounds,
    lastRound: isLoggedIn ? (companyDetail && companyDetail.lastRound) ? companyDetail.lastRound : null : defaultLastRound,
    postMoney: isLoggedIn ? (companyDetail && companyDetail.postMoney) ? companyDetail.postMoney : '' : '10000000',
    totalRaised: isLoggedIn ? (companyDetail && companyDetail.totalRaised) ? companyDetail.totalRaised : '' : '10000000',
    discountToLastRound: isLoggedIn ? (companyDetail && companyDetail.discountToLastRound) ? companyDetail.discountToLastRound : '' : '10000000',
    impliedValuation: isLoggedIn ? (companyDetail && companyDetail.impliedValuation) ? companyDetail.impliedValuation : '' : '10000000',
    valuationOverTime: isLoggedIn ? (companyDetail && companyDetail.valuationOverTime) ? companyDetail.valuationOverTime : [] : defaultValuationOverTime,
    competitors: isLoggedIn ? (companyDetail && companyDetail.competitors) ? companyDetail.competitors : [] : defaultCompetitors
  }
}

export default parseInvestDetails
