import history from '../../history'
import { images } from 'assets'
import { formatDecimal } from 'utils'

const Companies = ({ companies }) => {
  const handleAddMore = (e, account) => {
    e.stopPropagation()
    history.push(`/products/${account.urlName}`)
  }

  return companies.map((account) => {
    return (
      <div className='account-group' key={account.companyId}>
        <div
          className='account-card'
          onClick={() => history.push(`/portfolio/${account.urlName}`)}
        >
          <div className='left-group'>
            <div className='inner-group'>
              <img
                alt={`Linqto - ${account.name}`}
                src={account.s3IconUrl}
                style={{ width: '40px', borderRadius: '50%' }}
              />
              <div className='name'>{account.name}</div>
            </div>
          </div>
          {(account.status === 'CLOSED' || account.status === 'EXITED') ? (
            <div className='purchased exited'>Exited</div>
          ) : (
            <div className='right-group'>
              <div className='purchased'>
                {formatDecimal(account.purchased)}
              </div>
              <div className='add-btn'>
                <img
                  alt={`buy more ${account.name}`}
                  src={images.addMore}
                  onClick={(e) => handleAddMore(e, account)}
                ></img>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  })
}

export default Companies
