import 'rc-slider/assets/index.css'
import { Wrapper, Content, ConditionalRender } from 'components'
import Button from '../../Global/Button'
import OrderDetails from './OrderDetails'
import LinqtoBucksCheckbox from './LinqtoBucksCheckbox'

const PlaceOrder = ({ company, user, history, spinnerVisible, showModal, updateProductId, setAmount, entities, setEntityId, entityId, userName, setUseLinqtoBucks, useLinqtoBucks, linqtoBucks, urlName, investAmount, productId, rewardsEnabled, stickyHeader }) => {
  const maxBucksPercent = company.maxOrderLinqtoBucksPercent
  const profileEntites = [{ entityId: 'individual', name: userName, isDefault: false }]

  if (entities.length > 0) {
    entities.forEach((entity) => {
      if (entity.isDefault) {
        profileEntites.unshift(entity)
      } else {
        profileEntites.push(entity)
      }
    })
  }

  const placeOrder = () => {
    if (!user.isSignedIn) {
      localStorage.setItem('prev-route', history.location.pathname)
      history.push('/signin')
    } else {
      if (useLinqtoBucks && linqtoBucks > 0) {
        if (maxBucksPercent === 1) {
          if (linqtoBucks >= investAmount) {
            history.push({
              pathname: '/orderconfirmation/wire',
              search: `?companyId=${productId}&amount=${investAmount}&type=WIRE&productId=${productId}&entityId=${entityId}&useLinqtoBucks=${useLinqtoBucks}`,
              state: { urlName }
            })
          } else {
            showModal()
          }
        } else {
          showModal()
        }
      } else {
        showModal()
      }
    }
  }
  if (!user.isSignedIn) {
    return (
      <Wrapper className={`invest-order sign-in-cta ${stickyHeader ? 'stikey-header' : ''}`}>
        <Button mode='primary' onClick={placeOrder} size='md' customClass='place-order-button'>Sign in to See More</Button>
      </Wrapper>
    )
  }

  if (company.status === 'EXITED') {
    return (
      <Wrapper className={`invest-order fully-subscribed-cta ${stickyHeader ? 'stikey-header' : ''}`}>
        <Button disabled mode='primary' size='md' customClass='place-order-button'>Place Order</Button>
      </Wrapper>
    )
  }

  if (company.isSoldOut) {
    return (
      <Wrapper className={`invest-order fully-subscribed-cta ${stickyHeader ? 'stikey-header' : ''}`}>
        <Button disabled mode='primary' size='md' customClass='place-order-button'>Place Order</Button>
      </Wrapper>
    )
  }

  return (
    <Wrapper className='product-order invest-order'>
      <div className='invest-order-header'>
      Invest in {company.name}
      </div>
      <Content className='box invest-order-box'>
        <ConditionalRender isVisible={user.isSignedIn}>
          <OrderDetails
            {...company}
            {...user}
            setProductId={updateProductId}
            setAmount={setAmount}
            products={company.products}
            entities={entities}
            entityId ={entityId}
            setEntityId={setEntityId}
            profileEntites = {profileEntites}
            spinnerVisible ={spinnerVisible}
          />
          <ConditionalRender isVisible={rewardsEnabled && linqtoBucks > 0}>
            <LinqtoBucksCheckbox
              maxBucksPercent = {maxBucksPercent}
              setUseLinqtoBucks = {setUseLinqtoBucks}
              useLinqtoBucks = {useLinqtoBucks}
              disabled = {spinnerVisible}
              linqtoBucks = {linqtoBucks}
              investAmount = {investAmount}
            />
          </ConditionalRender>
        </ConditionalRender>
      </Content>
      <div className={`${stickyHeader ? 'stikey-header' : ''}`}>
        {!spinnerVisible ? <Button
          mode='primary'
          size='md'
          customClass='place-order-button'
          onClick={placeOrder}
        >Place Order</Button> : <Button mode='primary' size='md' loading disabled customClass='place-order-button'>Place Order</Button>}
      </div>
    </Wrapper>
  )
}
export default PlaceOrder
