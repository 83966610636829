import { Field } from 'redux-form'
import Input from '../../Global/InputField'
import ManualUpload from './ManualUpload'

const renderInput = ({ input, label, placeholder, meta, type, className, autoComplete = null, disabled, children, checked }) => {
  return (
    <Input input={input} meta={meta} label={label} placeholder={placeholder} type={type} autoComplete={autoComplete} className={`${className} ${meta.touched && meta.error ? 'error' : null}`} disabled={disabled} checked={checked} >{children}</Input>
  )
}

const renderBigErr = (err) => {
  if (err.length !== 0) {
    return (
      <div className='ui error message'>
        <div className='header'>Required Fields Missing</div>
        <p>{err}</p>
      </div>
    )
  }
}

const renderSelect = ({ input: { name, value, onChange, onBlur, onFocus }, meta, label, disabled = false, options }) => {
  return (<div className={`input-group  ${(meta.touched && meta.error) ? 'error' : null}`}>
    <label>{label}</label>
    <Field
      name={name}
      component='select'
      className={`render-location-dropdown ${(meta.touched && meta.error) ? 'error' : null}`}
      onBlur={onBlur}
      onFocus={onFocus}
      value={value}
      disabled={disabled}
      onChange={onChange}
    >
      <option value=''>Select</option>
      {options && options.map((o, i) => {
        return <option values={o} key={ 'doc' + i }>{o}</option>
      })
      }
    </Field>
    {(meta.touched && meta.error) && <div className='err-msg'>{meta.error}</div>}
  </div>
  )
}

const renderMonth = ({ input: { name, value, onChange, onBlur, onFocus }, meta, label, disabled = false, options }) => {
  return (<div className={`input-group ${(meta.touched && meta.error) ? 'error' : null}`}>
    <label>{label}</label>
    <Field
      name={name} component='select'
      className={`render-dropdown ${(meta.touched && meta.error) ? 'error' : null}`}
      onBlur={onBlur}
      onFocus={onFocus}
      value={value}
      disabled={disabled}
      onChange={onChange}
    >
      <option value=''>Select</option>
      {options && options.map((o) => {
        return <option value={o.id} key={ 'month' + o.id }>{o.m}</option>
      })
      }
    </Field>
    {(meta.touched && meta.error) && <div className='err-msg'>{meta.error}</div>}
  </div>
  )
}

const renderDocumentType = ({ input: { name, value, onChange, onBlur, onFocus }, meta, label, disabled = false, options }) => {
  return (<div className={`input-group ${(meta.touched && meta.error) ? 'error' : null}`}>
    <label>{label}</label>
    <Field
      name={name} component='select'
      className={`render-dropdown ${(meta.touched && meta.error) ? 'error' : null}`}
      onBlur={onBlur}
      onFocus={onFocus}
      value={value}
      disabled={disabled}
      onChange={onChange}
    >
      <option value=''>Select</option>
      {options && options.map((o) => {
        return <option value={o.value} key={ o.value }>{o.label}</option>
      })
      }
    </Field>
    {(meta.touched && meta.error) && <div className='err-msg'>{meta.error}</div>}
  </div>
  )
}

const renderDropdown = ({ input: { value, onChange, onBlur, onFocus }, meta, label, country, placeholder, disabled = false, countries, regions, getRegions, setRegions, isCountry }) => {
  const handleChange = async e => {
    onChange(e)
    try {
      if (e !== '') {
        const res = await getRegions(e)
        if (res) {
          setRegions(res)
        }
      } else {
        setRegions([])
      }
    } catch (err) {
      console.log(err)
    }
  }

  if (isCountry) {
    return (
      <div className={`input-group ${meta.touched && meta.error ? 'error' : null}`}>
        <label>{label}</label>
        <select classes={`render-location-dropdown ${meta.touched && meta.error ? 'error' : null}`}
          placeholder={placeholder}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          disabled={disabled}
          onChange={(e) => handleChange(e.target.value)}>
          <option value=''>Select Country</option>
          {countries && countries.length > 0 && countries.map((e, i) => <option value={e} key={`${i}-${e}`}>{e}</option>)}
        </select>
        {(meta.touched && meta.error) && <div className='err-msg'>{meta.error}</div>}
      </div>
    )
  } else {
    return (
      <div className={`input-group ${meta.touched && meta.error ? 'error' : null}`}>
        <label>{label}</label>
        <select classes={`render-location-dropdown ${meta.touched && meta.error ? 'error' : null}`}
          placeholder={placeholder}
          country={country}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          disabled={disabled}
          onChange={onChange}>
          <option value=''>Select Region</option>
          {regions && regions.length > 0 && regions.map((e) => <option value={e} key={e}>{e}</option>)}
        </select>
        {(meta.touched && meta.error) && <div className='err-msg'>{meta.error}</div>}
      </div>
    )
  }
}

const renderupload = ({ input, label, meta, disabled, display }) => {
  return (
    <div className={display ? 'show-zone' : 'hide-zone'}>
      <ManualUpload documentName={label} disabled={disabled} input={input} />
      {(meta.touched && meta.error) && <div className='err-msg'>{meta.error}</div>}
    </div>
  )
}

export default {
  renderInput,
  renderBigErr,
  renderDropdown,
  renderSelect,
  renderMonth,
  renderupload,
  renderDocumentType
}
