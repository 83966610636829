import { useEffect, useState, useCallback } from 'react'
import qs from 'qs'
import { FinancialAdvisor as connected } from '../../containers'
import Upload from '../Global/Upload/Upload'
import { PageLoading } from 'components'
import Button from '../Global/Button'
import Modal from '../Global/Modal'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { Field, reduxForm, change } from 'redux-form'
import renderer from '../Profile/partials/renderer'
import AddressInput from './AddressInput'
import render from '../OnBoarding/render'
import { Link } from 'react-router-dom'
import ConditionalRender from '../Global/ConditionalRender'
import { useDispatch } from 'react-redux'

const FinancialAdvisor = ({
  actions,
  firstName,
  lastName,
  uploadErr,
  history,
  handleSubmit,
  formValues,
  isAccredited,
  showVerificationForm,
  financialAdvisorAttestation,
  country
}) => {
  const [query, setQuery] = useState({})
  const [pageLoading, setPageLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [regions, setRegions] = useState([])
  const [countries, setCountries] = useState([])
  const [fileLoading, setFileLoading] = useState(false)
  const [fileUploaded, setFileUploaded] = useState('')
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const dispatch = useDispatch()

  const getFinancialAdvisor = useCallback(
    (query) => {
      setPageLoading(true)
      actions.getFinancialAdvisor(query).then(() => {
        setPageLoading(false)
        setQuery(query)
        actions.getCountries().then((res) => {
          setCountries(res)
        })
      })
    },
    [actions]
  )

  const getRegions = (country) => {
    actions.getRegions(country).then((res) => {
      setRegions(res)
    })
  }

  useEffect(() => {
    const query = qs.parse(history.location.search, {
      ignoreQueryPrefix: true
    })
    getFinancialAdvisor(query)
  }, [history, getFinancialAdvisor])

  useEffect(() => {
    return () => {
      actions.resetUploadErr()
    }
  }, [actions])

  const renderAccreditationOptions = (options) => {
    return options.map((ele, id) => {
      return (
        <div className='radio-option' key={ele.optionId}>
          <Field
            name='optionId'
            component='input'
            type='radio'
            value={ele.optionId}
            tag={`option-${id}`}
            id={ele.optionId}
            disabled={loading}
          />
          <label
            key={id}
            htmlFor={ele.optionId}
            className='radio__label content'
          >
            {ele.optionText}
          </label>
        </div>
      )
    })
  }

  const onSubmit = (formValues) => {
    setLoading(true)
    actions
      .saveFinancialAdvisorReqest({ ...formValues, ...query })
      .then((res) => {
        setLoading(false)
        if (res === 200) {
          setSubmitSuccess(true)
        }
      })
  }

  const renderVerificationPDF = (formValues) => {
    const queryStr = qs.stringify({ ...formValues, ...query })
    return `${process.env.REACT_APP_API_URL}/page/financialAdvisorPdf?${queryStr}`
  }

  const submitLetter = () => {
    setFileLoading(true)
    actions
      .uploadDocument(
        fileUploaded[0],
        'Verification Letter',
        'FA',
        query,
        () => {}
      )
      .then(() => {
        setSubmitSuccess(true)
        setFileLoading(false)
      })
  }

  const resetFeildValue = (field, value) => {
    dispatch(change('editForm', field, value))
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta
          title={seoTitleTemplate('Financial Advisor Attestation Letter')}
        />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta
        title={seoTitleTemplate('Financial Advisor Attestation Letter')}
      />
      <div className='page-container fa-container'>
        <div className='inner-container'>
          <h1 className='page-title'>Financial Advisor Verification</h1>
          <ConditionalRender isVisible={isAccredited}>
            <div className='content'>
              Thank you for assisting{' '}
              <b>
                {firstName} {lastName}
              </b>{' '}
              in verifying accredited investor status in order to make an
              investment on the Linqto Platform.
            </div>
            <div className='content space-above-sm space-below-sm'>
              We have since received the requested documentation and no further
              action is needed from you.{' '}
            </div>
            <Button
              mode='primary'
              size='md'
              onClick={() => history.push('/about')}
            >
              About Linqto
            </Button>
          </ConditionalRender>
          <ConditionalRender isVisible={!isAccredited}>
            <div className='content space-below-sm'>
              <b>
                {firstName} {lastName}
              </b>{' '}
              has requested your help in verifying accredited investor status in
              order to make an investment on the Linqto Platform. First, please
              enter your information below.
            </div>
          </ConditionalRender>
          <ConditionalRender isVisible={showVerificationForm && !isAccredited}>
            <form className='ui grid' onSubmit={handleSubmit(onSubmit)}>
              <div className='eight wide tablet four wide computer column'>
                <Field
                  name='firstName'
                  component={render.renderInput}
                  label='First Name'
                  type='text'
                  disabled={loading}
                ></Field>
              </div>
              <div className='eight wide tablet four wide computer column'>
                <Field
                  name='lastName'
                  component={render.renderInput}
                  label='Last Name'
                  type='text'
                  disabled={loading}
                ></Field>
              </div>
              <div className='eight wide tablet four wide computer column'>
                <Field
                  name='occupation'
                  component={renderer.renderInput}
                  label='Occupation'
                  type='text'
                  disabled={loading}
                ></Field>
              </div>
              <div className='eight wide tablet four wide computer column'>
                <Field
                  name='companyName'
                  component={render.renderInput}
                  label='Company'
                  type='text'
                  disabled={loading}
                ></Field>
              </div>
              <AddressInput
                disabled={loading}
                renderDropdown={renderer.renderDropdown}
                formValues={formValues}
                getRegions={getRegions}
                setRegions={setRegions}
                setCountries={setCountries}
                regions={regions}
                countries={countries}
                resetFeildValue={resetFeildValue}
              />
              <ConditionalRender isVisible={showVerificationForm}>
                <div className='sixteen wide column tablet_eight'>
                  <div className='content'>
                    {financialAdvisorAttestation.description}
                  </div>
                </div>
                <div className='radio-group'>
                  {formValues?.syncErrors?.accreditationType &&
                    formValues.fields?.accreditationType?.touched && (
                    <div className='err-msg'>
                      {formValues.syncErrors.accreditationType}
                    </div>
                  )}
                  {renderAccreditationOptions(
                    financialAdvisorAttestation.options
                  )}
                  {country === 'United States' && (
                    <div className='eight wide column tablet_eight'>
                      <div className='small-space' />
                      <Field
                        name='licenseNumber'
                        component={render.renderInput}
                        label=''
                        type='text'
                        disabled={loading}
                        placeholder='License #'
                      ></Field>
                    </div>
                  )}
                </div>
              </ConditionalRender>
              <div className='sixteen wide column'>
                <Button
                  mode='secondary'
                  size='md'
                  type='button'
                  onClick={() =>
                    window.open(
                      renderVerificationPDF(formValues && formValues.values)
                    )
                  }
                >
                  Generate Letter Preview
                </Button>
              </div>
              <Field
                name='verificationConfirmed'
                component={render.renderInput}
                type='checkbox'
                disabled={loading}
                className='fa-checkbox confirm-checkbox'
              >
                <div className='content checkbox-content'>
                  I confirm I have read the&#160;
                  <a
                    className='link primary'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={renderVerificationPDF(
                      formValues && formValues.values
                    )}
                  >
                    verification letter.
                  </a>
                </div>
              </Field>
              <div className='sixteen wide column'>
                <div className='btn-group start'>
                  <Button
                    mode='primary'
                    size='md'
                    type='submit'
                    loading={loading}
                    disabled={
                      loading ||
                      !formValues?.values?.verificationConfirmed ||
                      formValues?.syncErrors
                    }
                  >
                    Confirm Verification
                  </Button>
                </div>
              </div>
            </form>
            <hr />
          </ConditionalRender>
          <ConditionalRender isVisible={!isAccredited}>
            <div className='header'>Custom Letter Verification</div>
            <br />
            <div className='content space-below-sm'>
              A custom letter, on company letterhead, may be submitted to verify
              the accreditation status of {firstName} {lastName}.
            </div>
            <div className='content space-below-sm'>
              Provide your: name, address, professional credentials, and your
              relationship to {firstName} {lastName} (e.g. accountant, banker,
              broker, or attorney)
            </div>
            <div className='content space-below-sm'> -- and -- </div>
            <div className='content'>Confirm you: </div>
            <ol type='a' className='content space-below-sm'>
              <li className='content'>
                have access to{' '}
                <b>
                  {firstName} {lastName}
                </b>
                &apos;s financial records and know their financial position
              </li>
              <li className='content'>
                that on the basis of the above, you can attest that{' '}
                <b>
                  {firstName} {lastName}
                </b>{' '}
                has either the Gross Income or Net Worth to meet the
                requirements for Accredited Investor status.
              </li>
            </ol>
            <Upload
              type='FA'
              name='Verification Letter'
              setFileUploaded={setFileUploaded}
              fileUploaded={fileUploaded}
            />
            <Button
              mode='secondary'
              size='md'
              onClick={submitLetter}
              loading={fileLoading}
              disabled={fileLoading || !fileUploaded}
            >
              Submit
            </Button>
            <div className='content space-above-sm'>
              Need help?{' '}
              <Link to='/contact' className='link primary contact-us'>
                Contact Us
              </Link>
            </div>
          </ConditionalRender>
          {uploadErr && (
            <Modal
              clickToClose={false}
              pressToClose={false}
              crossToClose={false}
              mode='primary'
              size='md'
              modalHeader='Failed to Upload'
            >
              <div className='content'>
                An error occurred while uploading the file.
              </div>
              <Button mode='primary' onClick={() => actions.resetUploadErr()}>
                Close
              </Button>
            </Modal>
          )}
          {submitSuccess && (
            <Modal
              clickToClose={false}
              pressToClose={false}
              crossToClose={true}
              mode='primary'
              size='md'
              modalHeader=''
              hideModal={() => {
                location.reload()
              }}
            >
              <h3>
                Your verification letter has been successfully submitted!{' '}
              </h3>
              <div className='content space-below-sm FA-submit-success'>
                <b>What happens now?</b>
                <br /> We’ll review your letter and be in contact with your
                client shortly.{' '}
              </div>
              <div className='content space-above-sm FA-submit-success'>
                In the mean time, we invite you to learn more about Linqto and
                investment opportunities that may be of benefit to you and your
                clients.
              </div>
              <div className='small-space'></div>
              <div className='btn-group centered'>
                <Button
                  mode='primary'
                  size='md'
                  onClick={() => history.push('/about')}
                >
                  Learn More
                </Button>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </>
  )
}

const validate = (formValues) => {
  const errors = {}
  if (!formValues.firstName) {
    errors.firstName = 'Please enter first name.'
  }
  if (!formValues.lastName) {
    errors.lastName = 'Please enter last name.'
  }
  if (!formValues.occupation) {
    errors.occupation = 'Please enter occupation.'
  }
  if (!formValues.companyName) {
    errors.companyName = 'Please enter company name.'
  }
  if (!formValues.city) {
    errors.city = 'Please enter city.'
  }
  if (!formValues.state) {
    errors.state = 'Please enter state.'
  }
  if (!formValues.street1) {
    errors.street1 = 'Please enter address.'
  }
  if (!formValues.postalCode) {
    errors.postalCode = 'Please enter zip code.'
  }
  if (!formValues.verificationConfirmed) {
    errors.verificationConfirmed = 'Please agree to terms.'
  }
  if (!formValues.optionId) {
    errors.optionId = 'Please choose accreditation type.'
  }
  if (
    formValues.optionId === 'United States_3_SECURITIES_LICENSE' &&
    !formValues.licenseNumber
  ) {
    errors.licenseNumber = 'Please enter license number.'
  }
  return errors
}
const formWrapped = reduxForm({
  form: 'requestFinancialAdvisor',
  validate,
  shouldError: () => true,
  enableReinitialize: true
})(FinancialAdvisor)

export default connected(formWrapped)
