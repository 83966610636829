import allActions from '../actions'

/**
 * String actions that are needed for given component
 * mapped to the actual actions
 * @param {Array} actionNames
 * @returns {Object} mapped object of actions
 */
const mapActions = (actionName) => (
  actionName.reduce((obj, name) => ({ ...obj, [name]: allActions[name] }), {})
)
export default mapActions
