import {
  GET_BROKER_INFO,
  SUBMIT_BROKER_INFO
} from 'types/actionTypes'
import privateAPI from '../apis/private'
import { handleServerError } from './CommonActions'

export const getBrokerInfo = (entityId) => (dispatch) => {
  const url = entityId ? `/page/userBrokerInfo?entityId=${entityId}` : '/page/userBrokerInfo'
  return privateAPI
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_BROKER_INFO,
        payload: res.data
      })
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const submitBrokerInfo = (formValues) => (dispatch) => {
  console.log(formValues)
  return privateAPI
    .post('/page/userBrokerInfo', { brokerInfo: formValues })
    .then(() => {
      dispatch({
        type: SUBMIT_BROKER_INFO,
        payload: { status: 'success' }
      })
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return err.response.data.error
    })
}
