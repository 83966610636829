import axios from 'axios'

const request = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`
})
request.interceptors.request.use(config => {
  const token = localStorage.getItem('linqto_token')
  if (token) {
    config.headers.access_token = token
  }
  return config
})

export default request
