import { formatNumberWithLetter } from 'utils'
import moment from 'moment'

const InvestDetailValuationRounds = ({ fundingRounds }) => {
  return (
    <>
      <div className='ui grid unicorn-table invest-table'>
        <div className='four column row unicorn-table-header'>
          <div className='column'>Date</div>
          <div className='column'>Round</div>
          <div className='column column-right'>Amount</div>
          <div className='column column-right'>Valuation</div>
          { /* <div className='column min-45'>Investors</div> */}
        </div>
        { fundingRounds && fundingRounds.length > 0 && fundingRounds.map((f, i) =>
          <div key={`fundingRound_${i}`} className='four column row unicorn-table-body'>
            <div className='column dark-color'>{f.date && moment(f.date).format('MM/DD/YYYY')}</div>
            <div className='column dark-color'>{f.round}</div>
            <div className='column dark-color column-right'>{f.amount && formatNumberWithLetter(f.amount, true, true, 1, true)}</div>
            <div className='column dark-color column-right'>{f.valuation && formatNumberWithLetter(f.valuation, true, true, 1, true)}</div>
            { /* <div className='column dark-color min-45'>
              {f.investors && f.investors.length > 0 && f.investors.map(function (obj) {
                return obj.name
              }).join(', ')}
            </div> */ }
          </div>
        )}
      </div>
      <div className='unicorn-card-container'>
        {fundingRounds && fundingRounds.length > 0 && fundingRounds.map((f, i) =>
          <div className='unicorn-card' key={`mfundingRound_${i}`} >
            <div className='ui grid'>
              <div className='one column row unicorn-card-row'>
                <div className='column dark-color'>
                  <span className='semi-bold dark'>{f.date && moment(f.date).format('MM/DD/YYYY')}</span>
                  <span className='semi-bold dark padding-left'>Round: </span><span className='dark'>{f.round}</span></div>
              </div>
              <div className='one column row unicorn-card-row-line-sepretaor' />
              <div className='two column row unicorn-card-row'>
                <div className='column'><span className='semi-bold'>Amount: </span><span>{f.amount && formatNumberWithLetter(f.amount, true, true, 1, true)}</span></div>
                <div className='column'><span className='semi-bold'>Valuation: </span><span>{f.valuation && formatNumberWithLetter(f.valuation, true, true, 1, true)}</span></div>
              </div>
              { /* <div className='one column row unicorn-card-row'>
                <div className='column dark-color'><strong>Investors: </strong>
                  {f.investors && f.investors.length > 0 && f.investors.map(function (obj) {
                    return obj.name
                  }).join(', ')}
                </div>
              </div> */}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default InvestDetailValuationRounds
