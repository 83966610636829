import connected from '../../containers/OriginatioDetail'
import { useEffect, useState } from 'react'
import { PageLoading } from 'components'
import SeoMeta from 'components/Global/SeoMeta'
import OriginationDetailHeader from './partials/OriginationDetailHeader'
import OriginationDetailCompanyLogo from './partials/OriginationDetailCompanyLogo'
import OriginationDetailCompanyOffer from './partials/OriginationDetailCompanyOffer'
import OriginationDetailCompanyCTA from './partials/OriginationDetailCompanyCTA'
import OriginationDetailCompanyCopy from './partials/OriginationDetailCompanyCopy'
import OriginationNotAvailableModal from './partials/OriginationNotAvailableModal'
import OriginationFooter from 'components/Origination/partials/OriginationFooter'
import { seoTitleTemplate } from 'utils'

const OriginationDetail = ({ match, actions, company, history, interestedInBuying }) => {
  const [pageLoading, setPageLoading] = useState(true)
  const [shareClass, setShareClass] = useState('')
  const [amount, setAmount] = useState(0)
  const [shares, setShares] = useState(0)
  useEffect(() => {
    setPageLoading(true)
    const companyUrlName = match.params.companyName
    actions.getOriginationDetail(companyUrlName).then((res) => {
      if (res) {
        setAmount(res.minShares * res.sharePrice)
        setShares(res.minShares)
        setShareClass(res.shareClasses && res.shareClasses[0])
      }
      setPageLoading(false)
    })
  }, [])

  if (pageLoading) {
    return <>
      <SeoMeta title={seoTitleTemplate(`${company.name} | Sell`)} />
      <PageLoading />
    </>
  }
  return (
    <>
      <SeoMeta title={seoTitleTemplate(`${company.name} | Sell`)} />
      {interestedInBuying &&
      <div className='origination-container origination-details-container unicorns-details-container'>
        <OriginationDetailHeader />
        <div className='page-container'>
          <div className='inner-container'>
            <div className='sections-container'>
              <OriginationDetailCompanyLogo company={company}/>
              <OriginationDetailCompanyCopy company={company} page='detail' />
              <OriginationDetailCompanyOffer company={company} amount={amount} setAmount={setAmount} shares={shares} setShares={setShares} setShareClass={setShareClass}/>
              <OriginationDetailCompanyCTA history={history} company={company} amount={amount} shares={shares} shareClass={shareClass} />
            </div>
            <div className='content-separator' />
            <OriginationFooter />
          </div>
        </div>
      </div>
      }
      {!interestedInBuying &&
        <div className='page-container'>
          <div className='inner-container'>
            <OriginationNotAvailableModal />
          </div>
        </div>
      }
    </>
  )
}

export default connected(OriginationDetail)
