import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ portfolio, placeOrder, common }) => ({
  companies: portfolio.companies,
  sliderAmount: placeOrder.sliderAmount,
  confirmDetails: placeOrder.confirmDetails,
  orderId: placeOrder.orderId,
  placeOrderError: placeOrder.placeOrderError,
  spinnerVisible: common.spinnerVisible
})

const requiredActions = ['confirmOrder', 'commitOrder', 'hideErrorModal', 'showLoadingSpinner', 'hideLoadingSpinner']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
