import RewardHistoryItem from './RewardHistoryItem'

const RewardHistory = ({ transactions }) => {
  const renderHeader = () => {
    return (
      <div className='ui stackable padded grid'>
        <div className='three wide column'>
          <div className='text-left'>DATE</div>
        </div>
        <div className='four wide column'>
          <div className='text-left'>EVENT</div>
        </div>
        <div className='two wide column'>
          <div className='total'>AMOUNT</div>
        </div>
        <div className='one column'></div>
        <div className='six wide column'>
          <div className='text-left'>NOTE</div>
        </div>
      </div>
    )
  }
  const renderTransactions = (data) => {
    return data.map((transaction, index) => {
      return (
        <RewardHistoryItem
          key={transaction.createdAt + index}
          transaction={transaction}
        />
      )
    })
  }

  return (
    <div className='rewards-history-container'>
      <div className='title'>My Bucks History</div>
      <div className='order-list-container'>
        <div className='order-list'>
          <div className='order-headers'>{renderHeader()}</div>

          {transactions.length === 0 ? (
            <div className='order no-data'>
              You don&apos;t have any reward transactions yet
            </div>
          ) : (
            <div className='orders'>
              {renderTransactions(transactions)}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default RewardHistory
