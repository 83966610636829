import { getWebsiteURL } from 'utils'

const InvestDetailAboutHeader = ({ company }) => {
  const maxDescriptionLength = 620 // 6 lines & breaks into 2 praphas on the 7th line
  return (
    <>
      <div className='two column mobile-one row'>
        <div className='column'>
          <div className='row'>
            <span className='semi-bold dark'>Founded: </span>
            <span>{company.founded}</span>
          </div>
          <div className='row'>
            <span className='semi-bold dark'>Headquarters: </span>
            <span>{company.headquarters && `${company.headquarters.city}, `} {company.headquarters && company.headquarters.state}</span>
          </div>
        </div>
        <div className='column desktop-content'>
          {company.website && <a className='website-link' alt={company.website} rel='noopener noreferrer' target='_blank' href={getWebsiteURL(company.website)}>{company.website}</a>}
        </div>
      </div>
      <div className='one column mobile-one row'>
        <div className={`column desc ${company.description && company.description.length > maxDescriptionLength ? 'two-cols-text' : ''}`}>
          {company.description}
        </div>
        <div className='column desc'>
          {company.website && <a className='website-link mobile-content' alt={company.website} rel='noopener noreferrer' target='_blank'href={getWebsiteURL(company.website)}>{company.website}</a>}
        </div>
      </div>
    </>
  )
}
export default InvestDetailAboutHeader
