import { truncate } from 'utils'

const ProductCard = ({ p, showProductDetails }) => {
  const promoStyle = {
    RECENTLY_ADDED: 'teal',
    TOP_SELLER: 'red',
    FULLY_SUBSCRIBED: 'grey',
    LIMITED_SHARES: 'darkGrey',
    NONE: ''
  }

  return (
    <>
      <div className='column two'>
        {p.promoFlag !== 'NONE' && <span className={`flag ${promoStyle[p.promoFlag]}`}>{p.promoFlag.replace(/_/g, ' ') }</span> }
        <div className='product-card' key={p.urlName} onClick ={() => showProductDetails(p.urlName)}>
          <div className='product-title'>
            {p.logoUrl && <img className='icon' alt={p.name} src={p.logoUrl} />}
            <span className='sector'>{p.vertical}</span>
          </div>
          <div className='product-description'>
            {truncate(p.description, 115)}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductCard
