import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'actions/CommonActions'
import api from '../apis/public.js'
import { identify } from '../functions'
import { SIGN_IN } from 'types/actionTypes'

const initialState = {
  accessToken: '',
  email: '',
  isDebugAdmin: true,
  kycRequired: true,
  mfaRequired: true,
  redirectUrl: '',
  userId: 0,
  pageLoading: false
}

export const verifyEmail = createAsyncThunk(
  'verifyEmail',
  (info, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .post('/confirmRegistration', info)
      .then((res) => {
        if (res.data.email) {
          identify(res.data.email)
        }
        // NOTE:make sure the old state is still maintained if any other component need the global state. We can clean this up later
        dispatch({
          type: SIGN_IN,
          payload: res.data
        })
        return fulfillWithValue(res.data)
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
          return rejectWithValue(err.response)
        }
      })
  }
)

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(verifyEmail.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(verifyEmail.fulfilled, (state, { payload }) => {
        return {
          pageLoading: false,
          ...payload
        }
      })
      .addCase(verifyEmail.rejected, (state) => {
        state.pageLoading = false
      })
  }
})

export default userSlice.reducer
