import { GET_ORDER_DETAIL, MESSAGE_TRADER, RESET_LOADING } from 'types/actionTypes'

const INITIAL_STATE = {
  company: {},
  order: {},
  documents: [],
  uphold: {},
  message: 'init',
  filterOrders: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case GET_ORDER_DETAIL:
    return { ...state, ...action.payload }
  case MESSAGE_TRADER:
    return { ...state, ...action.payload }
  case RESET_LOADING:
    return { ...state, message: INITIAL_STATE.message }
  default:
    return state
  }
}
