import { useEffect, useState, useCallback } from 'react'
import connected from '../../containers/FAQ'
import FAQLink from './FAQLink'
import { PageLoading } from 'components'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'

const FAQ = ({ actions, faqs, match, title }) => {
  const [selected, setSelected] = useState('')
  const [loading, setLoading] = useState(false)
  const getFAQLinks = useCallback(() => {
    setLoading(true)
    const name = match.params.name || ''
    actions.getFAQLinks(name)
      .then(() => [
        setLoading(false)
      ])
  }, [match.params.name, actions])

  useEffect(() => {
    getFAQLinks()
  }, [getFAQLinks])
  if (loading) {
    return <PageLoading/>
  }
  return (
    <>
      <SeoMeta title={seoTitleTemplate(title)} />
      <div className='page-container'>
        <div className='inner-container'>
          <h1 className='page-title'>{title}</h1>
          {faqs && faqs.length && (
            <>
              {faqs.map((faq, i) => (
                <FAQLink
                  key={faq.question}
                  question={faq.question}
                  answer={faq.answer}
                  selected={selected}
                  onClick={() =>
                    setSelected(faq.question !== selected ? faq.question : '')
                  }
                />
              ))}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default connected(FAQ)
