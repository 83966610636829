import { useEffect, useState, useCallback } from 'react'
import { Profile as connected } from 'containers'
import { Field, reduxForm, formValueSelector, isDirty, change } from 'redux-form'
import { connect, useDispatch } from 'react-redux'
import history from '../../history'
import _ from 'lodash'
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { Identity, PageLoading } from 'components'
import Button from 'components/Global/Button'
import { seoTitleTemplate, isWhiteSpace } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import AddressInput from './partials/AddressInput'
import ProgressBar from './partials/ProgressBar'
import ProfileStatus from './partials/ProfileStatus'
import AddEntity from './partials/AddEntity'
import Entities from './partials/Entities'
import BrokerList from './partials/BrokerList'
import renderer from './partials/renderer'
import SameAddress from './partials/SameAddress'
import { isMobileOnly } from 'react-device-detect'
import SaveProfileModal from './partials/SaveProfileModal'
import NotificationBanner from 'components/Global/NotificationBanner'
import IraEntity from './partials/IraEntity'

const tooltip = {
  taxId: 'In most cases you’ll provide your Social Security Number here. An Individual Tax ID# can be used if you do not have a SSN.',
  foreignTaxId: 'A Foreign Tax ID is required if you are a non-US taxpayer.  You may also be able, and required, to provide a US Tax ID if you have one.',
  pCountry: 'We’ll use your residence for accreditation verification if you are not a US Citizen.',
  citizenshipCountry: 'We’ll need to know this for accreditation verification.',
  entities: 'Adding an investment entity gives you more options of how to fund your Linqto orders. Some examples of entities you can use to invest include: Trust (Family, Revocable, etc), Corporation (S-, C-, B-), Partnership,  Limited Liability Company, Solo 401k, Self-Directed IRA, Non-Profit Corporation, and Non-Profit Foundation.',
  brokerInfo: 'We’ll need this to distribute your shares in the event of an exit. Note, we can only distribute shares titled in the name of an entity to a brokerage account that is also titled in the name of the same entity. '
}

const Profile = ({ location, formValues, entity, entities, brokerInfos, hasCompletedOrder, actions, initialValues, handleSubmit, dirty }) => {
  const dispatch = useDispatch()
  const redirect = location.state && location.state.redirect
  const [bigError, setBigError] = useState(false)
  const [profileLoading, setProfileLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [submitEntityLoading, setSubmitEntityLoading] = useState(false)
  const [edited, setEdited] = useState(false)
  const [userEntity, setUserEntity] = useState({})
  const [formIntialValues, setFormIntialValues] = useState({})
  const [countries, setCountries] = useState([])
  const [primaryRegions, setPrimaryRegions] = useState([])
  const [mailingRegions, setMailingRegions] = useState([])
  const [banner, setBanner] = useState(false)
  const [hasKyc, setHasKyc] = useState(true)
  const [isUserProfileComplete, setIsUserProfileComplete] = useState(false)
  const [isAccredited, setIsAccredited] = useState(false)
  const [entityRequestSubmitted, setEntityRequestSubmitted] = useState('')
  const [directToVerifyStatus, setDirectToVerifyStatus] = useState(false)
  const [save, setSave] = useState(false)
  const [disable, setDisable] = useState(false)

  // if "save and verify" button is clicked, submit form and redirect to investor status page
  useEffect(() => {
    if (directToVerifyStatus) {
      handleSubmit(onSubmit)
    }
  }, [directToVerifyStatus])

  // show big error if submission faild and there are sync errors
  useEffect(() => {
    if (formValues && (formValues.submitFailed && formValues.syncErrors)) {
      setBigError(true)
    } else {
      setBigError(false)
    }
  }, [formValues && formValues.submitFailed, formValues && formValues.syncErrors])

  useEffect(() => {
    setPageLoading(true)
    actions.getUserProfile().then((user) => {
      if (user && user.userProfile) {
        setHasKyc(user.hasKyc)
        setIsUserProfileComplete(user.isUserProfileComplete)
        setIsAccredited(user.isAccredited)
        if (user.hasKyc && sessionStorage.getItem('identityVerified')) {
          setBanner(true)
          sessionStorage.removeItem('identityVerified')
        }
        const { addresses } = user.userProfile
        const primary = addresses[0]
        const mailing = addresses[1]
        if (primary && primary.country) {
          actions.getRegions(primary.country).then(res => setPrimaryRegions(res))
        }
        if (mailing && mailing.country) {
          actions.getRegions(mailing.country).then(res => setMailingRegions(res))
        }
      }
      actions.getCountries().then(res => {
        if (res && res.length > 0) {
          setCountries(res)
        }
        setPageLoading(false)
      })
    })
  }, [actions])

  useEffect(() => {
    if (formValues) {
      if (formValues.initial && formValues.initial.firstName !== '' && Object.keys(formIntialValues).length === 0) {
        setFormIntialValues(formValues.initial)
      }
      if (!_.isEqual(formIntialValues, formValues.values)) {
        setEdited(true)
      } else if (_.isEqual(formIntialValues, formValues.values)) {
        setEdited(false)
      }
    }
    if (entity && entity.entityId > 0) {
      setUserEntity(entity)
    }
  }, [actions, initialValues, redirect, edited, formValues, entity, formIntialValues])

  /**
   * If current form values are different than inititally loaded form values,
   * we set the save button to a disabled state (after submission)
   */
  useEffect(() => {
    if (formValues) {
      if (!_.isEqual(formIntialValues, formValues.values)) {
        setDisable(false)
      }
    }
  }, [formValues && formValues.initial, formValues && formValues.values])

  const onSubmit = useCallback(async (formValues) => {
    const {
      pStreet1,
      pStreet2,
      pCity,
      pZip,
      pState,
      pCountry,
      mStreet2,
      mStreet1,
      mCity,
      mZip,
      mState,
      mCountry
    } = formValues

    const addresses = !formValues.sameAddress ? [
      {
        type: 'ADDRESS',
        street1: pStreet1,
        street2: pStreet2,
        city: pCity,
        state: pState,
        country: pCountry,
        zip: pZip
      },
      {
        type: 'MAILING',
        street1: pStreet1,
        street2: pStreet2,
        city: pCity,
        state: pState,
        country: pCountry,
        zip: pZip
      }
    ] : [
      {
        type: 'ADDRESS',
        street1: pStreet1,
        street2: pStreet2,
        city: pCity,
        state: pState,
        country: pCountry,
        zip: pZip
      },
      {
        type: 'MAILING',
        street1: mStreet1,
        street2: mStreet2,
        city: mCity,
        state: mState,
        country: mCountry,
        zip: mZip
      }
    ]

    setProfileLoading(true)
    const entity = formValues.type ? { type: formValues.type } : {}
    const userProfile = { ..._.pick(formValues, ['firstName', 'lastName', 'taxId', 'foreignTaxId', 'citizenshipCountry', 'phone']), addresses }

    return actions.editProfile({ userProfile, entity }).then((res) => {
      setProfileLoading(false)
      if (res && res.status === 200) {
        setDisable(true)
        if (directToVerifyStatus) {
          history.push('/investor-status')
        } else if (redirect) {
          history.push(redirect)
        } else {
          setFormIntialValues(formValues)
          setHasKyc(res.data.hasKyc)
          setIsUserProfileComplete(res.data.isUserProfileComplete)
          setIsAccredited(res.data.isAccredited)
          setSave(true)
        }
      } else {
        setBigError(true)
      }
    })
  }, [actions, redirect, directToVerifyStatus])

  const downHandler = useCallback((event) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit)
    }
  }, [handleSubmit, onSubmit])

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [downHandler])

  const resetFeildValue = (field, value) => {
    dispatch(change('editForm', field, value))
  }

  const isValidPhone = (value) => {
    if (!parsePhoneNumber(value)) {
      resetFeildValue('phone', '')
    }
  }

  const submitEntity = (e) => {
    e.preventDefault()
    setSubmitEntityLoading(true)
    return actions.submitEntityCreation().then((res) => {
      setSubmitEntityLoading(false)
      if (res === 200) {
        setEntityRequestSubmitted('success')
      } else {
        setEntityRequestSubmitted('error')
      }
    })
  }

  const mask = (val) => {
    if (val?.length < 10 && val?.length > 3) {
      const spaceCount = 9 - val.length
      const formattedSSN = val + new Array(spaceCount).join(' ')
      const ssnWithDash = formattedSSN.slice(0, 3) + '-' + formattedSSN.slice(3, 5) + '-' + formattedSSN.slice(5, 10)
      // make sure we can delete without blocking by space or -
      let toSend = ssnWithDash.replace(/\s+/g, '')
      if (/-$/gm.test(toSend)) {
        toSend = toSend.replace(/-$/gm, '')
      }
      return toSend
    } else {
      return val
    }
  }

  const parseSSN = (val) => {
    return val.match(/\d/g) ? val.match(/\d+/g).join('') : ''
  }

  let submitBtn = ''

  if (redirect && !isAccredited) {
    submitBtn = (
      <>
        <Button size='md' disabled={!dirty || disable} mode='secondary'>Save and Return</Button>
        <Button size='md' disabled={!dirty || disable} mode='primary' onClick={(e) => setDirectToVerifyStatus(true)}>Save and Verify</Button>
      </>)
  } else if (redirect && isAccredited) {
    submitBtn = <Button size='md' disabled={!dirty || disable} mode='secondary'>Save and Return</Button>
  } else if (!redirect && !isAccredited) {
    submitBtn = <Button size='md' disabled={!dirty || disable} mode='primary' onClick={(e) => setDirectToVerifyStatus(true)}>Save and Continue to Accreditation</Button>
  } else if (!redirect && isAccredited) {
    submitBtn = <Button size='md' disabled={!dirty || disable} mode='primary'>Update</Button>
  }

  if (!hasKyc) {
    return <Identity />
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Profile')} />
        <PageLoading />
      </>)
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Profile')} />
      {save && <SaveProfileModal setSave={setSave} />}
      {banner ? <NotificationBanner
        text={'Identity Verified'}
        delay={500}
        stay={2500}
      /> : null}
      <div className='profile-container page-container'>
        <div className='inner-container'>
          <div className='header-progressbar-wrapper'>
            <h1 className='page-title subinfo'>Investor Profile <div className='web-account-email'><span>Account Email: <span className='profile-email'>{initialValues.email}</span></span></div></h1>
            <hr className='title-separator'/>
            <ProgressBar hasKyc={hasKyc} isUserProfileComplete={isUserProfileComplete} isAccredited={isAccredited} activeStep='BasicInfo'/>
            <h2>Basic Information <ProfileStatus isUserProfileComplete={isUserProfileComplete} /></h2>
            <div className='mobile-account-email'><span>Account Email: <span className='profile-email'>{initialValues.email}</span></span></div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='box-content'>
              <div className='ui grid'>
                <div className='eight wide column'>
                  <Field name='firstName' component={renderer.renderInput} label='First Name' type='text' redirect={redirect} disabled={profileLoading || pageLoading} />
                </div>
                <div className='eight wide column'>
                  <Field name='lastName' component={renderer.renderInput} label='Last Name' type='text' redirect={redirect} disabled={profileLoading || pageLoading} />
                </div>
                <div className='eight wide column tablet_eight'>
                  {formValues && (
                    <Field name='phone' label='Phone' component={renderer.renderPhoneInput} className='phone-input' disabled={profileLoading || pageLoading} validatePhone={isValidPhone} />
                  )}
                </div>
                <div className='eight wide mobile four wide tablet four wide computer column'>
                  <Field
                    name='taxId'
                    component={renderer.renderInput}
                    label='SSN/Tax ID'
                    type='text'
                    redirect={redirect}
                    disabled={profileLoading}
                    placeholder='000-00-0000'
                    format={(value, name) => mask(value)}
                    parse={(value, name) => parseSSN(value)}
                    maxLength='11'
                    tooltip={true}
                    content={tooltip.taxId}
                  />
                </div>
                <div className='eight wide mobile four wide tablet four wide computer column'>
                  <Field
                    name='foreignTaxId'
                    component={renderer.renderInput}
                    label='Foreign Tax ID'
                    type='text'
                    redirect={redirect}
                    disabled={profileLoading}
                    content={tooltip.foreignTaxId}
                    tooltip={true}
                  />
                </div>
                <div className='ui grid padded full-width'>
                  <AddressInput section={'p'} category={'Primary'} redirect={redirect} initialValues={initialValues} formValues={formValues} renderDropdown={renderer.renderDropdown} disabled={profileLoading || pageLoading} countries={countries} primaryRegions={primaryRegions} getRegions={actions.getRegions} setPrimaryRegions={setPrimaryRegions} tooltip={tooltip} resetFeildValue={resetFeildValue}/>
                  {!isMobileOnly && <SameAddress formValues={formValues} profileLoading={profileLoading}/>}
                  <div className='eight wide column tablet_eight'>
                    <Field
                      countries={countries}
                      name='citizenshipCountry'
                      component={renderer.renderDropdown}
                      label='Country of Citizenship'
                      redirect={redirect}
                      disabled={profileLoading}
                      tooltip={true}
                      content={tooltip.citizenshipCountry}
                      getRegions={() => {}}
                    />
                  </div>
                  {isMobileOnly && <SameAddress formValues={formValues} profileLoading={profileLoading}/>}
                  <AddressInput section={'m'} category={'Mailing'} redirect={redirect} initialValues={initialValues} formValues={formValues} sameAddress={(formValues && formValues.values) && !formValues.values.sameAddress} renderDropdown={renderer.renderDropdown} disabled={profileLoading || pageLoading} countries={countries} mailingRegions={mailingRegions} getRegions={actions.getRegions} setMailingRegions={setMailingRegions} resetFeildValue={resetFeildValue} />
                </div>
              </div>
            </div>
            <div className='box-group'>
              {hasCompletedOrder &&
                <div className='box entity-box broker-box'>
                  <BrokerList brokerInfos={brokerInfos} tooltip={tooltip.brokerInfo}/>
                </div>
              }
              {isAccredited &&
              <div className='box entity-box'>
                <AddEntity entityRequestSubmitted={entityRequestSubmitted} submitEntity={submitEntity} submitEntityLoading={submitEntityLoading}/>
                <Entities entities={entities} tooltip={tooltip.entities}/>
              </div>
              }
            </div>
            {userEntity && userEntity.entityId > 0 && <IraEntity entity={userEntity} redirect={redirect} loading={profileLoading || pageLoading} />}
            <div className='box-content'>
              <div className='ui grid'>
                <div className='error-container'>
                  { bigError && renderer.renderBigErr('Please make sure to fill in all required fields') }
                </div>
                {redirect ? <span className='content unaccredited-order-copy-margin'>If you have initiated an order, you must complete all steps to finalize your purchase.</span> : null}
                <div className='btn-group start side-by-side'>
                  {submitBtn}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

const validateFormValues = (formValues) => {
  const errors = {}

  if (!formValues.firstName || isWhiteSpace(formValues.firstName)) {
    errors.firstName = 'Please enter a first name.'
  }
  if (!formValues.lastName || isWhiteSpace(formValues.lastName)) {
    errors.lastName = 'Please enter a last name.'
  }
  if (formValues?.pCountry?.includes('United States') && (!formValues.taxId || formValues.taxId.length < 9 || isWhiteSpace(formValues.taxId))) {
    errors.taxId = 'Please enter valid tax ID.'
  }
  if (!formValues?.pCountry?.includes('United States') && (!formValues.foreignTaxId || isWhiteSpace(formValues.foreignTaxId))) {
    errors.foreignTaxId = 'Please enter your foreign tax ID.'
  }
  if (!formValues.phone) {
    errors.phone = 'Please enter a phone number.'
  }
  if (formValues.phone) {
    if (!parsePhoneNumber(formValues.phone)) {
      errors.phone = 'Please enter a phone number.'
    } else if (!isValidPhoneNumber(formValues.phone)) {
      errors.phone = 'Please enter a valid phone number.'
    }
  }
  if (!formValues.citizenshipCountry) {
    errors.citizenshipCountry = 'Please enter your country of citizenship.'
  }
  if (!formValues.pStreet1 || isWhiteSpace(formValues.pStreet1)) {
    errors.pStreet1 = 'Please enter your street address.'
  }
  if (!formValues.pCity || isWhiteSpace(formValues.pCity)) {
    errors.pCity = 'Please enter your city.'
  }
  if (!formValues.pZip || isWhiteSpace(formValues.pZip)) {
    errors.pZip = 'Please enter your postal code.'
  }
  if (!formValues.pState) {
    errors.pState = 'Please select a State or Province.'
  }
  if (!formValues.pCountry) {
    errors.pCountry = 'Please select a Country.'
  }
  if (!formValues.mStreet1 || isWhiteSpace(formValues.mStreet1)) {
    errors.mStreet1 = 'Please enter your street address.'
  }
  if (!formValues.mCity || isWhiteSpace(formValues.mCity)) {
    errors.mCity = 'Please enter your city.'
  }
  if (!formValues.mZip || isWhiteSpace(formValues.mZip)) {
    errors.mZip = 'Please enter your postal code.'
  }
  if (!formValues.mState) {
    errors.mState = 'Please select a State or Province.'
  }
  if (!formValues.mCountry) {
    errors.mCountry = 'Please select a Country.'
  }
  if (formValues.hasEntity && (!formValues.type || formValues.type === '')) {
    errors.type = 'Please select an IRA Type.'
  }
  return errors
}

let formWrapped = reduxForm({
  form: 'editForm',
  validate: validateFormValues
})(Profile)

const selector = formValueSelector('editForm')
formWrapped = connect(state => {
  const mCountry = selector(state, 'mCountry')
  const pCountry = selector(state, 'pCountry')
  return {
    mCountry,
    pCountry,
    dirty: isDirty('editForm')
  }
})(formWrapped)

export default connected(formWrapped)
