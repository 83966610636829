import React, { useEffect, useState } from 'react'
import Button from '../Global/Button'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import history from '../../history'
import { Field, reduxForm, reset } from 'redux-form'
import render from 'components/OnBoarding/render'
import { useDispatch, useSelector } from 'react-redux'
import { getContactUs } from 'actions/CommonActions'
import { PageLoading } from 'components'

const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined

const Contact = ({ actions, status, handleSubmit }) => {
  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const dispatch = useDispatch()
  const { addresses } = useSelector((state) => state.common)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPageLoading(true)
        await dispatch(getContactUs())
        setPageLoading(false)
      } catch (e) {
        setPageLoading(false)
        console.log(e)
      }
    }
    fetchData()
  }, [dispatch])

  const onSubmit = async (formValues) => {
    setLoading(true)
    try {
      const data = await actions.contactUs({ ...formValues })
      setLoading(false)
      if (data === undefined) {
        dispatch(reset('contactUsForm'))
        history.push('/contact/success')
      }
    } catch (e) {
      setLoading(false)
    }
  }

  if (pageLoading) {
    return <PageLoading />
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Contact Us')} />
      <div className='page-container contact-container'>
        <div className='inner-container'>
          <div className='contact-header'>
            <h1>Contact Us</h1>
            <span>Our expert team is here to help. Need a quicker response? Browse <span onClick={() => history.push('/faq')} className='underline'>FAQs.</span></span>
          </div>
          <div className='contact-form'>
            <form className='ui grid ' onSubmit={handleSubmit(onSubmit)}>
              <div className='eight wide column m-sixteen'>
                <Field
                  name='name'
                  component={render.renderInput}
                  label='Name'
                  type='text'
                  disabled={loading}
                />
              </div>
              <div className='eight wide column m-sixteen'>
                <Field
                  name='email'
                  component={render.renderInput}
                  label='Email'
                  type='email'
                  disabled={loading}
                  validate={email}
                />
              </div>
              <div className='sixteen wide column'>
                <Field
                  name='message'
                  component={render.renderInput}
                  label='Message'
                  type='textarea'
                  disabled={loading}
                  placeholder='My question is...'
                />
              </div>
              <div className='sixteen wide column'>
                <Button
                  mode='primary'
                  size='md'
                  loading={loading}
                  disabled={loading}
                >Send</Button>
              </div>
              <div className='info-group'>
                {addresses && addresses.length ? addresses.map((address, i) => (<div key={i} className='info'>
                  <div className='location'>{address.label}</div>
                  <div className='sub-text'>{address.street1}</div>
                  <div className='sub-text'>{address.city}, {address.state} {address.zip}</div>
                </div>
                )) : null}
              </div>
            </form>
            {status === 'failed' && (
              <div className='ui header'>
                Something went wrong, try again later
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const validate = (formValues) => {
  const errors = {}
  if (!formValues.name) {
    errors.name = 'You must enter a name.'
  }
  if (!formValues.email) {
    errors.email = 'You must enter an email.'
  }
  if (!formValues.message) {
    errors.message = 'You must enter a message.'
  }
  return errors
}
const formWrapped = reduxForm({
  form: 'contactUsForm',
  validate
})(Contact)

export default formWrapped
