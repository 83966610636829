import { formatDecimal } from 'utils'
import { images } from 'assets'
import { Button } from 'components'
import history from '../../history'

const RewardFeatures = ({ signUpBonus, firstOrderBonus }) => {
  return (
    <div className='rewards-features-container'>
      <div className='rewards-features-title'>
        We&apos;re growing the Linqto community because we want to make private investing more inclusive for you and your friends.
      </div>
      <div className='rewards-features-details'>
        <div className='rewards-features-text'>
          <div className='rewards-features-text-title'>
            1 Referral with 2 Rewards.
          </div>
          <ol>
            {signUpBonus > 0 &&
            <li> Refer friends and they get <strong>{formatDecimal(signUpBonus, true, 0)}</strong> Linqto Bucks.</li>
            }
            {firstOrderBonus > 0 &&
            <li> Once they complete their first investments, you get <strong>{formatDecimal(firstOrderBonus, true, 0)}</strong> Linqto Bucks.</li>
            }
          </ol>
          <div className='rewards-features-desc'>
            <div className='rewards-features-desc-title'>It&apos;s that simple.</div>
            Learn more in our <a href='/reward-terms-of-use' target='_blank'>Linqto Bucks Terms and Conditions.</a>
          </div>
        </div>
        <img
          src={images['linqto-bucks']}
          alt='Join Linqto Referral Program'
          className='rewards-features-img'
        />
      </div>
      <Button className='btn primary md' onClick={() => history.push('/signup')}>Refer a Friend</Button>
    </div>
  )
}

export default RewardFeatures
