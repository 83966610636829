import PropTypes from 'prop-types'

/**
 * @function ComponentRender - renders one of two components based on a condition
 * @returns chosen compoent
 */
const ComponentRender = ({ condition, ComponentOne, ComponentTwo, children, ...props }) => {
  if (condition) {
    return (<ComponentOne {...props}>{children}</ComponentOne>)
  } else {
    return (<ComponentTwo {...props}>{children}</ComponentTwo>)
  }
}

ComponentRender.propTypes = {
  condition: PropTypes.bool.isRequired,
  ComponentOne: PropTypes.func.isRequired,
  ComponentTwo: PropTypes.func.isRequired,
  children: PropTypes.node
}

export default ComponentRender
