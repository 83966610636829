// HOC wrapper for redux connect

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const mapDispatchToProps = (actions) => (dispatch) => {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
    dispatch
  }
}

const reduxConnect = (boundState, actions) => {
  return component => connect(boundState, mapDispatchToProps(actions))(component)
}
export default reduxConnect
