import { images } from 'assets'
import Button from './Global/Button'
import history from '../history'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'

const NotFound = () => {
  return (
    <>
      <SeoMeta title={seoTitleTemplate('404 Not Found')} />
      <div className='page-container'>
        <div className='inner-container'>
          <div className='not-found-container'>
            <div className='page-title'>We&apos;re sorry, this page does not exist.</div>
            <div className='or-svg-container'>
              <div className='or-line'></div>
              <img alt='404-not-found' src={images['404']} height={45} width={40} />
              <div className='or-line'></div>
            </div>
            <div className='back-to-invest-container'>
              <p>Let&apos;s get you back to investing.</p>
              <Button mode='secondary' onClick={() => history.push('/')}>Home</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound
