import { GET_INVEST, GET_INVEST_DETAILS, TOGGLE_INTEREST } from 'types/actionTypes'
import publicAPI from '../apis/public.js'
import privateAPI from '../apis/private.js'
import { handleServerError } from './CommonActions'
import parseInvestDetails from './parsing/parseInvestDetails'

export const getInvest = (vertical = '', query = '', sort = '') => (dispatch, getState) => {
  const { isSignedIn } = getState().user
  const api = isSignedIn ? privateAPI : publicAPI
  const url = `/page/invest?filterVertical=${encodeURIComponent(vertical)}&searchTerm=${query}&orderBy=${sort}`

  return api
    .get(url)
    .then((res) => {
      const verticals = res.data.verticals ? res.data.verticals : getState().invest.verticals
      dispatch({
        type: GET_INVEST,
        payload: { ...res.data, verticals }
      })
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const getInvestDetail = productId => async (dispatch, getState) => {
  const { isSignedIn } = getState().user
  const api = isSignedIn ? privateAPI : publicAPI
  return api
    .get(`/page/invest/${productId}`)
    .then((res) => {
      dispatch({
        type: GET_INVEST_DETAILS,
        payload: parseInvestDetails(res.data)
      })
      return res.data
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const toggleInterest = ({ companyUrlName, isInterested }) => async dispatch => {
  try {
    const response = await privateAPI.post(`/page/invest/${companyUrlName}/setUserInterest`, { interested: isInterested })
    dispatch({ type: TOGGLE_INTEREST, payload: response.data })
    return response.data
  } catch (err) {
    if (err.response) {
      dispatch(handleServerError(err.response.data.error))
    }
  }
}
