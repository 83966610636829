import { useEffect, useRef } from 'react'
import { applyPolyfills, defineCustomElements } from '@microblink/blinkid-in-browser-sdk/ui/loader'

function BlinkIdComponent ({
  onInitializeStatus,
  onResultReady,
  onErrorModal,
  analytics,
  recognizers,
  microblinkLicenseKey
}) {
  let licenseKey = microblinkLicenseKey
  if (window.location.host === 'localhost:3000') {
    licenseKey = process.env.REACT_APP_MICROBLINK_KEY
  }
  const blinkId = useRef(null)
  useEffect(() => {
    const blinkIdScanner = blinkId.current
    if (blinkId.current !== null) {
      const shadow = blinkIdScanner.shadowRoot
      setTimeout(() => {
        if (shadow !== null) {
          const parent = shadow.children
          if (parent !== null) {
            const toCheck = parent[0] && parent[0].children[0] ? parent[0] : parent[1]
            if (toCheck !== null && toCheck.children !== null && toCheck.children[0] !== null && toCheck.children[0] !== undefined) {
              const here = toCheck.children[0]
              const childList = here.shadowRoot
              if (childList !== null && (childList.children[2] || childList.children[3])) {
                const child = childList.children[2].id === 'screen-action' ? childList.children[2] : childList.children[3]
                if (child !== null && child !== undefined) {
                  const list = child.children
                  child.style.justifyContent = 'center'
                  if (list[1]) {
                    list[1].style.justifyContent = 'center'
                  }
                  const actionLabelsEle = list[0].className === 'action-label' && list[0]
                  if (actionLabelsEle) {
                    actionLabelsEle.remove()
                  }
                  const actionButtonsEle = list[0].className === 'action-buttons' ? list[0] : list[1]
                  if (actionButtonsEle) {
                    const targetGalleryBtn = actionButtonsEle.children[2]
                    if (targetGalleryBtn) {
                      targetGalleryBtn.remove()
                    }
                  }
                }
              }
            }
          }
        }
      }, 500)
    }
  // eslint-disable-next-line
  }, [blinkId.current])

  useEffect(() => {
    const sendInitializeStatus = (status) => onInitializeStatus(status)
    const sendOnResultReady = (results) => onResultReady(results)
    const sendOnError = (error) => {
      if (error.code === 'BROWSER_NOT_SUPPORTED') {
        onInitializeStatus('error')
      } else {
        onErrorModal(error)
      }
    }
    applyPolyfills().then(() => {
      defineCustomElements().then(() => {
        if (!blinkId.current) {
          return
        }
        blinkId.current.recognizers = recognizers
        blinkId.current.wasmType = 'BASIC'
        // NOTE: see here for available options: https://github.com/BlinkID/blinkid-in-browser/tree/master/ui/docs/components/blinkid-in-browser
        if (recognizers[0] && recognizers[0] === 'BlinkIdRecognizer') {
          blinkId.current.recognizerOptions = {
            BlinkIdRecognizer: {
              returnFullDocumentImage: true,
              returnFaceImage: true
            }
          }
        } else if (recognizers[0] && recognizers[0] === 'BlinkIdCombinedRecognizer') {
          blinkId.current.recognizerOptions = {
            BlinkIdCombinedRecognizer: {
              returnFullDocumentImage: true,
              returnFaceImage: true
            }
          }
        }
        blinkId.current.addEventListener('ready', () => {
          sendInitializeStatus('success')
        })

        blinkId.current.addEventListener('cameraScanStarted', () => {
          analytics.send({
            event: 'GTMVirtualPageview',
            VirtualPageviewURL: 'in-browser-sdk/blinkid/device-camera',
            VirtualPageviewTitle: 'Device Camera - BlinkID'
          })
        })
        blinkId.current.addEventListener('imageScanStarted', () => {
          analytics.send({
            event: 'GTMVirtualPageview',
            VirtualPageviewURL: 'in-browser-sdk/blinkid/from-gallery',
            VirtualPageviewTitle: 'Upload from gallery - BlinkID'
          })
        })
        blinkId.current.addEventListener('scanSuccess', (ev) => {
          if (blinkId.current) {
            const activeRecognizer = blinkId.current?.recognizers[0]
            analytics.send({
              event: 'GTMEvent',
              eventCategory: 'scan',
              eventAction: 'OK',
              eventLabel: `BlinkID - ${activeRecognizer}`
            })
            sendOnResultReady(ev.detail)
          }
        })

        blinkId.current.addEventListener('scanError', (ev) => {
          const activeRecognizer = blinkId.current?.recognizers[0]
          analytics.send({
            event: 'GTMEvent',
            eventCategory: 'scan',
            eventAction: 'Unsuccessful',
            eventLabel: `BlinkID - ${activeRecognizer}`
          })
          sendOnError(ev.detail)
        })
      })
    })
  }, [analytics, recognizers, onInitializeStatus, onErrorModal, onResultReady])

  useEffect(() => {
    const sendOnError = (error) => {
      if (error.code === 'BROWSER_NOT_SUPPORTED') {
        onInitializeStatus('error')
      } else {
        onErrorModal(error)
      }
    }
    blinkId.current.addEventListener('fatalError', (ev) => {
      sendOnError(ev.detail)
    })
    // eslint-disable-next-line
  }, [])

  return (
    <blinkid-in-browser
      license-key={licenseKey}
      ref={blinkId}
      enable-drag={false}
      show-action-labels={false}
      hide-feedback
      thorough-scan-from-image
      scan-from-image={false}
      include-success-frame
      engine-location= {window.location.origin}
    />
  )
}

export default BlinkIdComponent
