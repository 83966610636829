import { Link } from 'react-router-dom'
const Footer = ({ rewardsEnabled, unicornsEnabled }) => {
  return (
    <div id='footer-div' className='footer-container'>
      <div className='inner-container'>
        <div className='logo-group'>
          <Link to='/'>
            <img
              className='logo'
              alt='Linqto - Private Investing'
              src='/api/1/logo'
            ></img>
          </Link>
          <div className='copyright'>&#169; {new Date().getFullYear()} Linqto Inc.</div>
        </div>
        <div className='nav-group'>
          <div className='navs'>
            <div className='nav-title'>Company</div>
            <div className='item'>
              <Link to='/about' className='footer-link'>
                About
              </Link>
            </div>
            <div className='item'>
              <Link to='/team' className='footer-link'>
                Team
              </Link>
            </div>
            <div className='item'>
              <Link to='/products' className='footer-link'>
                Invest
              </Link>
            </div>
            <div className='item'>
              <Link to='/sell' className='footer-link'>
                Sell
              </Link>
            </div>
            {rewardsEnabled && (
              <div className='item'>
                <Link to='/rewards' className='footer-link'>
                  Linqto Bucks
                </Link>
              </div>
            )}
            <div className='item'>
              <a
                href='https://globalinvestorconference.com'
                className='footer-link'
              >
                Conference
              </a>
            </div>
            <div className='item'>
              <a
                href='https://www.linkedin.com/company/linqto-inc./jobs/'
                className='footer-link'
              >
                Career
              </a>
            </div>
            <div className='item'>
              <Link to='/terms-of-use' className='footer-link'>
                Terms of Use
              </Link>
            </div>
            <div className='item'>
              <Link to='/privacy' className='footer-link'>
                Privacy
              </Link>
            </div>
          </div>
        </div>
        <div className='nav-group'>
          <div className='navs'>
            <div className='nav-title'>Support</div>
            <div className='item'>
              <Link to='/faq' className='footer-link'>
                FAQ
              </Link>
            </div>
            <div className='item'>
              <Link to='/contact' className='footer-link'>
                Contact us
              </Link>
            </div>
            <div className='item'>
              <Link to='/signup' className='footer-link'>
                Sign up
              </Link>
            </div>
          </div>
          <div className='navs'>
            <div className='nav-title'>Social Links</div>
            <div className='item'>
              <a
                href='https://www.facebook.com/Linqto'
                className='footer-link'
                target='_blank'
                rel='noopener noreferrer'
              >
                Facebook
              </a>
            </div>
            <div className='item'>
              <a
                href='https://twitter.com/linqtoinc?lang=en'
                className='footer-link'
                target='_blank'
                rel='noopener noreferrer'
              >
                Twitter
              </a>
            </div>
            <div className='item'>
              <a
                href='https://www.linkedin.com/company/linqto-inc./'
                className='footer-link'
                target='_blank'
                rel='noopener noreferrer'
              >
                LinkedIn
              </a>
            </div>
            <div className='item'>
              <a
                href='https://www.youtube.com/channel/UCS8uFXbni3A-4FhwqNqpFBQ'
                className='footer-link'
                target='_blank'
                rel='noopener noreferrer'
              >
                YouTube
              </a>
            </div>
            <div className='item'>
              <a
                href='https://www.instagram.com/linqtoplatform/'
                className='footer-link'
                target='_blank'
                rel='noopener noreferrer'
              >
                Instagram
              </a>
            </div>
          </div>
          {unicornsEnabled && (
            <div className='navs'>
              <Link to='/market-insights/' className='nav-title'>
                Market Insights
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className='inner-container'>
        <div className='margined'>
        Disclaimer: Neither Linqto, Inc., nor any of its officers, directors, agents, employees, or affiliates (collectively “Linqto”) give any investment advice, endorsement, analysis, or recommendations with respect to any securities. The information on this website is provided for educational purposes and is not intended to influence any investment decision. No communication from Linqto, through this website or in any other medium, should be construed as a call to action to purchase any securities. Linqto strongly encourages potential investors to seek professional counsel prior to entering into any transaction for any securities.{' '}
          <a
            href='http://www.rainmakersecurities.com/disclosures'
            alt='Rainmaker Securities LLC'
            rel='noopener noreferrer'
            target='_blank'
          >
            Rainmaker Securities, LLC
          </a>
          , member{' '}
          <a
            href='https://www.sipc.org/'
            alt='SIPC'
            rel='noopener noreferrer'
            target='_blank'
          >
            FINRA/SIPC
          </a>
          .
        </div>
      </div>
    </div>
  )
}

export default Footer
