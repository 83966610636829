import { ConditionalRender } from 'components'
import history from '../../../history'
import Button from '../../Global/Button'
import { images } from 'assets'

const UnicornDetailsHeaderCta = ({ company, isLoggedIn, setInterestedUnicorn, buttonLoading, interestedUnicron }) => {
  const redirect = (url) => {
    history.push(url)
  }
  const interestedFlag = interestedUnicron === undefined ? false : interestedUnicron
  return (
    <>
      <ConditionalRender isVisible={isLoggedIn && company.sharePrice !== null}>
        <Button mode='primary' size='md' customClass='buy-unicorn' onClick={() => redirect(`/products/${company.urlName}`)} loading={buttonLoading}>
          <img src={images['unicorn-icon']} alt='Buy on Linqto'/>
          <div>Invest on Linqto</div>
        </Button>
      </ConditionalRender>
      <ConditionalRender isVisible={isLoggedIn && company.sharePrice === null && (!interestedFlag)}>
        <Button mode='secondary' size='md' customClass='interest-unicorn' onClick={() => setInterestedUnicorn(company.urlName, true)}>
          <div>I want to see this on Linqto</div>
        </Button>
      </ConditionalRender>
      <ConditionalRender isVisible={isLoggedIn && company.sharePrice === null && (interestedFlag) }>
        <Button mode='secondary' size='md' customClass='interest-unicorn interest-received' onClick={() => setInterestedUnicorn(company.urlName, false)}>
          <div>You requested we add this to Linqto</div>
          <img src={images['unicorn-interested-icon']} alt='Request Received'/>
        </Button>
      </ConditionalRender>
    </>
  )
}

export default UnicornDetailsHeaderCta
