import { aboutItems } from './AboutData'
import AboutCard from './AboutCard'
const AboutCards = () => {
  return (
    <div className='about-card-container'>
      <div className='inner-container'>
        <div className='card-inner-container'>
          {aboutItems && aboutItems.length && aboutItems.map(({ title, src, alt, description }, i) =>
            <AboutCard key={i} title={title} src={src} alt={alt} i={i} description={description} />)}
        </div>
      </div>
    </div>
  )
}

export default AboutCards
