import { useEffect } from 'react'
import { PageLoading, RewardHeader, RewardShare, RewardFeatures, RewardHistory } from 'components'
import connected from '../../containers/Rewards'
import ContactSupport from 'components/Global/ContactSupport'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'

const Rewards = ({ actions, spinnerVisible, linqtoBucks, referralUrl, signUpBonus, firstOrderBonus, significantInvestorMinimum, significantInvestorPercent, transactions, isLoggedIn }) => {
  useEffect(() => {
    actions.showLoadingSpinner()
    actions.getReward().then((res) => {
      actions.hideLoadingSpinner()
    })
  }, [actions])

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Linqto Bucks Referral Program')} />
      {spinnerVisible ? (
        <PageLoading />
      ) : (
        <div className='page-container rewards-container'>
          <div className='inner-container'>
            <RewardHeader bucks={linqtoBucks} isLoggedIn={isLoggedIn} />
            {isLoggedIn &&
            <>
              <RewardShare referralUrl={referralUrl} signUpBonus={signUpBonus} firstOrderBonus={firstOrderBonus} />
              {transactions.length > 0 &&
                <RewardHistory transactions={transactions} />
              }
              <ContactSupport />
            </>
            }
            {!isLoggedIn &&
            <RewardFeatures
              signUpBonus={signUpBonus}
              firstOrderBonus={firstOrderBonus}
              significantInvestorMinimum={significantInvestorMinimum}
              significantInvestorPercent={significantInvestorPercent}
            />
            }
          </div>
        </div>
      )}
    </>
  )
}

export default connected(Rewards)
