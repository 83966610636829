import Modal from '../Global/Modal'

const AccreditationTypesModal = ({
  setShowAccreditationTypesModal,
  status
}) => {
  return (
    <Modal
      mode='primary'
      size='md'
      hideModal={() => setShowAccreditationTypesModal(false)}
      modalHeader=''
      clickToClose
      pressToClose
      crossToClose
      innerStyle='accreditation-types-modal'
    >
      <div className='content'>
        <ul>
          {Object.values(status).map((ele, id) => {
            return <li key={`li-${id}`}>{ele}</li>
          })}
        </ul>
      </div>
    </Modal>
  )
}

export default AccreditationTypesModal
