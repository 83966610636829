import { useEffect, useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import connected from '../../containers/SignUp'
import { Link } from 'react-router-dom'
import Button from '../Global/Button'
import render from './render'
import ReferralInfo from './ReferralInfo'
import ReferralError from './ReferralError'
import qs from 'qs'
import { PageLoading } from 'components'
import history from '../../history'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import AccreditationModal from './AccreditationModal'

const SignUp = (props) => {
  const [loading, setLoading] = useState(false)
  const [hasReferrer, setHasReferrer] = useState(false)
  const [referrerName, setReferrerName] = useState('')
  const [signUpBonus, setSignUpBonus] = useState('')
  const [referrerLogo, setReferrerLogo] = useState('')
  const [referralError, setReferralError] = useState(false)
  const [referralErrorMessage, setReferralErrorMessage] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [internalServerError, setInternalServerError] = useState(false)
  const [isFromOrigination, setIsFromOrigination] = useState(false)
  useEffect(() => {
    if (props.location?.state?.fromOrigination) {
      setIsFromOrigination(props.location.state.fromOrigination)
    }
    const query = props.location.search
    let r = ''
    if (query) {
      r = qs.parse(query, { ignoreQueryPrefix: true }).r
    }
    props.actions.getSignupReferral(r).then((res) => {
      if (res.hasReferrer) {
        setLoading(false)
        setReferrerName(res.referrerName)
        setSignUpBonus(res.signupBonus)
        setHasReferrer(res.hasReferrer)
        setReferrerLogo(res.referrerLogoUrl ? res.referrerLogoUrl : '')
      } else {
        if (props.user.notifyMessage) {
          setReferralError(true)
          setReferralErrorMessage({ message: props.user.notifyMessage, title: 'Notice', actionLabel: 'Ok', action: 'redirect' })
        } else if (r) {
          setReferralError(true)
          setReferralErrorMessage({ message: 'The referral link has expired or the referrer doesn\'t exist. Please sign up and provide the referrer\'s name in the form so we can manually match it for you.', title: 'Something went Wrong', actionLabel: 'Continue to sign up', action: 'close' })
        }
        setLoading(false)
      }
    })
  }, [])

  // cleanup to reset err when component unmounts (link from signup to signin page)
  useEffect(() => () => props.actions.resetErr(), [])

  const onSubmit = async (formValues) => {
    setLoading(true)
    setInternalServerError(false)
    const redirectUrl = isFromOrigination ? `/sell/${props.location.state.urlName}` : ''
    const bodyTosend = { ...formValues, accreditedCountry: '', selfAccreditation: 'UNKNOWN', fromOrigination: isFromOrigination, redirectUrl }
    try {
      const res = await props.actions.signUp(bodyTosend)
      setLoading(false)
      if (res !== 500 && res !== 404) {
        history.push('/activate')
      } else {
        setInternalServerError(true)
      }
    } catch (e) {
      setLoading(false)
      setInternalServerError(true)
    }
  }
  const renderTerms = () => {
    if (isFromOrigination) {
      return (
        <span>
          I agree to the {' '}
          <a href='/terms-of-use'
            rel='noopener noreferrer'
            target='_blank'>Linqto Terms of Use</a> and{' '}
          <a target='_blank' rel='noopener noreferrer' href='/privacy'>Privacy Policy.</a>
        </span>
      )
    } else {
      return (
        <span>
          I meet the <span style={{ cursor: 'pointer' }} onClick={() => setShowModal(true)}><a>Accreditation Terms</a></span> and I agree to the {' '}
          <a href='/terms-of-use'
            rel='noopener noreferrer'
            target='_blank'>Linqto Terms of Use</a> and{' '}
          <a target='_blank' rel='noopener noreferrer' href='/privacy'>Privacy Policy.</a>
        </span>
      )
    }
  }
  const renderAccreditationOptions = () => {
    return (
      <>
        <div className='radio-option'>
          <Field
            name='accreditOption'
            component='input'
            type='radio'
            value='isAccredited'
            tag=''
            id='accredited'
            disabled={loading}
          />
          <label
            htmlFor='accredited'
            className='radio__label content'
          >
            I meet&nbsp;<span className='' style={{ cursor: 'pointer' }} onClick={() => setShowModal(true)}><a>accreditation terms.</a></span>
          </label>
        </div>
        <div className='radio-option'>
          <Field
            name='accreditOption'
            component='input'
            type='radio'
            value='notAccredited'
            tag=''
            id='not-accredited'
            disabled={loading}
          />
          <label
            htmlFor='not-accredited'
            className='radio__label content'
          >
            I am not accredited.
          </label>
        </div>
      </>
    )
  }

  const renderServerErr = () => {
    return (
      <div className='ui error message'>
        <div className='header'>Something went wrong.</div>
        <p>
          {props.errMsg === 'REGISTRATION_EMAIL_ALREADY_ACTIVATED' ? (
            <>
              You have already registered.{' '}
              <Link to='/signIn'>Sign in here.</Link>
            </>
          ) : (
            <>
              Please make sure all the information are correct or{' '}
              <Link to='/contact'>contact the support</Link>.
            </>
          )}
        </p>
      </div>
    )
  }

  const closeModal = () => {
    setReferralError(false)
    if (referralErrorMessage?.action === 'redirect') {
      const message = referralErrorMessage.message
      if (message.indexOf('Forge Trust') >= 0) {
        history.push('/rewards')
      } else {
        history.push('/')
      }
    }
  }

  if (loading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Sign Up')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      {showModal && <AccreditationModal hideModal={() => setShowModal(false)} accreditationTerms={props.accreditationTerms} />}
      <SeoMeta title={seoTitleTemplate('Sign Up')} />
      <div className='page-container'>
        <div className='inner-container'>
          <div className='signup-container'>
            {(hasReferrer && props.rewardsEnabled) && <ReferralInfo referrerName={referrerName} referralAmount= {signUpBonus} referrerLogo={referrerLogo} />}
            {referralError && <ReferralError referralErrorMessage={referralErrorMessage} closeModal={closeModal} />}
            <h1 className='signup-header'>Create Account</h1>
            <form
              className='ui stackable grid'
              onSubmit={props.handleSubmit(onSubmit)}
            >
              <div className='row'>
                <div className='eight wide column tablet_eight'>
                  <Field
                    name='firstName'
                    component={render.renderInput}
                    label='First Name'
                    type='text'
                    disabled={loading}
                  />
                </div>
                <div className='eight wide column tablet_eight'>
                  <Field
                    name='lastName'
                    component={render.renderInput}
                    label='Last Name'
                    type='text'
                    disabled={loading}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='eight wide column'>
                  <Field
                    name='email'
                    component={render.renderInput}
                    label='Email'
                    type='email'
                    autoComplete='username'
                    disabled={loading}
                  />
                </div>
                <div className='eight wide column'>
                  <Field
                    name='password'
                    component={render.renderInput}
                    label='Password'
                    type={showPassword ? 'text' : 'password'}
                    autoComplete='new-password'
                    disabled={loading}
                    viewPassword={() => setShowPassword(prev => !prev)}
                  />
                </div>
              </div>
              {(!hasReferrer && props.rewardsEnabled) &&
              <div className='row'>
                <div className='eight wide column'>
                  <Field
                    name='referredByName'
                    component={render.renderInput}
                    label='Referred by: (optional)'
                    description='Please enter first name and last name. follow the format of ‘John Doe’'
                    placeholder='First and Last Name of Referrer'
                    type='text'
                    disabled={loading}
                  />
                </div>
              </div>
              }
              <div className='sixteen wide column'>
                <Field
                  name='terms'
                  component={render.renderInput}
                  type='checkbox'
                  disabled={loading}
                >{renderTerms()}
                </Field>
              </div>
              {isFromOrigination && <div className='sixteen wide column'>
                <div className='content'>Accreditations:</div>
                {props.formValues?.syncErrors?.accreditOption &&
                  props.formValues.fields?.accreditOption?.touched && (
                  <div className='err-msg'>
                    {props.formValues.syncErrors.accreditOption}
                  </div>
                )}
                <div className='radio-group'>
                  {renderAccreditationOptions()}
                </div>
              </div>}
              <div className='sixteen wide column'>
                {(props.user.err || internalServerError) && renderServerErr()}
              </div>
              <div className='eight wide column'>
                <Button
                  style={{ width: '100%' }}
                  size='md'
                  mode='primary'
                  type='submit'
                  loading={loading}
                  disabled={loading}
                >{isFromOrigination ? 'Create Account' : 'Start Investing'}</Button>
              </div>
            </form>
            <span className='account-signin'>Already have an account? <span onClick={() => history.push('/signin')}>Sign In</span></span>
          </div>
        </div>
      </div>
    </>
  )
}

const validate = (formValues) => {
  const errors = {}
  if (!formValues.firstName) {
    errors.firstName = 'Please enter your first name.'
  }
  if (!formValues.lastName) {
    errors.lastName = 'Please enter your last name.'
  }
  if (!formValues.email) {
    errors.email = 'Please enter your email.'
  }
  if (!formValues.password) {
    errors.password = 'Please enter a password.'
  }
  if (!formValues.terms) {
    errors.terms = 'Please agree to the terms in order to proceed.'
  }
  if (!formValues.accreditOption) {
    errors.accreditOption = 'Please choose one accreditation option in order to proceed.'
  }
  if (formValues.password && formValues.password.length < 7) {
    errors.password = 'Password must be at least 8 characters long.'
  }
  const regex = /\d+/g
  if (formValues.password && !formValues.password.match(regex)) {
    errors.password = 'Password must contain at least one number.'
  }
  if (formValues.password !== formValues.confirmPsd) {
    errors.confirmPsd = 'Confirm Password should match the password above.'
  }
  return errors
}

const formWrapped = reduxForm({
  form: 'signUpForm',
  validate
})(SignUp)

export default connected(formWrapped)
