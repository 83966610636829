import Input from '../../Global/InputField'

const renderInput = ({ input, label, placeholder, meta, type, className, disabled, children, ...props }) => {
  return (
    <Input input={input} meta={meta} label={label} placeholder={placeholder} type={type} className={className} disabled={disabled} { ...props}>{children}</Input>
  )
}

export default {
  renderInput
}
