import { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import connect from '../containers/TermsOfUse'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading } from 'components'

const TermOfUse = ({ actions, pageDetail }) => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    actions.getTermsOfUse()
      .then(() => {
        setLoading(false)
      })
  }, [actions])

  if (loading) return <PageLoading />
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Terms of Use')} />
      <div className='page-container terms-container'>
        <div className='inner-container'>
          {parse(pageDetail)}
        </div>
      </div>
    </>
  )
}

export default connect(TermOfUse)
