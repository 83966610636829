import privateAPI from '../apis/private'
import { GET_INVESTOR_STATUS, UPLOAD_LOADING, REQUEST_FINANCIAL_ADVISOR } from 'types/actionTypes'
import axios from 'axios'
import FormData from 'form-data'
import { handleServerError } from './CommonActions'

export const getInvestorStatus = () => dispatch => {
  return privateAPI.get('/page/accreditedInvestorStatus')
    .then((res) => {
      dispatch({
        type: GET_INVESTOR_STATUS,
        payload: res.data
      })
      return res.data.crdNumber
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const uploadLoading = (status) => dispatch => {
  dispatch({
    type: UPLOAD_LOADING,
    payload: { uploadingStatus: status }
  })
}

export const uploadDocs = (acceptedFile, fileName, type, query, func) => dispatch => {
  let apiEndPoint = ''
  switch (type) {
  case 'AI':
    apiEndPoint = `${process.env.REACT_APP_API_URL}/page/accreditedInvestorStatus/documents`
    break
  case 'FA':
    apiEndPoint = `${process.env.REACT_APP_API_URL}/page/financialAdvisor/documents`
    break
  case 'ID':
    apiEndPoint = `${process.env.REACT_APP_API_URL}/page/kycStatus/documents`
    break
  default:
    apiEndPoint = `${process.env.REACT_APP_API_URL}/page/accreditedInvestorStatus/documents`
  }
  const token = localStorage.getItem('linqto_token')
  let headers = type !== 'AI' && query
  if (type === 'AI' && window.location.hostname === 'localhost') {
    headers = { access_token: token }
  }
  const data = new FormData()
  data.append('file', acceptedFile[0])
  data.append('name', fileName === 'Click to upload' ? acceptedFile[0].name.slice(0, 100) : fileName)
  const config = {
    method: 'post',
    url: apiEndPoint,
    headers,
    onUploadProgress: (progressEvent) => func(progressEvent),
    data: data
  }
  return axios(config)
    .then(() => {
    })
    .catch(err => {
      if (err.response) {
        if (err.response.status > 299 && err.response.status !== 401) {
          dispatch(handleServerError('UPLOAD_FAILED'))
        }
        localStorage.setItem('prev-route', '/investor-status')
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const requestFinancialAdvisor = (bodyToSend) => dispatch => {
  dispatch({
    type: REQUEST_FINANCIAL_ADVISOR,
    payload: { requestStatus: 'loading' }
  })
  return privateAPI.post('/financialAdvisorRequest', bodyToSend)
    .then(() => {
      dispatch({
        type: REQUEST_FINANCIAL_ADVISOR,
        payload: { requestStatus: 'success' }
      })
    })
    .catch(err => {
      dispatch({
        type: REQUEST_FINANCIAL_ADVISOR,
        payload: { requestStatus: 'failed' }
      })

      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const deleteAIDocs = (name) => dispatch => {
  return privateAPI.delete('/page/accreditedInvestorStatus/documents', { data: { name } })
    .then((res) => {
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const deleteFADocs = (name, query) => dispatch => {
  return privateAPI.delete('/page/financialAdvisor/documents', { headers: query, data: { name } })
    .then(res => {
    })
    .catch((err) => {
      if (err.response) {
        return 400
      }

      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const saveSelfAccreditation = (formValue) => dispatch => {
  return privateAPI.post('/page/accreditedInvestorStatus/selfAccreditation', formValue)
    .then(res => {
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const saveSecurityLicense = (formValue) => dispatch => {
  return privateAPI.post('/page/accreditedInvestorStatus/securitiesLicense', formValue)
    .then(res => {})
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}
