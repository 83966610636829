import React from 'react'
import Companies from './Companies'

class Accounts extends React.Component {
  render () {
    return (
      <div className='accounts-container'>
        <div className='portfolio'>
          <h1 className='page-title'>My Portfolio</h1>
          <Companies companies={this.props.companies} />
        </div>
      </div>
    )
  }
}

export default Accounts
