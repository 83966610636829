import React, { useEffect, useState, useRef, useCallback, Suspense } from 'react'
import { PageLoading } from 'components'
import connected from '../../containers/Unicorns'
import UnicornsHeader from './partials/UnicornsHeader'
import UnicornsFilters from './partials/UnicornsFilters'
import history from '../../history'
import { CircleLoader } from '../Global/ImageWithLoader'
import SeoMeta from 'components/Global/SeoMeta'
import _ from 'lodash'
import { getPageScrollPosition, setPageScrollPosition } from 'utils'

const UnicornsCard = React.lazy(() => import('./partials/UnicornsCard'))

const Unicorns = ({ actions, totalUnicorns, companies, totalValuations, sectors, countries, isLoggedIn, hasMore }) => {
  const url = new URL(window.location)
  const params = new URLSearchParams(url.search)
  const intialSort = params.get('sort') || ''
  const intialSector = params.get('sector') || ''
  const intialCountry = params.get('country') || ''
  const intialTerm = params.get('term') || ''

  const [query, setQuery] = useState(intialTerm)
  const [country, setCountry] = useState(intialCountry)
  const [sector, setSector] = useState(intialSector)
  const [sort, setSort] = useState(intialSort)
  const [pageNum, setPageNum] = useState(0)
  const [newSearch, setNewSearch] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [topScroll, setTopScroll] = useState(0)
  const searchInput = useRef()
  const countrySelect = useRef()
  const sectorSelect = useRef()
  const sortSelect = useRef()
  const observer = useRef()

  useEffect(() => {
    if (hasMore || newSearch) {
      setIsLoading(true)
      actions.getUnicorns(pageNum, newSearch, country, sector, query, sort, 12).then(() => {
        setIsLoading(false)
      })
    }
  // eslint-disable-next-line
  }, [actions, pageNum, sector, country, sort, query])

  const lastUnicornElementRef = useCallback(
    (node) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: [0.0, 1]
      }
      setTimeout(() => {
        observer.current = new IntersectionObserver(
          (entries) => {
            if (entries[0].intersectionRatio === 1 && entries[0].isIntersecting && hasMore) {
              setNewSearch(false)
              setPageNum((prev) => prev + 1)
            }
          }, observerOptions)
        if (node) observer.current.observe(node)
      }, 1000)
    },
    [isLoading, hasMore]
  )

  useEffect(() => {
    const onScroll = (event) => {
      setTopScroll(window.pageYOffset)
    }
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  useEffect(() => {
    window.onpopstate = e => {
      getPageScrollPosition()
    }
  })

  useEffect(() => {
    searchInput.current.value = query
  })

  const showUnicornDetails = (urlName, isOnPlatform) => {
    setPageScrollPosition(topScroll)
    if (isOnPlatform) history.push(`/products/${urlName}`)
    else history.push(`/market-insights/${urlName}`)
  }

  const buildQueryURL = (sort, country, sector, term) => {
    let urlString = '/market-insights/'
    if ((sort && sort !== '') || country || sector || term) {
      urlString += '?'
      if (sort && sort !== '') {
        urlString += `sort=${sort}`
      }
      if (country && country !== '') {
        urlString += `&country=${country}`
      }
      if (sector && sector !== '') {
        urlString += `&sector=${encodeURIComponent(sector)}`
      }
      if (term && term !== '') {
        urlString += `&term=${term}`
      }
    }
    return urlString
  }

  const getParams = (type, value) => {
    const url = new URL(window.location)
    const params = new URLSearchParams(url.search)
    const country = (type === 'country') ? value : params.get('country')
    const sector = (type === 'sector') ? value : params.get('sector')
    const term = (type === 'term') ? value : params.get('term')
    const sort = (type === 'sort') ? value : params.get('sort')
    return { sort, sector, country, term }
  }

  const changeSearchFilter = (item, type) => {
    if (type === 'sort') setSort(item)
    if (type === 'sector') setSector(item)
    if (type === 'country') setCountry(item)
    setNewSearch(true)
    setPageNum(0)
    const { sort, sector, country, term } = getParams(type, item)
    window.history.replaceState(null, null, buildQueryURL(sort, country, sector, term))
  }

  const debounceFun = _.debounce(function (val) {
    searchUnicorns(val)
  }, 250, {})

  const searchUnicorns = (val) => {
    setQuery(val)
    setNewSearch(true)
    setPageNum(0)
    searchInput.current.focus()
    const { sort, sector, country, term } = getParams('term', val)
    window.history.replaceState(null, null, buildQueryURL(sort, country, sector, term))
  }

  return (
    <>
      <SeoMeta title={'Linqto Market Insights | Linqto | private investing made simple'} />
      {pageNum === 0 && isLoading && !newSearch && <PageLoading />}
      <div className='linqto-portfolio-container unicorns-container' >
        <UnicornsHeader totalUnicorns={totalUnicorns} totalValuations={totalValuations} />
        <div className='page-container'>
          <div className='inner-container'>
            <div className='container'>
              <UnicornsFilters
                countries={countries}
                changeSearchFilter={changeSearchFilter}
                sectors={sectors}
                isLoading={isLoading}
                debounceFun={debounceFun}
                searchInputRef={searchInput}
                countrySelectRef={countrySelect}
                sectorSelectRef={sectorSelect}
                sortSelectRef={sortSelect}
                sector={sector}
                country={country}
                sort={sort}
                term={query}
                setQuery={setQuery}
              />
              <div className='ui grid'>
                <div className='three column row'>
                  {companies.map((unicorn, i) => {
                    if (companies.length === i + 1) {
                      return (
                        <Suspense key={i} fallback={<CircleLoader />}>
                          <UnicornsCard isLoggedIn={isLoggedIn} lastRef={lastUnicornElementRef} company={unicorn} showUnicornDetails={showUnicornDetails} />
                        </Suspense>
                      )
                    } else {
                      return (
                        <Suspense key={i} fallback={<CircleLoader />}>
                          <UnicornsCard isLoggedIn={isLoggedIn} company={unicorn} showUnicornDetails={showUnicornDetails}/>
                        </Suspense>
                      )
                    }
                  })}
                  {isLoading && hasMore && <CircleLoader /> }
                  {companies.length === 0 && <div className='empty-unicrons'>There are no results matching your search</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default connected(Unicorns)
