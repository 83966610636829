import { useEffect, useState, useCallback, Suspense } from 'react'
import { Transfer as connected } from 'containers'
import AddBank from './AddBank'
import { images } from 'assets'
import Image, { CircleLoader, ImgWithLoader } from '../Global/ImageWithLoader'
import LinkBankModal from './LinkBankModal'
import TransferModal from './TransferModal'
import { ConditionalRender, PageLoading, Row, Button } from 'components'
import Cookies from 'js-cookie'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'

const haveAccountReady = (accounts) => {
  let haveAccountReady = false
  for (let i = 0; i < accounts.length; i++) {
    const account = accounts[i]
    if (account.isReady) {
      haveAccountReady = true
    }
  }
  return haveAccountReady
}

const Transfer = ({ actions, plaidAccounts, accounts, history, dailyLimit, dailyLimitUsed, isAccredited }) => {
  const [token, setToken] = useState('')
  const [showAccountModal, setShowAccountModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showTransferModal, setShowTransferModal] = useState(false)
  const [permission, setPermission] = useState(false)

  const updateACHPage = useCallback(() => {
    setLoading(true)
    actions.getWalletPageAch()
      .then((res) => {
        setPermission(res.hasAddBankAccountPermission)
        setLoading(false)
        if (haveAccountReady(res.accounts) && localStorage.getItem('queue-action') === 'transfer' && res.hasAddBankAccountPermission && localStorage.getItem('transfer-modal') !== 'close') {
          setShowTransferModal(true)
          localStorage.removeItem('queue-action')
        } else {
          setShowTransferModal(false)
          localStorage.removeItem('transfer-modal')
        }
      })
  }, [actions])

  useEffect(() => {
    Cookies.set('redirectPath', history.location.pathname)
    updateACHPage()
    actions.getUpholdPlaidLinkToken()
      .then((linkToken) => setToken(linkToken))
  }, [actions, updateACHPage, history])

  useEffect(() => {
    if (plaidAccounts && plaidAccounts.length > 0) {
      setShowAccountModal(true)
    }
  }, [plaidAccounts])

  const toggleTransferModal = (status) => {
    setShowTransferModal(status)
  }

  const hideLinkBankModal = () => {
    setShowAccountModal(false)
    actions.cleanPlaidAccounts()
  }

  if (loading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Transfer Funds')} />
        <PageLoading/>
      </>
    )
  }
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Transfer Funds')} />
      <div className='page-container wallet-container'>
        <div className='inner-container'>
          <h1 className='page-title'>Transfer Funds</h1>
          <div>
            <ConditionalRender isVisible={!isAccredited} className='wallet'>
              <Suspense fallback={<CircleLoader />}>
                <ImgWithLoader className='image' alt='not accredited' src={images['not-accredited']}/>
              </Suspense>
              <div className='content'>Only accredited investors can use our direct ACH transfer ...  up to $50,000 daily.</div>
              <Button mode='primary' size='md' onClick={() => history.push('/investor-status')}>Get Accredited</Button>
            </ConditionalRender>
            <ConditionalRender isVisible={isAccredited && accounts.length === 0} className='wallet'>
              <Image className='image' alt='uphold not connect' src={images['no-bank']}/>
              <div className='content'>No banks linked. Link a bank account to transfer funds to your wallet.</div>
              <AddBank plaidLinkToken={token} setLoading={setLoading} permission={permission}/>
            </ConditionalRender>
            <ConditionalRender isVisible={isAccredited && accounts.length > 0} className='wallet'>
              <div className='ach-transfer'>
                <div className='title-group'>
                  <div className='title'>Banks <Image alt='refresh' src={images['refresh-btn']} className='refresh-btn' onClick={() => updateACHPage()}/></div>
                  <AddBank plaidLinkToken={token} setLoading={setLoading} permission={permission}/>
                </div>
                <div className='account-group'>
                  {accounts.map(account => (
                    <Row key={account.id} className='bank-account'>
                      <div className='left-group'>
                        <Image alt='usd' src={`data:image/png;base64, ${account.institution.logo}`} />
                        <span className='currency'>{account.institution.name}{' '}{account.label}-{account.accountNumberMasked}</span>
                      </div>
                      <div>{!account.isReady && <span className='balance'>Pending</span>}</div>
                    </Row>))}
                  <p>*Current daily transfer limit is ${`${(dailyLimit - dailyLimitUsed).toLocaleString('en-US')}`}</p>
                </div>
                <p className='note'>{haveAccountReady(accounts) ? '' : 'Please note that the pending status means Uphold is confirming the information. This should take a few minutes at most.'}</p>
                <div className='btn-group centered'>
                  <Button mode='primary' size='md' disabled={!haveAccountReady(accounts)} onClick={() => toggleTransferModal(true)}>Add Funds</Button>
                </div>
              </div>
            </ConditionalRender>
            {isAccredited && showAccountModal && <LinkBankModal plaidAccounts={plaidAccounts} hideModal={() => hideLinkBankModal()}/>}
            {isAccredited && showTransferModal && <TransferModal plaidAccounts={plaidAccounts} hideModal={() => toggleTransferModal(false)}/>}
          </div>
        </div>
      </div>
    </>
  )
}

export default connected(Transfer)
