import privateAPI from '../apis/private.js'
import publicAPI from '../apis/public.js'
import { SEARCH_ORIGINATION, GET_ORIGINATION_DETAILS, GET_ORIGINATION_OFFER, COMMIT_ORIGINATION_OFFER, GET_ORIGINATION_CONFIRMED } from 'types/actionTypes'
import { handleServerError } from './CommonActions'
import parseOriginationSearch from './parsing/parseOriginationSearch'
import axios from 'axios'
import FormData from 'form-data'

export const searchOrigination = (query = '') => (dispatch) => {
  const url = `/page/sellYourShares/search?searchTerm=${query}`
  return publicAPI
    .get(url)
    .then((res) => {
      dispatch({
        type: SEARCH_ORIGINATION,
        payload: { ...parseOriginationSearch(res.data.companies) }
      })
      return res.data.companies && parseOriginationSearch(res.data.companies)
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}
export const getOriginationDetail = companyUrlName => async (dispatch, getState) => {
  const { isSignedIn } = getState().user
  const api = isSignedIn ? privateAPI : publicAPI
  return api
    .get(`/page/sellYourShares/detail/${companyUrlName}`)
    .then((res) => {
      dispatch({
        type: GET_ORIGINATION_DETAILS,
        payload: res.data
      })
      return res.data
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const getOriginationOffer = (companyUrlName, shareClass, shares) => async (dispatch, getState) => {
  const { isSignedIn } = getState().user
  const api = isSignedIn ? privateAPI : publicAPI
  return api
    .get(`/page/sellYourShares/confirm/${companyUrlName}?companyUrlName=${companyUrlName}&shareClass=${shareClass}&shares=${shares}`)
    .then((res) => {
      dispatch({
        type: GET_ORIGINATION_OFFER,
        payload: res.data
      })
      return res.data
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const submitOriginationOffer = (companyUrlName, shareClass, shares, file) => async (dispatch) => {
  const data = new FormData()
  data.append('file', file[0])

  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/page/sellYourShares/confirm/${companyUrlName}?shares=${shares}&shareClass=${shareClass}`,
    data: (data)
  }
  if (window.location.hostname === 'localhost') {
    config.headers = { access_token: localStorage.getItem('linqto_token') }
  }

  return axios(config)
    .then((res) => {
      dispatch({
        type: COMMIT_ORIGINATION_OFFER,
        payload: res.data.originationId
      })
      return res.data.originationId
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const getOriginationConfirmedOffer = (originationId) => async (dispatch, getState) => {
  const { isSignedIn } = getState().user
  const api = isSignedIn ? privateAPI : publicAPI
  return api
    .get(`/page/sellYourShares/confirmed/${originationId}`)
    .then((res) => {
      dispatch({
        type: GET_ORIGINATION_CONFIRMED,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}
