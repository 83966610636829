import { useEffect, useState } from 'react'
import connected from '../../containers/Identity'
import Button from '../Global/Button'
import Modal from '../Global/Modal'
import history from '../../history'
import Loading from './pending'
import { isMobile } from 'react-device-detect'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading } from 'components'
import { images } from 'assets'
import IDVerifyModal from './partials/IDVerifyModal'

const Identity = ({ actions, isKycVerified, qrCodeImage }) => {
  const [loading, setLoading] = useState(false)
  const [showQRCodeModal, setShowQRCodeModal] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    setPageLoading(true)
    actions.getIdentity().then(() => {
      setPageLoading(false)
    })
  }, [actions])

  const handleVerifyClick = () => {
    setLoading(true)
    setShowQRCodeModal(true)
    actions.getSignInQRCode().then(() => {
      setLoading(false)
    })
  }

  if (pageLoading) {
    return <>
      <SeoMeta title={seoTitleTemplate('Complete Your Investor Profile')} />
      <PageLoading />
    </>
  }

  return (
    <>
      {modal && <IDVerifyModal hideModal={() => setModal(false)} />}
      <SeoMeta title={seoTitleTemplate('Complete Your Investor Profile')} />
      <div className='page-container'>
        <div className='inner-container identity-verification'>
          <h1 className='page-title'>Complete Your Investor Profile</h1>
          <div className='identity-verification-container'>
            {!isKycVerified ? (
              <>
                <div className='body'>
                  <div className='body-container'>
                    <div className='copy-container'>
                      <span className='header'>Identity Verification</span>
                      <span className='copy'>
                      We’ll use your phone’s camera to verify your identity with photo ID (driver’s license or passport) and a self-photo. </span>
                      {!isMobile && <span className='copy'>
                      When you click “Begin ID Verification” you’ll be provided a QR code to scan with your phone.
                      </span>}
                    </div>
                    <div className='identity-link-container'>
                      <span onClick={() => setModal(!modal)} className='identity-link'>Why am I being asked to do this?</span>
                      <a href='/privacy' target='_blank'>
                        <span className='identity-link'>Review Privacy Policy</span>
                      </a>
                    </div>
                  </div>
                  <div className='verification-btn-container'>
                    <Button
                      mode='primary'
                      onClick={() =>
                        isMobile
                          ? history.push('/identity/auto-verification/1')
                          : handleVerifyClick()
                      }
                    >
                      <div className='verification-btn'>
                        <img alt='404-not-found' src={images.camera} height={18} width={22} />
                        <span>Begin ID Verification</span>
                      </div>
                    </Button>
                  </div>
                  {showQRCodeModal && (
                    <Modal
                      modalHeader='Scan to proceed to mobile experience'
                      crossToClose
                      clickToClose
                      pressToClose
                      mode='primary'
                      size='md'
                      hideModal={() =>
                        setShowQRCodeModal(false)
                      }
                    >
                      {loading ? (
                        <div className='flex-center'>
                          <Loading />
                        </div>
                      ) : (
                        <div className='flex-center'>
                          <img
                            alt='qrcode for identity'
                            src={`data:image/png;base64,${qrCodeImage}`}
                          />
                        </div>
                      )}
                    </Modal>
                  )}
                </div>
              </>
            ) : (
              <div className='box'>
                <div className='box-body'>
                  <div className='box-item-column'>
                    <div className='content'>
                      Identity confirmation complete.
                    </div>
                  </div>
                  <div className='box-item'>
                    <div className='box-item-column status-verified'>
                      <div className='btn-group start two'>
                        <Button
                          mode='primary'
                          onClick={() => history.push('/products')}
                        >Start Investing</Button>
                        <a className='restart-link' onClick={() => history.push('/profile')}>Complete Investor Profile</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default connected(Identity)
