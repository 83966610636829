import React from 'react'
import { Portfolio as connected } from 'containers'
import { PageLoading, Accounts, OrderList } from 'components'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'

class Portfolio extends React.Component {
  componentDidMount () {
    this.props.actions.showLoadingSpinner()
    this.props.actions.getPortfolio()
      .then((res) => {
        this.props.actions.hideLoadingSpinner()
      })
  }

  render () {
    if (this.props.spinnerVisible) {
      return (
        <>
          <SeoMeta title={seoTitleTemplate('My Portfolio')} />
          <PageLoading />
        </>
      )
    }
    return (
      <>
        <SeoMeta title={seoTitleTemplate('My Portfolio')} />
        <div className='page-container portfolio-container'>
          <div className='inner-container'>
            <Accounts companies={this.props.companies}/>
            {this.props.recentOrders.length > 0 &&
              <OrderList orders={this.props.recentOrders}/>}
          </div>
        </div>
      </>
    )
  }
}

export default connected(Portfolio)
