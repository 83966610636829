import { useEffect, useState } from 'react'
import Modal from '../Global/Modal'
import { ConditionalRender, PageLoading } from 'components'
import { TransferModal as connected } from 'containers'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import Button from '../Global/Button'
import renderSelectInput from 'components/Global/Select'
import renderInput from './renderInput'
import { parseStringToFloat, currencyFormatter } from 'utils'
import CompleteTransfer from './CompleteTransfer'
import ReactHtmlParser from 'react-html-parser'

// initiate minimum value for 'amount' input text
const minValue = (max) => (value) => value && value.length ? parseStringToFloat(value) > max ? `Max: $${max.toLocaleString('en-US')} daily limit.` : undefined : undefined
let minValueDaily

let TransferModal = (props) => {
  const { accounts, cards, actions, dailyLimit, dailyLimitUsed, hideModal, valid, handleSubmit, amount, to, errorMessage, isUpholdOtpRequired } = props
  const [loading, setLoading] = useState(true)
  const [endingBalance, setEndingBalance] = useState(0)
  const [transferComplete, setTransferComplete] = useState(false)
  const [fieldLoading, setFieldLoading] = useState(true)

  useEffect(() => {
    (async () => {
      await actions.getWalletPageAchDeposit()
      setLoading(false)
      setFieldLoading(false)
    })()
  }, [actions])

  // format ending balance for account based on user interaction
  useEffect(() => {
    if (amount && amount.length && to && to.usdBalance >= 0) {
      const parsedInput = parseStringToFloat(amount)
      setEndingBalance(parsedInput + to.usdBalance)
    } else if (amount && amount.length <= 0 && to && to.usdBalance >= 0) {
      setEndingBalance(to.usdBalance)
    } else if (to && to.usdBalance) {
      setEndingBalance(to.usdBalance)
    } else {
      setEndingBalance(0)
    }
  }, [to, amount])

  useEffect(() => {
    minValueDaily = minValue(dailyLimit - dailyLimitUsed)
    return minValueDaily
  }, [dailyLimit, dailyLimitUsed])

  // run reset error once component unmounts
  useEffect(() => {
    return () => actions.resetErr()
  }, [actions])

  const onSubmit = async (formValues) => {
    const { from, to, amount, otp } = formValues
    const parsedInput = parseStringToFloat(amount)

    try {
      setFieldLoading(true)
      let body = {
        accountId: from.id,
        cardId: to.id,
        amount: parsedInput
      }
      if (isUpholdOtpRequired) {
        body = {
          ...body,
          otpToken: otp
        }
      }
      const response = await actions.createUpholdDeposit(body)
      if (response === 200) {
        setTransferComplete(true)
      }
    } catch (e) {
      console.log('ERROR: ', e)
      setTransferComplete(true)
    }
    setFieldLoading(false)
  }

  if (loading) {
    return (
      <Modal
        mode='primary'
        size='sm'
        modalHeader='Transfer'
        crossToClose
        innerStyle='mfa-modal'
      >
        <div className='flex-center'>
          <PageLoading />
        </div>
      </Modal>
    )
  }

  if (transferComplete) {
    return <CompleteTransfer />
  }

  return (
    <Modal
      mode='primary'
      size='sm'
      modalHeader='Transfer'
      crossToClose
      hideModal={hideModal}
      innerStyle='mfa-modal'
    >
      <div className='transfer-modal-field'>
        <Field
          name='from'
          label='From'
          type='select'
          options={accounts}
          component={renderSelectInput}
          hideModal={hideModal}
          disabled={fieldLoading}
        />
      </div>
      <div className='transfer-modal-field'>
        <Field
          name='to'
          label='To'
          type='select'
          options={cards}
          component={renderSelectInput}
          disabled={fieldLoading}
        />
      </div>
      <div className='transfer-modal-field'>
        <Field
          name='amount'
          component={renderInput}
          label='Amount(USD)'
          type='tel'
          format={currencyFormatter}
          validate={minValueDaily}
          disabled={fieldLoading}
        />
      </div>
      <div className='end-balance'>
        <span className='end-balance text'>Ending Balance</span>
        <span className='end-balance limit'>
            ${endingBalance.toLocaleString('en-US')}
        </span>
      </div>
      <ConditionalRender
        isVisible={isUpholdOtpRequired}
        className='verification'>
        <span className='auth-title'>Uphold Wallet 2-Step Verification</span>
        <span className='subtitle'>Please enter the code generated by your authenticator app.</span>
        <div className='transfer-modal-field'>
          <Field
            type='text'
            name='otp'
            component={renderInput}
            inputMode='numeric'
            autoComplete='one-time-code'
            maxLength='7'
            disabled={fieldLoading}
          />
        </div>
      </ConditionalRender>
      {errorMessage &&
        <div className='ui error message'>
          <div className='header'>{errorMessage.title}</div>
          <p className='error-msg'>{ReactHtmlParser(errorMessage.message)}</p>
        </div>}

      <div className='btn-group'>
        <Button size='md' mode='primary' disabled={!valid || amount <= 0} onClick={handleSubmit(onSubmit)}>
          Add Funds
        </Button>
      </div>
    </Modal>
  )
}

const validate = (formValues) => {
  const errors = {}
  if (!formValues.to) {
    errors.to = 'You must enter a Uphold card.'
  }
  if (!formValues.from) {
    errors.from = 'You must enter a bank account.'
  }
  if (!formValues.amount) {
    errors.amount = 'You must enter an amount.'
  }
  if (formValues.amount <= 0) {
    errors.amount = 'You must enter an amount greater than $0.'
  }
  if (formValues.amount && isNaN(parseStringToFloat(formValues.amount))) {
    errors.amount = 'Please enter a valid amount.'
  }
  if (!formValues.otp) {
    errors.otp = 'You must enter the verification code generated by your authenticator app.'
  }
  // if (formValues.otp && formValues.otp.length < 6) {
  //   errors.otp = 'Verification code must be at least 6 characters.'
  // }
  return errors
}

TransferModal = reduxForm({
  form: 'transferModalForm',
  validate
})(TransferModal)

// Decorate with connect to read form values
const selector = formValueSelector('transferModalForm') // <-- same as form name
TransferModal = connect(state => {
  // select individual redux form state
  const amount = selector(state, 'amount')
  const to = selector(state, 'to')
  return {
    amount,
    to
  }
})(TransferModal)

export default connected(TransferModal)
