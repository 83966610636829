const parsingCoreUser = (coreUserPrms) => {
  const result = {}
  result.isSignedIn = coreUserPrms.isLoggedIn ? coreUserPrms.isLoggedIn : false
  result.rewardsEnabled = coreUserPrms.rewardsEnabled ? coreUserPrms.rewardsEnabled : false
  result.unicornsEnabled = coreUserPrms.unicornsEnabled ? coreUserPrms.unicornsEnabled : false
  result.branchKey = coreUserPrms.branchKey ? coreUserPrms.branchKey : ''
  return result
}

export default parsingCoreUser
