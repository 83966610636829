import { useState } from 'react'
import { status } from '../../data'
import { Field } from 'redux-form'
import Button from '../../Global/Button'
import Input from '../../Global/InputField'
import PhoneInput from 'react-phone-number-input'
import Tooltip from '../../Global/Tooltip'
const renderInput = ({ input, label, placeholder, meta, type, className = '', autoComplete = null, disabled, children, checked, ...props }) => {
  return (
    <Input input={input} meta={meta} label={label} placeholder={placeholder} type={type} autoComplete={autoComplete} className={className} disabled={disabled} checked={checked} {...props}>{children}</Input>
  )
}

const renderOptions = () => {
  return Object.values(status).map((ele, id) => {
    return (
      <label key={id} className='content radio-option'>
        <Field
          name='selfAccreditation'
          component='input'
          type='radio'
          value={Object.keys(status)[id]}
          tag={`option-${id}`}
        />
        {ele}
      </label>
    )
  })
}

const renderBigErr = (err) => {
  if (err.length !== 0) {
    return (
      <div className='ui error message'>
        <div className='header'>Required Fields Missing</div>
        <p>{err}</p>
      </div>
    )
  }
}

const renderSubmitBtn = (loading, edited) => {
  if (loading) {
    return (
      <div className='btn-group start left'>
        <Button mode='primary' loading disabled size='md'>Save</Button>
      </div>
    )
  } else if (edited) {
    return (
      <div className='btn-group start left'>
        <Button mode='primary' type='submit' size='md' disabled={!edited}>Save</Button>
      </div>
    )
  } else if (edited) {
    return (
      <div className='btn-group start left'>
        <Button mode='primary' type='submit' size='md' disabled={!edited}>Save</Button>
      </div>
    )
  } else {
    return (
      <div className='btn-group start left'>
        <Button mode='primary' disabled size='md'>Save</Button>
      </div>
    )
  }
}

const renderPhoneInput = ({ input: { value, onChange, onFocus, onBlur }, meta, label, className, disabled, validatePhone }) => {
  return (
    <div className={`input-group ${className} ${meta.error ? 'error' : null}`}>
      <label>{label}</label>
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry='US'
        value={value}
        onChange = {onChange}
        onFocus={onFocus}
        placeholder=''
        disabled ={disabled}
        onBlur = { (e) => { validatePhone(e.target.value) } }
      />
      {(meta.error) && <div className='err-msg'>{meta.error}</div>}
    </div>
  )
}

const renderIRAType = ({ input: { name, value, onChange, onBlur, onFocus }, meta, redirect, label, disabled = false, options }) => {
  return (<div className={`input-group  ${redirect && !value ? 'error' : null}`}>
    <label>{label}</label>
    <Field name={name} component='select'
      className={`render-location-dropdown ${redirect && !value ? 'error' : null}`}
      onBlur={onBlur}
      onFocus={onFocus}
      value={value}
      disabled={disabled}
      onChange={onChange}
    >
      <option value=''>Select</option>
      {options && options.map((o, i) => {
        return <option values={o} key={ 'iraType' + i }>{o}</option>
      })
      }
    </Field>
    {meta.error && <div className='err-msg'>{meta.error}</div>}
  </div>
  )
}

const renderDropdown = ({ input: { value, onChange, onBlur, onFocus }, meta, redirect, label, country, placeholder, disabled = false, countries, regions, getRegions, setRegions, firstOption = '', options = [], tooltip = false, content = '', className = '', style = {} }) => {
  const [showHover, setShowHover] = useState(false)
  const handleChange = async e => {
    onChange(e)
    try {
      if (e !== '') {
        const res = await getRegions(e)
        if (res) {
          setRegions(res)
        }
      } else {
        setRegions([])
      }
    } catch (err) {
      console.log(err)
    }
  }
  if (label.includes('Country') && value && regions?.length === 0) {
    handleChange(value)
  }
  if (tooltip) {
    style.cursor = 'pointer'
  }

  if (label.includes('Country')) {
    return (
      <div className={`input-group ${meta.error ? 'error' : null} ${className}`}>
        <label style={style}><span onMouseEnter={() => setShowHover(true)} onMouseLeave={() => setShowHover(false)}>{label}</span>{tooltip && <Tooltip content={content} setShowHover={setShowHover} showHover={showHover} />}</label>
        <select classes={`render-location-dropdown ${meta.error ? 'error' : null}`}
          placeholder={placeholder}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          disabled={disabled}
          onChange={(e) => handleChange(e.target.value)}>
          <option value=''>Select Country</option>
          {countries && countries.length > 0 && countries.map((e, i) => <option value={e} key={`${i}-${e}`}>{e}</option>)}
        </select>
        {meta.error && <div className='err-msg'>{meta.error}</div>}
      </div>
    )
  } else if (label === 'State' || label === 'State or Province') {
    return (
      <div className={`input-group ${meta.error ? 'error' : null} ${className}`}>
        <label>{label}{tooltip && <Tooltip content={content} setShowHover={setShowHover} showHover={showHover}/>}</label>
        <select classes={`render-location-dropdown ${meta.error ? 'error' : null}`}
          placeholder={placeholder}
          country={country}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          disabled={disabled}
          onChange={onChange}>
          <option value=''>{placeholder || 'Select Region'}</option>
          {regions && regions.length > 0 && regions.map((e) => <option value={e} key={e}>{e}</option>)}
        </select>
        {(meta.error) && <div className='err-msg'>{meta.error}</div>}
      </div>
    )
  } else {
    return (
      <div className={`input-group ${meta.error ? 'error' : null}`}>
        <label>{label}</label>
        <select classes={`render-location-dropdown ${meta.error ? 'error' : null}`}
          placeholder={placeholder}
          country={country}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          disabled={disabled}
          onChange={onChange}>
          <option value=''>{firstOption}</option>
          {options && options.length > 0 && options.map((e) => <option value={e} key={e}>{e}</option>)}
        </select>
        {(meta.error) && <div className='err-msg'>{meta.error}</div>}
      </div>
    )
  }
}

export default {
  renderInput,
  renderOptions,
  renderSubmitBtn,
  renderPhoneInput,
  renderDropdown,
  renderIRAType,
  renderBigErr
}
