import privateAPI from '../apis/private'
import { ADD_SECURITY_MFA, GET_SECURITY_PAGE } from '../types/actionTypes'
import { handleServerError } from './CommonActions'

export const getSecurityPage = () => dispatch => {
  return privateAPI
    .get('/page/security')
    .then(res => {
      dispatch({
        type: GET_SECURITY_PAGE,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const removeSecurityMfa = (userMfaId) => dispatch => {
  return privateAPI
    .delete(`/page/security/mfas/${userMfaId}`)
    .then(res => {})
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const addSecurityMfa = (type, value) => dispatch => {
  return privateAPI
    .post('/page/security/mfas', { type, value })
    .then(res => {
      dispatch({
        type: ADD_SECURITY_MFA,
        payload: { currentMfa: res.data }
      })
      return res.data.userMfaId
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}
