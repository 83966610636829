import Button from '../../Global/Button'
import Input from '../../Global/InputField'

const OriginationOfferCompanyCTA = ({ onClick, onCheckBoxChange, touched, error, confirmed, loading }) => {
  return (
    <div className={'section-full origination-detail-cta'}>
      <div className='origination-detail-confirm'>
        <Input
          id='confirmOrderCheckbox'
          type='checkbox'
          value={confirmed}
          onChange={(e) =>
            onCheckBoxChange(e.target.checked)
          }
          meta={{ error, touched }}
          className='confirm-checkbox'>
          <span>
            I confirm I have read these documents and agree to be bound by their provisions, and that I have the authority to sell the shares I am offering. I understand that the transaction is not complete until the shares have been successfully transferred to Linqto.
          </span>
        </Input>
      </div>
      <div className='section-nar'>
        <Button
          mode='primary'
          size='md'
          customClass='place-order-button'
          onClick={onClick}
          loading={loading}
          disabled={!confirmed}
        >Submit</Button>
      </div>
    </div>
  )
}

export default OriginationOfferCompanyCTA
