import renderBirthDay from './renderBirthDay'
import { Field } from 'redux-form'
import renderer from './renderer'

const BirthInput = ({ formValues, disabled }) => {
  if (!formValues || !formValues.values) {
    formValues = {}
    formValues.values = { birthDay: 1, birthMonth: 1, birthYear: 1990 }
  }
  return (
    <>
      <div className='three wide column'>
        <Field name='birthMonth' component={renderer.renderMonth} label='Birth Month*' disabled={disabled} options={renderBirthDay.renderMonth()}/>
      </div>
      <div className='three wide column'>
        <Field name='birthDay' component={renderer.renderSelect} label='Birth Day*' disabled={disabled} options={formValues && renderBirthDay.renderDay(formValues.values)} />
      </div>
      <div className='three wide column'>
        <Field name='birthYear' component={renderer.renderSelect} label='Birth Year*' disabled={disabled} options={renderBirthDay.renderYear()} />
      </div>
    </>
  )
}

export default BirthInput
