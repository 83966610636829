const parsingUnicorns = (company, isLoggedIn) => {
  const defaultOfficers = [
    { name: 'officer name 1', workHistory: 'Work History', title: 'Manager', status: 'Current' },
    { name: 'officer name 1', workHistory: 'Work History', title: 'Manager', status: 'Current' },
    { name: 'officer name 1', workHistory: 'Work History', title: 'Manager', status: 'Current' }
  ]

  const defaultFundingRounds = [
    { date: '2021-01-01', round: 'Seed', amount: '11M', postMoneyValuation: '211M', investors: 'Investor 1, Invsetor 2' },
    { date: '2021-01-01', round: 'Seed', amount: '11M', postMoneyValuation: '211M', investors: 'Investor 1, Invsetor 2' },
    { date: '2021-01-01', round: 'Seed', amount: '11M', postMoneyValuation: '211M', investors: 'Investor 1, Invsetor 2' }
  ]

  const defaultCompetitorFundings = [
    { latestFundingDate: '2021-01-01', companyName: 'Company Name', totalFunding: '11M', mosaicScore: '211', location: 'United States' },
    { latestFundingDate: '2021-01-01', companyName: 'Company Name', totalFunding: '11M', mosaicScore: '211', location: 'United States' },
    { latestFundingDate: '2021-01-01', companyName: 'Company Name', totalFunding: '11M', mosaicScore: '211', location: 'United States' }
  ]
  const defaultPartners = [
    { date: '2021-01-01', type: 'Company Type', name: 'Business Partners' },
    { date: '2021-01-01', type: 'Company Type', name: 'Business Partners' },
    { date: '2021-01-01', type: 'Company Type', name: 'Business Partners' }
  ]

  const defaultCompetitorFundingsOther = [
    { companyName: 'Company Name' },
    { companyName: 'Company Name' },
    { companyName: 'Company Name' }
  ]
  return {
    companyId: company.companyId,
    logoUrl: company.logoUrl,
    urlName: company.urlName,
    name: company.name,
    description: company.description,
    foundedYear: isLoggedIn ? company.foundedYear ? company.foundedYear : null : '1000',
    estimatedRevenue: isLoggedIn ? company.estimatedRevenue ? company.estimatedRevenue : null : '1000',
    lastRaisedDate: isLoggedIn ? company.lastRaisedDate ? company.lastRaisedDate : null : '2000-01-01',
    lastRaised: isLoggedIn ? company.lastRaisedDate ? company.lastRaised : null : '100000',
    sector: isLoggedIn ? company.sector ? company.sector : null : 'Fin Tech',
    stage: isLoggedIn ? company.stage ? company.stage : null : 'Alive / Active',
    totalRaised: isLoggedIn ? company.totalRaised ? company.totalRaised : null : '100000',
    valuation: isLoggedIn ? company.valuation ? company.valuation : '' : 100000,
    websiteUrl: company.websiteUrl ? company.websiteUrl : null,
    sharePrice: company.sharePrice ? company.sharePrice : null,
    mosaic: {
      market: isLoggedIn ? (company.mosaic && company.mosaic.market) ? company.mosaic.market : null : '100',
      momentum: isLoggedIn ? (company.mosaic && company.mosaic.momentum) ? company.mosaic.momentum : null : '100',
      money: isLoggedIn ? (company.mosaic && company.mosaic.money) ? company.mosaic.money : null : '100',
      overall: isLoggedIn ? (company.mosaic && company.mosaic.overall) ? company.mosaic.overall : null : '100',
      management: isLoggedIn ? (company.mosaic && company.mosaic.management) ? company.mosaic.management : null : '100'
    },
    address: {
      city: isLoggedIn ? company.address && company.address.city ? company.address.city : '' : 'City Name',
      country: isLoggedIn ? company.address && company.address.country ? company.address.country : '' : 'United States',
      postalCode: isLoggedIn ? company.address && company.address.postalCode ? company.address.postalCode : '' : '00000',
      state: isLoggedIn ? company.address && company.address.state ? company.address.state : '' : 'California',
      street: isLoggedIn ? company.address && company.address.street ? company.address.street : null : '111 Street Name'
    },
    investors: isLoggedIn ? company.investors ? company.investors : null : 'Inversor Name',
    officers: isLoggedIn ? company.officers ? company.officers : [] : defaultOfficers,
    fundingRounds: isLoggedIn ? company.fundingRounds ? company.fundingRounds : [] : defaultFundingRounds,
    competitorFundingsUnicrons: isLoggedIn ? (company.competitorsFundings && company.competitorsFundings.unicorn) ? company.competitorsFundings.unicorn : [] : defaultCompetitorFundings,
    competitorFundingsOther: isLoggedIn ? (company.competitorsFundings && company.competitorsFundings.other) ? company.competitorsFundings.other : [] : defaultCompetitorFundingsOther,
    partners: isLoggedIn ? company.partners ? company.partners : [] : defaultPartners,
    boardMemebers: isLoggedIn ? company.boardMemebers ? company.boardMemebers : [] : defaultOfficers,
    isInterested: company.isInterested ? company.isInterested : null
  }
}

export default parsingUnicorns
