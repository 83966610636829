import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ unicorn }) => ({
  companies: unicorn.companies,
  isLoggedIn: unicorn.isLoggedIn,
  sectors: unicorn.sectors,
  countries: unicorn.countries,
  totalUnicorns: unicorn.totalUnicorns,
  totalValuations: unicorn.totalValuations,
  hasMore: unicorn.hasMore
})

const requiredActions = ['getUnicorns']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
