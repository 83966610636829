import privateAPI from '../apis/private.js'
import { GET_ORDER_DETAIL, MESSAGE_TRADER } from 'types/actionTypes'
import parseOrderDetail from './parsing/parseOrderDetail'
import { handleServerError } from './CommonActions'

export const getOrderDetail = id => dispatch => {
  return privateAPI
    .get(`/page/orderDetail/${id}`)
    .then(res => {
      dispatch({
        type: GET_ORDER_DETAIL,
        payload: { ...res.data, order: parseOrderDetail(res.data.order) }
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const messageTrader = (msg, orderId) => dispatch => {
  return privateAPI
    .post(`/page/orderDetail/${orderId}/messageTrader`, { message: msg })
    .then(res => {
      dispatch({
        type: MESSAGE_TRADER,
        payload: { message: 'success' }
      })
    })
    .catch(err => {
      dispatch({
        type: MESSAGE_TRADER,
        payload: { message: 'failed' }
      })
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}
