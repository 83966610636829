import { useState, useEffect, useRef } from 'react'

const NotificationBanner = ({ text, delay, stay }) => {
  const mountedRef = useRef(true)
  const [bannerStlye, setBannerStlye] = useState('notification-banner-down')

  useEffect(() => {
    setTimeout(() => {
      if (mountedRef.current) {
        setBannerStlye('notification-banner-down')
      }
      setTimeout(() => {
        if (mountedRef.current) {
          setBannerStlye('notification-banner-up')
        }
      }, (stay + delay))
    }, (delay))
    return () => { mountedRef.current = false }
  }, [])

  return (
    <div className={`notification-banner ${bannerStlye}`} ref={mountedRef}>
      {text}
    </div>
  )
}

export default NotificationBanner
