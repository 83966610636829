import { GET_CONFIRM_DETAILS, COMMIT_ORDER, GET_ORDER_CONFIRMATION, PLACE_ORDER_ERROR, CLEAR_ORDER_ERROR } from 'types/actionTypes'

const INITIAL_STATE = {
  confirmDetails: {
    company: {},
    documents: [],
    order: {},
    isUpholdOtpRequired: false,
    entityName: ''
  },
  orderId: null,
  confirmation: {},
  sliderAmount: 1,
  placeOrderError: {
    type: '',
    message: ''
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case GET_CONFIRM_DETAILS:
    return {
      ...state,
      confirmDetails: {
        company: action.payload.company,
        documents: action.payload.documents,
        order: action.payload.order,
        isUpholdOtpRequired: action.payload.isUpholdOtpRequired
      }
    }
  case COMMIT_ORDER:
    return { ...state, orderId: action.payload }
  case GET_ORDER_CONFIRMATION:
    return { ...state, confirmation: action.payload }
  case PLACE_ORDER_ERROR:
    return { ...state, placeOrderError: action.payload }
  case CLEAR_ORDER_ERROR:
    return { ...state, placeOrderError: INITIAL_STATE.placeOrderError }
  default:
    return state
  }
}
