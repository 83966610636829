import { Content } from 'components'
import { images } from 'assets'

const ProductDocuments = ({ documents }) => (
  <Content className='agreements'>
    {documents.map((d) => {
      return (
        <a
          className='agreement box'
          key={d.name}
          href={d.url}
          target='_blank'
          rel='noopener noreferrer'
          aria-label={d.name}
        >
          <span role='agreement' className='name' >{d.name}</span>
          <img alt='next-click' src={images['next-click']} />
        </a>
      )
    })}
  </Content>
)

export default ProductDocuments
