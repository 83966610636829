import * as UserActions from './UserActions'
import * as PlaceOrderActions from './PlaceOrderActions'
import * as ProfileActions from './ProfileActions'
import * as PortfolioAction from './PortfolioActions'
import * as OrderDetailActions from './OrderDetailActions'
import * as WalletActions from './WalletActions'
import * as CommonActions from './CommonActions'
import * as InvestorStatusAction from './InvestorStatusAction'
import * as KycActions from './KycActions'
import * as SecurityActions from './SecurityActions'
import * as RewardActions from './RewardActions'
import * as LinqtoPortfolioActions from './LinqtoPortfolioActions'
import * as UnicornsActions from './UnicornsActions'
import * as FinancialAdvisorActions from './FinancialAdvisorActions'
import * as InvestActions from './InvestActions'
import * as OriginationActions from './OriginationActions'
import * as BrokerInfoActions from './BrokerInfoActions'

export default {
  ...UserActions,
  ...PlaceOrderActions,
  ...ProfileActions,
  ...PortfolioAction,
  ...OrderDetailActions,
  ...WalletActions,
  ...CommonActions,
  ...InvestorStatusAction,
  ...KycActions,
  ...SecurityActions,
  ...RewardActions,
  ...LinqtoPortfolioActions,
  ...UnicornsActions,
  ...FinancialAdvisorActions,
  ...InvestActions,
  ...OriginationActions,
  ...BrokerInfoActions
}
