import { Wrapper, Image, Row } from 'components'
import { formatDecimal } from 'utils'

const UpholdCardRow = ({ account, onSelect }) => {
  return (<Row className='uphold-account' onClick={() => onSelect && onSelect(account)}>
    <div className='left-group'>
      <Image alt='usd' src={account.iconUrl} />
      <span className='currency'>{account.label}</span>
    </div>
    <div><span className='balance'>{formatDecimal(account.balance)}</span>{' '}</div>
  </Row>)
}

const UpholdCardRowDisabled = ({ account }) => {
  return (<Row className='uphold-account uphold-disabled'>
    <div className='left-group'>
      <Image alt='usd' src={account.iconUrl} />
      <span className='currency'>{account.label}</span>
    </div>
    <div><span className='balance'>{formatDecimal(account.balance)}</span>{' '}</div>
  </Row>)
}

const UpholdCard = ({ account, totalInvestedAmount, onSelect }) => {
  if (totalInvestedAmount && totalInvestedAmount > account.balance) {
    return (<UpholdCardRowDisabled account={account}/>)
  }
  return (
    <UpholdCardRow account={account} onSelect={onSelect} />
  )
}

const UpholdBalance = ({ cards, onSelect, totalInvestedAmount }) => {
  return (
    <Wrapper className='uphold-balance'>
      {!onSelect && <div className='title-group'>
        <div className='title'>Available Balances</div>
      </div>}
      {cards.map((account) => (
        <UpholdCard key={account.id} account={account} totalInvestedAmount={totalInvestedAmount} onSelect={onSelect} />
      ))}
    </Wrapper>
  )
}

export default UpholdBalance
