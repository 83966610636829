import React from 'react'
import { images } from '../../assets'
import { Ebook as connected } from 'containers'
import { Button } from '..'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
class Ebook extends React.Component {
  componentDidMount () {
    this.props.actions.getEbook()
  }

  handleDownload = () => {
    if (!this.props.isSignedIn) {
      localStorage.setItem('prev-route', this.props.history.location.pathname)
      this.props.history.push('/signin')
    } else {
      window.open(`${this.props.downloadUrl}`)
    }
  }

  render () {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('The Intelligent Investor: Silicon Valley')} />
        <div className='page-container container'>
          <h1 className='page-title'>The Intelligent Investor: Silicon Valley</h1>
          <h2>Download a free copy of the eBook</h2>
          <div className='page-content'>
                With our compliments, here is your free copy of the new book by Alison Davis and Matthew Le Merle,
            <em>The Intelligent Investor - Silicon Valley</em>. Practical wisdom for investors and entrepreneurs from
                50 leading Silicon Valley angels and venture capitalists.
          </div>
          <div className='center ui grid'>
            <div className='stackable three column row'>
              <div className='five wide column'><img alt='The Intelligent Investor' className='app-img' src={images.IntelligentInvestor}></img></div>
              <div className='nine wide column'>
                <div className='quote'>&quot;I look to a world that is decentralized, to currency like Bitcoin that is global, open and transparent, to health care that uses data to drive diagnoses and treatment.
                      I expect governments will have to compete for us, as we become more mobile and nomadic. I envision some of the biggest
                      industries in the world will be transformed over the next ten years
                      because of AI, Bitcoin, the blockchain, smart contracts and surveillance.&quot;
                </div>
                <div className='page-content'> - - Tim Draper</div>
              </div>
              <div className='two wide column'></div>
            </div>
          </div>
          <div className='margined'>
            <Button
              className={'btn-green'}
              onClick={() => this.handleDownload()}
            >Download the free eBook
            </Button>
          </div>
        </div>
      </>
    )
  }
}
export default connected(Ebook)
