import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Nav as connected } from 'containers'
import { images } from 'assets'

const Nav = ({ isSignedIn, rewardsEnabled, ...props }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [toggleStatus, setToggleStatus] = useState(false)

  const logOut = () => {
    props.actions.signOut().then(() => {
      window.location = '/'
    })
  }

  const accountNavItems = [
    { route: '/portfolio', name: 'My Portfolio', tag: 'portfolio', show: false, domain: 'user', showOnMobile: true },
    { route: '/wallet', name: 'Wallet', tag: 'wallet', show: false, domain: 'user', showOnMobile: true },
    { route: '/profile', name: 'Investor Profile', tag: 'profile', show: false, domain: 'user', showOnMobile: true },
    { route: '/security', name: 'Security', tag: 'security', show: false, domain: 'user', showOnMobile: true },
    { route: '/documents', name: 'Documents', tag: 'documents', show: false, domain: 'user', showOnMobile: true },
    { route: '/investor-status', name: 'Investor Status', tag: 'investor', show: false, domain: 'user', showOnMobile: true },
    { route: '/wire-instructions', name: 'Wire Instructions', tag: 'wire', show: false, domain: 'user', showOnMobile: true },
    { route: '/', name: 'Sign out', tag: 'signout', show: false, domain: 'user', showOnMobile: true, action: logOut }
  ]

  const aboutNavItems = [
    { route: '/about', name: 'About Linqto', tag: 'about', show: false, domain: 'global', showOnMobile: true },
    { route: '/team', name: 'Team', tag: 'team', show: false, domain: 'global', showOnMobile: true }
  ]
  // NOTE: array of nav items
  const mainNavItems = [
    { route: '/products', name: 'Invest', tag: 'invest', show: false, domain: 'global', showOnMobile: true },
    { route: '/sell', name: 'Sell', tag: 'sell', show: false, domain: 'global', showOnMobile: true },
    { route: '/rewards', name: 'Linqto Bucks', tag: 'rewards', show: false, domain: 'global', showOnMobile: true },
    { route: '/linqto-portfolio', name: 'Portfolio', tag: 'linqtoPortfolio', show: false, domain: 'global', showOnMobile: true },
    { route: '/', name: 'About', tag: 'aboutLinqto', show: false, domain: 'global', showOnMobile: false, submenu: aboutNavItems },
    { route: '/signin', name: 'Sign in', tag: 'sign-in', show: false, domain: 'public', showOnMobile: true },
    { route: '/signup', name: 'Sign Up', tag: 'signup', show: false, domain: 'public', showOnMobile: true, style: 'button' },
    { route: '/', name: 'Account', tag: 'account', show: false, domain: 'user', showOnMobile: false, submenu: accountNavItems }
  ]

  const filterNavByStatus = (nav) => {
    // NOTE: we will have to make sure the flag format is always tag+Enabled
    nav.map((i) => {
      const flag = props[i.tag + 'Enabled']
      if (flag === undefined || flag === null) {
        i.show = true
      } else {
        i.show = flag
      }
      return i
    })
    const newNav = (isSignedIn) ? (nav.filter(i => i.domain === 'user' || i.domain === 'global')) : (nav.filter(i => i.domain === 'public' || i.domain === 'global'))
    return newNav
  }

  const renderNavItem = (item) => {
    if (item.action) {
      return (
        <div key={`nav-${item.tag}`}
          className='item dropdown'
          onClick={item.action}>
          {item.name}
        </div>
      )
    }
    return (
      <NavLink key={`nav-${item.tag}`}
        to={item.route}
        onClick={() => setToggleStatus(false)}
        className={`item  ${item.tag} ${toggleStatus ? 'mobile' : ''}`}>
        {item.style && item.style === 'button' ? <div className='nav-button'>{item.name}</div> : item.name}
      </NavLink>
    )
  }

  const renderDropDownItem = (item) => {
    return (
      <div key={item.tag} className='item dropdown dropdown-6' onMouseOver={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
        {item.name}
        {showDropdown &&
          <div className='dropdown_menu dropdown_menu--animated dropdown_menu-6' >
            {item.submenu.map((i) => {
              if (i.action) {
                return (
                  <div key={`nav-${i.tag}`} className='item dropdown' onClick={i.action}>
                    <img alt={i.name} src={images[`${i.tag}`]}></img>
                    {i.name}
                  </div>
                )
              }
              return (
                <NavLink key={`nav-${i.tag}`} to={i.route} onClick={() => setShowDropdown(false)} className='item dropdown'>
                  <img alt={i.name} src={images[`${i.tag}`]}></img>
                  <span>{i.name}</span>
                </NavLink>
              )
            })}
          </div>
        }
      </div>
    )
  }

  const renderMobileNav = (items) => {
    // NOTE: array for items to show on mobile web only
    const mobileItems = []
    items.map((i) => {
      if (i.showOnMobile) {
        mobileItems.push(i)
      }
      if (i.submenu && i.submenu.length > 0) {
        i.submenu.map(t => {
          if (t.showOnMobile) {
            mobileItems.push(t)
          }
        })
      }
    })

    return (
      <ul className='main-nav mobile-active'>
        {mobileItems.map((i) => {
          return renderNavItem(i)
        })}
      </ul>
    )
  }

  const renderWebNav = (items) => {
    return (
      <ul className='main-nav'>
        {items.map((i) => {
          if (i.submenu && i.submenu.length > 0) {
            return renderDropDownItem(i)
          }
          return renderNavItem(i)
        })
        }
      </ul>
    )
  }

  const renderNavHeader = () => {
    return (
      <div className='mobile-nav-container'>
        <NavLink to='/' className='logo home' onClick={() => setToggleStatus(false)}>
          <img alt='Linqto - Private Investing' src='/api/1/logo' />
        </NavLink>
        <div id='nav-icon' className={`toggle-icon ${toggleStatus ? 'open' : ''}`} onClick={() => setToggleStatus(!toggleStatus)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    )
  }

  return (
    <div id='header-div' className='nav-container'>
      {renderNavHeader()}
      {renderWebNav(filterNavByStatus(mainNavItems))}
      {toggleStatus && renderMobileNav(filterNavByStatus(mainNavItems))}
    </div>
  )
}

export default connected(Nav)
