import { useEffect } from 'react'
import { App as connected } from 'containers'
import {
  Nav,
  Footer,
  NotFound,
  TermOfUse,
  Privacy,
  Contact,
  About,
  Landing,
  SignUp,
  SignIn,
  ActivateEmail,
  Verify,
  ConfirmResetPassword,
  OrderDetail,
  OrderConfirmation,
  OrderConfirmed,
  Portfolio,
  Invest,
  InvestDetail,
  ProductOrderList,
  Profile,
  BrokerInfo,
  Wallet,
  InvestorStatus,
  FinancialAdvisor,
  Ebook,
  Identity,
  Documents,
  WireInstruction,
  Security,
  AutoVerification,
  ManualVerification,
  AutoSignIn,
  FAQ,
  Transfer,
  Rewards,
  RewardsTermOfUse,
  LinqtoPortfolio,
  Team,
  Unicorns,
  UnicornDetails,
  Origination,
  OriginationDetail,
  OriginationOffer,
  OriginationConfirmed,
  DeleteAccount
} from 'components'
import history from '../history'
import { Switch, Route } from 'react-router-dom'
import Modal from './Global/Modal'
import ReactGA from 'react-ga'
import { SEOvalues } from './data'
import { Helmet } from 'react-helmet'
import qs from 'qs'
import { trackPageView } from '../functions'
import FormSuccess from './Global/FormSuccess'

const App = (props) => {
  useEffect(() => {
    // Disable the auto scroll for browser & setting intial scroll to 0
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }
    window.scrollTo(0, 0)

    // load Branch
    ;(function (b, r, a, n, c, h, _, s, d, k) {
      if (!b[n] || !b[n]._q) {
        for (; s < _.length;) c(h, _[s++])
        d = r.createElement(a)
        d.async = 1
        d.src = 'https://cdn.branch.io/branch-latest.min.js'
        k = r.getElementsByTagName(a)[0]
        k.parentNode.insertBefore(d, k)
        b[n] = h
      }
    })(
      window,
      document,
      'script',
      'branch',
      function (b, r) {
        b[r] = function () {
          b._q.push([r, arguments])
        }
      },
      { _q: [], _v: 1 },
      'addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking'.split(
        ' '
      ),
      0
    )

    props.actions.getCoreUser()
    const cjEventObj = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    })
    const cjKey = Object.keys(cjEventObj).filter(
      (key) => key.toLowerCase() === 'cjevent'
    )
    const cjEvent = cjEventObj[cjKey[0]]
    if (cjEvent) {
      props.actions.saveCjEvent(cjEvent)
    }
    // init Google Analytics
    ReactGA.initialize('UA-24571397-1') //, { debug: true }
    ReactGA.pageview(window.location.pathname + window.location.search)
    // hubspot for init page load
    trackPageView(window.location.pathname)

    history.listen((location) => {
      window.ga('set', 'page', location.pathname + location.search)
      window.ga('send', 'pageview')
      // hubspot tracking
      trackPageView(location.pathname)
      // setting scroll to the top when moving from page to page
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    })
  }, [])
  return (
    /** Note: Overflow hidden fixes width of this div on mobile devices  */
    <>
      <Helmet>
        ‍<title>{SEOvalues.PAGE_TITLE}</title>
        <meta name='description' content={SEOvalues.PAGE_META_DESCRIPTION} />
        <meta name='keywords' content={SEOvalues.PAGE_KEYWORDS} />
        <meta property='og:title' content={SEOvalues.PAGE_TITLE} />
        <meta property='og:type' content='investment' />
        <meta property='og:url' content='https://www.linqto.com' />
        <meta property='og:image' content='https://i.imgur.com/DiMZaq4.png' />
      </Helmet>
      <div style={{ overflow: 'hidden' }}>
        <Nav
          history={props.history}
          rewardsEnabled={props.rewardsEnabled}
        />
        <Switch>
          <Route exact path='/' component={Landing} />
          <Route exact path='/:name/success' component={FormSuccess} />
          <Route exact path='/products' component={Invest} />
          <Route exact path='/products/:id' component={InvestDetail} />
          <Route
            exact
            path='/orderconfirmation/:id'
            component={OrderConfirmation}
          />
          <Route exact path='/orderconfirmed/:id' component={OrderConfirmed} />
          <Route exact path='/team' component={Team} />
          <Route exact path='/about' component={About} />
          <Route exact path='/signup' component={SignUp} />
          <Route exact path='/signin' component={SignIn} />
          <Route exact path='/auto-sign-in' component={AutoSignIn} />
          <Route exact path='/signin/mfas' component={SignIn} />
          <Route
            exact
            path='/signin/mfas/:mfaType/:userMfaId'
            component={SignIn}
          />
          <Route exact path='/activate' component={ActivateEmail} />
          <Route
            exact
            path='/verify'
            render={() => <Verify {...props} history={history} />}
          />
          <Route
            exact
            path='/confirmResetPassword'
            render={() => (
              <ConfirmResetPassword {...props} history={history} />
            )}
          />
          <Route exact path='/profile' component={Profile} />
          <Route exact path='/order/:id' component={OrderDetail} />
          <Route exact path='/portfolio' component={Portfolio} />
          <Route exact path='/rewards' component={Rewards} />
          <Route path='/linqto-portfolio' component={LinqtoPortfolio} />
          <Route exact path='/market-insights/:id' component={UnicornDetails} />
          <Route path='/market-insights' component={Unicorns} />
          <Route
            exact
            path='/portfolio/:companyId'
            component={ProductOrderList}
          />
          <Route exact path='/wallet/transfer-funds' component={Transfer} />
          <Route exact path='/wallet' component={Wallet} />
          <Route exact path='/terms-of-use' component={TermOfUse} />
          <Route
            exact
            path='/reward-terms-of-use'
            component={RewardsTermOfUse}
          />
          <Route exact path='/privacy' component={Privacy} />
          <Route
            exact
            path='/contact'
            render={() => <Contact {...props} />}
          />
          <Route exact path='/investor-status' component={InvestorStatus} />
          <Route exact path='/financial-advisor' component={FinancialAdvisor} />
          <Route exact path='/ebook' component={Ebook} />
          <Route
            exact
            path='/identity/auto-verification/:steps'
            component={AutoVerification}
          />
          <Route exact path='/identity' component={Identity} />
          <Route
            exact
            path='/identity/manual-verification'
            component={ManualVerification}
          />
          <Route exact path='/documents' component={Documents} />
          <Route exact path='/wire-instructions' component={WireInstruction} />
          <Route exact path='/security' component={Security} />
          <Route exact path='/security/:mfaType/:userMfaId/:steps' component={Security}/>
          <Route exact path='/security/delete-account' component={DeleteAccount}/>
          <Route path='/faq/:name' component={FAQ} />
          <Route path='/faq' component={FAQ} />
          <Route path='/sell/offerconfirmation/' component={OriginationOffer} />
          <Route path='/sell/offerconfirmed/:originationId' component={OriginationConfirmed} />
          <Route path='/sell/:companyName' component={OriginationDetail} />
          <Route path='/sell' component={Origination} />
          <Route path='/broker-information/:entityId' component={BrokerInfo} />
          <Route path='/broker-information/' component={BrokerInfo} />
          <Route path='*' component={NotFound} />
        </Switch>
        <Footer
          rewardsEnabled={props.rewardsEnabled}
          unicornsEnabled={props.unicornsEnabled}
        />
        {props.systemErr && (
          <Modal
            clickToClose={false}
            pressToClose={false}
            crossToClose={false}
            hideModal={() => console.log('err modal')}
            mode='primary'
            size='md'
            modalHeader='An Unexpected Error Occurred'
          >
            <div className='content'>
              Please try refreshing the page or go back to the previous page.{' '}
              <br />
              You can also contact us at{' '}
              <a className='link primary' href='mailto:invest@linqto.com'>invest@linqto.com</a>.
            </div>
          </Modal>
        )}
      </div>
    </>
  )
}

export default connected(App)
