const UnicornDetailsCompetitorFundingsOther = ({ competitorFundings, style }) => {
  return (
    <>
      <div className='ui grid unicorn-table'>
        <div className='five column row '>
          <div className='column' style={{ paddingLeft: 0 }}><strong>Companies</strong></div>
          <div className='column'> { competitorFundings && competitorFundings.map((f, i) =>
            <div key={`competitorOthrtFundings_${i}`} className={`column ${style}`}>{f.companyName}</div>
          )}
          </div>
        </div>
      </div>
      <div className='unicorn-card-container'>
        <div className='unicorn-card' >
          <div className='ui grid'>
            {competitorFundings && competitorFundings.map((f, i) =>
              <div className='two column row unicorn-card-row' key={`mcompetitorOthrtFundings_${i}`}>
                <div className={`column  ${style}`}>{i === 0 && <strong>Companies: </strong>}</div>
                <div className={`column ${style}`}>{f.companyName}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default UnicornDetailsCompetitorFundingsOther
