import React from 'react'
import history from '../../history'
import MfaModal from './Mfa/MfaModal'
import connect from '../../containers/Security'
import _ from 'lodash'
import { PageLoading } from 'components'
import Button from '../Global/Button'
import PsdModal from './ChangePassword/PsdModal'
import SecurityItem from './Mfa/SecurityItem'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { Link } from 'react-router-dom'

const mfas = [
  {
    qrCodeUrl: '',
    type: 'TOTP',
    userMfaId: null,
    value: '',
    verified: false
  },
  {
    qrCodeUrl: '',
    type: 'SMS',
    userMfaId: null,
    value: '',
    verified: false
  },
  {
    qrCodeUrl: '',
    type: 'EMAIL',
    userMfaId: null,
    value: '',
    verified: false
  }
]

const typeName = {
  TOTP: 'Authenticator App',
  SMS: 'SMS Text Message',
  EMAIL: 'Email Authentication'
}

const typeDescription = {
  TOTP: 'Time-based one-time 6-digits codes',
  SMS: '',
  EMAIL: 'Email: '
}

const typeIcons = {
  TOTP: 'security_icon_auth_app',
  SMS: 'security_icon_text_msg',
  EMAIL: 'security_icon_email'
}

class Security extends React.Component {
  state = {
    showModal: false,
    type: '',
    mfas: [],
    userMfaId: '',
    pageLoading: false,
    showPsdModal: false
  }

  componentDidMount () {
    this.setState({ pageLoading: true })
    this.props.actions.getSecurityPage()
      .then(() => {
        this.mapMfaArr()
        this.setState({ pageLoading: false })
      })
    const type = this.props.match.params.mfaType
    const userMfaId = this.props.match.params.userMfaId
    if (type && userMfaId) {
      this.setState({ showModal: true, type, userMfaId })
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.mfas.length !== this.props.mfas.length) {
      this.mapMfaArr()
    } else {
      for (let i = 0; i < prevProps.mfas.length; i++) {
        const ele = prevProps.mfas[i]
        const objToMatch = _.find(this.props.mfas, (m) => m.userMfaId === ele.userMfaId)
        if (!_.isEqual(objToMatch, ele)) {
          this.mapMfaArr()
        }
      }
    }
  }

  mapMfaArr = () => {
    const mfaToRender = [...mfas]
    this.props.mfas.forEach((m) => {
      _.find(mfaToRender, (o, i) => {
        if (m.type === o.type) {
          mfaToRender[i] = m
        }
      })
    })
    this.setState({ mfas: mfaToRender })
  }

  handleClick = (type) => {
    let value = ''
    if (type === 'email') {
      value = this.props.email
    }
    this.setState({ showModal: true, type })
    if (type === 'sms') {
      this.setState({ userMfaId: 'e' })
      history.push(`/security/${type}/e/1`)
    } else {
      this.props.actions.addSecurityMfa(type.toUpperCase(), value)
        .then((userMfaId) => {
          this.setState({ userMfaId })
          history.push(`/security/${type}/${userMfaId}/1`)
        })
    }
  }

  handleAddSMS = (value) => {
    this.props.actions.addSecurityMfa('SMS', value)
      .then((userMfaId) => {
        this.setState({ userMfaId })
        history.push(`/security/sms/${userMfaId}/2`)
      })
  }

  hideModal = () => {
    history.push('/security')
    this.setState({ showModal: false, type: '', userMfaId: '' })
  }

  handleRemoveMfa = (userMfaId) => {
    this.props.actions.removeSecurityMfa(userMfaId)
      .then(() => {
        this.props.actions.getSecurityPage()
          .then(() => {
            this.mapMfaArr()
          })
      })
  }

  renderMfaOptions = () => {
    return this.state.mfas.map(m => {
      if (m.userMfaId && m.verified) {
        return (
          <SecurityItem key={m.type} option={m} name={typeName[m.type]} description={typeDescription[m.type]} icon={typeIcons[m.type]} email={this.props.email} handelClick={this.handleRemoveMfa} disabled={this.state.mfas.filter(v => v.verified).length === 1} />
        )
      } else {
        return (
          <SecurityItem key={m.type} option={m} name={typeName[m.type]} description={typeDescription[m.type]} icon={typeIcons[m.type]} email={this.props.email} handelClick={this.handleClick} />
        )
      }
    })
  }

  render () {
    if (this.state.pageLoading) {
      return (
        <>
          <SeoMeta title={seoTitleTemplate('Security')} />
          <PageLoading />
        </>
      )
    }
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Security')} />
        <div className='page-container security-container'>
          <div className='inner-container'>
            <h1 className='page-title'>Security</h1>
            <div className='box'>
              <div className='box-title'>
                Account Settings
              </div>
              <div className='box-body'>
                <div className='box-item centered'>
                  <div className='btn-group start'>
                    <Button mode='secondary' size='md' customClass='change-pwd-btn' onClick={() => this.setState({ showPsdModal: true })}>Change Password</Button>
                    {this.state.showPsdModal && <PsdModal hideModal={() => this.setState({ showPsdModal: false })} />}
                  </div>
                </div>
              </div>
            </div>
            {this.props.mfaEnabled &&
              <div className='box'>
                <div className='box-title mfa-auth-box-section'>
                Multi-Factor Authentication
                  <span>At least one authentication method must be enabled.</span>
                </div>
                <div className='box-body'>
                  {this.renderMfaOptions()}
                </div>
              </div>
            }
            <Link className='delete-account' to='/security/delete-account'>Delete Account</Link>
            {this.state.showModal && <MfaModal hideModal={this.hideModal} type={this.state.type} userMfaId={this.state.userMfaId} mfas={this.state.mfas} currentMfa={this.props.currentMfa} setPageLoading={(state) => this.setState({ pageLoading: state })} handleAddSMS={this.handleAddSMS}/>}
          </div>
        </div>
      </>
    )
  }
}

export default connect(Security)
