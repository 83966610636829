const FAQLink = ({ question, answer, selected, onClick }) => {
  return (
    <div className='faq-link' key={question} onClick={() => onClick(question)}>
      <div className='faq-link question'>
        <div
          className={`faq-link arrow ${
            selected === question ? 'open' : 'close'
          }`}
        >
          <svg
            width='12'
            height='12'
            viewBox='0 0 9 9'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M0 9V0L9 4.38462L0 9Z' fill='#43C89A' id='arrowItem' />
          </svg>
        </div>
        <span>{question}</span>
      </div>
      <p
        className={`faq-link answer active ${
          selected === question ? 'open' : 'close'
        }`}
        dangerouslySetInnerHTML={{ __html: answer }}
      ></p>
    </div>
  )
}

export default FAQLink
