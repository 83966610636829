import { SEARCH_ORIGINATION, GET_ORIGINATION_DETAILS, GET_ORIGINATION_OFFER, COMMIT_ORIGINATION_OFFER, GET_ORIGINATION_CONFIRMED, ORIGINATION_ERROR } from 'types/actionTypes'

const INITIAL_STATE = {
  companies: [],
  company: {},
  offer: {},
  originationId: null,
  offerConfirmed: {},
  interestedInBuying: true
}
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case SEARCH_ORIGINATION:
    return { ...state, companies: action.payload }
  case GET_ORIGINATION_DETAILS:
    return { ...state, company: action.payload, interestedInBuying: action.payload.isOriginationAvailable }
  case GET_ORIGINATION_OFFER:
    return { ...state, offer: action.payload, interestedInBuying: true }
  case COMMIT_ORIGINATION_OFFER:
    return { ...state, originationId: action.payload }
  case GET_ORIGINATION_CONFIRMED:
    return { ...state, offerConfirmed: action.payload }
  case ORIGINATION_ERROR:
    return { ...state, interestedInBuying: action.payload.interestedInBuying }
  default:
    return state
  }
}
