const Toggle = ({ checked, handleChange, label }) => {
  return (
    <div className='toggle-switch-container'>
      <label className='toggle-switch-container-label'>{label}</label>
      <div>
        <div className='toggle-switch'>
          <input
            type='checkbox'
            className='toggle-switch-checkbox'
            name='toggleSwitch'
            id='toggleSwitch'
            checked={checked}
            onChange={handleChange}
          />
          <label className='toggle-switch-label' htmlFor='toggleSwitch'>
            <span className='toggle-switch-inner' />
            <span className='toggle-switch-switch' />
          </label>
        </div>
      </div>
    </div>
  )
}

export default Toggle
