
export const status = {
  NET_WORTH:
    'I am an individual with a net worth (either individually or with my spouse, if any), and all property and other assets excluding my primary residence, that exceeds $1,000,000 including investments.',
  ANNUAL_INCOME:
    'I am an individual with annual income that was at least $200,000 in each of the two most recent years, and I expect such income in the current year.',
  JOINT_INCOME:
    'I am an individual with an annual income, jointly with my spouse, of at least $300,000 in each of the two most recent years, and I expect such income in the current year.',
  SECURITIES_LICENSE:
    'I am an individual that holds in good standing a Series 7, 65, or 82 license.',
  FOREIGN_INVESTOR:
    'I am a non-US investor and qualify as the equivalent of an Accredited Investor in my country of residence.'
}

export const month = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
}

export const days = {
  1: 31,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31
}

export const ACCstatus = {
  VERIFIED_MANUAL: 'Verified',
  VERIFIED_AUTO: 'Verified',
  NOT_VERIFIED: 'Not Verified',
  FAILED: 'Not Verified',
  ACCREDITED: 'Verified',
  QUALIFIED_PURCHASER: 'Qualify Purchaser',
  UNDER_REVIEW: 'Under Review'
}

export const SEOvalues = {
  PAGE_TITLE: 'Linqto | Private Investing',
  PAGE_META_DESCRIPTION: 'Private investing made simple',
  PAGE_KEYWORDS: ''
}

export const deleteAccountReasons = {
  TOO_MANY_EMAIL: 'Too many emails',
  MISSED_PROMOTION: 'I missed out on a promotion',
  INVESTMENT_MINIMUM: 'Investment minimum of $10k is too high',
  NOT_QUALIFIED: 'I don\'t qualify as an accredited investor',
  NO_COMPANIES: 'No companies I want to invest in right now',
  UNABLE_TO_FUND: 'Unable to fund orders with a preferred payment method',
  OTHER: 'Other (Please specify below)'
}
