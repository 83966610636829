import { images } from 'assets'
import { formatInvestmentValue } from 'utils'

const UnicornsHeader = ({ totalUnicorns, totalValuations }) => {
  return (
    <>
      <div className='page-header' >
        <img src={images['radial-lines-thin']} className='unicorn-backgeound' alt='unciron background'/>
        <div className='inner-container'>
          <div className='page-header-title-container'>
            <h1 className='page-header-title'>Linqto Market Insights Daily</h1>
            <div className='powered-by'>Powered by <img alt='CB Insights' src={images.cbinsightslogo} /></div>
            <div className='page-header-sub-title'>Industry leading market insights provided to our members</div>
          </div>
          <img src={images['unicorn-hard-light']} alt='Linqto Unicorn' className='unicorn-header-img' />
        </div>
      </div>
      <div className='inner-container'>
        <div className='page-header-fact-container'>
          <div className='page-header-fact-col page-header-fact-col-num'>
            <div className='page-header-fact-number'>{formatInvestmentValue(totalUnicorns)}</div>
            <div className='page-header-fact-number'>{formatInvestmentValue(totalValuations, true, true, 2)}</div>
          </div>
          <div className='page-header-fact-col '>
            <div className='page-header-fact-desc'>Unicorns Globally</div>
            <div className='page-header-fact-desc'>Cumulative Valuation</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UnicornsHeader
