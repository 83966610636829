import Input from '../Global/InputField'

const renderInput = ({
  input,
  label,
  placeholder,
  meta,
  type,
  autoComplete,
  className,
  disabled,
  content,
  maxLength
}) => {
  return (
    <Input
      input={input}
      meta={meta}
      label={label}
      placeholder={placeholder}
      type={type}
      autoComplete={autoComplete}
      className={className}
      disabled={disabled}
      maxLength={maxLength}
    >
      <span>{content}</span>
    </Input>
  )
}

export default renderInput
