import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ invest, placeOrder, wallet, user, common }) => ({
  details: invest.details,
  uphold: wallet.uphold,
  investAmount: placeOrder.sliderAmount,
  spinnerVisible: common.spinnerVisible,
  user
})

const requiredActions = ['getInvestDetail', 'connectUphold', 'getUpholdAccounts']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)
export default connected
