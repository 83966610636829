import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ broker, form }) => {
  return {
    initialValues: broker.initialValues,
    formValues: form.brokerInfoForm,
    enableReinitialize: true,
    status: broker.status
  }
}
const requiredActions = ['getBrokerInfo', 'submitBrokerInfo']
const actions = mapActions(requiredActions)
const connected = component => reduxConnect(mapStateToProps, actions)(component)
export default connected
