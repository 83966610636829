import MemberCard from './MemberCard'
import Button from '../../Global/Button'
import history from '../../../history'
const Leaderships = ({ boardMembers, teamLeaders }) => {
  return (
    <div className='inner-container leadership-container'>
      <div className='section-title'>Our Leadership</div>
      <div className='membercards-container'>
        {teamLeaders.length > 0 && teamLeaders.map((data, i) => <MemberCard key={i} {...data}/>)}
      </div>
      <div className='section-title director'>Board of Directors</div>
      <div className='membercards-container director'>
        {boardMembers.length > 0 && boardMembers.map((data, i) => <div key={i} className='membercard-container director'><span className='name director'>{data.name}</span>{data.title}</div>)}
      </div>
      <Button mode='secondary' size='md' onClick={() => history.push('/team')}>Meet Our Whole Team</Button>
    </div>
  )
}

export default Leaderships
