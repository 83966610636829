import { useEffect, useState, useRef } from 'react'
import { Invest as connected } from 'containers'
import { PageLoading, Wrapper } from 'components'
import ProductList from './partials/ProductList'
import InvestHeader from './partials/InvestHeader'
import ProductFilters from './partials/ProductFilters'
import { CircleLoader } from '../Global/ImageWithLoader'
import history from '../../history'
import SeoMeta from 'components/Global/SeoMeta'
import _ from 'lodash'
import { seoTitleTemplate, getPageScrollPosition, setPageScrollPosition } from 'utils'
import AccountBanner from '../Global/AccountBanner'

const Invest = ({ actions, companies, needToCompleteInvestorProfile, isLoggedIn, verticals }) => {
  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const pageTitle = seoTitleTemplate('Invest')
  const pageDescription = 'Linqto is private investing made simple. Browse our product list to learn more about our offerings and to begin your journey in private equity investing.'

  const url = new URL(window.location)
  const params = new URLSearchParams(url.search)
  const intialSort = params.get('sort') || ''
  const intialVertical = params.get('vertical') || ''
  const intialTerm = params.get('term') || ''
  const [query, setQuery] = useState(intialTerm)
  const [vertical, setVertical] = useState(intialVertical)
  const [sort, setSort] = useState(intialSort)
  const [topScroll, setTopScroll] = useState(0)
  const searchInput = useRef()
  const sectorSelect = useRef()
  const sortSelect = useRef()
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    setLoading(true)
    actions.getInvest(vertical, query, sort).then(() => {
      setLoading(false)
      setPageLoading(false)
      getPageScrollPosition()
    })
  }, [actions, vertical, query, sort])

  useEffect(() => {
    const onScroll = (event) => {
      setTopScroll(window.pageYOffset)
    }
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem('dismissAccountBanner')) {
      setShowBanner(false)
    } else if (isLoggedIn && needToCompleteInvestorProfile) {
      setShowBanner(true)
    } else {
      setShowBanner(false)
    }
  }, [isLoggedIn, needToCompleteInvestorProfile])

  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.value = query
    }
  })

  const buildQueryURL = (sort, vertical, term) => {
    let urlString = '/products/'
    if ((sort && sort !== '') || vertical || term) {
      urlString += '?'
      if (sort && sort !== '') {
        urlString += `sort=${sort}`
      }
      if (vertical && vertical !== '') {
        urlString += `&vertical=${encodeURIComponent(vertical)}`
      }
      if (term && term !== '') {
        urlString += `&term=${term}`
      }
    }
    return urlString
  }

  const getParams = (type, value) => {
    const url = new URL(window.location)
    const params = new URLSearchParams(url.search)
    const vertical = (type === 'vertical') ? value : params.get('vertical')
    const term = (type === 'term') ? value : params.get('term')
    const sort = (type === 'sort') ? value : params.get('sort')
    return { sort, vertical, term }
  }

  const changeSearchFilter = (item, type) => {
    if (type === 'sort') setSort(item)
    if (type === 'vertical') setVertical(item)
    const { sort, vertical, term } = getParams(type, item)
    window.history.replaceState(null, null, buildQueryURL(sort, vertical, term))
  }

  const debounceFun = _.debounce(function (val) {
    searchInvestList(val)
  }, 250, {})

  const searchInvestList = (val) => {
    setQuery(val)
    searchInput.current.focus()
    const { sort, vertical, term } = getParams('term', val)
    window.history.replaceState(null, null, buildQueryURL(sort, vertical, term))
  }

  const hideBanner = () => {
    sessionStorage.setItem('dismissAccountBanner', true)
    setShowBanner(false)
  }

  const showProductDetails = (urlName) => {
    setPageScrollPosition(topScroll)
    history.push(`/products/${urlName}`)
  }

  const completeProfile = () => {
    history.push('/profile')
  }

  if (pageLoading) {
    return <>
      <SeoMeta title={pageTitle} description={pageDescription} />
      <PageLoading />
    </>
  }

  return (
    <>
      <SeoMeta title={pageTitle} description={pageDescription} />
      <Wrapper className='page-container invest-container'>
        <div className='inner-container'>
          <div className='container'>
            {showBanner && <AccountBanner hideBanner={hideBanner} completeProfile={completeProfile} />}
            <InvestHeader />
            <ProductFilters
              changeSearchFilter={changeSearchFilter}
              verticals={verticals}
              debounceFun={debounceFun}
              searchInputRef={searchInput}
              sectorSelectRef={sectorSelect}
              sortSelectRef={sortSelect}
              vertical={vertical}
              sort={sort}
              term={query}
              setQuery={setQuery}
              loading={loading}
            />
            {!loading && <ProductList
              list={companies}
              showProductDetails={showProductDetails}
            />
            }
            {loading && <CircleLoader /> }
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default connected(Invest)
