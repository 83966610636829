import { images } from 'assets'
const AboutHiring = () => {
  return (
    <div className='about-hiring-container inner-container'>
      <div className='half'>
        <img className='growing-img' src={images['about-growing']}/>
      </div>
      <div className='half'>
        <div className='section-title'>
          We’re growing!
        </div>
        <div className='content linkedin'>
          See current openings listed on <a target='_blank' without rel='noreferrer' href='https://www.linkedin.com/company/linqto-inc./jobs/' className='inline-text-link linkedin'>LinkedIn<img className='linkedin-icon' src={images.linkedIn}></img></a>
        </div>
        <div className='content'>
          Don’t see what you’re looking for?
        </div>
        <div className='content'>
          <a style={{ lineHeight: '48px' }} className='inline-text-link' href='mailto: Jobs@linqto.com'>Email us</a> your resume and we’ll see if there’s a fit on one of our teams.
        </div>
      </div>
    </div>
  )
}

export default AboutHiring
