const DeleteAccountReasons = ({
  reasons,
  setSelectedOption,
  setErr,
  setOtherReason,
  radioGroup,
  url
}) => {
  return (
    <div
      className='radio-group'
      onChange={(e) => {
        setSelectedOption(e.target.value)
        setErr(false)
        setOtherReason('')
      }}
    >
      {Object.values(reasons).map((ele, id) => {
        return (
          <div key={Object.keys(reasons)[id]}>
            <div className='radio-option'>
              <input
                name='deleteAccountReasons'
                component='input'
                type='radio'
                id={Object.keys(reasons)[id]}
                value={ele}
                ref={(el) =>
                  (radioGroup.current[Object.keys(reasons)[id]] = el)
                }
              />
              <label
                htmlFor={Object.keys(reasons)[id]}
                className='radio__label'
              >
                {ele}
              </label>
            </div>
            {ele === reasons.TOO_MANY_EMAIL &&
              radioGroup.current?.TOO_MANY_EMAIL?.checked && (
              <a
                className='btn secondary md'
                alt='Update Email Preferences'
                rel='noopener noreferrer'
                href={url}
              >
                  Update Email Preferences
              </a>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default DeleteAccountReasons
