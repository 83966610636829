import React from 'react'
import { ProductOrderList as connected } from 'containers'
import { OrderList, PageLoading } from 'components'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'

class ProductOrderList extends React.Component {
  componentDidMount () {
    this.props.actions.showLoadingSpinner()
    this.props.actions.getProductOrders(this.props.match.params.companyId)
      .then((status) => {
        this.props.actions.hideLoadingSpinner()
        if (!this.props.isSignedIn && status === 401) {
          localStorage.setItem('prev-route', this.props.location.pathname)
          this.props.history.push('/signIn')
        }
      })
  }

  render () {
    if (this.props.spinnerVisible) {
      return (
        <>
          <SeoMeta title={seoTitleTemplate('My Portfolio')} />
          <PageLoading />
        </>
      )
    }
    return (
      <>
        <SeoMeta title={seoTitleTemplate(this.props.company.name)} />
        <div className='page-container'>
          <div className='inner-container'>
            <OrderList orders={this.props.filterOrders} company={this.props.company}/>
          </div>
        </div>
      </>
    )
  }
}

export default connected(ProductOrderList)
