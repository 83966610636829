export const trackPageView = (path) => {
  const _hsq = window._hsq = window._hsq || []
  _hsq.push(['setPath', path])
  _hsq.push(['trackPageView'])
}

export const identify = (email) => {
  const _hsq = window._hsq = window._hsq || []
  _hsq.push(['identify', {
    email
  }])
  _hsq.push(['trackPageView'])
}
