import { GET_FINANCIAL_ADVISOR } from 'types/actionTypes'
import axios from 'axios'
import { handleServerError } from './CommonActions'

export const getFinancialAdvisor = (query) => dispatch => {
  const config = {
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/page/financialAdvisor`,
    headers: query
  }
  return axios(config)
    .then((res) => {
      dispatch({
        type: GET_FINANCIAL_ADVISOR,
        payload: res.data
      })
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const saveFinancialAdvisorReqest = (formValues) => dispatch => {
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/page/financialAdvisor`,
    data: formValues
  }
  return axios(config)
    .then(() => {
      return 200
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}
