import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ form, user }) => ({
  formValues: form.signUpForm,
  user,
  initialValues: { email: user.prefillEmail },
  errMsg: user.errMsg,
  rewardsEnabled: user.rewardsEnabled,
  accreditationTerms: user.accreditationTerms
})
const requiredActions = ['signUp', 'resetErr', 'getSignupReferral']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)
export default connected
