const parseDocuments = ({ uploadedDocuments = [], taxDocuments = [] }) => {
  return {
    taxDocuments: taxDocuments.length > 0 ? taxDocuments.map((doc) => {
      doc.type = 'DOC'
      doc.delete = false
      return doc
    }) : [],
    uploadedDocuments: uploadedDocuments.length > 0 ? uploadedDocuments.map((doc) => {
      doc.type = 'USER_UPLOADED'
      doc.delete = true
      return doc
    }) : []
  }
}

export default parseDocuments
