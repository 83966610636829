const AboutVideo = ({ videoUrl }) => {
  return (
    <div className='inner-page-container'>
      <div className='about-video-container'>
        <h1 className='page-title video-title'>About Linqto</h1>
        <div className='content'>
        Linqto makes private securities investing more accessible, affordable and efficient. At the same time, we provide liquidity to founders, early investors and longer term employees. Linqto is easy to use, and our expert team is always available to answer your questions.
        </div>
        <div className='video-container'>
          <iframe
            title='About Linqto Video'
            src={videoUrl}
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            className='acc-video'
          />
        </div>
      </div>
    </div>
  )
}

export default AboutVideo
