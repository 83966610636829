import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ KYC }) => ({
  documents: KYC.documents,
  isKycVerified: KYC.isKycVerified,
  isGlobalIdLinked: KYC.isGlobalIdLinked,
  kycStatus: KYC.kycStatus,
  kycFailedReason: KYC.kycFailedReason,
  qrCodeImage: KYC.qrCodeImage
})
const requiredActions = ['getIdentity', 'uploadLoading', 'submitForReview', 'cancelReview', 'getSignInQRCode']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)
export default connected
