export const aboutItems = [
  {
    title: 'Right place, right time.',
    description:
      'Access to leading, top-tier private companies before they go public.',
    src: 'about-direction'
  },
  {
    title: 'Verify once.',
    description:
      'Once you’re verifed with us, you can easily make purchases as an accredited investor.',
    src: 'about-verify'
  },
  {
    title: 'Quick and easy.',
    description:
      'Low minimum orders that can be paid with cash or cryptocurrencies, and funding done right in the platform.',
    src: 'about-quick'
  }
]
