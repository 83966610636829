import { useState, useCallback, useMemo, useEffect } from 'react'
import connected from '../../containers/Origination'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import OriginationHeader from './partials/OriginationHeader'
import OriginationCopy from './partials/OriginationCopy'
import OriginationFooter from './partials/OriginationFooter'
import OriginationSearch from './partials/OriginationSearch'
import { debounce } from 'lodash'
import history from '../../history'

const Origination = ({ actions }) => {
  const [openMenu, setOpenMenu] = useState(false)
  const pageTitle = seoTitleTemplate('Sell')

  const fetchData = useCallback(
    async (e) => {
      if (e.length > 0) {
        return await actions.searchOrigination(e)
      }
    },
    [actions.searchOrigination]
  )
  // NOTE: zeta global tracking - Conversion tag - Start
  const zyncCall = () => {
    const script = document.createElement('script')
    const zmpID = 'linqto'
    script.src =
      'https://live.rezync.com/sync?c=16b6410431b6374e780104abb0443ca8&p=e5efeb03b795692c2bc830ec176bdd03&k=linqto-pixel-1601&zmpID=' + zmpID
  }
  useEffect(() => {
    if (['complete', 'interactive'].indexOf(document.readyState) >= 0) {
      zyncCall()
    } else {
      window.addEventListener('DOMContentLoaded', function () {
        zyncCall()
      })
    }
  }, [])
  // NOTE: zeta global tracking - Conversion tag - End
  const loadSuggestedOptions = useMemo(
    () =>
      debounce((inputValue, callback) => {
        fetchData(inputValue).then((options) => callback(options))
      }, 500),
    [fetchData]
  )

  const onSelectionChange = (item) => {
    if (item) {
      history.push(`/sell/${item.value}`)
    }
  }

  const onInputChange = (val) => {
    if (val.length === 0) {
      setOpenMenu(false)
    } else {
      setOpenMenu(true)
    }
  }

  return (
    <>
      <SeoMeta title={pageTitle} description='' />
      <div className='origination-container'>
        <OriginationHeader />
        <div className='page-container'>
          <div className='inner-container'>
            <OriginationCopy />
            <div className='origination-body'>
              <OriginationSearch
                loadSuggestedOptions={loadSuggestedOptions}
                onSelectionChange={onSelectionChange}
                onInputChange={onInputChange}
                openMenu={openMenu}
              />
            </div>
            <OriginationFooter />
          </div>
        </div>
      </div>
    </>
  )
}

export default connected(Origination)
