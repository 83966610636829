import { useState, useEffect, useCallback } from 'react'
import BlinkIdComponent from './BlinkIdComponent'
import AnalyticsService from './services/analytics'
import Loading from '../pending'
import { Button } from 'components'

const MicroBlink = ({ type, setMicroBlinkError, manualUpload, saveIdScanData, handleFinishScan, microblinkLicenseKey, logEvent }) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [error, setError] = useState(null)
  const [blinkIdRecognizers, setBlinkIdRecognizers] = useState(['BlinkIdRecognizer'])
  // Screen state management
  const [screenState, setScreenState] = useState('loading')
  const [resultsData, setResultsData] = useState(null)

  // Services
  const analytics = new AnalyticsService()
  // Product selection according to location effect
  useEffect(() => {
    setScreenState('loading')
    let recognizers = ['BlinkIdCombinedRecognizer']
    if (type === 'Passport') {
      recognizers = ['BlinkIdRecognizer']
    }
    setBlinkIdRecognizers(recognizers)
  }, [type])

  const cleanUp = useCallback(data => {
    const faceImageRaw = data.faceImage.rawImage
    const faceImage64 = getDataUrl(faceImageRaw)
    data.faceImage.encodedImage = faceImage64
    data.faceImage.rawImage = null
    const barCodeRawBytesInUnit8 = data.barcode.barcodeData.rawBytes
    const barCodeRawBytesInBase64 = Buffer.from(barCodeRawBytesInUnit8).toString('base64')
    data.barcode.barcodeData.rawBytes = barCodeRawBytesInBase64
    const signatureImageRaw = data.signatureImage.rawImage
    const signatureImage64 = getDataUrl(signatureImageRaw)
    data.signatureImage.encodedImage = signatureImage64
    data.signatureImage.rawImage = null
    if (data.fullDocumentFrontImage) {
      const fullDocumentFrontImageRaw = data.fullDocumentFrontImage.rawImage
      const fullDocumentFrontImage64 = getDataUrl(fullDocumentFrontImageRaw)
      data.fullDocumentFrontImage.encodedImage = fullDocumentFrontImage64
      data.fullDocumentFrontImage.rawImage = null
    }
    if (data.fullDocumentBackImage) {
      const fullDocumentBackImageRaw = data.fullDocumentBackImage.rawImage
      const fullDocumentBackImage64 = getDataUrl(fullDocumentBackImageRaw)
      data.fullDocumentBackImage.encodedImage = fullDocumentBackImage64
      data.fullDocumentBackImage.rawImage = null
    }
    if (data.digitalSignature) {
      const digitalSignatureRaw = data.digitalSignature.signature
      const digitalSignature64 = Buffer.from(digitalSignatureRaw).toString('base64')
      data.digitalSignature.signature = digitalSignature64
    }
    if (data.fullDocumentImage) {
      const fullDocumentImageRaw = data.fullDocumentImage.rawImage
      const fullDocumentImage64 = getDataUrl(fullDocumentImageRaw)
      data.fullDocumentImage.encodedImage = fullDocumentImage64
      data.fullDocumentImage.rawImage = null
    }
    return data
  }, [])

  useEffect(() => {
    if (resultsData) {
      const newData = cleanUp(resultsData)
      setLoading(true)
      saveIdScanData(newData)
        .then((res) => {
          setLoading(false)
          setScreenState('success')
          if (res === 'success') {
            handleFinishScan()
          } else {
            setMessage('The data is damage or invalid. Please try again.')
            logEvent('/identityVerification/step3/microblinkError', { error: 'The data is damage or invalid. Please try again' })
          }
        })
    }
  }, [resultsData, cleanUp, saveIdScanData, handleFinishScan, logEvent])

  const getDataUrl = (img) => {
    if (img === null) {
      return ''
    }
    const faceImageEl = document.createElement('canvas')
    faceImageEl.width = img.width
    faceImageEl.height = img.height

    const ctx = faceImageEl.getContext('2d')
    let base64 = ''
    if (img) {
      ctx.putImageData(img, 0, 0)
      base64 = faceImageEl.toDataURL().replace('data:image/png;base64,', '')
    }
    return base64
  }

  const handleMicroBlinkError = (error) => {
    setError('<div>Something went wrong and we couldn\'t complete this action. Our team can help you quickly get verified another way.</div>')
    setMicroBlinkError(true)
    logEvent('/identityVerification/step3/microblinkError', error)
  }

  if (loading) {
    return <div style={{ textAlign: 'center' }}><Loading /></div>
  }

  if (error) {
    return (
      <div className={`btn-group ${screenState === 'error' ? 'is-active' : ''}`}>
        <div className='auto-verification-error'>
          <div className='content'>Something went wrong. Your device or browser may not be supported. You can update your device and try again.</div>
          <div className='content'>You can also manually upload your identifying documents.</div>
          <Button size='md' mode='secondary' onClick={() => manualUpload('microblink') }>Manual Upload</Button>
        </div>
      </div>
    )
  }

  return (
    <div id='showcase-container'>
      {/* Showcase */}
      <div className='start is-active' id='showcase-start' >
        {/* SUCCESS screen if wasm is loaded correctly */}
        <BlinkIdComponent
          onInitializeStatus={(status) => setScreenState(status)}
          onResultReady={(results) => setResultsData(results.recognizer)}
          analytics={analytics}
          recognizers={blinkIdRecognizers}
          onErrorModal={(error) => handleMicroBlinkError(error)}
          microblinkLicenseKey={microblinkLicenseKey}
          logEvent={logEvent}
        />
        {message.length > 0 && <p className='content'>{message}</p>}
      </div>
    </div>
  )
}

export default MicroBlink
