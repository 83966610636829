import { GET_SECURITY_PAGE, ADD_SECURITY_MFA } from 'types/actionTypes'

const INITIAL_STATE = {
  isVerified: false,
  qrCodeUrl: '',
  mfas: [],
  currentMfa: {},
  email: '',
  mfaEnabled: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case GET_SECURITY_PAGE:
    return { ...state, ...action.payload }
  case ADD_SECURITY_MFA:
    return { ...state, ...action.payload }
  default:
    return state
  }
}
