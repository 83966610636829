import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ user }) => ({
  mfaRequired: user.mfaRequired,
  mfas: user.mfas,
  isSignedIn: user.isSignedIn
})
const requiredActions = ['verifySecurityPageMfas', 'getSigninMfas', 'sendSignInMfa', 'verifySignInMfa']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)
export default connected
