import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ origination }) => ({
  company: origination.company,
  interestedInBuying: origination.interestedInBuying
})

const requiredActions = ['getOriginationDetail']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
