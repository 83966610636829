import connected from '../../containers/OriginationConfirmed'
import { useEffect, useState } from 'react'
import { PageLoading } from 'components'
import SeoMeta from 'components/Global/SeoMeta'
import OriginationDetailHeader from 'components/OriginationDetail/partials/OriginationDetailHeader'
import OriginationDetailCompanyLogo from 'components/OriginationDetail/partials/OriginationDetailCompanyLogo'
import OriginationOfferCompanyOffer from 'components/OriginationOffer/partials/OriginationOfferCompanyOffer'
import OriginationDetailCompanyCopy from 'components/OriginationDetail/partials/OriginationDetailCompanyCopy'
import { seoTitleTemplate } from 'utils'

const OriginationConfirmed = ({ match, actions, offerConfirmed }) => {
  const [pageLoading, setPageLoading] = useState(false)

  useEffect(() => {
    setPageLoading(true)
    const originationId = match.params.originationId
    actions.getOriginationConfirmedOffer(originationId).then(() => {
      setPageLoading(false)
    })
  }, [])

  if (pageLoading) {
    return <>
      <SeoMeta title={seoTitleTemplate('Order Confirmed | Sell')} />
      <PageLoading />
    </>
  }
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Order Confirmed | Sell')} />
      <div className='origination-container origination-details-container unicorns-details-container'>
        <OriginationDetailHeader />
        <div className='page-container'>
          <div className='inner-container'>
            <div className='sections-container'>
              <OriginationDetailCompanyLogo company={offerConfirmed}/>
              <OriginationOfferCompanyOffer company={offerConfirmed} />
              <OriginationDetailCompanyCopy company={offerConfirmed} page='confirmed' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default connected(OriginationConfirmed)
