import { useEffect, useState } from 'react'
import { PageLoading } from 'components'
import connected from '../../containers/LinqtoPortfolio'
import LinqtoPortfolioHeader from './partials/LinqtoPortfolioHeader'
import LinqtoPortfolioCompanies from './partials/LinqtoPortfolioCompanies'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'

const LinqtoPortfolio = ({ actions, companies, completedOrdersTotal, investorPurchases, liquidSharesPurchases }) => {
  const [pageLoading, setPageLoading] = useState(false)
  const [filteredCompanies, setFilteredCompanies] = useState([])
  const [companiesList, setCompaniesList] = useState('ALL')

  useEffect(() => {
    setPageLoading(true)
    actions.getLinqtoPortfolio().then((res) => {
      setPageLoading(false)
    })
  }, [actions])

  useEffect(() => {
    setFilteredCompanies(companies)
  }, [companies])

  const handleChange = (val) => {
    let result = []
    if (val === 'ALL') {
      result = companies
    } else {
      result = companies.filter(obj => obj.status === 'EXITED' || obj.status === 'EXITING' || obj.status === 'CLOSED')
    }
    setFilteredCompanies(result)
    setCompaniesList(val)
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Linqto Portfolio')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Linqto Portfolio')} />
      <div className='page-container linqto-portfolio-container'>
        <LinqtoPortfolioHeader
          companiesTotal={companies.length}
          completedOrdersTotal={completedOrdersTotal}
          investorPurchases={investorPurchases}
          liquidSharesPurchases={liquidSharesPurchases} />
        <LinqtoPortfolioCompanies companies={filteredCompanies} handleChange={handleChange} companiesList={companiesList}/>
      </div>
    </>
  )
}

export default connected(LinqtoPortfolio)
