import { useCallback } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import Button from '../Global/Button'
import { Transfer as connected } from 'containers'

const AddBank = ({ actions, plaidLinkToken, setLoading, permission }) => {
  const onSuccess = useCallback((token, metadata) => {
    setLoading(true)
    actions.walletPagePlaidAccountsRequest(token)
      .then(() => {
        setLoading(false)
      })
  }, [actions, setLoading])

  const onExit = useCallback((err, metadata) => {
    console.log('exit err', err)
  }, [])

  const onLoad = useCallback(() => {
    console.log('Plaid on Load')
  }, [])

  const config = {
    token: plaidLinkToken,
    onSuccess,
    onExit,
    onLoad
  }
  const { open, ready } = usePlaidLink(config)

  const connectUpholdAndOpen = async () => {
    await actions.connectUphold()
    localStorage.setItem('transfer-modal', 'close')
  }

  // TODO: need to handle the error when initiating Plaid
  return (
    <div className='btn-group'>
      <Button mode='primary' size='md' onClick={() => permission ? open() : connectUpholdAndOpen()} disabled={!ready}>
        Add Bank Account
      </Button>
    </div>
  )
}

export default connected(AddBank)
