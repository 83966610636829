import { useState } from 'react'
import Tooltip from '../../Global/Tooltip'
import BrokerListItem from './BrokerListItem'

const BrokerList = ({ brokerInfos, tooltip }) => {
  const [showHover, setShowHover] = useState(false)
  return (
    <div className='entity-list broker-list'>
      <div
        className='entity-list-title'
        onMouseEnter={() => setShowHover(true)}
        onMouseLeave={() => setShowHover(false)}
      >
        <h5>Brokerage Account Information</h5>
        <Tooltip
          content={tooltip}
          showHover={showHover}
          setShowHover={setShowHover}
        />
      </div>
      {brokerInfos.length > 0 && brokerInfos.map((f, i) =>
        <BrokerListItem key={`broker-info-${i}`} name={f.name} brokerage={f.brokerage} exists={f.exists} entityId={f.entityId} />
      )}
    </div>
  )
}

export default BrokerList
