const InvestDetailSummaryOfficers = ({ officers }) => {
  const keyOfficersTitles = (titles) => {
    return titles.filter(obj => obj.isCurrent).map(obj => obj.title).join(', ')
  }

  const keyOfficersWorkHistory = (workHistory) => {
    return workHistory.map(obj => obj.companyName).join(', ')
  }
  return (
    <>
      <div className='ui grid unicorn-table invest-table'>
        <div className='four column row unicorn-table-header'>
          <div className='column' style={{ paddingLeft: 0 }}>
              Name
          </div>
          <div className='column'>Work History</div>
          <div className='column'>Title</div>
          <div className='column'>Status</div>
        </div>
        {officers && officers.map((p, i) =>
          <div key={`keyOfficer_${i}`} className='four column row unicorn-table-body'>
            <div className='column dark-color semi-bold'>{p.name}</div>
            <div className='column'>{p.workHistory && p.workHistory.length > 0 && keyOfficersWorkHistory(p.workHistory)}</div>
            <div className='column dark-color'>{p.companyTitles && p.companyTitles.length > 0 && keyOfficersTitles(p.companyTitles)}</div>
            <div className='column'>{keyOfficersTitles(p.companyTitles).length > 0 && 'Current'}</div>
          </div>
        )}
      </div>
      <div className='unicorn-card-container'>
        {officers && officers.map((p, i) =>
          <div className='unicorn-card' key={`mkeyOfficer_${i}`} >
            <div className='ui grid'>
              <div className='two column row unicorn-card-row'>
                <div className='column column-bold'>{p.name}</div>
                <div className='column'>{p.companyTitles && p.companyTitles.length > 0 && keyOfficersTitles(p.companyTitles)}</div>
              </div>
              <div className='one column row unicorn-card-row-line-sepretaor' />
              <div className='one column row unicorn-card-row'>
                <div className='column column-bold'>Work History:</div>
                <div className='column'>{p.workHistory && p.workHistory.length > 0 && keyOfficersWorkHistory(p.workHistory)}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
export default InvestDetailSummaryOfficers
