import { DISCONNECT_UPHOLD, GET_UPHOLD_ACCOUNTS, GET_UPHOLD_PLAID_LINK_TOKEN, GET_PLAID_ACCOUNTS, GET_ACH, UPHOLD_UNKNOWN_ERROR, RESET_ERR, GET_UPHOLD_DEPOSIT } from 'types/actionTypes'

const INITIAL_STATE = {
  uphold: {
    hasUpholdWallet: false,
    isLoggedIn: false,
    cards: [],
    isUpholdOtpRequired: false
  },
  plaidLinkToken: '',
  plaidAccounts: [],
  accounts: [],
  dailyLimit: 50000,
  dailyLimitUsed: 0,
  hasAddBankAccountPermission: false,
  isAccredited: false,
  errorMessage: '',
  isUpholdAchEnabled: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case DISCONNECT_UPHOLD:
    return { ...state, ...INITIAL_STATE }
  case GET_UPHOLD_ACCOUNTS:
    return { ...state, uphold: action.payload }
  case GET_UPHOLD_PLAID_LINK_TOKEN:
    return { ...state, plaidLinkToken: action.payload }
  case GET_PLAID_ACCOUNTS:
    return { ...state, plaidAccounts: action.payload }
  case GET_ACH:
    return { ...state, ...action.payload }
  case GET_UPHOLD_DEPOSIT:
    return { ...state, uphold: { ...state.uphold, cards: action.payload.cards, isUpholdOtpRequired: action.payload.isUpholdOtpRequired } }
  case UPHOLD_UNKNOWN_ERROR:
    return { ...state, errorMessage: action.payload }
  case RESET_ERR:
    return { ...state, errorMessage: '' }
  default:
    return state
  }
}
