import { images } from 'assets'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'

const ActivateEmail = (props) => {
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Email Activation')} />
      <div className='page-container'>
        <div className='activate-container'>
          <h1 className='page-title'>An email has been sent to you!</h1>
          <img
            style={{ margin: '0 auto' }}
            alt='activate'
            src={images.activateEmail}
          ></img>
          <p className='content'>
            Please verify your email address to sign up by clicking the button in your email.  Button expires after 24 hours.
          </p>
        </div>
      </div>
    </>
  )
}

export default ActivateEmail
