import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'actions/CommonActions'
import parseHomePage from 'actions/parsing/parseHomePage.js'
import api from '../apis/public.js'

const initialState = {
  homeProducts: [],
  hasUpholdWallet: false,
  promotedCompany: {
    name: '',
    urlName: '',
    iconUrl: ''
  },
  gicDate: '',
  gicEndDate: '',
  securityScorecard: {
    date: Date.now(),
    score: 100
  },
  isAccredited: false,
  isLoggedIn: false,
  pageLoading: false
}

export const getHomePage = createAsyncThunk('getHomePage', (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
  return api
    .get('/page/home')
    .then(res => {
      return fulfillWithValue(parseHomePage(res.data))
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    })
})

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getHomePage.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getHomePage.fulfilled, (state, { payload }) => {
        return {
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getHomePage.rejected, (state) => {
        state.pageLoading = false
      })
  }
})

export default homeSlice.reducer
