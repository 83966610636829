import { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SeoMeta from 'components/Global/SeoMeta'
import { seoTitleTemplate } from 'utils'
import { deleteAccountReasons } from '../../data'
import { submitDeleteAccount } from 'slices/securitySlice'
import Button from '../../Global/Button'
import Input from '../../Global/InputField'
import ConfirmDeleteModal from './ConfirmDeleteModal'
import SuccessModal from './SuccessModal'
import DeleteAccountReasons from './DeleteAccountReasons'

const DeleteAccount = () => {
  const dispatch = useDispatch()
  const radioGroup = useRef([])
  const url =
    'https://hs-20548088.s.hubspotemail.net/hs/manage-preferences/unsubscribe'
  const { loading } = useSelector((state) => state.securitySlice)
  const [selectedOption, setSelectedOption] = useState('')
  const [showConfimModal, setShowConfimModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [otherReason, setOtherReason] = useState('')
  const [err, setErr] = useState(false)
  const [touched, setTouched] = useState(false)

  const checkErr = () => {
    if (radioGroup.current?.OTHER?.checked && otherReason === '') {
      setErr(true)
      setTouched(true)
      return false
    }
    return true
  }

  const submitRequestDelete = () => {
    const reason = radioGroup.current?.OTHER?.checked
      ? otherReason
      : selectedOption
    dispatch(submitDeleteAccount(reason)).then(() => {
      setShowConfimModal(false)
      setShowDeleteModal(true)
    })
  }

  const requestAccountDeletion = () => {
    if (checkErr()) {
      setShowConfimModal(true)
    }
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Delete Account')} />
      <div className='page-container'>
        <div className='inner-container'>
          <div className='box delete-account-box'>
            <h4>We&apos;re sorry to see you go. </h4>
            <div className='content'>
              Why are you requesting account deletion?
            </div>
            <DeleteAccountReasons
              reasons={deleteAccountReasons}
              setSelectedOption={setSelectedOption}
              setOtherReason={setOtherReason}
              setErr={setErr}
              radioGroup={radioGroup}
              url={url}
            />
            <Input
              className='delete-account-input'
              type='text'
              disabled={!radioGroup.current?.OTHER?.checked || loading}
              input={{ name: 'otherReason', value: otherReason }}
              onChange={(e) => {
                setOtherReason(e.target.value)
                setErr(false)
                setTouched(true)
              }}
              maxLength={100}
              meta={{
                error: err && 'Please enter a reason.',
                touched: touched
              }}
              ariaLabel='OtherReason'
            />
            <div className='cta-container'>
              <Button
                mode='primary'
                size='md'
                loading={loading}
                onClick={requestAccountDeletion}
                disabled={loading || selectedOption === ''}
              >
                Request Account Deletion
              </Button>
            </div>
          </div>
        </div>
        {showConfimModal && (
          <ConfirmDeleteModal
            submitRequestDelete={submitRequestDelete}
            setShowConfimModal={setShowConfimModal}
            loading={loading}
          />
        )}
        {showDeleteModal && <SuccessModal />}
      </div>
    </>
  )
}

export default DeleteAccount
