import history from '../../../history'
import { images } from 'assets'
import { formatNumberWithLetter } from 'utils'
import UnicornDetailsHeaderCta from './UnicornDetailsHeaderCta'
import UnicornDetailsHeaderSummary from './UnicornDetailsHeaderSummary'
import UnicornCompanyDescription from './UnicornCompanyDescription'

const UnicornDetailsHeader = ({ company, isLoggedIn, setInterestedUnicorn, buttonLoading, interestedUnicron }) => {
  const redirect = (url) => {
    localStorage.setItem('prev-route', `${history.location.pathname}`)
    history.push(url)
  }

  return (
    <div className='page-header'>
      <img src={images['radial-lines-gray']} className='unicorn-backgeound' alt='unciron background'/>
      <div className='inner-container page-header-company-info'>
        <div className='page-header-company-logo'>
          <div className='page-header-company-logo-wrapper'>
            <div className='page-header-company-logo-bg'>
              {company.logoUrl && <img src={`${window.location.origin}${company.logoUrl}`} alt={company.name} />}
            </div>
          </div>
          <div className='page-header-company-title-container'>
            <div className='page-header-company-title'>{company.name}</div>
          </div>
        </div>
        <div className='page-header-company-details'>
          <div className='page-header-company-details-desc'>
            <UnicornCompanyDescription description={company.description}/>
            { company && company.sharePrice &&
              <div className='page-header-company-share'>
                <span>Share Price on Linqto:</span> {formatNumberWithLetter(company.sharePrice, true, true, 2)}
              </div>
            }
            <UnicornDetailsHeaderCta company={company} isLoggedIn={isLoggedIn} setInterestedUnicorn={setInterestedUnicorn} buttonLoading={buttonLoading} interestedUnicron={interestedUnicron}/>
            {!isLoggedIn &&
            <div className='page-header-company-signin'>
              <span onClick={() => redirect('/signup')}>Sign up</span> to see the details. Already a member? <span onClick={() => redirect('/signin')}>Sign in</span>
            </div>
            }
          </div>
        </div>
      </div>
      <UnicornDetailsHeaderSummary isLoggedIn={isLoggedIn} company={company}/>
    </div>
  )
}

export default UnicornDetailsHeader
