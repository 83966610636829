import { Suspense, useState } from 'react'
import { BaseModal } from 'components'
import { CircleLoader, ImgWithLoader } from '../Global/ImageWithLoader'

const Card = ({ people }) => {
  const [show, setShow] = useState(false)

  const showModal = () => setShow(true)

  const hideModal = () => setShow(false)

  return (
    <>
      <div
        className='item'
        key={people.name}
        onClick={() => showModal()}
      >
        <div className='img-container'>
          <Suspense fallback={<CircleLoader />}>
            <ImgWithLoader
              alt={people.name}
              className='headshot'
              src={people.imageUrl}
            />
          </Suspense>
        </div>
        <div className='name'>{people.name}</div>
        <div className='job-title'>{people.title}</div>
      </div>
      {show && (
        <BaseModal mode='primary' size='md' innerStyle='bio-modal' hideModal={hideModal} modalHeader='' clickToClose pressToClose crossToClose>
          <div className='top'>
            <Suspense fallback={<CircleLoader />}>
              <ImgWithLoader
                alt={people.name}
                className='headshot'
                src={people.imageUrl}
              />
            </Suspense>
            <div className='right'>
              <div className='name'>{people.name}</div>
              <div className='job-title'>{people.title}</div>
            </div>
          </div>
          <div className='content bio'>
            {people.bio}
          </div>
        </BaseModal>
      )}
    </>
  )
}

export default Card
