import moment from 'moment'
import { CountUp } from 'use-count-up'

// String formatting
export const toCamelCase = (str) => {
  if (str) {
    const strArr = str.split(/\s+/g)
    for (let i = 0; i < strArr.length; i++) {
      if (strArr[i].length === 0) {
        continue
      }
      strArr[i] = strArr[i][0].toUpperCase().concat(strArr[i].split('').splice(1, strArr[i].length - 1).join('').toLowerCase())
    }
    return strArr.join(' ')
  } else {
    return ''
  }
}

export const toLowerCase = (str) => {
  const arr = str.split('_')
  const newArr = []
  for (let i = 0; i < arr.length; i++) {
    newArr.push(arr[i].toLowerCase())
  }
  return newArr.join(' ')
}

export const toUpperCase = (str) => {
  const arr = str.split(' ')
  const newArr = []
  for (let i = 0; i < arr.length; i++) {
    newArr.push(arr[i].toUpperCase())
  }
  return newArr.join('_')
}

export const toCamalCase = (str) => {
  const strArr = str.split(/\s+/g)
  for (let i = 0; i < strArr.length; i++) {
    if (strArr[i].length === 0) {
      continue
    }
    strArr[i] = strArr[i][0].toUpperCase().concat(
      strArr[i]
        .split('')
        .splice(1, strArr[i].length - 1)
        .join('')
        .toLowerCase()
    )
  }
  return strArr.join(' ')
}

export const cleanSeriesName = (seriesName) => {
  if (!seriesName) return ''
  return seriesName.replace(/Linqto Liquidshares LLC - /, '').split(' - ')[1]
}

// Date formatting
export const formatDate = (timestamp) => {
  if (isNaN(timestamp)) return ''
  return moment(timestamp).format('MM/DD/YYYY')
}
export const formatDateNow = (timestamp) => {
  return moment(timestamp).fromNow()
}

// Number and Decimal formatting
export const formatDecimal = (value, showCurrency = true, decimaldigits = 2) => {
  if (!value && value !== 0) return ''
  if (value === 'Fully Subscribed') return value
  const symbol = showCurrency ? '$' : ''
  const formattedValue = value.toLocaleString('en-US', { maximumFractionDigits: decimaldigits, minimumFractionDigits: decimaldigits })
  return symbol + formattedValue
}

// Whole Number formatting (Whole Shares)
export const formatWholeNumber = (value) => {
  if (!value && value !== 0) return ''
  const formattedValue = value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 })
  return formattedValue
}

// convert string to number (for transfer modal 'amount' interactions)
export const parseStringToFloat = (value) => {
  return parseFloat(value.replace(/,/g, ''))
}

// format user input into valid currency (USD)
export const currencyFormatter = (value) => {
  if (!value || value.match(/^[A-Za-z]+$/)) return ''
  const currentInput = parseStringToFloat(value)
  return currentInput.toLocaleString('en-US')
}

// Whole Number formatting with no decimals
export const formatNumberNoDeci = (value) => {
  if (!value && value !== 0) return ''
  const formattedValue = value.toLocaleString('en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0 })
  return formattedValue
}

export const roundDecimal = (value, precision) => {
  const multiplier = 10 ** (precision || 0)
  return Math.round(value * multiplier) / multiplier
}

// Whole Number formatting with two decimals and K,M
export const formatNumberWithLetter = (value, showCurrency = false, showInKMB = false, decimaldigits = 0, removeDecimals = false) => {
  let amount = value
  const isK = value >= 1000
  const isM = value >= 1000000
  const isB = value >= 1000000000
  const isT = value >= 1000000000000
  if (showInKMB) {
    if (isT) {
      amount = value / 1000000000000
    } else if (isB) {
      amount = value / 1000000000
    } else if (isM) {
      amount = value / 1000000
    } else if (isK) {
      amount = value / 1000
    } else {
      amount = value
    }
  }
  let formattedValue = amount.toLocaleString('en-US', { maximumFractionDigits: decimaldigits, minimumFractionDigits: decimaldigits })
  if (removeDecimals) formattedValue = roundDecimal(formattedValue, decimaldigits)
  formattedValue = `${showCurrency ? '$' : ''}${formattedValue}${showInKMB ? (isT ? 'T' : isB ? 'B' : isM ? 'M' : isK ? 'K' : '') : ''}`
  return formattedValue
}

export const formatInvestmentValue = (value, showCurrency = false, showInKMB = false, decimalPlaces = 0) => {
  let amount = value
  const isK = value >= 1000
  const isM = value >= 1000000
  const isB = value >= 1000000000
  const isT = value >= 1000000000000
  if (showInKMB) {
    if (isT) {
      amount = value / 1000000000000
    } else if (isB) {
      amount = value / 1000000000
    } else if (isM) {
      amount = value / 1000000
    } else if (isK) {
      amount = value / 1000
    } else {
      amount = value
    }
  }
  return (
    <>
      {showCurrency && '$'}<CountUp isCounting start={1} end={amount} duration={1.8} thousandsSeparator=',' decimalPlaces={decimalPlaces}/>
      {showInKMB && (isT ? 'T' : isB ? 'B' : isM ? 'M' : isK ? 'K' : '')}
    </>
  )
}

export const seoTitleTemplate = (title) => {
  return `${title} | Linqto | Private Investing Made Simple `
}

export const truncate = (str, charCount) => {
  if (str && str.length > charCount) {
    const trimmedString = str.substr(0, charCount)
    return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))) + '...'
  } else {
    return str
  }
}

export const getWebsiteURL = (url) => {
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    url = 'https://' + url
  }
  return url
}
// setting the localStorage for page scroll to use later on the page
export const setPageScrollPosition = (topScroll) => {
  localStorage.setItem('scrollPosition', topScroll)
}

// getting the localStorage for page scroll to move the scroll to that position
export const getPageScrollPosition = () => {
  setTimeout(() => {
    if (localStorage.getItem('scrollPosition')) {
      window.scrollTo(0, localStorage.getItem('scrollPosition'))
      localStorage.removeItem('scrollPosition')
    }
  }, 0)
}

// check if sting is contains only white spaces
export const isWhiteSpace = (str) => !str.replace(/\s/g, '').length
