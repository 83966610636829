import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ wallet }) => ({
  accounts: wallet.accounts,
  dailyLimit: wallet.dailyLimit,
  dailyLimitUsed: wallet.dailyLimitUsed,
  cards: wallet.uphold.cards,
  errorMessage: wallet.errorMessage,
  isUpholdOtpRequired: wallet.uphold.isUpholdOtpRequired
})

const requiredActions = [
  'getUpholdPlaidLinkToken',
  'walletPagePlaidAccountsRequest',
  'getWalletPageAch',
  'getUpholdAccounts',
  'getWalletPageAchDeposit',
  'createUpholdDeposit',
  'resetErr'
]
const actions = mapActions(requiredActions)

const connected = (component) =>
  reduxConnect(mapStateToProps, actions)(component)
export default connected
