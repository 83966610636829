import { useState, useCallback, useEffect } from 'react'
import { images } from 'assets'
import { isMobileOnly } from 'react-device-detect'
function calcCoords (blockRect, hoverRect, docWidth) {
  const isLeft = blockRect.right + hoverRect.width > docWidth
  const coords = {}
  if (!isLeft) {
    coords.x = blockRect.right - hoverRect.width * 0.4
    coords.y = blockRect.top - hoverRect.height - 8
    coords.type = 'right'
  } else {
    coords.x = blockRect.left - 5 - hoverRect.width * 0.75
    coords.y = blockRect.top - hoverRect.height - 8
    coords.type = 'left'
  }
  if (isMobileOnly) {
    coords.x = docWidth / 2 - hoverRect.width / 2
  }
  return coords
}
const Tooltip = ({ content, setShowHover, showHover }) => {
  const [blockRect, setBlockRect] = useState()
  const [hoverRect, setHoverRect] = useState()
  const [coords, setCoords] = useState()
  const [docWidth, setDocWidth] = useState(window.innerWidth)
  const [scrollY, setScrollY] = useState(window.scrollY)
  const blockRef = useCallback((node) => {
    if (node) {
      setBlockRect(node.getBoundingClientRect())
    }
  }, [docWidth, scrollY])
  const hoverRef = useCallback((node) => {
    if (node) {
      setHoverRect(node.getBoundingClientRect())
    }
  }, [docWidth, scrollY])
  useEffect(() => {
    if (showHover) {
      const coords = calcCoords(blockRect, hoverRect, docWidth)
      setCoords(coords)
    }
    return () => setCoords({})
  }, [showHover, blockRect, hoverRect, docWidth])

  useEffect(() => {
    window.addEventListener('resize', () => setDocWidth(window.innerWidth))
    window.addEventListener('scroll', () => setScrollY(window.scrollY))
    return () => {
      window.removeEventListener('resize', () => setDocWidth(window.innerWidth))
      window.removeEventListener('scroll', () => setScrollY(window.scrollY))
    }
  }, [])
  return (
    <div className='tooltip' ref={blockRef} onMouseEnter={() => setShowHover(true)} onMouseLeave={() => setShowHover(false)}>
      <img src={images.tooltip} className='tooltip-icon' alt='tooltip'/>
      <div
        ref={hoverRef}
        className={`tooltip-box box ${(!showHover || !coords) && 'hidden'}`}
        style={{
          left: coords && coords.x,
          top: coords && coords.y
        }}
      ><img src={images.tooltip} className='tooltip-icon' alt='tooltip'/>{content}</div>
    </div>
  )
}

export default Tooltip
