import { images } from 'assets'

const AccountBanner = ({ hideBanner, completeProfile }) => {
  return (
    <div className='account-banner-container'>
      <div className='account-banner-header'>
        <div className='account-banner-title'>Account created!</div>
        <span className='account-banner-close' onClick ={hideBanner}>
          <img className='about-hero' alt='Close' src={images['banner-close']} />
        </span>
      </div>
      <div className='account-banner-body'>
        <div className='account-banner-content'>In order to invest,  please complete your investor profile.</div>
        <a className='account-banner-link' onClick ={completeProfile}>Start now</a>
      </div>
    </div>
  )
}

export default AccountBanner
