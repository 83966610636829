import { GET_REWARD } from 'types/actionTypes'

const INITIAL_STATE = {
  transactions: [],
  linqtoBucks: '',
  referralUrl: '',
  signUpBonus: '',
  firstOrderBonus: '',
  significantInvestorMinimum: '',
  significantInvestorPercent: ''
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case GET_REWARD:
    return { ...state, ...action.payload }
  default:
    return state
  }
}
