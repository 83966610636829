import Button from '../../Global/Button'
import { images } from 'assets'

const AddEntity = ({
  submitEntity,
  entityRequestSubmitted,
  submitEntityLoading
}) => {
  return (
    <>
      {!entityRequestSubmitted && (
        <div className='add-entity'>
          <div className='btn-group start'>
            <Button
              mode='secondary'
              size='md'
              type='button'
              onClick={(e) => submitEntity(e)}
              disabled={submitEntityLoading}
              loading={submitEntityLoading}
            >
              I Want to Add an Entity
            </Button>
          </div>
        </div>
      )}
      {entityRequestSubmitted === 'success' && (
        <div className='box message-box'>
          <img
            alt='Identity Step'
            src={images['profile-complete']}
            className='entity-icon'
          />
          <div>
            Thanks for letting us know you want to add an entity. We&apos;ll
            reach out shortly to set up this investment option for you.
          </div>
        </div>
      )}
      {entityRequestSubmitted === 'error' && (
        <div className='box message-box'>
          <img
            alt='Identity Step'
            src={images['profile-error']}
            className='entity-icon entity-icon-error'
          />
          <div>
            An error has occured. Please email{' '}
            <a href='mailto:invest@linqto.com'>invest@linqto.com</a> to add an
            entity.{' '}
          </div>
        </div>
      )}
    </>
  )
}

export default AddEntity
