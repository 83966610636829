import React from 'react'
import Dropzone from 'react-dropzone'
import { Upload as connected } from '../../containers'
import history from '../../history'
import CameraModal from '../Kyc/CameraModal'
import { images } from 'assets'
// import ProgressBar from './ProgressBar'

class Upload extends React.Component {
  state = {
    fileToUpload: '',
    uploadErr: '',
    dragging: false,
    loading: 'init',
    progress: '0',
    showCameraModal: false,
    capturedPhoto: ''
  }

  acceptFile = (acceptedFile, fileName, fileRejections) => {
    this.setState({ fileToUpload: fileName })
    if (fileRejections.length === 0) {
      this.setState({ dragging: false, loading: 'loading' })
      this.props.actions
        .uploadDocs(
          acceptedFile,
          fileName,
          this.props.type,
          this.props.query,
          this.handleProgress
        )
        .then(() => {
          if (this.props.type === 'AI') {
            this.props.actions.getInvestorStatus()
          } else if (this.props.type === 'FA') {
            this.props.actions.getFinancialAdvisor(this.props.query)
          } else if (this.props.type === 'ID') {
            this.props.actions.getIdentity()
          }
          this.setState({
            uploadErr: '',
            dragging: false,
            loading: 'init',
            progress: 0
          })
        })
    } else {
      this.setState({
        uploadErr: fileRejections[0].errors[0].message,
        dragging: false,
        loading: 'init',
        progress: 0
      })
    }
  }

  handleImageCapture = (file, fileName) => {
    this.setState({ loading: 'loading' })
    this.props.actions
      .uploadDocs(
        [file],
        fileName,
        this.props.type,
        this.props.query,
        this.handleProgress
      )
      .then(() => {
        if (this.props.type === 'AI') {
          this.props.actions.getInvestorStatus()
        } else if (this.props.type === 'FA') {
          this.props.actions.getFinancialAdvisor(this.props.query)
        } else if (this.props.type === 'ID') {
          this.props.actions.getIdentity()
        }
        this.setState({
          uploadErr: '',
          loading: 'init',
          progress: '0',
          showCameraModal: false
        })
      })
  }

  handleProgress = (progressEvent) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    )
    this.setState({ progress: `${percentCompleted}%` })
  }

  handleDelete = (e) => {
    e.stopPropagation()
    const self = this.props
    if (history.location.pathname === '/investor-status') {
      this.props.actions.deleteAIDocs(this.props.name).then((status) => {
        if (!this.props.isSignedIn && status === 401) {
          localStorage.setItem('prev-route', history.location.pathname)
          history.push('/signIn')
        } else {
          self.actions.getInvestorStatus()
        }
      })
    } else if (history.location.pathname === '/financial-advisor') {
      this.props.actions
        .deleteFADocs(this.props.name, this.props.query)
        .then((status) => {
          if (status === 400) {
            self.showModal()
          } else {
            self.actions.getFinancialAdvisor(this.props.query)
          }
        })
    } else if (history.location.pathname === '/identity') {
      this.props.actions.deleteIDDocs(this.props.name)
        .then((status) => {
          if (!this.props.isSignedIn && status === 401) {
            localStorage.setItem('prev-route', history.location.pathname)
            history.push('/signIn')
          } else {
            self.actions.getIdentity()
          }
        })
    }
  }

  renderDelete = (name) => {
    if (typeof this.props.delete !== 'undefined' && !this.props.delete) {
      return <div></div>
    }
    if (
      history.location.pathname === '/investor-status' &&
      !this.props.AIDelete
    ) {
      return <div></div>
    } else if (
      history.location.pathname === '/financial-advisor' &&
      !this.props.FADelete
    ) {
      return <div></div>
    } else {
      return (
        <div
          className='delete-icon'
          onClick={(e) => this.handleDelete(e)}
        >
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M2 2L18 18'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M18 2L2 18'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
      )
    }
  }

  render () {
    let { name, url, type, readOnly, loading } = this.props
    if (!readOnly) {
      readOnly = false
    }
    return (
      <>
        {url && url.length > 0 ? (
          <div className='upload-container'>
            <section
              className='drop-zone-container'
              onClick={() => type === 'DOC' && window.open(url)}
              style={{ width: `${readOnly && '100%'}` }}
            >
              <div className={`drop-area uploaded ${type === 'DOC' && 'pointer'}`}>
                <div className='left-group'>
                  <img
                    alt='pdf'
                    className='upload-img'
                    src={images.pdf}
                  />
                  <span className='doc-title'>{name}</span>
                </div>
                {(!readOnly && !loading) && this.renderDelete(name)}
              </div>
            </section>
            {(!readOnly && type === 'ID') && <a href={url} target='_blank' rel='noopener noreferrer' className='webcam'><img className='preview-img' src={images.preview} alt='preview' /></a>}
          </div>
        ) : (
          <>
            <div className='upload-container'>
              <Dropzone
                onDrop={(acceptedFile, fileRejections) =>
                  this.acceptFile(acceptedFile, name, fileRejections)
                }
                onDragEnter={() => this.setState({ dragging: true })}
                onDragLeave={() => this.setState({ dragging: false })}
                multiple={false}
                accept='.pdf, .docx, .png, .jpg, .jpeg'
                onDropAccepted={() => this.setState({ checkFileformat: true })}
                onDropRejected={() => this.setState({ checkFileformat: false })}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className={`drop-zone-container ${type === 'ID' && 'with-cam'}`}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div
                        className={`drop-area ${
                          this.state.dragging && 'dragging'
                        }`}
                      >
                        <img
                          alt='upload'
                          className='upload-img'
                          src={images[`${
                            this.state.dragging ? 'dragging' : 'upload'
                          }`]}
                        />
                        <span className='doc-title'>{name}</span>
                      </div>
                      {this.state.loading === 'loading' &&
                        this.state.fileToUpload.length !== 0 && (
                        <div
                          className='progress-bar'
                          style={{ width: this.state.progress }}
                        ></div>
                      )}
                    </div>
                    {this.state.uploadErr.length !== 0 && (
                      <div>{this.state.uploadErr}</div>
                    )}
                  </section>
                )}
              </Dropzone>
              {(this.props.withCamera && type === 'ID') && <div className='webcam' onClick={() => this.setState({ showCameraModal: true })}><img className='cam-img' src={images.webcam} alt='webcam' /></div>}
            </div>
            {this.state.showCameraModal && <CameraModal hideModal={() => this.setState({ showCameraModal: false })} updateCapturePhoto={(img) => this.setState({ capturedPhoto: img })} fileName={name} handleImageCapture={this.handleImageCapture} loading={this.state.loading}/>}
          </>
        )}
      </>
    )
  }
}

export default connected(Upload)
