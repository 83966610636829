import { Wrapper, Image, ConditionalRender } from 'components'
import { formatDecimal } from 'utils'
import { isMobile } from 'react-device-detect'
// import Toggle from 'components/Global/Toggle'
import useWindowSize from 'hooks/useWindowSize'
// import { useDispatch, useSelector } from 'react-redux'
// import { toggleInterest } from 'actions/InvestActions'

const InvestDetailHeader = ({ isSignedIn, currentProduct, logoUrl, isSoldOut, name, status }) => {
  const { width } = useWindowSize()
  // const dispatch = useDispatch()
  // const { details: { urlName, isInterested } } = useSelector(state => state.invest)

  return (
    <Wrapper className={`invest-header ${!isSignedIn ? 'not-signed-header' : isSoldOut ? 'fully-subscribed-header' : ''}`}>
      <div className={`${isSoldOut || width < 767 ? 'company-sold-out' : 'company-available'}`}>
        {logoUrl && <Image
          className='invest-logo'
          alt={name}
          src={logoUrl}
        />}
        {!logoUrl && <div className='invest-name'>{name}</div>}
        <ConditionalRender isVisible={!isSignedIn}>
          <ConditionalRender isVisible={!isMobile}>
            <div className='offer-price-not-signed'>Linqto Offer Price: $<span className='blur-info'>11.00</span></div>
          </ConditionalRender>
          <ConditionalRender isVisible={isMobile}>
            <div className='offer-price-not-signed-mobile'>Offer Price: <span>$</span><span className='blur-info'>11.00</span></div>
          </ConditionalRender>
        </ConditionalRender>
        <ConditionalRender isVisible={isSignedIn}>
          <ConditionalRender isVisible={!isSoldOut}>
            <div className='offer-price'>Offer Price:  <span className='price'>{formatDecimal(currentProduct.sharePrice)}</span></div>
          </ConditionalRender>
          <ConditionalRender isVisible={isSoldOut}>
            <div className='fully-subscribed'>FULLY SUBSCRIBED</div>
          </ConditionalRender>
          <ConditionalRender isVisible={status === 'EXITED'}>
            <div className='fully-subscribed'>EXITED</div>
          </ConditionalRender>
        </ConditionalRender>
      </div>
      {/* <ConditionalRender isVisible={isSignedIn && !isSoldOut}>
        <Toggle label='Turn on email updates.' handleChange={() => dispatch(toggleInterest({ companyUrlName: urlName, isInterested: !isInterested }))} checked={isInterested} />
      </ConditionalRender> */}
    </Wrapper>
  )
}
export default InvestDetailHeader
