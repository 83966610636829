import Modal from '../../Global/Modal'

const IDVerifyModal = ({ hideModal }) => {
  return (
    <>
      <Modal
        hideModal={hideModal}
        pressToClose
        crossToClose
        mode='primary'
        size='md'
        modalHeader='Why am I being asked to ID verify?'
      >
        <div className='content'>
            We are required to verify an investor’s identity, with the goal of limiting and preventing cases of fraud, tax evasion and money laundering.
        </div>
      </Modal>
    </>
  )
}

export default IDVerifyModal
