import { images } from 'assets'

const AppIntro = () => {
  return (
    <div className='container app-container'>
      <div className='inner-container'>

        <div className='intro-container'>
          <div className='hero-header app-header'>Linqto works best on mobile</div>
          <div className='app-content'>Download our app now. Available in the App Store for iOS and the Play Store for Android.</div>
          <div className='download-group'>
            <a href='https://apps.apple.com/us/app/linqto/id1464707632' target='_blank' rel='noopener noreferrer'>
              <img
                className='icon app-store'
                alt='app-store'
                src={images['app-store']}
              ></img>
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.linqto.investor.id' target='_blank' rel='noopener noreferrer'>
              <img
                className='icon'
                alt='google-play'
                src={images['google-play']}
              ></img>
            </a>
          </div>
        </div>
        <div className='app-img'>
          <img
            alt='linqto-app'
            src={images['linqto-app']}
          ></img>
        </div>
      </div>
    </div>
  )
}

export default AppIntro
