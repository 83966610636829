import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'actions/CommonActions'
import api from '../apis/public.js'

const initialState = {
  aboutUsYouTubeUrl: '',
  teamLeaders: [],
  boardMembers: [],
  gicImageUrl: '',
  gicUrl: '',
  pageLoading: false
}

export const getAboutPage = createAsyncThunk('getAboutPage', (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
  return api
    .get('/page/about')
    .then(res => {
      return fulfillWithValue(res.data)
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    })
})

export const aboutSlice = createSlice({
  name: 'about',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAboutPage.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getAboutPage.fulfilled, (state, { payload }) => {
        return {
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getAboutPage.rejected, (state) => {
        state.pageLoading = false
      })
  }
})

export default aboutSlice.reducer
