import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { InvestDetail as connected } from 'containers'
import { OrderModal, Wrapper, Content, PageLoading, ConditionalRender } from 'components'
import InvestDetailHeader from './partials/InvestDetailHeader'
import InvestDetailTabs from './partials/InvestDetailTabs'
import PlaceOrder from './partials/PlaceOrder'
import InvestDetailAbout from './partials/InvestDetailAbout'
import InvestDetailSummary from './partials/InvestDetailSummary'
import InvestDetailValuation from './partials/InvestDetailValuation'
import UnicornMosaicModal from '../UnicornDetails/partials/UnicornMosaicModal'
import SeoMeta from 'components/Global/SeoMeta'
import _ from 'lodash'
import Toggle from 'components/Global/Toggle'
import { useDispatch } from 'react-redux'
import { toggleInterest } from 'actions/InvestActions'

const InvestDetail = ({ match, details, actions, user, history, uphold }) => {
  const [showModal, setShowModal] = useState(false)
  const [showMosaicModal, setMosaicShowModal] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [productId, setProductId] = useState(0)
  const [investAmount, setInvestAmount] = useState(0)
  const [productsIndex, setProductsIndex] = useState(0)
  const [entityId, setEntityId] = useState('individual')
  const [useLinqtoBucks, setUseLinqtoBucks] = useState(false)
  const [upholdEmailVerficationError, setUpholdEmailVerficationError] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const pageTitle = (details.pageTitle && details.pageTitle !== '') ? details.pageTitle : `${details.name} | Invest in ${details.name} with Linqto | Linqto Investing`
  const [stickyHeader, setStickyHeader] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    // Sets the pathname to local storage to handle redirect
    Cookies.set('redirectPath', location.pathname)
    const productId = match.params.id
    fetchProductDetails(productId)
  }, [])

  useEffect(() => {
    let scrollTop = 0
    const scrollHeight = (!user.isSignedIn || details.status === 'EXITED' || details.isSoldOut) ? 130 : 380
    const onScroll = (event) => {
      scrollTop = event.target.documentElement.scrollTop
      if (scrollTop > scrollHeight) {
        setStickyHeader(true)
      } else {
        setStickyHeader(false)
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const fetchProductDetails = (id) => {
    setPageLoading(true)
    actions.getInvestDetail(id).then((res) => {
      if (res && res.company && res.company.products) {
        const product = res.company.products[0]
        setProductId(product.productId)
        setInvestAmount(Math.round(Math.ceil(product.minimumPurchase / product.sharePrice) * product.sharePrice * 100) / 100)
        let defaultEntity
        if (res.entities && res.entities.length > 0) {
          defaultEntity = res.entities.filter(function (a) {
            return a.isDefault
          })[0]
          if (defaultEntity) {
            setEntityId(defaultEntity.entityId)
          }
        }
      }
      setPageLoading(false)
    })
  }

  const updateProductId = (id) => {
    const index = _.findIndex(details.products, (product) => product.productId === id)
    setProductId(id)
    setProductsIndex(index)
  }

  const getUpholdAccounts = async () => {
    setLoading(true)
    await actions.getUpholdAccounts().then((res) => {
      if (res === 'UPHOLD_EMAIL_VERIFICATION_REQUIRED') setUpholdEmailVerficationError(true)
    })
    setLoading(false)
    setShowModal(true)
  }

  const setUseLinqtoBucksfun = (e) => {
    setUseLinqtoBucks(e.target.checked)
  }

  if (pageLoading) {
    return <PageLoading />
  }
  return (
    <>
      <SeoMeta title={pageTitle} description={details.pageMetaDescription} keywords={details.pageMetaKeywords} />
      <Wrapper className='invest-detail-container'>
        <div className='page-container invest-header-container'>
          <div className='inner-container'>
            <Content className='order-group invest-details-info'>
              <InvestDetailHeader isSignedIn={user ? user.isSignedIn : false } currentProduct={details.products ? details.products[productsIndex] : {}} logoUrl={details.logoUrl} isSoldOut={details.isSoldOut} name={details.name} status={details.status} />
              {!details?.isSoldOut ? <PlaceOrder
                actions={actions}
                company={details}
                user={user}
                history={history}
                spinnerVisible={loading}
                showModal={() => getUpholdAccounts()}
                updateProductId={updateProductId}
                setAmount={setInvestAmount}
                setEntityId={setEntityId}
                entityId={entityId}
                entities={details.entities ? details.entities : []}
                userName={details.user && `${details.user.firstName} ${details.user.lastName}`}
                setUseLinqtoBucks={setUseLinqtoBucksfun}
                useLinqtoBucks={useLinqtoBucks}
                linqtoBucks={details.linqtoBucks}
                urlName={match.params.id}
                investAmount={investAmount}
                productId={productId}
                rewardsEnabled={user.rewardsEnabled}
                stickyHeader = {stickyHeader}
              /> : <Toggle label='Notify me if this becomes available. ' handleChange={() => dispatch(toggleInterest({ companyUrlName: details.urlName, isInterested: !details.isInterested }))} checked={details.isInterested} />}
            </Content>
          </div>
        </div>
        <div className={`page-container invest-tabs-container ${stickyHeader ? 'stikey-padding' : ''}`}>
          <div className='inner-container'>
            <InvestDetailTabs activeIndex={activeIndex} setActiveIndex={setActiveIndex} stickyHeader={stickyHeader}/>
            <ConditionalRender isVisible={activeIndex === 0 }>
              <InvestDetailAbout company={details} />
            </ConditionalRender>
            <ConditionalRender isVisible={activeIndex === 1 }>
              <InvestDetailSummary setMosaicShowModal={setMosaicShowModal} isSignedIn={user.isSignedIn} company={details}/>
            </ConditionalRender>
            <ConditionalRender isVisible={activeIndex === 2 }>
              <InvestDetailValuation isSignedIn={user.isSignedIn} company={details} isSoldOut={details.isSoldOut}/>
            </ConditionalRender>
          </div>
        </div>
        <ConditionalRender isVisible={showMosaicModal}>
          <UnicornMosaicModal setShowModal={setMosaicShowModal} />
        </ConditionalRender>
        {showModal &&
          <OrderModal
            investAmount={investAmount}
            productId={productId}
            urlName={match.params.id}
            uphold={uphold}
            hideModal={() => setShowModal(false)}
            connectUphold={() => actions.connectUphold()}
            history={history}
            entityId={entityId}
            useLinqtoBucks={useLinqtoBucks}
            linqtoBucks={details.linqtoBucks}
            maxOrderLinqtoBucksPercent = {details.maxOrderLinqtoBucksPercent}
            upholdEmailVerficationError= {upholdEmailVerficationError}
          />
        }
      </Wrapper>
    </>
  )
}

export default connected(InvestDetail)
