import { Wrapper } from 'components'
import InvestDetailSummaryOfficers from './InvestDetailSummaryOfficers'
import InvestDetailSummaryFacts from './InvestDetailSummaryFacts'
import InvestDetailSummaryHeader from './InvestDetailSummaryHeader'

const InvestDetailSummary = ({ setMosaicShowModal, isSignedIn, company }) => {
  return (
    <Wrapper className={`invest-tab ${!isSignedIn ? 'blur-info' : ''}`} >
      <div className='ui grid'>
        <InvestDetailSummaryHeader setMosaicShowModal={setMosaicShowModal} company={company} />
        <div className='one column mobile-one row desktop-content no-padding-bottom'>
          <div className='column'>
            <div className='row section-seprator' />
          </div>
        </div>
        <div className='one column mobile-one row outer-container'>
          <div className='mobile-grey'>
            <div className='column'>
              <span className='dark full-line mobile-title'>Key Officers</span>
              <InvestDetailSummaryOfficers officers={company.keyOfficers} />
            </div>
          </div>
          <div className='one column mobile-one row'>
            <div className='column'>
              <div className='ui grid outer-container'>
                <div className='two column mobile-one row no-padding-bottom'>
                  <div className='column'>
                    <div className='row'>
                      {company.customerFacts && <InvestDetailSummaryFacts facts={company.customerFacts} title='Customers' icon='invest_customers' />}
                    </div>
                  </div>
                  <div className='column'>
                    <div className='row'>
                      {company.keyInvestors && <InvestDetailSummaryFacts facts={company.keyInvestors} title='Key Investors' icon='invest_investors' />}
                    </div>
                  </div>
                </div>
                <div className='two column mobile-one row no-padding-top'>
                  <div className='column'>
                    <div className='row'>
                      {company.marketFacts && <InvestDetailSummaryFacts facts={company.marketFacts} title='Size of Market' icon='invest_market' />}
                    </div>
                  </div>
                  <div className='column'>
                    <div className='row'>
                      {company.marketPositionFacts && <InvestDetailSummaryFacts facts={company.marketPositionFacts} title='Market Position' icon='invest_position' />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
export default InvestDetailSummary
