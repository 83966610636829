import { GET_LINQTO_PORTFOLIO } from 'types/actionTypes'

const INITIAL_STATE = {
  companies: [],
  completedOrdersTotal: 0,
  investorPurchases: 0,
  liquidSharesPurchases: 0
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case GET_LINQTO_PORTFOLIO:
    return { ...state, ...action.payload }
  default:
    return state
  }
}
