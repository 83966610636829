import { BaseModal, Content, Wrapper, Row } from 'components'
import Button from '../../Global/Button'
import useWindowSize from 'hooks/useWindowSize'
import { useHistory } from 'react-router-dom'
import parse from 'html-react-parser'
const VerifyAccreditationModal = ({ twoButtons, error, hideModal, paymentType }) => {
  const { width } = useWindowSize()
  const history = useHistory()
  const message = () => {
    const Msg = `In order to complete your order, you must verify your status as an accredited investor, (ie: sufficient income or net worth). <br><br>${paymentType === 'UPHOLD' ? 'If you initiate and fail to complete an order funded with an Uphold account, you may incur nonrefundable Uphold fees.' : 'If you place an order now, it will not be finalized until you verify.'}`
    return Msg
  }
  return (
    <BaseModal mode='error' size='md' hideModal={hideModal} modalHeader={error.title}>
      <Wrapper className='error-modal'>
        <Content className='error'>
          <div className='content message'>{parse(message())}</div>
        </Content>
        <Row className='stackable-btns'>
          <Button size='md' mode='primary' onClick={() => history.push('/investor-status')}>{width > 476 ? 'Verify My Accreditation Status' : 'Verify Accreditation'}</Button>
          <Button size='md' mode='secondary' onClick={() => hideModal(true)}>Skip and Place Order</Button>
        </Row>
      </Wrapper>
    </BaseModal>
  )
}

export default VerifyAccreditationModal
