import { Field } from 'redux-form'
import renderer from './renderer'
const SameAddress = ({ formValues, profileLoading }) => {
  return (
    <div className='eight wide column tablet_eight'>
      <Field
        name='sameAddress'
        component={renderer.renderInput}
        type='checkbox'
        key={(formValues && formValues.values) && formValues.values.sameAddress}
        checked={(formValues && formValues.values) && formValues.values.sameAddress}
        disabled ={profileLoading}
      >
              This is not my mailing address
      </Field>
    </div>
  )
}

export default SameAddress
