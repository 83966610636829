import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ common }) => ({
  pageDetail: common.rewardsTermsOfUsePage
})

const requiredActions = ['getRewardsTermsOfUse']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
