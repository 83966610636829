const MemberCard = ({ name, title, imageUrl }) => {
  return (
    <div className='membercard-container'>
      <div className='overlay'></div>
      <img src={imageUrl}/>
      <div className='name'>{name}</div>
      <div className='title'>{title}</div>
    </div>
  )
}

export default MemberCard
