import { GET_CONFIRM_DETAILS, COMMIT_ORDER, GET_ORDER_CONFIRMATION, PLACE_ORDER_ERROR, CLEAR_ORDER_ERROR } from 'types/actionTypes'
import privateAPI from '../apis/private.js'
import parseConfirmOrder from './parsing/parseConfirmOrder'
import parseOrderConfirmed from './parsing/parseOrderConfirmed'
import parseErrorMessages from './parsing/parseErrorMessages'
import { handleServerError } from './CommonActions'

export const confirmOrder = ({ upholdId, companyId, type, amount, productId, entityId, useLinqtoBucks }) => (dispatch) => {
  const body = {
    companyId: companyId,
    paymentType: type,
    upholdCardId: upholdId,
    productId: productId,
    amount,
    useLinqtoBucks: useLinqtoBucks === 'true'
  }
  if (entityId !== 'individual') {
    body.entityId = entityId
  }
  return privateAPI
    .post('/page/confirmOrder', body)
    .then((res) => {
      dispatch({
        type: GET_CONFIRM_DETAILS,
        payload: parseConfirmOrder(res.data)
      })
      return res.data
    })
    .catch(err => {
      if (err.response && err.response.status === 500) {
        const data = err.response.data
        dispatch({
          type: PLACE_ORDER_ERROR,
          payload: parseErrorMessages(data)
        })
      } else {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const commitOrder = ({ productId, type, amount, temporaryUpholdTransactionId, upholdOtpToken, entityId, useLinqtoBucks }) => (dispatch) => {
  const body = {
    productId,
    amount,
    paymentType: type,
    upholdOtpToken,
    temporaryUpholdTransactionId,
    useLinqtoBucks: useLinqtoBucks === 'true'
  }
  if (entityId !== 'individual') {
    body.entityId = entityId
  }
  return privateAPI
    .post('/page/confirmOrder/commit', body)
    .then(res => {
      dispatch({
        type: COMMIT_ORDER,
        payload: res.data.orderId
      })
    })
    .catch(err => {
      if (err.response && err.response.status === 500) {
        const data = err.response.data
        dispatch({
          type: PLACE_ORDER_ERROR,
          payload: parseErrorMessages(data)
        })
      } else {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const getOrderConfirmation = (orderId) => (dispatch) => {
  return privateAPI
    .get(`/page/orderConfirmed/${orderId}`)
    .then(res => {
      dispatch({
        type: GET_ORDER_CONFIRMATION,
        payload: parseOrderConfirmed(res.data)
      })
      return res.data
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const hideErrorModal = () => ({
  type: CLEAR_ORDER_ERROR
})
