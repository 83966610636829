import { month, days } from '../../data'

const renderDay = (values) => {
  const month = values.birthMonth
  const year = values.birthYear
  const daysArr = []
  if (Number(month) === 2) {
    if (year % 4 === 0) {
      for (let i = 0; i < 28; i++) {
        daysArr.push(i + 1)
      }
      return daysArr
    } else {
      for (let i = 0; i < 29; i++) {
        daysArr.push(i + 1)
      }
      return daysArr
    }
  } else {
    for (let i = 0; i < days[Number(month)]; i++) {
      daysArr.push(i + 1)
    }
    return daysArr
  }
}

const renderYear = () => {
  const curYear = new Date().getFullYear()
  const yearArr = []
  const maxYear = curYear - 18
  const minYear = curYear - 100
  for (let i = maxYear; i > minYear; i--) {
    yearArr.push(i)
  }
  return yearArr
}

const renderMonth = () => {
  const daysMonth = []
  Object.values(month).forEach((m, id) => {
    daysMonth.push({ id: id + 1, m })
  })
  return daysMonth
}

export default {
  renderDay,
  renderYear,
  renderMonth
}
