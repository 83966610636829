import Modal from '../../Global/Modal'
import Button from '../../Global/Button'

const ConfirmDeleteModal = ({
  setShowConfimModal,
  submitRequestDelete,
  loading
}) => {
  return (
    <>
      <Modal
        mode='primary'
        size='md'
        crossToClose
        modalHeader=''
        innerStyle='confirm-delete-modal'
        hideModal={() => setShowConfimModal(false)}
      >
        <h4>Are you sure?</h4>
        <div className='content'>
          Deleting your account will remove all information you’ve submitted to
          complete your investor profile, including uploaded documents.
        </div>
        <div className='btn-group two'>
          <Button
            mode='secondary'
            onClick={() => submitRequestDelete()}
            loading={loading}
          >
            Request Deletion
          </Button>
          <Button mode='primary' onClick={() => setShowConfimModal(false)}>
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default ConfirmDeleteModal
