import { useEffect, useState } from 'react'
import {
  PageLoading,
  OrderSection,
  Wrapper,
  Content,
  Row,
  ConditionalRender,
  ComponentRender,
  ErrorModal
} from 'components'
import errorTypes from 'types/errorTypes'
import Button from '../Global/Button'
import Input from '../Global/InputField'
import qs from 'qs'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import VerifyAccreditationModal from './partials/VerifyAccreditationModal'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { confirmOrder, commitOrder } from 'slices/placeOrderSlice'

const OrderConfirmation = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    isUpholdOtpRequired,
    order,
    placeOrderError,
    pageLoading,
    company,
    documents,
    commitLoading,
    orderId,
    isAccredited
  } = useSelector((state) => state.placeOrderSlice)

  const [confirmed, setConfirmed] = useState(false)
  const [authCode, setAuthCode] = useState('')
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [confirmedBucks, setConfirmedBucks] = useState(false)
  const [err, setErr] = useState({})
  const [touched, setTouched] = useState({
    confirmed: false,
    authCode: false,
    confirmedBucks: false
  })
  const [hideAccreditationModal, setHideAccreditationModal] = useState(false)

  useEffect(() => {
    refreshOrder()
  }, [])

  useEffect(() => {
    let timerId
    // Create a timeout to refresh the page data when the Order's expiration has been reached
    const { msToLive } = order
    if (msToLive) {
      timerId = setTimeout(() => {
        refreshOrder()
      }, msToLive)
    }
    return () => {
      clearTimeout(timerId)
    }
  }, [order])
  const refreshOrder = () => {
    const query = location.search
    const { companyId, amount, type, productId, entityId, useLinqtoBucks } =
      qs.parse(query, { ignoreQueryPrefix: true })
    const upholdId = location.pathname.split('/orderconfirmation/')[1]
    dispatch(confirmOrder({
      upholdId,
      companyId,
      type,
      amount,
      productId,
      entityId,
      useLinqtoBucks
    }))
      .then(({ meta, payload }) => {
        if (meta.requestStatus === 'rejected') {
          setShowErrorModal(true)
        }
      })
  }
  const hanldeCommitOrder = () => {
    const query = location.search
    const { entityId, useLinqtoBucks } = qs.parse(query, {
      ignoreQueryPrefix: true
    })

    const { err, touched } = checkErr(
      isUpholdOtpRequired,
      order.linqtoBucksUsed
    )
    if (Object.keys(err).length === 0) {
      let body = {
        productId: order.productId,
        type: order.paymentType,
        amount: order.amount,
        entityId,
        useLinqtoBucks
      }
      if (order.paymentType === 'UPHOLD') {
        body = {
          ...body,
          temporaryUpholdTransactionId: order.temporaryUpholdTransactionId,
          upholdOtpToken: authCode
        }
      }
      dispatch(commitOrder(body))
        .then(({ meta, payload }) => {
          if (meta.requestStatus === 'fulfilled') {
            const orderId = payload.orderId
            history.push(`/orderconfirmed/${orderId}`)
          } else if (meta.requestStatus === 'rejected') {
            setShowErrorModal(true)
          }
        })
    } else {
      setErr(err)
      setTouched(touched)
    }
  }
  const checkErr = (isUpholdOtpRequired, linqtoBucksUsed) => {
    const err = {}
    const touched = {}
    if (isUpholdOtpRequired && !authCode) {
      err.authCode = true
      touched.authCode = true
    }
    if (!confirmed) {
      err.confirmed = true
      touched.confirmed = true
    }

    if (linqtoBucksUsed > 0 && !confirmedBucks) {
      err.confirmedBucks = true
      touched.confirmedBucks = true
    }
    return { err, touched }
  }
  const closeModal = () => {
    let path
    let redirect
    const urlName = location.state.urlName
    switch (placeOrderError.type) {
    case errorTypes.PLACE_ORDER_USER_PROFILE_NOT_COMPLETE:
      path = '/profile'
      redirect = location.pathname + location.search
      break
    case errorTypes.PLACE_ORDER_PRODUCT_INSUFFICIENT_AVAILABLE_SHARES:
    case errorTypes.UPHOLD_INSUFFICIENT_FUNDS:
    case errorTypes.UPHOLD_INSUFFICIENT_UNLOCKED_FUNDS:
      history.goBack()
      break
    case errorTypes.UPHOLD_OTP_REQUIRED:
    case errorTypes.UPHOLD_OTP_INCORRECT:
      setErr({ authCode: true })
      break
    case errorTypes.NOT_LOGGED_IN_TOKEN_INVALID:
      path = '/signin'
      localStorage.setItem('prev-route', `/products/${urlName}`)
      break
    case errorTypes.PLACE_ORDER_EXCEEDED_NON_ACCREDITED_WIRE_ORDERS_CAP:
      path = '/investor-status'
      break
    case 'Internal Server Error':
      path = `/products/${urlName}`
      break
    default:
      path = null
    }
    setShowErrorModal(false)
    if (path) {
      history.push(path, { redirect, urlName })
    }
  }
  const onInputChange = (func, val, key) => {
    func(val)
    setErr({ [key]: false })
    setTouched({ [key]: true })
  }
  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Confirm Order')} />
        <PageLoading />
      </>
    )
  }
  const linqtoBucksUsed = order?.linqtoBucksUsed
  return (
    <>
      {(!hideAccreditationModal && !isAccredited) && (
        <VerifyAccreditationModal
          twoButtons
          error={{ title: 'Verify Accreditation' }}
          hideModal={() => setHideAccreditationModal(true)}
          paymentType={order?.paymentType}
        />
      )}
      <SeoMeta title={seoTitleTemplate('Confirm Order')} />
      <Wrapper>
        <Content className='page-container order-confirmation'>
          <Content className='inner-container'>
            <OrderSection
              isOrderConfirm
              company={company}
              order={order}
              documents={documents}
              orderId={orderId}
            >
              <Row className='place-order-box__details confirm-checkbox'>
                <Input
                  id='confirmOrderCheckbox'
                  type='checkbox'
                  value={confirmed}
                  onChange={(e) =>
                    onInputChange(setConfirmed, e.target.checked, 'confirmed')
                  }
                  meta={{ error: err.confirmed, touched: touched.confirmed }}
                  className='confirm-checkbox'
                  disabled={commitLoading}
                >
                  {' '}
                  I confirm I have read these documents and agree to be bound by
                  their provisions. I understand that the transaction is not
                  complete until Accredited Investor status is validated and
                  funds have been received.
                </Input>
              </Row>
              {linqtoBucksUsed > 0 && (
                <Row className='place-order-box__details confirm-checkbox confirm-bucks'>
                  <Input
                    id='confirmBucksCheckbox'
                    type='checkbox'
                    value={confirmedBucks}
                    onChange={(e) =>
                      onInputChange(
                        setConfirmedBucks,
                        e.target.checked,
                        'confirmedBucks'
                      )
                    }
                    meta={{
                      error: err.confirmedBucks && 'error',
                      touched: touched.confirmedBucks
                    }}
                    className='confirm-checkbox'
                    disabled={commitLoading}
                  >
                    {' '}
                    I have read and agree to be bound by the{' '}
                    <a href='/reward-terms-of-use' target='_blank'>
                      Linqto Bucks Terms and Conditions
                    </a>
                    .
                  </Input>
                </Row>
              )}
              <ConditionalRender
                isVisible={isUpholdOtpRequired}
                className='place-order-box__details verification review'
              >
                <div className='line'></div>
                <span className='auth-title'>
                  Uphold Wallet 2-Step Verification
                </span>
                <span className='subtitle'>
                  Please enter the code generated by your authenticator app.
                </span>
                <Input
                  type='text'
                  autoComplete='one-time-code'
                  maxLength={7}
                  value={authCode}
                  onChange={(e) =>
                    onInputChange(setAuthCode, e.target.value, 'authCode')
                  }
                  meta={{
                    error: err.authCode && 'Please enter a valid code.',
                    touched: touched.authCode
                  }}
                  disabled={commitLoading}
                  ariaLabel='UpholdOTP'
                />
                <span className='uphold-support'>
                  Problems with the authentication?{' '}
                  <a
                    href='https://support.uphold.com/hc/en-us/articles/360040851591'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Get help
                  </a>
                </span>
              </ConditionalRender>
              <ComponentRender
                condition={!commitLoading}
                ComponentOne={() => (
                  <Button
                    mode='primary'
                    size='md'
                    onClick={hanldeCommitOrder}
                    customClass='place-order-button'
                  >
                    Confirm Order
                  </Button>
                )}
                ComponentTwo={() => (
                  <Button
                    mode='primary'
                    loading
                    disabled
                    customClass='place-order-button'
                  />
                )}
              />
            </OrderSection>
          </Content>
        </Content>
        {showErrorModal && <ErrorModal
          error={placeOrderError}
          hideModal={closeModal}
          message='OK'
        />}
      </Wrapper>
    </>
  )
}

export default OrderConfirmation
