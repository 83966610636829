import { GET_IDENTITY, GET_FINISH_SIGN_UP, GET_SIGNIN_QRCODE, GET_KYC_STATUS, SUBMIT_KYC_STATUS } from 'types/actionTypes'

const INITIAL_STATE = {
  documents: [],
  isKycVerified: '',
  isReviewPending: '',
  selfAccreditation: '',
  accreditedCountry: '',
  isGlobalIdLinked: '',
  kycStatus: '',
  kycFailedReason: '',
  country: '',
  qrCodeImage: '',
  url: '',
  birthday: 1,
  birthMonth: 1,
  birthYear: 1,
  address: ''
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case GET_IDENTITY:
    return { ...state, ...action.payload }
  case GET_FINISH_SIGN_UP:
    return { ...state, ...action.payload }
  case GET_SIGNIN_QRCODE:
    return { ...state, ...action.payload }
  case GET_KYC_STATUS:
    return { ...state, ...action.payload }
  case SUBMIT_KYC_STATUS:
    return { ...state, ...action.payload }
  default:
    return state
  }
}
