import { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { images } from 'assets'
const dismissInterval = 1800000 // 30 minutes
const EventBanner = ({ gicDate, gicEndDate }) => {
  const [dismiss, setDismiss] = useState(false)
  const bannerContainer = useRef(null)
  useEffect(() => {
    const dismissEvent = localStorage.getItem('dismissEvent')
    const dismissTime = localStorage.getItem('dismissTime')
    const timeDiff = Date.now() - dismissTime
    if (dismissEvent !== null) {
      if (dismissEvent && timeDiff < dismissInterval) {
        setDismiss(dismissEvent)
      } else {
        setDismiss(false)
      }
    }
  }, [])
  const clickToDismiss = (e) => {
    e.stopPropagation()
    bannerContainer.current.classList.add('hide-banner')
    localStorage.setItem('dismissEvent', true)
    localStorage.setItem('dismissTime', Date.now())
    setTimeout(() =>
      setDismiss(true)
    , 1000)
  }

  const formatDate = (start, end) => {
    if (!end || moment(start).format('MMM DD') === moment(end).format('MMM DD')) {
      return moment(start).format('MMM DD')
    } else if (moment(start).format('MM') === moment(end).format('MM')) {
      const endDate = moment(end).format('DD')
      const startDate = moment(start).format('MMM DD')
      return startDate + ' - ' + endDate
    } else {
      const endDate = moment(end).format('MMM DD')
      const startDate = moment(start).format('MMM DD')
      return startDate + ' - ' + endDate
    }
  }

  return (
    <>
      {!dismiss && <div className='event-banner-container' ref={bannerContainer}>
        <div className='event-content-group pointer' onClick={() => window.open('https://globalinvestorconference.com')}>
          <span>Sign up for our Global Investor Conference</span>
          <div className='event-date'>{formatDate(gicDate, gicEndDate)}</div>
        </div>
        <div className='event-dismiss pointer' onClick={(e) => clickToDismiss(e)}><img alt='close-icon' src={images['close-icon']}></img></div>
      </div>}
    </>
  )
}

export default EventBanner
