import { useEffect } from 'react'
import parse from 'html-react-parser'
import connect from '../../containers/RewardsTermsOfUse'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'

const TermOfUse = ({ actions, pageDetail }) => {
  useEffect(() => {
    actions.getRewardsTermsOfUse()
  }, [actions])
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Linqto Bucks Referral Program Terms and Conditions')} />
      <div className='page-container terms-container'>
        <div className='inner-container'>
          {parse(pageDetail)}
        </div>
      </div>
    </>
  )
}

export default connect(TermOfUse)
