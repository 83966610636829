import _ from 'lodash'

const parseProfile = (data) => {
  const u = data.userProfile || {}

  const primaryAdress = u.addresses.filter(function (a) {
    return a.type === 'ADDRESS'
  })[0] || []

  const mailingAddress = u.addresses.filter(function (a) {
    return a.type === 'MAILING'
  })[0] || []

  const primary = _.pick(primaryAdress, ['street1', 'street2', 'city', 'state', 'country', 'zip'])
  const mailing = _.pick(mailingAddress, ['street1', 'street2', 'city', 'state', 'country', 'zip'])

  return {
    email: u.email ? u.email : '',
    firstName: u.firstName ? u.firstName : '',
    lastName: u.lastName ? u.lastName : '',
    phone: u.phone ? u.phone : '',
    taxId: u.taxId ? u.taxId : '',
    pStreet1: primaryAdress.street1 ? primaryAdress.street1 : '',
    pStreet2: primaryAdress.street2 ? primaryAdress.street2 : '',
    pCity: primaryAdress.city ? primaryAdress.city : '',
    pState: primaryAdress.state ? primaryAdress.state : '',
    pCountry: primaryAdress.country ? primaryAdress.country : '',
    pZip: primaryAdress.zip ? primaryAdress.zip : '',
    mStreet1: mailingAddress.street1 ? mailingAddress.street1 : '',
    mStreet2: mailingAddress.street2 ? mailingAddress.street2 : '',
    mCity: mailingAddress.city ? mailingAddress.city : '',
    mState: mailingAddress.state ? mailingAddress.state : '',
    mCountry: mailingAddress.country ? mailingAddress.country : '',
    mZip: mailingAddress.zip ? mailingAddress.zip : '',
    sameAddress: !_.isEqual(primary, mailing) || false,
    type: (data.entity && data.entity.isComplete) ? data.entity.name : '',
    hasEntity: data.entity,
    citizenshipCountry: u.citizenshipCountry ? u.citizenshipCountry : '',
    foreignTaxId: u.foreignTaxId ? u.foreignTaxId : ''
  }
}

export default parseProfile
