import {
  GET_USER_PROFILE,
  EDIT_PROFILE,
  CHANGE_PASSWORD
} from 'types/actionTypes'
import parseProfile from '../actions/parsing/parsingProfile'

const INITIAL_STATE = {
  userProfile: {},
  entity: {},
  entities: [],
  brokerInfos: [],
  hasCompletedOrder: false,
  changestatus: 'init',
  initialValues: {
    firstName: '',
    lastName: '',
    phone: '',
    taxId: '',
    pStreet1: '',
    pStreet2: '',
    pCity: '',
    pState: '',
    pCountry: '',
    pZip: '',
    mStreet1: '',
    mStreet2: '',
    mCity: '',
    mState: '',
    mCountry: '',
    mZip: '',
    sameAddress: true,
    foreignTaxId: '',
    citizenshipCountry: ''
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case GET_USER_PROFILE:
    return { ...state, ...action.payload, initialValues: { ...parseProfile(action.payload) } }
  case EDIT_PROFILE: {
    return { ...state, ...action.payload }
  }
  case CHANGE_PASSWORD:
    return { ...state, ...action.payload }
  default:
    return state
  }
}
