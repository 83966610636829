import { useState } from 'react'
import { images } from 'assets'
import Input from '../Global/InputField'
import { formatDecimal } from 'utils'

const RewardShare = ({ referralUrl, signUpBonus, firstOrderBonus }) => {
  const [copied, setCopied] = useState(0)
  const [checked, setChecked] = useState(false)

  const inputValue = (checked) ? referralUrl : 'Check the box below to get your link!'

  const shareTitle = 'Join Linqto with my personal link 💵'
  const shareTxt =
    signUpBonus > 0
      ? `Join Linqto with my personal link and get ${formatDecimal(signUpBonus, true, 0)} Linqto Bucks at sign up. ${encodeURIComponent(referralUrl)}`
      : `Join Linqto with my personal link. ${encodeURIComponent(referralUrl)}`

  const linkCopy = (referralUrl) => {
    if (checked) {
      navigator.clipboard.writeText(referralUrl)
      setCopied(1)
    }
  }

  const facebookShare = (referralUrl) => {
    if (checked) {
      const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        referralUrl
      )}`
      const win = window.open(url, '_blank')
      win.focus()
    }
  }

  const twitterShare = (shareTxt) => {
    if (checked) {
      const url = `https://twitter.com/intent/tweet?text=${shareTxt}`
      const win = window.open(url, '_blank')
      win.focus()
    }
  }

  const LinkedinShare = (referralUrl) => {
    if (checked) {
      const url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        referralUrl
      )}`
      const win = window.open(url, '_blank')
      win.focus()
    }
  }

  const emailShare = (shareTitle, shareTxt) => {
    if (checked) {
      const url = `mailto:?subject=${shareTitle}&body=${shareTxt}`
      window.open(url, '_blank')
    }
  }

  return (
    <>
      <div className='rewards-share-container'>
        <div className='rewards-share-inner'>
          <img
            src={images['linqto-bucks']}
            alt='Join Linqto Referral Program'
            className='rewards-share-img'
          />
          <div className='rewards-share'>
            <div className='title-group'>
              <div className='title'>Share your personal referral link with your friends.</div>
              <div className='note'>
                {`They sign up and get ${formatDecimal(signUpBonus, true, 0)}. They complete a purchase, and you get ${formatDecimal(firstOrderBonus, true, 0)}. Simple.`}
              </div>
            </div>
            <div className={`input-share-container ${!checked ? 'disabled' : ''}`}>
              <div className='input-share'>
                <div>
                  <input value={inputValue} type='text' readOnly />
                  <span
                    copied={copied}
                    onAnimationEnd={() => setCopied(0)}
                    className='ghost-input'
                    onClick={() => linkCopy(referralUrl)}
                  >
                    {referralUrl}
                  </span>
                  {copied === 1 ? (
                    <>
                      <button
                        className='copy-btn desktop-hidden'
                        onClick={() => linkCopy(referralUrl)}
                      >
                        <img src={images.rewards_icon_copy} alt='Copy Link' />
                      </button>
                      <div className='share-text'>Link copied</div>
                    </>
                  ) : (
                    <button
                      className='copy-btn'
                      onClick={() => linkCopy(referralUrl)}
                    >
                      <img src={images.rewards_icon_copy} alt='Copy Link' />
                    </button>
                  )}
                </div>
              </div>
              <div className='actions-share'>
                <button
                  className='share-btn'
                  onClick={() => emailShare(shareTitle, shareTxt)}
                >
                  <img src={images.rewards_icon_email} alt='Share link by email' />
                </button>

                <button
                  className='share-btn'
                  onClick={() => facebookShare(referralUrl)}
                >
                  <img
                    src={images.rewards_icon_facebook}
                    alt='Share link with Facebook'
                  />
                </button>
                <button
                  className='share-btn'
                  onClick={() => twitterShare(shareTxt)}
                >
                  <img
                    src={images.rewards_icon_twitter}
                    alt='Share link with Twitter'
                  />
                </button>
                <button
                  className='share-btn'
                  onClick={() => LinkedinShare(referralUrl)}
                >
                  <img
                    src={images.rewards_icon_linkedin}
                    alt='Share link with Linkedin'
                  />
                </button>
              </div>
            </div>
            <Input type='checkbox' name='useLinqtoBucks' onChange={(e) => setChecked(true)} disabled={checked} className='linqto-bucks'>
                  I have read and agree to be bound by the  <a href='/reward-terms-of-use' target='_blank'>Linqto Bucks Terms and Conditions</a>.
            </Input>
          </div>
        </div>
        <div className='rewards-info'>
          <strong>Can’t share the link?</strong> Ask your friends to include your name in the &quot;Referral&quot; box during sign up.
        </div>
      </div>
      <div className='rewards-steps'>
        <div className='rewards-steps-title'> Next Steps?</div>
        Keep sharing those referrals because there is no limit to your Linqto Bucks Rewards. On your next investment, check the Linqto Bucks box to apply your rewards.
      </div>
    </>
  )
}

export default RewardShare
