import { formatDecimal } from 'utils'
import Slider from 'rc-slider'
import { images } from 'assets'
import { useState } from 'react'

const icon = {
  greenplus: images.greenplus,
  greenminus: images.greenminus,
  greyplus: images.greyplus,
  greyminus: images.greyminus
}

const OriginationDetailCompanyOffer = ({ company, setShareClass, amount, setAmount, shares, setShares }) => {
  const [sliderIndex, setSliderIndex] = useState(company.minShares)
  const increment = company.sharesIncrement
  const minPurchase = company.minShares * company.sharePrice
  const minShares = company.minShares
  const maxPurchase = company.maxShares * company.sharePrice
  const maxShares = company.maxShares

  const updateSliderIndex = (index) => {
    if (index < 0 || index > maxShares) {
      return
    }
    let share = (index)
    let amount = company.sharePrice * share

    if (amount > maxPurchase) {
      amount = maxPurchase
      share = maxShares
    }

    if (amount < minPurchase) {
      amount = minPurchase
      share = minShares
    }
    setAmount(amount)
    setSliderIndex(index)
    setShares(share)
  }

  const toTitleCase = (e) => {
    return e.replace(/_/g, ' ').replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      }
    )
  }
  return (
    <div className='section-nar origination-detail-offer'>
      {!company.isLoggedIn &&
      <div className='box orginiation-order-box not-logged-in'>
        <div className='offer'>LINQTO OFFER</div>
        <div className='share'>Price Per Share:</div>
        <div className='price'>{formatDecimal(company.sharePrice)}</div>
      </div>}
      {company.isLoggedIn &&
      <div className='box orginiation-order-box orginiation-order-slider-box'>
        <div className='offer'>LINQTO OFFER</div>
        <div className='share'>Price Per Share: {formatDecimal(company.sharePrice)}</div>
        <div className='box-row slider-row'>
          <div className='slider-btn minus' onClick={() => updateSliderIndex(sliderIndex - increment)}>
            <img alt='minus' src={sliderIndex <= minShares ? icon.greyminus : icon.greenminus} />
          </div>
          <Slider
            className='slider'
            railStyle={{ height: '5px' }}
            handleStyle={{ border: 'none', height: '26px', width: '26px', marginTop: '-13px', boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)' }}
            trackStyle={{ backgroundColor: '#077187', height: '5px' }}
            min={minShares}
            max={maxShares}
            step={1}
            defaultValue={minShares}
            onChange={val => updateSliderIndex(val)}
            value={sliderIndex}
            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
          />
          <div className='slider-btn plus' onClick={() => updateSliderIndex(sliderIndex + increment)}>
            <img alt='plus' src={sliderIndex >= maxShares ? icon.greyplus : icon.greenplus} />
          </div>
        </div>
        <div className='box-row shares-row'>
          <span>{formatDecimal(minShares, false, 0)}</span>
          <span>{formatDecimal(maxShares, false, 0)}</span>
        </div>
        <div className='box-row selling-row'>
          <span>Selling </span><span>{formatDecimal(shares, false, 0)} Shares</span>
        </div>
        <div className='box-row total-row'>
          <span className='total-proceeds'>Total Proceeds: </span>
          <span className='total-price'>{formatDecimal(amount, true, 0)}</span>
        </div>
        <div className='box-row select-row'>
          <select onChange={(e) => setShareClass(e.target.value)}>
            {company.shareClasses && company.shareClasses.length > 0 && company.shareClasses.map((e) =>
              <option key={e} value={e}>{toTitleCase(e)}</option>)}
          </select>
        </div>
      </div>}
    </div>
  )
}

export default OriginationDetailCompanyOffer
