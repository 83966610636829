import { images } from 'assets'
import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'

const InvestDetailSummaryFacts = ({ facts, title, icon }) => {
  const [style, setStyle] = useState('')
  const [showReadMore, setShowReadMore] = useState(false)
  const [readMoreLink, setReadMoreLink] = useState('Read More')

  const containerRef = useRef()
  const maxHeight = isMobile ? 195 : 215
  useEffect(() => {
    if (containerRef.current) {
      if (containerRef.current.offsetHeight > maxHeight) {
        setStyle('maxHeight')
        setShowReadMore(true)
      }
    }
  }, [])

  const setDescription = () => {
    if (readMoreLink === 'Read More') {
      setStyle('')
      setReadMoreLink('Read Less')
    } else {
      setStyle('maxHeight')
      setReadMoreLink('Read More')
    }
  }

  return (
    <div className='fact-container'>
      <img className='icon' alt={title} src={images[icon]} />
      <div className='fact-title'>{title}</div>
      <div className={`fact-items ${style}`} ref={containerRef}>
        {facts && facts.map((p, i) =>
          <div key={`fact${i}`} className='fact-item'>
            {p.fact}
          </div>
        )}
      </div>
      {showReadMore && <a onClick={() => setDescription()}>{readMoreLink}</a>}
    </div>
  )
}
export default InvestDetailSummaryFacts
