import { images } from 'assets'

const UnicornsFilters = ({ countries, changeSearchFilter, sectors, isLoading, debounceFun, searchInputRef, countrySelectRef, sectorSelectRef, sortSelectRef, sector, sort, country, term, setQuery }) => {
  return (
    <div className='unicrons-filters'>
      <div className='ui grid'>
        <div className='select-column column'>
          <select
            ref={sortSelectRef}
            value={sort}
            onChange={(e) => changeSearchFilter(e.target.value, 'sort')}
            disabled={isLoading}>
            <option value='' >Sort by Name</option>
            <option value='VALUATION'>Sort by Valuation</option>
            <option value='MOSAIC'>Sort by Mosaic</option>
          </select>
        </div>
        <div className='select-column column'>
          <select
            ref={sectorSelectRef}
            value={sector}
            onChange={(e) => changeSearchFilter(e.target.value, 'sector')}
            disabled={isLoading}>
            <option value='' >Sector</option>
            {sectors.length > 0 && sectors.map((e, i) => <option value={e} key={`unicorn-sector-${i}`}>{e}</option>)}
          </select>
        </div>
        <div className='select-column column'>
          <select
            value={country}
            ref={countrySelectRef}
            onChange={(e) => changeSearchFilter(e.target.value, 'country')}
            disabled={isLoading}>
            <option value='' >Country</option>
            {countries.length > 0 && countries.map((e, i) => <option value={e} key={`unicorn-country-${i}`}>{e}</option>)}
          </select>
        </div>
        <div className='select-input column'>
          <div className='unicrons-filters-search'>
            <img alt='Search Unicrons' src={images['icon-search']} className='search-btn'/>
            <div className='input-group'>
              <input
                type='text'
                ref={searchInputRef}
                placeholder='Search all Unicorns'
                onChange={(e) => {
                  debounceFun(e.target.value)
                } }
                autoComplete='off'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnicornsFilters
