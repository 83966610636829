import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ investorStatus }) => ({
  requestStatus: investorStatus.requestStatus
})

const requiredActions = ['requestFinancialAdvisor']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
