import { GET_INVEST, GET_INVEST_DETAILS, TOGGLE_INTEREST } from 'types/actionTypes'

const INITIAL_STATE = {
  companies: [],
  isLoggedIn: false,
  needToCompleteInvestorProfile: false,
  verticals: [],
  details: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case GET_INVEST:
    return { ...state, ...action.payload }
  case GET_INVEST_DETAILS:
    return { ...state, details: action.payload }
  case TOGGLE_INTEREST:
    return { ...state, details: { ...state.details, isInterested: !state.details.isInterested } }
  default:
    return state
  }
}
