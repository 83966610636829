import Button from '../../Global/Button'
const AboutGIC = ({ gicImageUrl, gicUrl }) => {
  return (
    <div className='about-gic-container'>
      <div className='inner-container'>
        <div className='about-gic-inner-container'>
          <div className='half left'>
            <div className='section-title'>
              Join Us at the Global Investor Conference
            </div>
            <div className='content'>
            We created GIC to help identify the most outstanding and progressive entrepreneurs building world-changing companies. Industry experts discuss NFTs, the Metaverse, DeFi, Crypto, Gaming, DAOs and so much more. It’s an opportunity to learn from and connect with the brightest minds, both building and investing in tomorrow’s technology.
            </div>
            <Button mode='secondary' size='md' onClick={() => window.open(gicUrl)}>Learn More</Button>
          </div>
          <div className='half right'>
            <img className='gic-image' src={gicImageUrl}></img>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutGIC
