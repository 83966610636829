import React from 'react'
import { Helmet } from 'react-helmet'

const SeoMeta = ({ title, description = '', keywords = '' }) => {
  return (
    <Helmet>‍
      <title>{title}</title>
      {description !== '' && <meta name='description' content={description} />}
      <meta property='og:title' content={title} />
      <meta property='og:url' content={window.location.href} />
      {keywords !== '' && <meta name='keywords' content={keywords} /> }
    </Helmet>
  )
}

export default SeoMeta
