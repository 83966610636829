import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ origination }) => ({
  offerConfirmed: origination.offerConfirmed
})

const requiredActions = ['getOriginationConfirmedOffer']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
