import { useState, useEffect } from 'react'
import Modal from 'components/Global/Modal'
import Input from 'components/Global/InputField'
import Button from 'components/Global/Button'
import Image from 'components/Global/ImageWithLoader'
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { isMobile } from 'react-device-detect'
import { useSelector, useDispatch } from 'react-redux'
import {
  verifySecurityPageMfas,
  sendSecurityPageMfa
} from 'slices/securitySlice'
const header = {
  totp: 'Authenticator App',
  sms: 'SMS Text Message',
  email: 'Email Authentication'
}
const label = {
  totp: [
    'Step 1: Download Authenticator App',
    isMobile
      ? 'Step 2: Add account to your Authenticator app'
      : 'Step 2: Please scan the QR code with your authenticator app.',
    'Step 3: One-time Password'
  ],
  email: ['Please enter the one-time password sent to'],
  sms: [
    'Step 1: Please enter your phone number',
    'Step 2: Please enter the one time setup passcode in the text message'
  ]
}

const notes = {
  email: 'Did not see the email?',
  sms: 'Did not see the text message?'
}

let interval
const MfaModal = ({ userMfaId, hideModal, type, handleAddSMS }) => {
  const [steps, setSteps] = useState(1)
  const [otp, setOtp] = useState('')
  const [formErr, setFormErr] = useState('')
  const [sentTimeStamp, setSentTimeStamp] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [touched, setTouched] = useState(false)
  const { currentMfa, email } = useSelector((state) => state.security)
  const { submitOTPLoading } = useSelector((state) => state.securitySlice)
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      clearInterval(interval)
    }
  }, [])

  const handleOtpSubmit = () => {
    if (otp.length !== 6) {
      setFormErr('The one-time password should be 6 digits.')
    } else if (userMfaId && otp.length === 6) {
      dispatch(verifySecurityPageMfas({ userMfaId, otp })).then(
        ({ meta, payload }) => {
          if (meta.requestStatus === 'fulfilled') {
            hideModal()
          }
          if (meta.requestStatus === 'rejected' && payload) {
            setFormErr(payload.err)
          }
        }
      )
    }
  }

  const handleSendAgain = () => {
    if (submitOTPLoading) {
      return
    }
    clearInterval(interval)
    const date = new Date()
    setSentTimeStamp(date.getTime())
    setCurrentTime(date.getTime())
    dispatch(sendSecurityPageMfa(userMfaId))
    interval = setInterval(() => {
      const newDate = new Date()
      setCurrentTime(newDate.getTime())
    }, 1000)
  }

  const renderResend = () => {
    const seconds = Math.floor((currentTime - sentTimeStamp) / 1000)
    if (sentTimeStamp === 0 || seconds > 30) {
      return <span onClick={handleSendAgain}>Click to send again.</span>
    } else if (seconds < 30 || seconds === 30) {
      return (
        <span className='time-counter'>
          resent {Math.floor((currentTime - sentTimeStamp) / 1000)}s ago
        </span>
      )
    }
  }

  const openAuthenticatorApp = (e) => {
    const otpAuthUrl = currentMfa.otpAuthUrl
    window.open(otpAuthUrl)
    return false
  }

  const handleClickAddSMS = () => {
    if (!phoneNumber || !isValidPhoneNumber(phoneNumber)) {
      setFormErr('Please enter a valid phone number.')
    } else {
      handleAddSMS(phoneNumber)
      setSteps(2)
    }
  }
  const renderButton = () => {
    if (type === 'totp' && steps !== 3) {
      if (steps === 1) {
        return (
          <div className='btn-group centered'>
            <Button mode='primary' size='md' type='submit' onClick={() => setSteps(2)}>
              Next
            </Button>
          </div>
        )
      } else if (steps === 2) {
        return (
          <div className='btn-group centered'>
            <Button mode='primary' size='md' type='submit' onClick={() => setSteps(3)}>
              Next
            </Button>
          </div>
        )
      }
    } else if (type === 'sms' && steps === 1) {
      return (
        <div className='btn-group centered'>
          <Button mode='primary' size='md' type='submit' onClick={handleClickAddSMS}>
            Next
          </Button>
        </div>
      )
    } else {
      return (
        <div className='btn-group centered'>
          <Button
            type='submit'
            mode='primary'
            size='md'
            onClick={handleOtpSubmit}
            disabled={submitOTPLoading}
            loading={submitOTPLoading}
          >
            Confirm
          </Button>
        </div>
      )
    }
  }
  const renderInnerContent = () => {
    if (type === 'totp' && steps !== 3) {
      if (steps === 1) {
        return (
          <p className='content'>
            If you haven&apos;t have an authenticator app already, search
            &apos;Authenticator App&apos; on App Store or Google Play. We
            support all major third-party apps such as Google Authenticator,
            Authy, Microsoft Authenticator.
          </p>
        )
      } else if (steps === 2) {
        if (isMobile) {
          return (
            <p className='mobile-setup-link' onClick={openAuthenticatorApp}>
              Click to set up
            </p>
          )
        } else {
          return (
            <div className='item-group'>
              {currentMfa?.qrCodeUrl && (
                <Image
                  alt='qrCode'
                  src={currentMfa.qrCodeUrl + '?width=200&height=200'}
                />
              )}
            </div>
          )
        }
      }
    } else if (type === 'sms' && steps === 1) {
      return (
        <div
          className={`input-group phone-input ${formErr ? 'error' : null}`}
        >
          <PhoneInput
            international
            countryCallingCodeEditable={false}
            defaultCountry='US'
            value={phoneNumber}
            onChange={(val) => {
              setPhoneNumber(val)
              setFormErr('')
            }}
            autoFocus
            data-testid='phone-input'
          />
          <div className='err-msg'>{formErr}</div>
        </div>
      )
    } else {
      return (
        <Input
          type='number'
          label=''
          input={{ name: 'otp', value: otp }}
          meta={{
            touched: touched,
            error: formErr
          }}
          onChange={(e) => {
            setOtp(e.target.value)
            setFormErr('')
          }}
          onFocus={() => {
            setTouched(true)
          }}
          disabled={submitOTPLoading}
          maxLength='6'
          autoFocus
          autoComplete='one-time-code'
          className='no-label'
          ariaLabel='otp-code'
        />
      )
    }
  }
  const renderNotes = () => {
    if ((type === 'sms' && steps === 2) || type === 'email') {
      return (
        <div className='note'>
          {notes[type]} {renderResend()}
        </div>
      )
    }
  }
  return (
    <Modal
      mode='primary'
      size='sm'
      innerStyle='mfa-modal'
      hideModal={() => hideModal(userMfaId)}
      crossToClose={userMfaId.toString().length > 0}
      modalHeader={header[type]}
    >
      <div className='security mfa'>
        <label>
          {label[type][steps - 1]} {type === 'email' && email}
        </label>
        <div className='content'>
          <form onSubmit={(e) => e.preventDefault()}>
            {renderInnerContent()}
            {renderButton()}
          </form>
        </div>
        {renderNotes()}
      </div>
    </Modal>
  )
}

export default MfaModal
