import LinqtoPortfolioCard from './LinqtoPortfolioCard'
import Button from '../../Global/Button'

const LinqtoPortfolioCompanies = ({ companies, handleChange, companiesList }) => {
  return (
    <div className='inner-container'>
      <div className='container'>
        <div className='btn-group start'>
          <Button mode={companiesList === 'ALL' ? 'primary' : 'secondary'} size='md' onClick={() => handleChange('ALL')}>All</Button>
          <Button mode={companiesList === 'EXITED' ? 'primary' : 'secondary'} size='md' onClick={() => handleChange('EXITED')}>Exits</Button>
        </div>
        <div className='ui grid'>
          <div className='three column row'>
            { companies.length > 0 && companies.map((company, i) => {
              return (
                <LinqtoPortfolioCard key={`linqto-portfolio${i}`} company={company} />)
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LinqtoPortfolioCompanies
