import { useEffect, useState } from 'react'
import Card from './Card.js'
import { PageLoading } from 'components'
import SeoMeta from 'components/Global/SeoMeta'
import { seoTitleTemplate } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { getTeamPage } from 'slices/teamSlice'

const pageDescription =
  'The Linqto team is enabled by technology and powered by teamwork. Learn more about our management team and what makes Linqto different.'
const Team = () => {
  const dispatch = useDispatch()
  const { teamMembers, boardMembers, advisors, pageLoading } = useSelector(
    (state) => state.team
  )
  const [tab, setTab] = useState('team')
  const [activeList, setActiveList] = useState([])
  const nav = {
    team: {
      name: 'LINQTO TEAM',
      list: teamMembers
    },
    board: {
      name: 'OUR BOARD',
      list: boardMembers
    },
    advisor: {
      name: 'ADVISORS',
      list: advisors
    }
  }
  useEffect(() => {
    dispatch(getTeamPage())
  }, [])

  useEffect(() => {
    setActiveList(teamMembers)
  }, [teamMembers])

  const renderPeople = (activeList) => {
    return activeList.map((p) => {
      return <Card people={p} key={p.name}></Card>
    })
  }

  const handleOnClick = (e) => {
    const tab = e.target.getAttribute('tab')
    const activeList = nav[tab].list
    setTab(tab)
    setActiveList(activeList)
  }

  const renderNav = () => {
    return Object.keys(nav).map((key) => (
      <div
        key={key}
        className={`item ${key === tab && 'active'}`}
        tab={key}
        onClick={handleOnClick}
      >
        {nav[key].name}
      </div>
    ))
  }

  if (pageLoading) return <PageLoading />
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Team')} description={pageDescription} />
      <div className='page-container about-container'>
        <div className='inner-container'>
          <div className='top-group'>
            <h1 className='header'>Enabled by tech.</h1>
            <h1 className='header'>Powered by teamwork.</h1>
          </div>
          <div className='team-container'>
            <div className='team-nav'>{renderNav()}</div>
            <div className='team-group'>
              {renderPeople(activeList)}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Team
