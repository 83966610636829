import { useEffect, useState } from 'react'
import qs from 'qs'
import { connect } from 'react-redux'
import { signInWithCode } from '../../actions/KycActions'
import Loading from './pending'
import history from '../../history'
import { images } from 'assets'
import Button from '../Global/Button'

const AutoSignIn = ({ location, signInWithCode }) => {
  const [status, setStatus] = useState('init')

  useEffect(() => {
    const code = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (!code.code || code.code.length === 0) {
      setStatus('failed')
    } else {
      signInWithCode(code)
        .then((res) => {
          if (res === 200) {
            history.push('/identity/auto-verification/1')
          } else {
            setStatus('failed')
          }
        })
    }
  }, [location, signInWithCode])
  if (status === 'failed') {
    return (
      <div className='verify'>
        <div className='status-container'>
          <div className='message'>
            <img alt='activate-failed' src={images.failed} />
            <p>The link has expired.</p>
          </div>
          <Button mode='primary' size='md' onClick={() => history.push('/')}>Back to Home</Button>
        </div>
      </div>
    )
  }
  return (
    <div className='page-container content' style={{ alignItems: 'center', justifyContent: 'center' }}><Loading />You will be redirected soon...</div>
  )
}

export default connect(null, { signInWithCode })(AutoSignIn)
