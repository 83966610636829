import Select from 'react-select'
import { images } from 'assets'
import { Image } from 'components'
import '../../assets/scss/style.scss'

const icon = {
  greenplus: images.greenplus
}

const placeholders = {
  From: 'Please select a bank account',
  To: 'Please select a Uphold card'
}

const baseStyles = {
  control: {
    borderRadius: '7px',
    padding: '4.25px',
    boxShadow: 'none'
  },
  menu: {
    marginTop: 0,
    padding: 0
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0
  },
  group: {
    padding: 0
  },
  singleValue: {
    width: '100%',
    textAlign: 'end',
    padding: '0 1rem'
  },
  placeholder: {
    width: '100%',
    textAlign: 'end',
    padding: '0 1rem'
  }
}

// custom styles for Select option
const customStyles = {
  control: base => ({
    ...base,
    ...baseStyles.control,
    border: '2.5px solid rgb(220, 220, 220)',
    '&:hover': {
      border: '2.5px solid rgb(210, 210, 210)'
    }
  }),
  menu: base => ({
    ...base,
    ...baseStyles.menu
  }),
  menuList: base => ({
    ...base,
    ...baseStyles.menuList
  }),
  group: provided => ({
    ...provided,
    ...baseStyles.group
  }),
  singleValue: (provided) => ({
    ...provided,
    ...baseStyles.singleValue
  }),
  placeholder: (provided) => ({
    ...provided,
    ...baseStyles.placeholder
  })
}

const errorStyles = {
  control: (base) => ({
    ...base,
    ...baseStyles.control,
    border: '2.5px solid rgba(225, 65, 65, 0.8)',
    '&:hover': {
      border: '2.5px solid rgba(225, 65, 65, 0.8)'
    }
  }),
  menu: base => ({
    ...base,
    ...baseStyles.menu
  }),
  menuList: base => ({
    ...base,
    ...baseStyles.menuList
  }),
  group: provided => ({
    ...provided,
    ...baseStyles.group
  }),
  singleValue: (provided) => ({
    ...provided,
    ...baseStyles.singleValue
  }),
  placeholder: (provided) => ({
    ...provided,
    ...baseStyles.placeholder
  })
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '15px 0',
  header: {
    fontWeight: '600',
    fontSize: '18px',
    textTransform: 'capitalize'
  }
}
const formatGroupLabel = data => {
  const { label, hideModal } = data
  return <div style={groupStyles}>
    <span style={groupStyles.header}>{label}</span>
    {data.label === 'Banks' &&
      (<div style={groupStyles.icon} onClick={() => hideModal(false)}>
        <Image alt='plus' src={icon.greenplus} />
      </div>)}
  </div>
}

const Option = props => {
  const { innerProps, innerRef, data } = props
  return (
    <article ref={innerRef} {...innerProps} className='selector'>
      <div>
        <Image alt='plus' src={data.institution ? `data:image/png;base64, ${data.institution.logo}` : data.iconUrl} />
      </div>
      <div className='option'>
        <span className='option-label'>{data.label}</span>
        <span className='option-mask'>{data.accountNumberMasked && data.accountNumberMasked}</span>
      </div>
      <div className='balance'>
        {data.usdBalance >= 0 && <span>${data.usdBalance.toLocaleString('en-US')}</span>}
      </div>
    </article>
  )
}

const renderSelectInput = ({ input, name, id, options, label, meta, disabled = false, ...custom }) => {
  const values = [{
    label: label === 'From' ? 'Banks' : 'My Accounts',
    options: label === 'To' ? options.filter(o => o.currency === 'USD') : [...options],
    hideModal: label === 'From' ? custom.hideModal : null
  }]

  const handleChange = (value) => {
    if (value.accountNumberMasked && !value.label.includes('-')) {
      value.label = `${value.label} - ${value.accountNumberMasked}`
    }
    input.onChange(value)
  }

  return (
    <div>
      <label className='select-label'>{label}</label>
      <Select
        {...input}
        styles={meta.touched && !input.value ? errorStyles : customStyles}
        maxMenuHeight={200}
        options={values}
        components={{ Option, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        placeholder={placeholders[label]}
        id={id}
        name={name}
        value={input.value}
        onChange={(value) => handleChange(value)}
        onBlur={(value) => input.onBlur()}
        formatGroupLabel={formatGroupLabel}
        isDisabled={disabled}
      />
      {(meta.touched && meta.error) && <div className='err-msg'>{meta.error}</div>}
    </div>)
}

export default renderSelectInput
