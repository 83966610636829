import { GET_UNICORNS, GET_UNICORN_DETAILS, POST_INTERESTED_INUNICORN } from 'types/actionTypes'

const INITIAL_STATE = {
  company: {},
  isLoggedIn: false,
  companies: [],
  sectors: [],
  countries: [],
  totalUnicorns: 1,
  totalValuations: 1,
  hasMore: true
}
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case GET_UNICORNS:
    return { ...state, ...action.payload }
  case GET_UNICORN_DETAILS:
    return { ...state, ...action.payload }
  case POST_INTERESTED_INUNICORN:
    return { ...state, ...action.payload }
  default:
    return state
  }
}
