import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ order, user, common }) => ({
  company: order.company,
  order: order.order,
  documents: order.documents,
  uphold: order.uphold,
  message: order.message,
  isSignedIn: user.isSignedIn,
  spinnerVisible: common.spinnerVisible
})

const requiredActions = ['getOrderDetail', 'messageTrader', 'resetLoading', 'showLoadingSpinner', 'hideLoadingSpinner']
const actions = mapActions(requiredActions)
const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
