import { formatDecimal } from 'utils'

const RewardHeader = ({ bucks, isLoggedIn }) => {
  return (
    <div className='rewards-header'>
      <h1 className='page-title'>Linqto Bucks Referral Program</h1>
      {isLoggedIn && <div className='my-bucks'>My Bucks: {formatDecimal(bucks)}</div>}
    </div>
  )
}

export default RewardHeader
