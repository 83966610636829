import { useState } from 'react'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { Row, Image } from 'components'
import { images } from 'assets'
import { formatDecimal, formatWholeNumber } from 'utils'

const icon = {
  greenplus: images.greenplus,
  greenminus: images.greenminus,
  greyplus: images.greyplus,
  greyminus: images.greyminus
}

const OrderDetails = ({ products, isSignedIn, setAmount, setProductId, entities, entityId, setEntityId, profileEntites, spinnerVisible }) => {
  const [sliderIndex, setSliderIndex] = useState(0)
  const [selectedProduct, setSelectedProduct] = useState(products[0])
  const [amountUsd, setAmountUsd] = useState(Math.ceil(selectedProduct.minimumPurchase / selectedProduct.sharePrice) * selectedProduct.sharePrice)

  if (!isSignedIn) return null

  const firstProduct = products[0]
  const lastProduct = products[products.length - 1]

  const availableShares = firstProduct.availableShares
  const increment = firstProduct.purchaseIncrement
  const minPurchase = firstProduct.minimumPurchase
  const minSharePrice = lastProduct.sharePrice
  // Round the MaxPurchase down to the nearest whole share
  const maxPurchase = Math.floor(availableShares * minSharePrice / selectedProduct.sharePrice) * selectedProduct.sharePrice
  const sliderOptionsCount = Math.ceil((maxPurchase - minPurchase) / increment)

  // This is called when someone drags the slider AND when someone clicks the Plus/Minus buttons
  const updateSliderIndex = (index) => {
    // If it is out of bounds, don't allow it
    if (index < 0 || index > sliderOptionsCount) {
      return
    }

    // Compute the Dollar Amount and the Selected Product
    let amount = minPurchase + (increment * index)
    if (amount > maxPurchase) {
      amount = maxPurchase
    }
    let selectedProduct = products[0]
    for (let i = products.length - 1; i >= 0; --i) {
      if (amount >= products[i].minimumPurchase) {
        selectedProduct = products[i]
        break
      }
    }

    // Round the Amount up to the nearest whole share
    amount = Math.round(Math.ceil(amount / selectedProduct.sharePrice) * selectedProduct.sharePrice * 100) / 100
    if (amount > maxPurchase) {
      amount = maxPurchase
    }

    // Set the values
    setAmountUsd(amount)
    setAmount(amount)
    setSliderIndex(index)
    setSelectedProduct(selectedProduct)
    setProductId(selectedProduct.productId)
  }
  return (
    <>
      <Row className='invest-order-row'>
        <span>Amount in USD</span>
        <span className='price'>{formatDecimal(amountUsd)}</span>
      </Row>
      <Row className='invest-order-row '>
        <div className='slider-btn' onClick={() => updateSliderIndex(sliderIndex - 1)}>
          <Image alt='minus' src={sliderIndex <= 0 ? icon.greyminus : icon.greenminus} />
        </div>
        <Slider
          className='slider'
          railStyle={{ height: '5px' }}
          handleStyle={{ border: 'none', height: '30px', width: '30px', marginTop: '-13px', boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)' }}
          trackStyle={{ backgroundColor: '#077187', height: '5px' }}
          min={0}
          max={sliderOptionsCount}
          step={1}
          defaultValue={0}
          onChange={val => updateSliderIndex(val)}
          value={sliderIndex}
          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
        />
        <div className='slider-btn' onClick={() => updateSliderIndex(sliderIndex + 1)}>
          <Image alt='plus' src={sliderIndex >= sliderOptionsCount ? icon.greyplus : icon.greenplus} />
        </div>
      </Row>
      <Row className='invest-order-row'>
        <span>Estimated shares</span>
        <span>{formatWholeNumber((amountUsd / selectedProduct.sharePrice))}</span>
      </Row>
      {entities && entities.length > 0 &&
        <Row className='invest-order-row top-aligned'>
          <label>Purchase as</label>
          <select value={entityId} onChange={(e) => setEntityId(e.target.value)} disabled={spinnerVisible}>
            {profileEntites && profileEntites.length > 0 && profileEntites.map((e) => <option value={e.entityId} key={e.name}>{e.name}</option>)}
          </select>
        </Row>
      }
    </>
  )
}

export default OrderDetails
