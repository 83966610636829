const ContactSupport = () => {
  return (
    <div className='contact-support-container'>
      Have a question?  <a href='/contact' target='_blank'> Contact Support
      </a>
    </div>
  )
}

export default ContactSupport
