import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ KYC }) => ({
  country: KYC.country,
  legalName: KYC.legalName,
  birthDay: KYC.birthDay,
  birthMonth: KYC.birthMonth,
  birthYear: KYC.birthYear,
  microblinkVerified: KYC.microblinkVerified,
  selfieUploaded: KYC.selfieUploaded,
  address: KYC.address,
  microblinkLicenseKey: KYC.microblinkLicenseKey
})
const requiredActions = ['getIdentity', 'saveIdScanData', 'uploadSelfie', 'confirmKycStatusVerified', 'restartKycProcess', 'logEvent']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)
export default connected
