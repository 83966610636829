import React, { useState } from 'react'
import Modal from '../Global/Modal'
import Button from '../Global/Button'
import Webcam from 'react-webcam'
import Pending from './pending'

const videoConstraints = {
  width: '640',
  height: '360',
  aspectRatio: 0.75
}

const CameraModal = ({ hideModal, updateCapturePhoto, fileName, handleImageCapture, loading }) => {
  const webcamRef = React.useRef(null)
  const [capturedImg, setCaptureImg] = useState(null)
  const [capturedFile, setCaptureFile] = useState(null)
  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot({
      height: 1080,
      width: 1920
    })
    window.fetch(imageSrc)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], 'testingimg.jpg', { type: 'image/jpg', path: 'testingimg.jpg' })
        const url = URL.createObjectURL(file)
        setCaptureImg(url)
        setCaptureFile(file)
      })
  }
  const reset = () => {
    setCaptureImg(null)
    setCaptureFile(null)
  }
  return (
    <Modal mode='primary' size='md' pressToClose crossToClose clickToClose hideModal={hideModal} modalHeader='Take Photo'>
      <div>
        {(!capturedFile && loading !== 'loading') &&
          <Webcam
            videoConstraints={videoConstraints}
            audio={false}
            ref={webcamRef}
            screenshotQuality={1}
            forceScreenshotSourceSize
            screenshotFormat='image/jpeg'
          />}
        {(capturedImg && loading !== 'loading') && <img style={{ width: '640px', height: '360px' }} alt='captured-img' src={capturedImg} />}
        {loading === 'loading' && <div className='pending-container'><Pending /></div>}
        <div className='btn-group centered'>
          {!capturedFile && <Button mode='primary' size='md' onClick={capture}>Capture photo</Button>}
          {capturedFile && <Button mode='secondary' size='md' onClick={() => reset()} disabled={loading === 'loading'}>Retake Photo</Button>}
          {capturedFile && <Button mode='primary' size='md' onClick={() => handleImageCapture(capturedFile, fileName)} disabled={loading === 'loading'} loading={loading === 'loading'}>Confirm Photo</Button>}
        </div>
      </div>
    </Modal>
  )
}

export default CameraModal
