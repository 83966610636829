const label = {
  PENDING: {
    color: 'yellow',
    value: 'Pending'
  },
  COMPLETED: {
    color: 'teal',
    value: 'Completed'
  },
  TRANSFERRING: {
    color: 'olive',
    value: 'Transferring'
  },
  CANCELED: {
    color: 'red',
    value: 'Canceled'
  },
  FUNDED: {
    color: 'orange',
    value: 'Funded'
  },
  CLOSED: {
    color: 'grey',
    value: 'Closed'
  }
}
const StatusTag = ({ status }) => {
  return (
    <div className={`label ${label[status].color}`}>
      {label[status].value}
    </div>
  )
}

export default StatusTag
