import { Wrapper } from 'components'
import InvestDetailValuationRounds from './InvestDetailValuationRounds'
import InvestDetailValuationHeader from './InvestDetailValuationHeader'
import InvestDetailValuationOverTime from './InvestDetailValuationOverTime'
import InvestDetailValuationCompetitive from './InvestDetailValuationCompetitive'

const InvestDetailValuation = ({ isSignedIn, company, isSoldOut }) => {
  return (
    <Wrapper className={`invest-tab ${!isSignedIn ? 'blur-info' : ''}`} >
      <div className='ui grid outer-container'>
        <InvestDetailValuationHeader postMoney={company.postMoney} lastRound={company.lastRound} totalRaised={company.totalRaised} status= {company.status} isSoldOut={isSoldOut} impliedValuation={company.impliedValuation}/>
        <div className='one column mobile-one row desktop-content no-padding-bottom'>
          <div className='column'>
            <div className='row section-seprator' />
          </div>
        </div>
        <div className='one column mobile-one row outer-container'>
          <div className='mobile-grey'>
            <div className='column'>
              <span className='dark full-line mobile-title'>Funding Rounds</span>
              <InvestDetailValuationRounds fundingRounds={company.fundingRounds} />
            </div>
          </div>
        </div>
        {company.valuationOverTime && company.valuationOverTime.length > 0 && <div className='one column mobile-one row outer-container'>
          <div className='column'>
            <span className='dark full-line mobile-title'>Valuation Over Time</span>
            <InvestDetailValuationOverTime valuationOverTime={company.valuationOverTime} />
          </div>
        </div>}
        <div className='one column mobile-one row outer-container'>
          <div className='column'>
            {company.competitors && company.competitors.length > 0 &&
            <>
              <span className='dark full-line mobile-title'>Competitive Landscape</span>
              <InvestDetailValuationCompetitive competitors={company.competitors} />
            </>}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
export default InvestDetailValuation
