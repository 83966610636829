import Button from '../../Global/Button'
const OriginationDetailCompanyCTA = ({ company, history, shares, shareClass }) => {
  return (
    <div className={`section-nar origination-detail-cta ${!company.isLoggedIn ? 'detail-not-logged' : ''}`}>
      {company.isLoggedIn && <Button
        mode='primary'
        size='md'
        customClass='place-order-button'
        onClick={() =>
          history.push({
            pathname: '/sell/offerconfirmation',
            search: `?companyUrlName=${company.urlName}&shares=${shares}&shareClass=${shareClass}`
          })}
      >Continue</Button>
      }
      {!company.isLoggedIn && <Button
        mode='primary'
        size='md'
        customClass='place-order-button'
        onClick={() => {
          localStorage.setItem('prev-route', window.location.pathname)
          history.push('/signup', { fromOrigination: true, urlName: company.urlName })
        }}
      > Sign Up to Continue</Button>
      }
    </div>
  )
}

export default OriginationDetailCompanyCTA
