import privateAPI from '../apis/private.js'
import { GET_LINQTO_PORTFOLIO } from 'types/actionTypes'
import { handleServerError } from './CommonActions'

export const getLinqtoPortfolio = () => (dispatch) => {
  return privateAPI
    .get('/page/linqtoPortfolio')
    .then(res => {
      dispatch({
        type: GET_LINQTO_PORTFOLIO,
        payload: { ...res.data }
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}
