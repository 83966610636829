import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ origination }) => ({
  offer: origination.offer,
  interestedInBuying: origination.interestedInBuying
})

const requiredActions = ['saveOriginationOrder', 'getOriginationOffer', 'submitOriginationOffer']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
