import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ portfolio, user, common }) => ({
  companies: portfolio.companies,
  recentOrders: portfolio.recentOrders,
  isSignedIn: user.isSignedIn,
  spinnerVisible: common.spinnerVisible
})

const requiredActions = ['getPortfolio', 'showLoadingSpinner', 'hideLoadingSpinner']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
