import { Row } from 'components'
import { formatDecimal } from 'utils'
import Input from '../../Global/InputField'

const LinqtoBucksCheckbox = ({ maxBucksPercent, setUseLinqtoBucks, useLinqtoBucks, disabled, linqtoBucks, investAmount }) => {
  const showLinqtoPercUsed = (maxBucksPercent > 0 && maxBucksPercent < 1) && (linqtoBucks >= (investAmount * maxBucksPercent))
  if (maxBucksPercent === 0) {
    return (
      <Row className='invest-order-row linqto-bucks-not-used'>*Linqto Bucks cannot be used for this order.</Row>
    )
  }
  if (maxBucksPercent > 0) {
    return (
      <Row className='invest-order-row check-box-row'>
        <div className={`linqto-checkbox-container ${showLinqtoPercUsed ? 'half-row' : 'full-row'}`}>
          <Input type='checkbox' name='useLinqtoBucks' onChange={(e) => setUseLinqtoBucks(e)} value={useLinqtoBucks} disabled={disabled} className='linqto-bucks'>
            Apply Linqto Bucks
          </Input>
          <span className='linqto-bucks-available'>{formatDecimal(linqtoBucks, true, 0)} available</span>
        </div>
        { useLinqtoBucks && showLinqtoPercUsed &&
          <div className='linqto-bucks-percent'>*Linqto Bucks can be used for {maxBucksPercent * 100}% of the order.</div>
        }
      </Row>
    )
  }
}
export default LinqtoBucksCheckbox
