import privateAPI from '../apis/private.js'
import { GET_PORTFOLIO, GET_PRODUCT_ORDERS } from 'types/actionTypes'
import parseOrders from './parsing/parseOrders'
import { handleServerError } from './CommonActions'

export const getPortfolio = () => (dispatch) => {
  return privateAPI
    .get('/page/portfolio')
    .then(res => {
      dispatch({
        type: GET_PORTFOLIO,
        payload: { ...res.data, recentOrders: parseOrders(res.data.recentOrders) }
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const getProductOrders = (id) => dispatch => {
  return privateAPI
    .get(`/page/portfolio/${id}`)
    .then(res => {
      dispatch({
        type: GET_PRODUCT_ORDERS,
        payload: { ...res.data, filterOrders: parseOrders(res.data.recentOrders) }
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}
