import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ invest }) => ({
  companies: invest.companies,
  isLoggedIn: invest.isLoggedIn,
  needToCompleteInvestorProfile: invest.needToCompleteInvestorProfile,
  verticals: invest.verticals
})
const requiredActions = ['getInvest']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)
export default connected
