import { useHistory } from 'react-router-dom'
import Modal from '../../Global/Modal'
import Button from '../../Global/Button'

const SuccessModal = () => {
  const history = useHistory()
  return (
    <>
      <Modal
        mode='primary'
        size='md'
        crossToClose
        modalHeader=''
        innerStyle='success-modal'
        hideModal={() => history.push('/security')}
      >
        <div className='content'>
          Your request to delete has been sent to our team and they will respond
          within 2 business days.
        </div>
        <div className='btn-group centered'>
          <Button mode='primary' onClick={() => history.push('/security')}>
            Okay
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default SuccessModal
