import { images } from 'assets'
const ProfileStatus = ({ isUserProfileComplete }) => {
  const profileStatusImg = isUserProfileComplete ? images['profile-complete'] : images['profile-incomplete']
  return (
    <div className='profile-status'>
      <img alt='profile status' src={profileStatusImg}/>
      {isUserProfileComplete ? 'Complete' : 'Incomplete'}
    </div>
  )
}

export default ProfileStatus
