import { useState } from 'react'
import { truncate } from 'utils'

const UnicornCompanyDescription = ({ description }) => {
  const maxCharLength = 260
  const [truncatedDescription, setTruncatedDescription] = useState(truncate(description, maxCharLength))
  const [readMoreLink, setReadMoreLink] = useState('Read More')

  const setDescription = () => {
    if (readMoreLink === 'Read More') {
      setTruncatedDescription(description)
      setReadMoreLink('Read Less')
    } else {
      setTruncatedDescription(truncate(description, maxCharLength))
      setReadMoreLink('Read More')
    }
  }
  return (
    <>
      <div className='page-header-company-desc'>{truncatedDescription}</div>
      {description && description.length > maxCharLength &&
      <span onClick={() => setDescription()}>{readMoreLink}</span>
      }
    </>
  )
}

export default UnicornCompanyDescription
