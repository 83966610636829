import { SET_LOADING_SPINNER, HIDE_LOADING_SPINNER, CONTACT_US, RESET_LOADING, GET_EBOOK, GET_WIRE_INSTRUCTION, SYSTEM_ERROR, GET_PRIVACY, GET_TERMS_OF_USE, GET_FAQ_LINKS, GET_REWARDS_TERMS_OF_USE, GET_CONTACT_US, UPLOAD_ERROR } from 'types/actionTypes'

const INITIAL_STATE = {
  spinnerVisible: false,
  status: 'init',
  isLoggedIn: false,
  isAccredited: false,
  hasUpholdWallet: false,
  downloadUrl: '',
  wirePage: '',
  systemErr: false,
  privacyPage: '',
  termsOfUsePage: '',
  rewardsTermsOfUsePage: '',
  faqs: [],
  title: '',
  addresses: [],
  uploadErr: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case SET_LOADING_SPINNER:
    return { ...state, spinnerVisible: action.payload }
  case HIDE_LOADING_SPINNER:
    return { ...state, spinnerVisible: action.payload }
  case CONTACT_US:
    return { ...state, ...action.payload }
  case RESET_LOADING:
    return { ...state, status: INITIAL_STATE.status }
  case GET_EBOOK:
    return {
      ...state,
      ...action.payload
    }
  case GET_WIRE_INSTRUCTION:
    return {
      ...state,
      ...action.payload
    }
  case GET_PRIVACY:
    return { ...state, ...action.payload }
  case GET_TERMS_OF_USE:
    return { ...state, ...action.payload }
  case SYSTEM_ERROR:
    return { ...state, ...action.payload }
  case GET_FAQ_LINKS:
    return { ...state, ...action.payload }
  case GET_REWARDS_TERMS_OF_USE:
    return { ...state, ...action.payload }
  case GET_CONTACT_US:
    return { ...state, addresses: [...action.payload] }
  case UPLOAD_ERROR:
    return { ...state, ...action.payload }
  default:
    return state
  }
}
