import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ origination }) => ({
  companies: origination.companies
})

const requiredActions = ['searchOrigination']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
