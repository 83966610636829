import { useState, useEffect } from 'react'
import ConditionalRender from '../ConditionalRender'
import { useDropzone } from 'react-dropzone'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { uploadDocument } from '../../../actions/CommonActions'
import {
  renderFileName,
  RenderLoadingUpload,
  RenderErrorUpload,
  RenderSuccessUpload,
  RenderIntitalUpload
} from './renderer'

const Upload = ({
  type = '',
  query = '',
  name = '',
  placeHolder = isMobile ? 'Upload file' : 'Drag & drop your file here',
  enableView = false,
  allowDelete = false,
  autoUpload = false, // upload file once it's dropped in the dropzone
  fileUploaded = [],
  setFileUploaded = () => {},
  onDropComplete = null,
  onDelete = null,
  url = '', // document get back from API and render
  ariaLabel = '',
  callAfterSuccessUpload
}) => {
  const [validationErr, setValidationErr] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [uploadErrorMessage, setUploadErrorMessage] = useState('')
  const [uploadErrorTitleMessage, setUploadErrorTitleMessage] = useState('')
  const [fileUploading, setFileUploading] = useState(false)
  const [progress, setProgress] = useState(0)
  const dispatch = useDispatch()
  const { uploadErr } = useSelector((state) => state.common)

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    onDrop: (acceptedFiles) => completeDrop(acceptedFiles),
    onDragEnter: () => setDragging(true),
    onDragLeave: () => setDragging(false),
    accept: '.pdf, .png, .jpg, .jpeg',
    onDropRejected: (fileRejections) => UploadRejected(fileRejections)
  })

  const completeDrop = (acceptedFiles) => {
    if (onDropComplete) {
      // if onDrop complete function passed to the compoment, just exceude it
      onDropComplete(acceptedFiles)
    } else {
      // if no onDrop sent, check if it's autoupload to dispatch the upload action
      if (acceptedFiles.length > 0) {
        if (autoUpload) {
          setFileUploading(true)
          dispatch(
            uploadDocument(
              acceptedFiles[0],
              renderFileName(name, acceptedFiles),
              type,
              query,
              loadProgress
            )
          ).then((res) => {
            if (res === 200 && callAfterSuccessUpload) {
              callAfterSuccessUpload()
            }
            setFileUploading(false)
            setFileUploaded(acceptedFiles)
          })
        } else {
          // if it's not auto upload just set the fileUplod from parent component
          setFileUploaded(acceptedFiles)
        }
      }
    }
  }

  const UploadRejected = (fileRejections) => {
    // setting a specific error message when file uploading is not supported
    if (fileRejections[0]?.errors[0]?.code === 'file-invalid-type') {
      setUploadErrorTitleMessage('Upload Failed: File Not Supported')
      setUploadErrorMessage('Please upload a .png, .jpg, .jpeg or .pdf file.')
    } else {
      setUploadErrorTitleMessage('Upload Failed')
      setUploadErrorMessage(fileRejections[0]?.errors[0]?.message)
    }
    setValidationErr(true)
  }

  useEffect(() => {
    // render server error
    if (uploadErr) {
      setFileUploaded('')
      setValidationErr(true)
      setUploadErrorTitleMessage('Upload Failed: Please Try Again')
    }
  }, [uploadErr])

  const handleDelete = (e) => {
    setValidationErr(false)
    setFileUploaded('')
    setUploadErrorTitleMessage('')
    setUploadErrorMessage('')
    if (onDelete) {
      onDelete()
    }
  }

  const loadProgress = (progressEvent) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded / progressEvent.total)
    )
    setProgress(percentCompleted)
  }
  return (
    <div className='upload-container-new'>
      <ConditionalRender
        isVisible={
          url.length === 0 &&
          fileUploaded.length === 0 &&
          !validationErr &&
          !fileUploading
        }
      >
        <RenderIntitalUpload
          placeHolder={placeHolder}
          dragging={dragging}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          open={open}
        />
      </ConditionalRender>
      <ConditionalRender isVisible={fileUploading}>
        {<RenderLoadingUpload progress={progress} testId={name}/>}
      </ConditionalRender>
      <ConditionalRender isVisible={fileUploaded.length > 0 && !validationErr}>
        <RenderSuccessUpload
          enableView={enableView}
          name={name}
          fileUploaded={fileUploaded}
          handleDelete={handleDelete}
          ariaLabel={ariaLabel}
        />
      </ConditionalRender>
      <ConditionalRender isVisible={validationErr}>
        <RenderErrorUpload
          enableView={enableView}
          uploadErrorTitleMessage={uploadErrorTitleMessage}
          uploadErrorMessage={uploadErrorMessage}
          handleDelete={handleDelete}
        />
      </ConditionalRender>
      <ConditionalRender isVisible={url.length > 0 &&
          !fileUploading}>
        <RenderSuccessUpload
          enableView={enableView}
          name={name}
          fileUploaded={fileUploaded}
          handleDelete={handleDelete}
          url={url}
          allowDelete={allowDelete}
          ariaLabel={ariaLabel}
        />
      </ConditionalRender>
    </div>
  )
}

export default Upload
