import { images } from 'assets'
import Button from '../../Global/Button'

const Steps = ({ history, isLoggedIn, isAccredited }) => {
  return (
    <div className='container steps-container'>
      <div className='inner-container'>

        <div className='hero-header steps-header'>
          3 Simple Steps to Invest
        </div>
        <div className='steps'>
          <div className='step-container'>
            <img className='icon' alt='verify' src={images.verify} />
            <div className='step-title'>Verify</div>
          </div>
          <div className='line-container'>
            <div className='line'></div>
          </div>
          <div className='step-container'>
            <img className='icon choose' alt='browse' src={images.choose} />
            <div className='step-title'>Choose</div>
          </div>
          <div className='line-container'>
            <div className='line'></div>
          </div>
          <div className='step-container'>
            <img className='icon' alt='browse' src={images.invest} />
            <div className='step-title'>Invest</div>
          </div>
        </div>

        <div className='btn-group centered'>
          {(isLoggedIn && isAccredited) &&
              <Button
                mode='primary'
                size='md'
                onClick={() => history.push('/products')}
              >Invest Now</Button>}
          {(isLoggedIn && !isAccredited) &&
              <Button
                mode='primary'
                size='md'
                onClick={() => history.push('/investor-status')}
              >Get Verified</Button>}
          {!isLoggedIn && <Button
            mode='primary'
            size='md'
            onClick={() => history.push('/investor-status')}
          >Get Verified</Button>}
        </div>
      </div>
    </div>
  )
}

export default Steps
