import { formatDate, formatDecimal } from 'utils'

const ReasonMessage = {
  SIGNUP: 'Referral - Sign Up',
  SIGNUP_CANCELED: 'Referral - Sign Up - Canceled',
  REFEREE_INITIAL_PURCHASE: 'Referral - First Purchase',
  REFEREE_INITIAL_PURCHASE_CANCELED: 'Referral - First Purchase - Canceled',
  SIGNIFICANT_INVESTOR_PURCHASE: 'Significant Investor Purchase',
  SIGNIFICANT_INVESTOR_PURCHASE_CANCELED: 'Significant Investor Purchase - Canceled',
  PURCHASE: 'Purchase',
  PURCHASE_CANCELED: 'Purchase - Canceled',
  SUPPORT: 'Support'
}

const RewardHistoryItem = ({ transaction }) => {
  return (
    <div className='order'>
      <div className='ui stackable padded grid'>
        <div className='three wide column'>
          <div className='sm-label'>DATE</div>
          <div className='text-left'>{formatDate(transaction.createdAt)}</div>
        </div>
        <div className='four wide column' valign='middle'>
          <div className='sm-label'>EVENT</div>
          <div className='text-left'>{ReasonMessage[transaction.reason] || ReasonMessage.SUPPORT}</div>
        </div>
        <div className='two wide column'>
          <div className='sm-label'>AMOUNT</div>
          <div className='total'>{transaction.amount < 0 && '-'}{formatDecimal(Math.abs(transaction.amount))}</div>
        </div>
        <div className='one column'></div>
        <div className='six wide column'>
          <div className='sm-label'>NOTE</div>
          <div className='text-left'>{transaction.note}</div>
        </div>
      </div>
    </div>
  )
}

export default RewardHistoryItem
