import React from 'react'
import Upload from './Upload'
import { OptionalDocuments as connected } from '../../containers'
class OptionalDocuments extends React.Component {
  renderDocuments = () => {
    return this.props.documents.map(doc => {
      return <Upload key={doc.name} {...doc}/>
    })
  }

  render () {
    return (
      <div className='required-container'>
        <div className='info-title option info-group'>
          <div>Optional Documents {this.props.documents.length > 0 && this.props.accreditedStatus === 'NOT_VERIFIED' && <span className=''>(your documents have been received and are in review)</span>}</div>
        </div>
        {this.props.documents.length > 0 && this.renderDocuments()}
        <Upload name='Click to upload' url='' type='AI' query=''/>
      </div>
    )
  }
}

export default connected(OptionalDocuments)
