import { Wrapper, Image, Row } from 'components'
import { formatDecimal } from 'utils'
import { images } from 'assets'

const LinqtoBalance = ({ balance }) => {
  return (
    <Wrapper className='uphold-balance linqto-balance'>
      <Row className='uphold-account uphold-disabled bucks-account'>
        <div className='left-group'>
          <Image alt='Linqto Bucks' src={images['bucks-icon']} />
          <span className='currency'>Linqto Bucks</span>
        </div>
        <div><span className='balance'>{formatDecimal(balance)}</span>{' '}</div>
      </Row>
    </Wrapper>)
}

export default LinqtoBalance
