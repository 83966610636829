import { formatInvestmentValue } from 'utils'

const LinqtoPortfolioHeader = ({ investorPurchases, completedOrdersTotal, liquidSharesPurchases, companiesTotal }) => {
  return (
    <div className='page-header'>
      <div className='inner-container'>
        <h1 className='page-header-title'>Linqto Liquidshares Portfolio </h1>
        <div className='page-header-facts'>
          <div>
            <div className='column page-header-fact-item'>
              <div className='page-header-fact-number'>{formatInvestmentValue(liquidSharesPurchases, false)}</div>
            </div>
            <div className='column page-header-fact-item'>
              <div className='page-header-fact-number'>{formatInvestmentValue(investorPurchases, false)}</div>
            </div>
            <div className='column page-header-fact-item'>
              <div className='page-header-fact-number'>{formatInvestmentValue(completedOrdersTotal, true, true, 1)} </div>
            </div>
          </div>
          <div>
            <div className='column page-header-fact-item'>
              <div className='page-header-fact-desc'>Investments in {companiesTotal} Companies</div>
            </div>
            <div className='column page-header-fact-item'>
              <div className='page-header-fact-desc'>Member Investments</div>
            </div>
            <div className='column page-header-fact-item'>
              <div className='page-header-fact-desc'>Amount of Investments</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LinqtoPortfolioHeader
