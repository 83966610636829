const InvestDetailSummaryHeader = ({ setMosaicShowModal, company }) => {
  return (
    <>
      <div className='two column mobile-one row'>
        <div className='column'>
          <div className='ui grid'>
            <div className='two column mobile-two row'>
              <div className='column'>
                <div className='row'>
                  <span className='semi-bold dark full-line mobile-title'>Industry</span>
                  <span>{company.industry && company.industry.industry}</span>
                </div>
              </div>
              <div className='column'>
                <div className='row'>
                  <span className='semi-bold dark full-line mobile-title'>Primary Vertical</span>
                  <span>{company.vertical}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='column'>
          <div className='row'>
            <span className='semi-bold dark full-line mobile-spaced mobile-title'>Mosaic Score</span>
            {company.mosaic && company.mosaic.overall && <span className='half-line'>{company.mosaic.overall}</span>}
            {company.mosaic && company.mosaic.money && <span className='half-line'>Money - {company.mosaic.money}</span>}
            {company.mosaic && company.mosaic.momentum && <span className='half-line'>Momentum - {company.mosaic.momentum}</span>}
            {company.mosaic && company.mosaic.management && <span className='half-line'>Management - {company.mosaic.management}</span>}
            {company.mosaic && company.mosaic.market && <span className='half-line'>Market - {company.mosaic && company.mosaic.market}</span>}
            {company.mosaic && <span className='under-line dark full-line line-space semi-bold' onClick={() => setMosaicShowModal(true) }>What is a Mosaic Score?</span>}
          </div>
        </div>
      </div>
      <div className='one column mobile-one row'>
        <div className='column'>
          <span className='semi-bold dark mobile-title'>Employees:</span> <span>{company.employeeCount}</span>
        </div>
      </div>
    </>
  )
}
export default InvestDetailSummaryHeader
