import { useEffect } from 'react'
import SeoMeta from 'components/Global/SeoMeta'
import AboutVideo from './partials/AboutVideo'
import AboutCards from './partials/AboutCards'
import Leaderships from './partials/Leaderships'
import AboutGIC from './partials/AboutGIC'
import AboutHiring from './partials/AboutHiring'
import { PageLoading } from 'components'
import { images } from 'assets'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { getAboutPage } from 'slices/aboutSlice'
import Image from 'components/Global/ImageWithLoader'

const pageTitle = 'About Linqto | The Leader in Private Equity Investing'
const pageDescription =
  'The Linqto team is enabled by technology and powered by teamwork. Learn more about our management team and what makes Linqto different.'
const headerImage = isMobile ? images['about-hero-mobile'] : images['about-hero-desktop']

const About = () => {
  const dispatch = useDispatch()
  const { pageLoading, aboutUsYouTubeUrl, boardMembers, teamLeaders, gicImageUrl, gicUrl } = useSelector(state => state.about)

  useEffect(() => {
    dispatch(getAboutPage())
  }, [])

  if (pageLoading) return <PageLoading />
  return (
    <>
      <SeoMeta title={pageTitle} description={pageDescription} />
      <div className='about-container'>
        <Image src={headerImage} className='about-hero'
          alt='About Hero'/>
        <AboutVideo videoUrl={aboutUsYouTubeUrl}/>
        <AboutCards />
        <Leaderships boardMembers={boardMembers} teamLeaders={teamLeaders}/>
        <AboutGIC gicImageUrl={gicImageUrl} gicUrl={gicUrl}/>
        <AboutHiring />
      </div>
    </>
  )
}

export default About
