import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ user, common }) => ({
  isSignedIn: user.isSignedIn,
  downloadUrl: common.downloadUrl
})
const requiredActions = ['getEbook']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)
export default connected
