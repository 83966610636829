import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ wallet }) => ({
})
const requiredActions = ['upholdLinkPlaidAccounts', 'getWalletPageAch']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)
export default connected
