import { Image } from 'components'
import history from '../../../history'

const CardHeader = ({ status, urlName, websiteUrl, children }) => {
  if (status === 'OPEN') {
    return (<a onClick={ () => history.push(`/products/${urlName}`)} >{children}</a>)
  } else {
    if (websiteUrl) {
      return (<a href={websiteUrl} target='_blank' rel='noopener noreferrer' >{children}</a>)
    } else {
      return (<>{children}</>)
    }
  }
}

const LinqtoPortfolioCard = ({ company }) => {
  return (
    <div className={`column two card-container-${company.status}`}>
      <CardHeader status={company.status} websiteUrl={company.websiteUrl} urlName={company.urlName}>
        <div className={`card CARD-${company.status}`}>
          <Image src={`${window.location.origin}${company.logoUrl}`} alt={company.companyName} />
          { (company.status === 'EXITED' || status === 'CLOSED') && <div className='card-status'>Exited</div> }
          { company.status === 'EXITING' && <div className='card-status'>Exiting</div> }
        </div>
      </CardHeader>
    </div>
  )
}

export default LinqtoPortfolioCard
