import { GET_FINANCIAL_ADVISOR } from 'types/actionTypes'

const INITIAL_STATE = {
  advisorName: '',
  allowDocumentDelete: true,
  documents: [],
  financialAdvisorRequestId: '',
  firstName: '',
  isAccredited: false,
  lastName: '',
  showVerificationForm: false,
  country: '',
  financialAdvisorAttestation: {
    description: '',
    options: [
      {
        accreditationType: '',
        optionId: '',
        optionText: ''
      }
    ]
  },
  initialValues: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case GET_FINANCIAL_ADVISOR:
    return { ...state, ...action.payload, initialValues: { country: action.payload.country } }
  default:
    return state
  }
}
