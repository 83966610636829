import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ reward, user, common }) => ({
  isSignedIn: user.isSignedIn,
  spinnerVisible: common.spinnerVisible,
  transactions: reward.transactions,
  linqtoBucks: reward.linqtoBucks,
  referralUrl: reward.referralUrl,
  signUpBonus: reward.signUpBonus,
  firstOrderBonus: reward.firstOrderBonus,
  significantInvestorMinimum: reward.significantInvestorMinimum,
  significantInvestorPercent: reward.significantInvestorPercent,
  isLoggedIn: reward.isLoggedIn
})

const requiredActions = ['getReward', 'showLoadingSpinner', 'hideLoadingSpinner']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
