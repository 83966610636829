import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ user, investorStatus, financialAdvisor }) => ({
  isSignedIn: user.isSignedIn,
  uploading: investorStatus.uploadingStatus,
  AIDelete: investorStatus.allowDocumentDelete,
  FADelete: financialAdvisor.allowDocumentDelete
})

const requiredActions = ['getInvestorStatus', 'deleteAIDocs', 'deleteFADocs', 'getFinancialAdvisor', 'signOut', 'uploadDocs', 'getIdentity', 'deleteIDDocs']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
