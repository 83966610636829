import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import EventBanner from './partials/EventBanner'
import Hero from './partials/Hero'
import Steps from './partials/Steps'
import TopListing from './partials/TopListings'
import Uphold from './partials/Uphold'
import Feature from './partials/Feature'
import AppIntro from './partials/AppIntro'
import LinqtoBucks from './partials/LinqtoBucks'
import LinqtoUnicrons from './partials/LinqtoUnicrons'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading } from 'components'
import { connectUphold } from 'actions/WalletActions'
import { getHomePage } from 'slices/homeSlice'

const Landing = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pageLoading, homeProducts, hasUpholdWallet, promotedCompany, gicDate, gicEndDate, securityScorecard, isLoggedIn, isAccredited } = useSelector(state => state.home)
  const { unicornsEnabled, rewardsEnabled } = useSelector(state => state.user)
  const pageTitle = 'Private Equity Investing | Linqto Private Investing'
  const pageDescription = 'Identify, evaluate, and invest in the world\'s leading Unicorns. You\'re three simple steps away from private equity investing, learn more with Linqto.'
  const upholdAction = !isLoggedIn ? () => history.push('/signin') : hasUpholdWallet ? () => history.push('/wallet') : connectUphold()
  const isCurrent = gicDate > Date.now()

  useEffect(() => {
    dispatch(getHomePage())
  }, [])

  if (pageLoading) {
    return <>
      <SeoMeta title={pageTitle} description={pageDescription} />
      <PageLoading />
    </>
  }

  return (
    <>
      <SeoMeta title={pageTitle} description={pageDescription} />
      <div className='landing-container'>
        {(gicDate && isCurrent) &&
          <EventBanner gicDate={gicDate} gicEndDate={gicEndDate}/>
        }
        <Hero
          history={history}
          promotedCompany={promotedCompany} />
        <Steps
          history={history}
          isLoggedIn={isLoggedIn}
          isAccredited={isAccredited} />
        {homeProducts && <TopListing
          homeProducts={homeProducts}
          history={history} />
        }
        {unicornsEnabled &&
          <LinqtoUnicrons
            linqtoUnicornAction={() => history.push('/market-insights')} />
        }
        {rewardsEnabled &&
          <LinqtoBucks
            isSignedIn={isLoggedIn}
            linqtoBucksAction={() => history.push('/rewards')} />
        }
        <Uphold
          upholdText={!hasUpholdWallet ? 'Connect Uphold Wallet' : 'Go to Wallet'}
          upholdAction={upholdAction} />
        <Feature securityScorecard={securityScorecard}/>
        <AppIntro />
      </div>
    </>
  )
}

export default Landing
