import renderer from './renderer'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

let AddressInput = ({
  formValues,
  section,
  category,
  redirect,
  renderDropdown,
  pCountry,
  mCountry,
  sameAddress,
  disabled,
  countries,
  primaryRegions,
  mailingRegions,
  getRegions,
  setMailingRegions,
  setPrimaryRegions,
  tooltip,
  resetFeildValue
}) => {
  return (
    <>
      <div className='ui grid padded full-width'>
        {!sameAddress && <>
          {category === 'Mailing' &&
        <div className='sixteen wide column tablet_sixteen'>
          <hr/>
          <h3>Mailing Addresss</h3>
        </div>}
          <div className='eight wide column tablet_eight'>
            <Field
              name={`${section}Street1`}
              component={renderer.renderInput}
              label='Address Line 1'
              type='text'
              disabled ={disabled}
              redirect={redirect}
              required={redirect}
            />
          </div>
          <div className='eight wide column tablet_eight'>
            <Field
              name={`${section}Street2`}
              component={renderer.renderInput}
              label='Address Line 2'
              type='text'
              disabled ={disabled}
              redirect={redirect}
            />
          </div>
          <div className='eight wide column tablet_eight'>
            <Field
              name={`${section}City`}
              component={renderer.renderInput}
              label='City'
              type='text'
              disabled ={disabled}
              redirect={redirect}
              required={redirect}
            />
          </div>
          <div className='eight wide column tablet_eight'>
            <Field
              name={`${section}Zip`}
              component={renderer.renderInput}
              label='Postal Code'
              type='text'
              disabled ={disabled}
              redirect={redirect}
              required={redirect}
            />
          </div>
          <div className='eight wide column tablet_eight'>
            <Field
              countries={countries}
              name={`${section}Country`}
              component={renderDropdown}
              label={category === 'Primary' ? 'Country of Residence' : 'Country'}
              redirect={redirect}
              getRegions={getRegions}
              onChange={() => { resetFeildValue(`${section}State`, '') }}
              setRegions={category === 'Primary' ? setPrimaryRegions : setMailingRegions}
              disabled ={disabled}
              tooltip={category === 'Primary'}
              content={category === 'Primary' ? tooltip.pCountry : ''}
            />
          </div>
          <div className='eight wide column tablet_eight'>
            <Field
              regions={category === 'Mailing' ? mailingRegions : primaryRegions}
              name={`${section}State`}
              component={renderDropdown}
              label='State or Province'
              redirect={redirect}
              disabled ={disabled}
              country={category === 'Mailing' ? mCountry : pCountry}
              getRegions={getRegions}
              setRegions={category === 'Mailing' ? setMailingRegions : setPrimaryRegions}
            />
          </div>
        </>}
      </div>
    </>
  )
}

AddressInput = reduxForm({
  form: 'editForm'
})(AddressInput)

const selector = formValueSelector('editForm')
AddressInput = connect(state => {
  const pCountry = selector(state, 'pCountry')
  const mCountry = selector(state, 'mCountry')
  return {
    pCountry,
    mCountry
  }
})(AddressInput)

export default AddressInput
