import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ profile, user, form }) => {
  return {
    initialValues: profile.initialValues,
    user: user.user,
    userProfile: profile.userProfile,
    entity: profile.entity || {},
    formValues: form.editForm,
    enableReinitialize: true,
    // keepDirtyOnReinitialize: true,
    isSignedIn: user.isSignedIn,
    entities: profile.userProfile.entities || [],
    hasCompletedOrder: profile.hasCompletedOrder,
    brokerInfos: profile.brokerInfos
  }
}
const requiredActions = ['editProfile', 'getUserProfile', 'getCountries', 'getRegions', 'submitEntityCreation']
const actions = mapActions(requiredActions)
const connected = component => reduxConnect(mapStateToProps, actions)(component)
export default connected
