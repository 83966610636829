import renderer from '../Profile/partials/renderer'
import { Field } from 'redux-form'

const AddressInput = ({
  redirect,
  renderDropdown,
  disabled,
  setRegions,
  regions,
  setCountry,
  countries,
  getRegions,
  formValues,
  resetFeildValue
}) => {
  return (
    <>
      <div className='eight wide column tablet_eight'>
        <Field
          name='street1'
          component={renderer.renderInput}
          label='Company Street Address Line 1'
          type='text'
          disabled={disabled}
          redirect={redirect}
        />
      </div>
      <div className='eight wide column tablet_eight'>
        <Field
          name='street2'
          component={renderer.renderInput}
          label='Address Line 2'
          type='text'
          disabled={disabled}
          redirect={redirect}
        />
      </div>
      <div className='eight wide tablet four wide computer column'>
        <Field
          countries={countries}
          name='country'
          component={renderDropdown}
          label='Country'
          redirect={redirect}
          disabled={disabled}
          onChange={() => {
            resetFeildValue('state', '')
          }}
          getRegions={getRegions}
          setRegions={setRegions}
          regions={regions}
        />
      </div>
      <div className='eight wide tablet four wide computer column'>
        <Field
          regions={regions}
          name='state'
          component={renderDropdown}
          label='State or Province'
          redirect={redirect}
          disabled={disabled}
          setRegions={setRegions}
          placeholder='Select One'
        />
      </div>
      <div className='eight wide tablet four wide computer column'>
        <Field
          name='city'
          component={renderer.renderInput}
          label='City'
          type='text'
          disabled={disabled}
          redirect={redirect}
        />
      </div>
      <div className='eight wide tablet four wide computer column'>
        <Field
          name='postalCode'
          component={renderer.renderInput}
          label='Postal Code'
          type='text'
          disabled={disabled}
          redirect={redirect}
        />
      </div>
    </>
  )
}

export default AddressInput
