import { images } from 'assets'
const AboutCard = ({ src, alt, title, description }) => {
  return (
    <div className='about-card'>
      <img src={images[`${src}`]} className='card-img'/>
      <div className='title'>{title}</div>
      <div className='description'>{description}</div>
    </div>
  )
}

export default AboutCard
