import React, { useState, useEffect } from 'react'
import Upload from './Upload'
import _ from 'lodash'

const DOC = [
  {
    name: 'Last year W2',
    url: '',
    type: 'AI',
    query: ''
  },
  {
    name: 'Previous year W2',
    url: '',
    type: 'AI',
    query: ''
  },
  {
    name: 'Last year tax returns',
    url: '',
    type: 'AI',
    query: ''
  },
  {
    name: 'Previous year tax returns',
    url: '',
    type: 'AI',
    query: ''
  },
  {
    name: 'Last year 1099',
    url: '',
    type: 'AI',
    query: ''
  },
  {
    name: 'Previous year 1099',
    url: '',
    type: 'AI',
    query: ''
  }
]

const RequiredDocuments = ({ type, documents, uploadLoading, accreditedStatus }) => {
  const [files, setFiles] = useState({})
  const [fileStatus, setFileStatus] = useState(true)

  useEffect(() => {
    const doc = DOC.map(d => {
      d.url = ''
      return d
    })

    if (type === 'ANNUAL_INCOME' || type === 'ENTITY') {
      documents.forEach((c) => {
        _.find(doc, (o) => {
          if (c.name === o.name) {
            o.url = c.url
          }
        })
      })
      setFiles({
        yourDoc: doc
      })
    } else if (type === 'JOINT_INCOME' && !fileStatus) {
      const spouseDoc = _.cloneDeep(doc).map((ele) => {
        ele.name = `Spouse ${ele.name}`
        return ele
      })
      doc.splice(2, 0, spouseDoc[0])
      doc.splice(3, 0, spouseDoc[1])
      doc.splice(6, 0, spouseDoc[2])
      doc.splice(7, 0, spouseDoc[3])
      doc.splice(10, 0, spouseDoc[4])
      doc.splice(11, 0, spouseDoc[5])
      documents.forEach((c) => {
        _.find(doc, (o) => {
          if (c.name === o.name) {
            o.url = c.url
          }
        })
        _.find(spouseDoc, (s) => {
          if (c.name === s.name) {
            s.url = c.url
          }
        })
      })
      setFiles({
        yourDoc: doc.slice(0)
      })
    } else if (type === 'JOINT_INCOME' && fileStatus) {
      const spouseDoc = _.cloneDeep(doc).map((ele) => {
        ele.name = `Spouse ${ele.name}`
        return ele
      })
      doc.splice(2, 0, spouseDoc[0])
      doc.splice(3, 0, spouseDoc[1])
      doc.splice(10, 0, spouseDoc[4])
      doc.splice(11, 0, spouseDoc[5])
      documents.forEach((c) => {
        _.find(doc, (o) => {
          if (c.name === o.name) {
            o.url = c.url
          }
        })
        _.find(spouseDoc, (s) => {
          if (c.name === s.name) {
            s.url = c.url
          }
        })
      })
      setFiles({
        yourDoc: doc.slice(0)
      })
    }
  }, [type, documents, fileStatus])

  const renderUpload = () => {
    const yourDoc = files.yourDoc
    const spouseDoc = files.spouseDoc || []
    if (type === 'JOINT_INCOME' && !fileStatus) {
      return [
        <div key='your Docs' className='doc-header' />,
        yourDoc.map((ele, id) => {
          if (id !== 0 && id % 4 === 0) {
            return (
              <React.Fragment key={ele.name + id}>
                <div className='ui horizontal divider'>Or</div>
                <Upload {...ele} uploadLoading={uploadLoading} />
              </React.Fragment>
            )
          }
          return (
            <React.Fragment key={ele.name + id}>
              <Upload {...ele} uploadLoading={uploadLoading} />
            </React.Fragment>
          )
        })
      ]
    } else if (type === 'JOINT_INCOME' && fileStatus) {
      return [
        <div key='your Docs' className='doc-header' />,
        yourDoc.map((ele, id) => {
          if (id === 4 || id === 6) {
            return (
              <React.Fragment key={ele.name + id}>
                <div className='ui horizontal divider'>Or</div>
                <Upload {...ele} uploadLoading={uploadLoading} />
              </React.Fragment>
            )
          }
          return (
            <React.Fragment key={ele.name + id}>
              <Upload {...ele} uploadLoading={uploadLoading} />
            </React.Fragment>
          )
        })
      ]
    } else {
      return [
        <div key='your Docs' className='doc-header' />,
        yourDoc.map((ele, id) => {
          if (id !== 0 && id % 2 === 0) {
            return (
              <React.Fragment key={ele.name + id}>
                <div className='ui horizontal divider'>Or</div>
                <Upload {...ele} uploadLoading={uploadLoading} />
              </React.Fragment>
            )
          }
          return (
            <React.Fragment key={ele.name + id}>
              <Upload {...ele} uploadLoading={uploadLoading} />
            </React.Fragment>
          )
        }),
        <div key='spouse Docs' className='doc-header'>
          {spouseDoc.length > 0 && 'Spouse Documents'}
        </div>,
        spouseDoc.map((ele, id) => {
          if (id !== 0 && id % 2 === 0) {
            return (
              <React.Fragment key={ele.name + id}>
                <div className='ui horizontal divider'>Or</div>
                <Upload {...ele} uploadLoading={uploadLoading} />
              </React.Fragment>
            )
          }
          return (
            <React.Fragment key={ele.name + id}>
              <Upload {...ele} uploadLoading={uploadLoading} />
            </React.Fragment>
          )
        })
      ]
    }
  }

  return (
    <div className='required-container'>
      <div className='info-title option info-group'>
        <div>Required Documents {documents.length > 0 && type !== 'FOREIGN_INVESTOR' && type !== 'NET_WORTH' && accreditedStatus === 'NOT_VERIFIED' && <span className=''>(your documents have been received and are in review)</span>}</div>
      </div>
      {type === 'JOINT_INCOME' && (
        <div className='file-group'>
          <div
            className={`file-btn ${fileStatus && 'selected'}`}
            onClick={() => setFileStatus(true)}
          >
            File Jointly
          </div>
          <div
            className={`file-btn ${!fileStatus && 'selected'}`}
            onClick={() => setFileStatus(false)}
          >
            File Seperately
          </div>
        </div>
      )}
      {Object.keys(files).length > 0 && renderUpload()}
    </div>
  )
}

export default RequiredDocuments
