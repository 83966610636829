import { Wrapper, Content, Image } from 'components'
import { images } from 'assets'
import { Link } from 'react-router-dom'
import Button from '../../Global/Button'

const LinqtoBucks = ({ isSignedIn, linqtoBucksAction }) => (
  <Wrapper className='container linqto-container'>
    <div className='inner-container'>
      <Image alt='Linqto Bucks Referral Program' src={images['linqto-bucks']} />
      <Content className='text-content'>
        <div className='linqto-header'>Linqto Bucks Referral Program</div>
        <div className='linqto-sub-header'>
        1 Referral with 2 Rewards.
        </div>
        <div className='linqto-content'>
            As an accredited member, refer friends and you both get Linqto Bucks.
        </div>
        <div className='linqto-content'>
            It&apos;s that simple.
        </div>
        <Button mode='primary' size='md' onClick={linqtoBucksAction}>Refer a Friend</Button>
        {!isSignedIn && <div className='linqto-signup'>Not an accredited member yet? <Link to='/signup'>Sign up first.</Link></div>}
      </Content>
    </div>
  </Wrapper>
)

export default LinqtoBucks
