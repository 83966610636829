import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ placeOrder, common }) => ({
  confirmation: placeOrder.confirmation,
  spinnerVisible: common.spinnerVisible
})

const requiredActions = ['getPortfolio', 'getOrderConfirmation', 'showLoadingSpinner', 'hideLoadingSpinner']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)

export default connected
