import { BaseModal } from 'components'
import Button from '../../Global/Button'
import history from '../../../history'

const OriginationOfferNotAvailableModal = () => {
  return (
    <BaseModal mode='primary' size='md' innerStyle='origination-modal' modalHeader='No Longer Purchasing'>
      <div className='content'>
        Thank you for your interest in selling your shares to Linqto. For this company, we have just met our quota and are no longer purchasing.
      </div>
      <div className='content'>
        Please check back soon.
      </div>
      <div className='btn-group centered'>
        <Button mode='primary' size='md' onClick={() => history.push('/')}>Return to Home</Button>
      </div>
    </BaseModal>
  )
}

export default OriginationOfferNotAvailableModal
