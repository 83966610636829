import { useEffect, useState } from 'react'
import qs from 'qs'
import { images } from 'assets'
import Button from '../../Global/Button'
import Input from '../../Global/InputField'
import history from '../../../history'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'

const renderInput = ({ input, label, placeholder, meta, type, autoComplete, className, disabled, children, viewPassword, disableLabel }) => {
  return (
    <Input input={input} meta={meta} label={label} placeholder={placeholder} type={type} autoComplete={autoComplete} className={className} disabled={disabled} viewPassword={viewPassword} disableLabel={disableLabel}>{children}</Input>
  )
}
const ConfirmResetPassword = ({ actions, password, confirmPassword, mfaRequired, handleSubmit }) => {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    const code = qs.parse(history.location.search, { ignoreQueryPrefix: true })
    const response = await actions.resetPassword({ ...code, password })
    if (response === 200) {
      setStatus('success')
    } else {
      setStatus('failed')
    }
  }

  useEffect(() => {
    if (password === confirmPassword && (password !== undefined && confirmPassword !== undefined)) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [password, confirmPassword])

  const renderContent = () => {
    switch (status) {
    case 'success':
      return (
        <div className='reset-container'>
          <img
            alt='email-success'
            src={images.success}
            className='reset-icon'
          ></img>
          <p>You have successfully reset your password.</p>
          {mfaRequired && <div className='btn-group centered'>
            <Button mode='primary' size='md' onClick={() => history.push('/signin')}>Sign in</Button>
          </div>}
        </div>
      )
    case 'failed':
      return (
        <>
          <div className='reset-container'>
            <img
              alt='email-success'
              src={images.failed}
              className='reset-icon'
            ></img>
            <p>The link has expired.</p>
          </div>
        </>
      )
    default:
      return (
        <div className='page-container'>
          <div className='inner-container'>
            <form className='ui grid' onSubmit={handleSubmit(onSubmit)}>
              <h2 className='modal-title'>Reset your password</h2>
              <div className='sixteen wide column'>
                <Field
                  name='password'
                  component={renderInput}
                  label='New Password'
                  disabled={loading}
                  type={showPassword ? 'text' : 'password'}
                  viewPassword={() => setShowPassword(prev => !prev)}
                />
              </div>
              <div className='sixteen wide column'>
                <Field
                  name='confirmPassword'
                  component={renderInput}
                  label='Confirm Password'
                  disabled={loading}
                  type={showConfirmPassword ? 'text' : 'password'}
                  viewPassword={() => setShowConfirmPassword(prev => !prev)}
                />
              </div>
              <div className='sixteen wide column'>
                <div className='btn-group start'>
                  <Button mode='primary' disabled={loading || disabled} loading={loading}>Reset Password</Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Confirm Reset Password')} />
      <div className='reset-password-container'>{renderContent()}</div>
    </>
  )
}

const validate = (formValues) => {
  const errors = {}
  const regex = /\d+/g
  if (!formValues.password) {
    errors.password = 'Please enter a new password.'
  }
  if (formValues.password && formValues.password.length < 7) {
    errors.password = 'Password must be at least 8 characters long.'
  }
  if (!formValues.confirmPassword) {
    errors.confirmPassword = 'Please confirm your password.'
  }
  if (formValues.password && !formValues.password.match(regex)) {
    errors.password = 'Password must contain at least one number.'
  }
  if (formValues.password !== formValues.confirmPassword) {
    errors.confirmPassword = 'Confirm password should match the new password above.'
  }
  return errors
}

let formWrapped = reduxForm({
  form: 'resetPasswordForm',
  validate,
  enableReinitialize: true
})(ConfirmResetPassword)

const selector = formValueSelector('resetPasswordForm')
formWrapped = connect(state => ({
  password: selector(state, 'password'),
  confirmPassword: selector(state, 'confirmPassword')
}))(formWrapped)

export default formWrapped
