const InvestHeader = () => {
  return (
    <>
      <h1 className='page-title'>Invest</h1>
      <div className='content desktop-content'>
      We&apos;ve done the research and selected some of the best private tech companies so that you can invest in them through the Linqto platform. Review our proprietary analysis - now with data powered by CB Insights - and invest in high-valuation start-ups, or even unicorns, before they go to the public market.
      </div>
      <div className='content mobile-content tablet-content'>
        Linqto has done the research and hand-selected the following companies especially for our investors.
      </div>
    </>
  )
}

export default InvestHeader
