
const transaction = {
  COMPLETED: 'Your transaction is complete.',
  PENDING: 'Your transaction will not be complete until your Accredited Investor status has been validated and you have completed your wire transfer.',
  FUNDED: 'Your transaction will not be complete until your Accredited Investor status has been validated.',
  TRANSFERRING: 'Please visit our wire instruction page for wire transfer details.'
}

const parseOrderConfirmed = (data) => {
  return {
    order: {
      orderId: data.order.orderId,
      transactionStatus: transaction[data.order.status]
    },
    portfolio: data.companies,
    accStatus: data.order.status
  }
}

export default parseOrderConfirmed
