import React from 'react'

const PageLoading = () => (
  <div className='ui segment' style={{ height: '90vh' }}>
    <div className='ui active inverted dimmer'>
      <div className='ui large text loader'>Loading</div>
    </div>
  </div>
)
export default PageLoading
