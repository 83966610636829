import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ user }) => ({
  resetErr: user.resetErr
})

const requiredActions = ['resetPasswordEmail', 'resetLoading']
const actions = mapActions(requiredActions)

const connected = (compoenent) =>
  reduxConnect(mapStateToProps, actions)(compoenent)
export default connected
