import { useState } from 'react'
import { ResetModal as connected } from 'containers'
import { BaseModal } from 'components'
import Button from '../Global/Button'
import Input from '../Global/InputField'

const ResetModal = (props) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [systemError, setSystemError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [touched, setTouched] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    if (email.length === 0) {
      setError('Please enter a valid email.')
      setLoading(false)
      setTouched(true)
    } else {
      setTouched(false)
      setError('')
      props.actions.resetPasswordEmail(email)
        .then((status) => {
          setLoading(false)
          if (status === 200) {
            setSuccess(true)
            setSystemError(false)
          } else if (status === 'err') {
            setSystemError(true)
            setSuccess(false)
          }
        })
    }
  }

  const handleChange = (e) => {
    setTouched(false)
    setSystemError(false)
    setEmail(e.target.value)
  }

  const handleFocus = () => {
    setTouched(true)
    setSystemError(false)
  }

  const renderContent = () => {
    return (
      <>
        {!success && <div className='reset-password-modal'>
          <Input type='email' label='Email' input={{ value: email }} meta={{ touched, error }} onChange={(e) => handleChange(e)} onFocus={() => handleFocus()} disabled={loading}/>
        </div>}
        {success && <><div className='content'>
            Email successfully sent.<br/> Please check to reset your password.
        </div><div className='btn-group left'>
          <Button mode='primary' size='md' onClick={props.hideModal}>Ok</Button>
        </div></>}
        {systemError && <div className='ui error message'>
          <div className='header'>Please try again.</div>
          <p>Something went wrong</p>
        </div>}
        {!success && <div className='btn-group left'>
          <Button mode='primary' size='md' type='submit' loading={loading} disabled={loading}>Submit</Button>
        </div>}
      </>
    )
  }

  return (
    <BaseModal
      mode='primary'
      size='sm'
      modalHeader='Reset Password'
      innerStyle='reset-modal'
      hideModal={() => props.hideModal()}
      clickToClose pressToClose crossToClose
    >
      <form onSubmit={onSubmit}>
        {renderContent()}
      </form>
    </BaseModal>
  )
}

export default connected(ResetModal)
