import { SIGN_UP, RESET_ERR, SIGN_IN, SIGN_OUT, UPDATE_LOADING, RESET_PASSWORD, PREFILL_EMAIL, GET_CORE_USER, GET_SIGNIN_MFAS, GET_SIGNUP_REFERRAL, GET_BRANCH_DATA } from 'types/actionTypes'
import privateAPI from '../apis/private.js'
import publicAPI from '../apis/public.js'
import { handleServerError } from './CommonActions'
import parsingCoreUser from './parsing/parsingCoreUser'
import branch from 'branch-sdk'
import { identify } from '../functions'
import * as Sentry from '@sentry/react'

export const getCoreUser = () => dispatch => {
  privateAPI.get('/core')
    .then(res => {
      dispatch({
        type: GET_CORE_USER,
        payload: parsingCoreUser(res.data)
      })

      // NOTE: set User and Tags for sentry
      const userId = res.data.userId
      const appInstallId = res.data.appInstallId
      localStorage.setItem('appInstallId', appInstallId)
      if (userId) {
        Sentry.setUser({ id: userId })
      }
      Sentry.setTag('appInstallId', appInstallId)

      if (res.data.email) {
        identify(res.data.email)
      }
      if (res.data.branchKey) {
        let branchData = {}
        branch.init(res.data.branchKey, {}, function (err, data) {
          if (err) {
            console.log('branch err', err)
          } else {
            branch.getBrowserFingerprintId((err, data) => {
              if (err) {
                console.log('getBrowserFingerprintId[err]', err)
              } else {
                branchData = JSON.parse(localStorage.getItem('branch_session_first')) || {}
                branchData.browser_fingerprint_id = data
                branchData.branchKey = res.data.branchKey
                branchData.http_origin = window.location.href
                branchData.user_agent = navigator.userAgent
                branchData.screen_width = window.screen.width
                branchData.screen_height = window.screen.height
                branchData.language = navigator.language
                dispatch(saveBranchData(branchData))
              }
            })
          }
        })
      }
      if (!res.data.isLoggedIn) {
        localStorage.removeItem('linqto_token')
      }
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const saveBranchData = (branchData) => dispatch => {
  privateAPI.post('/saveBranchData', { branchData })
    .then(res => {
      dispatch({
        type: GET_BRANCH_DATA,
        payload: { branchData }
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const signUp = (formValues) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_LOADING,
    payload: { status: 'loading' }
  })
  const { branchData } = getState().user
  return publicAPI
    .post('/register', { ...formValues, branchData })
    .then((res) => {
      dispatch({
        type: UPDATE_LOADING,
        payload: { status: 'success' }
      })
      dispatch({
        type: SIGN_UP,
        payload: res.data
      })
    })
    .catch((err) => {
      if (err.response.data && err.response.status === 500) {
        dispatch({
          type: UPDATE_LOADING,
          payload: { status: 'failed' }
        })
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      }
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return err.response.status
    })
}

export const resetErr = () => dispatch => {
  dispatch({
    type: RESET_ERR
  })
}

export const signIn = (formValues) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_LOADING,
    payload: { status: 'loading' }
  })
  const { branchData } = getState().user
  return publicAPI
    .post('/signIn', { ...formValues, branchData })
    .then((res) => {
      dispatch({
        type: SIGN_IN,
        payload: res.data
      })

      // NOTE: set User and Tags for sentry
      const userId = res.data.userId
      const appInstallId = localStorage.getItem('appInstallId')
      if (userId) {
        Sentry.setUser({ id: userId })
      }
      Sentry.setTag('appInstallId', appInstallId)

      // NOTE: set User in Hubspot
      if (res.data.email) {
        identify(res.data.email)
      }
      return res.data
    })
    .catch((err) => {
      if (err.response && err.response.status === 403) {
        dispatch({
          type: SIGN_IN,
          payload: {
            errMsg: 'Wrong email or password',
            err: err.response.status
          }
        })
        return 403
      }
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const getSigninMfas = () => dispatch => {
  return privateAPI
    .get('/page/security/signIn')
    .then((res) => {
      dispatch({
        type: GET_SIGNIN_MFAS,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const sendSignInMfa = (userMfaId) => dispatch => {
  return privateAPI
    .post(`/page/security/signIn/mfas/${userMfaId}/send`)
    .then(() => {
    })
    .catch(err => {
      if (err) {
        return { err: 'Something went wrong.' }
      }
    })
}

export const verifySignInMfa = (userMfaId, otp) => dispatch => {
  return privateAPI
    .post(`/page/security/signIn/mfas/${userMfaId}/verify`, { userMfaId, otp })
    .then((res) => {
      if (res.status === 200) {
        return 'success'
      }
    })
    .catch(err => {
      if (err.response && err.response.data.error === 'MFA_VERICATION_INCORRECT') {
        return { err: 'The one time password is incorrrect. Try again.' }
      }
      dispatch(handleServerError(err.response.data.error))
    })
}

export const signOut = () => (dispatch) => {
  return privateAPI
    .post('/signOut')
    .then((res) => {
      dispatch({
        type: SIGN_OUT
      })
      localStorage.removeItem('linqto_token')
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const resetPasswordEmail = (email) => dispatch => {
  return privateAPI.post('/resetPassword', { email })
    .then(res => {
      return 200
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return 'err'
    })
}

export const resetPassword = (info) => dispatch => {
  return privateAPI.post('/confirmResetPassword', info)
    .then(res => {
      if (res.data.email) {
        identify(res.data.email)
      }
      dispatch({
        type: RESET_PASSWORD,
        payload: { ...res.data, isSignedIn: true }
      })
      return 200
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}

export const prefillEmail = email => dispatch => {
  dispatch({
    type: PREFILL_EMAIL,
    payload: { prefillEmail: email }
  })
}

export const getSignupReferral = referralCode => async (dispatch) => {
  const url = `/register?referralCode=${referralCode}`
  return publicAPI
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_SIGNUP_REFERRAL,
        payload: { ...res.data }
      })
      return res.data
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
}
