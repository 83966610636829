import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'actions/CommonActions'
import api from '../apis/public.js'
import parseConfirmOrder from 'actions/parsing/parseConfirmOrder'
import parseErrorMessages from 'actions/parsing/parseErrorMessages'
const initialState = {
  pageLoading: false,
  company: {
    companyId: 0,
    iconUrl: '',
    logoUrl: '',
    name: '',
    s3IconUrl: '',
    url: ''
  },
  documents: [
    {
      name: '',
      url: ''
    }
  ],
  isAccredited: true,
  isUpholdOtpRequired: true,
  order: {
    amount: 0,
    entityName: '',
    linqtoBucksUsed: 0,
    linqtoFee: 0,
    msToLive: 0,
    paymentType: 'WIRE',
    productId: 0,
    sharePrice: 0,
    shares: 0,
    temporaryUpholdTransactionId: 0,
    total: 0,
    upholdCardCurrency: '',
    upholdCardId: '',
    upholdCardLabel: '',
    upholdCommission: 0,
    upholdExchangeRate: 0,
    upholdFee: 0
  },
  placeOrderError: {
    type: '',
    message: ''
  },
  commitLoading: false,
  orderId: 0
}

export const confirmOrder = createAsyncThunk('confirmOrder', ({ upholdId, companyId, type, amount, productId, entityId, useLinqtoBucks }, { dispatch, fulfillWithValue, rejectWithValue }) => {
  const body = {
    companyId: companyId,
    paymentType: type,
    upholdCardId: upholdId,
    productId: productId,
    amount,
    useLinqtoBucks: useLinqtoBucks === 'true'
  }
  if (entityId !== 'individual') {
    body.entityId = entityId
  }
  return api
    .post('/page/confirmOrder', body)
    .then((res) => {
      return fulfillWithValue(parseConfirmOrder(res.data))
    })
    .catch(err => {
      if (err.response && err.response.status === 500) {
        const data = err.response.data
        return rejectWithValue(parseErrorMessages(data))
      } else {
        dispatch(handleServerError(err.response.data.error))
        return rejectWithValue(err.response)
      }
    })
})

export const commitOrder = createAsyncThunk('commitOrder', ({ productId, type, amount, temporaryUpholdTransactionId, upholdOtpToken, entityId, useLinqtoBucks }, { dispatch, fulfillWithValue, rejectWithValue }) => {
  const body = {
    productId,
    amount,
    paymentType: type,
    upholdOtpToken,
    temporaryUpholdTransactionId,
    useLinqtoBucks: useLinqtoBucks === 'true'
  }
  if (entityId !== 'individual') {
    body.entityId = entityId
  }
  return api
    .post('/page/confirmOrder/commit', body)
    .then(res => {
      return fulfillWithValue({ orderId: res.data.orderId })
    })
    .catch(err => {
      if (err.response && err.response.status === 500) {
        const data = err.response.data
        return rejectWithValue(parseErrorMessages(data))
      } else {
        dispatch(handleServerError(err.response.data.error))
        return rejectWithValue(err.response)
      }
    })
})

export const placeOrderSlice = createSlice({
  name: 'placeOrderSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(confirmOrder.pending, (state) => {
        if (state?.order?.paymentType === 'UPHOLD') {
          state.pageLoading = false
        } else {
          state.pageLoading = true
        }
      })
      .addCase(confirmOrder.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(confirmOrder.rejected, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(commitOrder.pending, (state) => {
        state.commitLoading = true
      })
      .addCase(commitOrder.fulfilled, (state, { payload }) => {
        return {
          ...state,
          commitLoading: false,
          ...payload
        }
      })
      .addCase(commitOrder.rejected, (state, { payload }) => {
        return {
          ...state,
          commitLoading: false,
          ...payload
        }
      })
  }
})

export default placeOrderSlice.reducer
