import { useEffect, useState } from 'react'
import { SignIn as connected } from 'containers'
import { Field, reduxForm } from 'redux-form'
import ResetModal from './ResetModal'
import Button from '../Global/Button'
import MfaModal from './MfaModal'
import history from '../../history'
import render from './render'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'

const SignIn = ({ match, isSignedIn, actions, errMsg, handleSubmit, mfaRequired }) => {
  const [loading, setLoading] = useState(false)
  const [showResetModal, setShowResetModal] = useState(false)
  const [showMfaModal, setShowMfaModal] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (mfaRequired) {
      setShowMfaModal(true)
    } else {
      history.push('/signin')
    }
  }, [])

  useEffect(() => {
    return () => {
      actions.resetErr()
    }
  }, [actions])

  const onSubmit = (formValues) => {
    setLoading(true)
    actions.signIn(formValues)
      .then((res) => {
        setLoading(false)
        if (res !== 403) {
          if (res.mfaRequired) {
            setShowMfaModal(true)
            history.push('/signin/mfas')
          } else {
            localStorage.getItem('prev-route')
            if (localStorage.getItem('prev-route')) {
              history.push(localStorage.getItem('prev-route'))
              localStorage.removeItem('prev-route')
            } else {
              history.push('/')
            }
          }
        }
      })
  }

  const renderServerErr = () => {
    return (
      <div className='ui error message'>
        <div className='header'>Please try again.</div>
        <p>{errMsg}</p>
      </div>
    )
  }

  const hideResetModal = () => {
    setShowResetModal(false)
  }

  const hideMfaModal = (note) => {
    setShowMfaModal(false)
    if (!note) {
      actions.signOut()
    }
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Sign In')} />
      <div className='signin'>
        <div className='signin-container'>
          <h1 className='signin-header'>Welcome Back</h1>
          <form
            className='ui grid'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='sixteen wide column'>
              <Field
                name='email'
                component={render.renderInput}
                label='Email'
                type='email'
                autoComplete='username'
                disabled={loading}
              />
            </div>
            <div className='sixteen wide column'>
              <Field
                name='password'
                component={render.renderInput}
                label='Password'
                type={showPassword ? 'text' : 'password'}
                autoComplete='current-password'
                disabled={loading}
                viewPassword={() =>
                  setShowPassword(prev => !prev)
                }
              />
            </div>
            <div className='sixteen wide column'>
              <Field
                name='terms'
                component={render.renderInput}
                type='checkbox'
                disabled={loading}
              ><span>
              I agree to the <a target='_blank' rel='noopener noreferrer' href='/terms-of-use'>Linqto Terms of Use</a> and {' '}
                  <a target='_blank' rel='noopener noreferrer' href='/privacy'>Privacy Policy</a>
                </span>
              </Field>
            </div>
            <div className='sixteen wide column'>
              {errMsg && renderServerErr()}
            </div>
            <div className='sixteen wide column'>
              <Button mode='primary' size='md' type='submit' loading={loading} disabled={loading}>Sign In with Email</Button>
              <div
                className='reset-content'
                onClick={() => setShowResetModal(true)}
              >
                Forgot your password?
              </div>
            </div>
          </form>
          <Button mode='secondary' size='md' onClick={() => history.push('/signUp')} loading={loading} disabled={loading}>Sign Up</Button>
        </div>
        {showResetModal && <ResetModal hideModal={hideResetModal} />}
        {showMfaModal && <MfaModal match={match} hideModal={hideMfaModal} />}
      </div>
    </>
  )
}

const validate = (formValues) => {
  const errors = {}
  if (!formValues.email) {
    errors.email = 'You must enter an email.'
  }
  if (!formValues.password) {
    errors.password = 'You must enter a password.'
  }
  if (!formValues.terms) {
    errors.terms = 'You must agree to the terms in order to proceed.'
  }
  return errors
}

const formWrapped = reduxForm({
  form: 'signInForm',
  validate
})(SignIn)

export default connected(formWrapped)
