import Modal from '../Global/Modal'
import { useEffect, useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import Input from '../Global/InputField'
import Button from '../Global/Button'
import { LinkBankModal as connected } from 'containers'

const LinkBankModal = ({ handleSubmit, plaidAccounts, actions, hideModal }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [message, setMessage] = useState('Save and Close')

  useEffect(() => {
    if (plaidAccounts && plaidAccounts.length) {
      const allAccountsLinked = plaidAccounts.every(acct => acct.linked)
      if (allAccountsLinked) {
        setError('All accounts are linked')
      } else {
        setMessage('Link Accounts')
      }
    }
  }, [plaidAccounts])

  const onSubmit = (formValues) => {
    const arrayToSend = []
    if (formValues && Object.keys(formValues).length === 0) {
      hideModal()
      return
    }
    plaidAccounts.forEach(ele => {
      if (formValues[ele.id]) {
        ele.label = ele.name
        arrayToSend.push(ele)
      }
    })
    setLoading(true)
    actions.upholdLinkPlaidAccounts(arrayToSend)
      .then((res) => {
        setLoading(false)
        // if res is error from uphold or plaid, tell user there is a problem in the modal
        // if success
        if (res === 200) {
          actions.getWalletPageAch()
        }
        hideModal()
      })
    // TODO: need to handle when failed to link account
  }

  return (
    <Modal mode='primary' size='md' modalHeader='Choose Accounts' className='link-account-modal' crossToClose hideModal={hideModal} pressToClose>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className=''>
          {plaidAccounts.map((a, id) => <Field
            name={`${a.id}`}
            component={renderInput}
            type='checkbox'
            disabled={a.linked ? a.linked : loading}
            key={a.id}
            className='link-account-checkbox'
            checked={a.linked}
          >
            <div className='left-group'>
              <div>{a.name}</div>
              <small>{a.type}</small>
            </div>
            <div>$ {a.available}</div>
          </Field>)}
        </div>
        <div style={{ textAlign: 'center', fontWeight: '600' }}>{error}</div>
        <div className='btn-group centered'>
          <Button size='md' mode='primary' type='submit' loading={loading} disabled={loading}>{message}</Button>
        </div>
      </form>
    </Modal>
  )
}

const renderInput = ({ input, label, placeholder, meta, type, autoComplete, className, disabled, children, checked }) => {
  return (
    <Input input={input} meta={meta} label={label} placeholder={placeholder} type={type} autoComplete={autoComplete} className={className} disabled={disabled} checked={checked}>{children}</Input>
  )
}

const validate = (formValues) => {
  const errors = {}
  return errors
}

const formWrapped = reduxForm({
  form: 'LinkBankForm',
  validate
})(LinkBankModal)

export default connected(formWrapped)
