import React from 'react'
import history from '../../history'
import { Link } from 'react-router-dom'
import { formatDate, formatDecimal, cleanSeriesName } from 'utils'

const label = {
  Rejected: 'red',
  Canceled: 'red',
  Pending: 'yellow',
  Expired: 'grey',
  Completed: 'teal',
  Transferring: 'olive',
  Funded: 'orange',
  Closed: 'grey'
}

class OrderList extends React.Component {
  renderOrder = (data) => {
    return data.map((order) => {
      return (
        <div
          className='order'
          key={order.orderId}
          onClick={() => history.push(`/order/${order.orderId}`)}
        >
          <div className='ui stackable padded grid'>
            <div className='two wide column'>
              <div className='sm-label'>Date</div>
              <div className='date'>{formatDate(order.createdAt)}</div>
            </div>
            <div className='three wide column' valign='middle'>
              <div className='sm-label'>Product</div>
              <div className='product'>{order.companyName}</div>
            </div>
            <div className='two wide column'>
              <div className='sm-label'>Series</div>
              <div className='series'>{cleanSeriesName(order.seriesName)}</div>
            </div>
            <div className='two wide column'>
              <div className='sm-label'>Total</div>
              <div className='total'>
                {formatDecimal(order.price)}
              </div>
            </div>
            <div className='two wide column'>
              <div className='sm-label'>Share Price</div>
              <div className='sharePrice'>
                {formatDecimal(order.sharePrice)}
              </div>
            </div>
            <div className='two wide column'>
              <div className='sm-label'>Shares</div>
              <div className='shares'>{formatDecimal(order.shares, false)}</div>
            </div>
            <div className='three wide column'>
              <div className='sm-label'>Status</div>
              <div className={`status label ${label[order.status]}`}>
                {order.status}
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  renderHeader = () => {
    return (
      <div className='ui stackable padded grid'>
        <div className='two wide column'>
          <div className='date'>Date</div>
        </div>
        <div className='three wide column'>
          <div className='product'>product</div>
        </div>
        <div className='two wide column'>
          <div className='series'>series</div>
        </div>
        <div className='two wide column'>
          <div className='total'>total</div>
        </div>
        <div className='two wide column'>
          <div className='sharePrice'>share price</div>
        </div>
        <div className='two wide column'>
          <div className='share'>shares</div>
        </div>
        <div className='three wide column'>
          <div className='status'>status</div>
        </div>
      </div>
    )
  }

  renderCompany = (company) => {
    return (
      <div className='order-list-title'>
        <Link
          to={{
            pathname: `/products/${company.urlName}`,
            state: { productId: company.companyId }
          }}
        >
          {company.name}
        </Link>
        <img
          className='company-Image'
          alt={`Linqto ${company.name} series`}
          src={company.s3IconUrl}
        ></img>
      </div>
    )
  }

  render () {
    return (
      <div className={`order-list-container ${!this.props.company ? 'recent-orders' : 'company'}`}>
        <div className='order-list'>
          <>
            {this.props.company
              ? this.renderCompany(this.props.company)
              : <h1 className='page-title'>Recent Orders</h1>}
          </>
          <div className='order-headers'>{this.renderHeader()}</div>
          <div className='orders'>
            {/* TODO use conditional rendering here  */}
            {(this.props.orders.length === 0 && this.props.company) ? (
              <div className='order no-data'>
                {`You haven't bought any of ${
                  this.props.company && this.props.company.name
                }. `}
                <Link
                  to={{
                    pathname: `/products/${this.props.company.urlName}`,
                    state: { productId: this.props.company.companyId }
                  }}
                >
                  Click here to buy now!
                </Link>
              </div>
            ) : (
              this.renderOrder(this.props.orders)
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default OrderList
