import { toCamalCase } from 'utils'

const parseOrders = o => {
  if (o.status && o.createdAt && o.paymentType && o.orderId) {
    return {
      ...o,
      status: !o.status.length ? '' : toCamalCase(o.status),
      createdAt: o.createdAt ? o.createdAt : '',
      expiresAt: o.expiresAt ? o.expiresAt : '',
      seriesName: o.seriesName ? o.seriesName : '',
      shares: o.shares ? o.shares : '',
      sharePrice: !isNaN(o.sharePrice) ? o.sharePrice : '',
      paymentType: !o.paymentType.length ? '' : toCamalCase(o.paymentType),
      upholdExchangeRate: o.upholdExchangeRate ? o.upholdExchangeRate.toFixed(4) : ' ',
      statusUpdatedAt: o.statusUpdatedAt ? o.statusUpdatedAt : '',
      amount: !isNaN(o.amount) ? o.amount : '',
      linqtoFee: !isNaN(o.linqtoFee) ? o.linqtoFee : '',
      total: o.total ? o.total : 0
    }
  }
  return {}
}

export default parseOrders
