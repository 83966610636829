import reduxConnect from './reduxConnect'
import mapActions from './mapActions'

const mapStateToProps = ({ wallet }) => ({
  plaidLinkToken: wallet.plaidLinkToken,
  plaidAccounts: wallet.plaidAccounts,
  isAccredited: wallet.isAccredited,
  hasAddBalanceAccountPermission: wallet.hasAddBalanceAccountPermission,
  accounts: wallet.accounts,
  dailyLimit: wallet.dailyLimit,
  dailyLimitUsed: wallet.dailyLimitUsed
})
const requiredActions = ['getUpholdPlaidLinkToken', 'walletPagePlaidAccountsRequest', 'getWalletPageAch', 'cleanPlaidAccounts', 'connectUphold']
const actions = mapActions(requiredActions)

const connected = component => reduxConnect(mapStateToProps, actions)(component)
export default connected
