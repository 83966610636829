import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Content, Header, Wrapper, Image } from 'components'

const Card = ({ p }) => (
  <Link key={p.companyId} to={`/products/${p.urlName}`}>
    <Content className='card'>
      <Row className='row'>
        <Image alt={`Linqto ${p.name} series`} src={p.iconUrl} />
        <Content className='info'>
          <span className='company-name'>{p.name}</span>
        </Content>
      </Row>
    </Content>
  </Link>
)

const TopListings = ({ homeProducts }) => (
  <Wrapper className='container top-listings-container'>
    <div className='inner-container'>
      <Header className='hero-header top-listing-header'>Our Top Listings</Header>
      <div className='ui grid'>
        <div className='doubling three column row stackable'>
          {homeProducts.map(product => (
            <div className='column' key={product.companyId}><Card key={product.companyId} p={product} /></div>
          ))}
        </div>
      </div>
    </div>
  </Wrapper>
)

export default TopListings
