import { Wrapper } from 'components'
import InvestDetailAboutHeader from './InvestDetailAboutHeader'
import InvestDetailAboutKeyFacts from './InvestDetailAboutKeyFacts'

const InvestDetailAbout = ({ company }) => {
  return (
    <Wrapper className='invest-tab'>
      <div className='ui grid outer-container'>
        <InvestDetailAboutHeader company={company} />
        <div className='one column mobile-one row outer-container'>
          <div className='column '>
            {company.keyFacts &&
            <div className='grey-background rounded'>
              <div className='title'>Key Facts</div>
              <div className='desc two-cols-text'>
                <InvestDetailAboutKeyFacts keyFacts={company.keyFacts} />
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
export default InvestDetailAbout
